import { createReducer, on } from "@ngrx/store";
import { HomePageModuleState } from "../state/home";
import * as fromActions from './../action/home.actions';

export const initialState: HomePageModuleState = {
    homePage: {
        data: null,
        error: null,
        loaded: false,
        loading: false
    },
    gwcHomePageData: {
        data: null,
        error: null,
        loaded: false,
        loading: false
    }
};


export const homeReducer = createReducer<HomePageModuleState>(
    initialState,
    on(fromActions.LoadHomepageData, (state) => ({
        ...state,
        homePage: {
            ...state.homePage,
            loading: true,
            error: null
        }
    })),
    on(fromActions.LoadHomepageDataSuccess, (state, { html }) => ({
        ...state,
        homePage: {
            ...state.homePage,
            data: html,
            loading: false,
            loaded: true,
            error: null
        }
    })),
    on(fromActions.LoadHomepageDataError, (state, { error }) => ({
        ...state,
        homePage: {
            ...state.homePage,
            data: null,
            loading: false,
            error: error
        }
    })),
    on(fromActions.LoadGWCHomepageData, (state) => ({
        ...state,
        gwcHomePageData: {
            ...state.gwcHomePageData,
            loading: true,
            error: null
        }
    })),
    on(fromActions.LoadGWCHomepageDataSuccess, (state, { data }) => ({
        ...state,
        gwcHomePageData: {
            ...state.gwcHomePageData,
            data: data,
            loading: false,
            loaded: true,
            error: null
        }
    })),
    on(fromActions.LoadGWCHomepageDataError, (state, { error }) => ({
        ...state,
        gwcHomePageData: {
            ...state.gwcHomePageData,
            data: null,
            loading: false,
            error: error
        }
    })),
);