import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlComponent } from './html.component';
import { DirectiveModule } from 'src/app/directive/directive.module';



@NgModule({
  declarations: [
    HtmlComponent
  ],
  imports: [
    CommonModule,
    DirectiveModule
  ],
  exports: [
    HtmlComponent
  ]
})
export class HtmlModule {
}
