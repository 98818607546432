import { Injectable } from '@angular/core';

import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable()
export class ProfileModuleLanguageResolver implements Resolve<LocaleResourceSet> {

    constructor(
        private translateService: TranslateService,
        private localResourceService: LocalResourceService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {


        //------------------------------------------------------------
        // Profile Page
        this.translateService.set('myprofile.profile-category', 'Profile Category', 'en');
        this.translateService.set('myprofile.fas-fa-info-circle', 'fas fa-info-circle', 'en');
        this.translateService.set('myprofile.fas-fa-plus-circle', 'fas fa-plus-circle', 'en');
        this.translateService.set('myprofile.fas-fa-briefcase', 'fas fa-briefcase', 'en');
        this.translateService.set('myprofile.fas-fa-highlighter', 'fas fa-highlighter', 'en');
        this.translateService.set('myprofile.fas-fa-list-alt', 'fas fa-list-alt', 'en');
        this.translateService.set('myprofile.add', 'Add', 'en');
        this.translateService.set('myprofile.save', 'Save', 'en');
        this.translateService.set('myprofile.cancel', 'Cancel', 'en');
        this.translateService.set('myprofile.skills', 'Skills', 'en');
        this.translateService.set('myprofile.search-for-skills', 'Search for Skills', 'en');
        this.translateService.set('myprofile.your-skills', 'Your Skills', 'en');
        this.translateService.set('myprofile.choose-your-skills-from-list', 'Choose Your Skills from list', 'en');
        this.translateService.set('myprofile.interests', 'Interests', 'en');
        this.translateService.set('myprofile.search-for-interests', 'Search for Interests', 'en');
        this.translateService.set('myprofile.your-interests', 'your Interests', 'en');
        this.translateService.set('myprofile.choose-your-interests-from-list', 'choose your Interests from list', 'en');
        this.translateService.set('myprofile.you-dont-have-any-similar-matches-Please-add-Skills-interests-to-get-your-matches', 'You dont have any similar matches. Please add skills and interests to get your matches', 'en');
        this.translateService.set('myprofile.similar-skills', 'Similar Skills', 'en');
        this.translateService.set('myprofile.interests', 'Interests', 'en');
        this.translateService.set('myprofile.fas-fa-heart', 'fas fa-heart', 'en');
        this.translateService.set('myprofile.fas-fa-star', 'fas fa-star', 'en');
        this.translateService.set('myprofile.portfolio', 'Portfolio', 'en');
        this.translateService.set('myprofile.link', 'Link', 'en');
        this.translateService.set('myprofile.title', 'Title', 'en')
        this.translateService.set('myprofile.organization-details', 'Organization Details', 'en')
        this.translateService.set('myprofile.edit-profile', 'Edit Profile', 'en')
        this.translateService.set('myprofile.edit-organization-details', 'Edit Organization Details', 'en')
        this.translateService.set('myprofile.change-picture', 'Change Picture', 'en')
        this.translateService.set('myprofile.first-name', 'First Name', 'en')
        this.translateService.set('myprofile.first-name.placeholder', 'First Name', 'en')
        this.translateService.set('myprofile.last-name', 'Last Name', 'en')
        this.translateService.set('myprofile.last-name.placeholder', 'Last Name', 'en')
        this.translateService.set('myprofile.professional-summary', 'Professional Summary', 'en')
        this.translateService.set('myprofile.professional-summary.placeholder', 'Professional Summary Details', 'en')
        this.translateService.set('myprofile.job-title', 'Job Title', 'en')
        this.translateService.set('myprofile.job-title.placeholder', 'Job Title', 'en')
        this.translateService.set('myprofile.phone-number', 'Phone Number', 'en')
        this.translateService.set('myprofile.extension', 'Extension', 'en')
        this.translateService.set('myprofile.extension.placeholder', 'Extension', 'en')
        this.translateService.set('myprofile.profile-video', 'Profile Video', 'en')
        this.translateService.set('myprofile.profile-video.placeholder', 'https://www.youtube.com/watch?v=xxxxxxxxxxx', 'en')
        this.translateService.set('myprofile.organization-name', 'Organization Name', 'en')
        this.translateService.set('myprofile.enter-organization-name.placeholder', 'Enter Organization Name', 'en')
        this.translateService.set('myprofile.enter-organization-name-or-select-from-existing.placeholder', 'Enter Organization Name', 'en')
        this.translateService.set('myprofile.email-id', 'Email ID', 'en')
        this.translateService.set('myprofile.email.placeholder', 'Email', 'en')
        this.translateService.set('myprofile.phone-number', 'Phone Number', 'en')
        this.translateService.set('myprofile.ext', 'Ext', 'en')
        this.translateService.set('myprofile.extension.placeholder', 'Extension', 'en')
        this.translateService.set('myprofile.fax', 'FAX', 'en')
        this.translateService.set('myprofile.fax.placeholder', 'FAX', 'en')
        this.translateService.set('myprofile.no-of-employees', 'no.of Employees', 'en')
        this.translateService.set('myprofile.select-number-of-employees.placeholder', 'Select Number of Employees', 'en')
        this.translateService.set('myprofile.business-description', 'Business Description', 'en')
        this.translateService.set('myprofile.you-entered', 'You Entered', 'en')
        this.translateService.set('myprofile.of', 'of', 'en')
        this.translateService.set('myprofile.facebook-profile', 'Facebook Profile', 'en')
        this.translateService.set('myprofile.facebook-profile.placeholder', 'https://www.facebook.com/profile/', 'en')
        this.translateService.set('myprofile.twitter-profile', 'Twitter Profile', 'en')
        this.translateService.set('myprofile.twitter-profile.placeholder', 'https://www.twitter.com/profile/', 'en')
        this.translateService.set('myprofile.linkedin-profile', 'Linkedin Profile', 'en')
        this.translateService.set('myprofile.linkedin-profile.placeholder', 'https://www.linkedin.com/profile/', 'en')
        this.translateService.set('myprofile.instagram-profile', 'Instagram Profile', 'en')
        this.translateService.set('myprofile.instagram-profile.placeholder', 'https://www.instagram.om/profile/', 'en')
        this.translateService.set('myprofile.website-url', 'Website URL', 'en')
        this.translateService.set('myprofile.website-url.placeholder', 'https://organization.com/', 'en')
        this.translateService.set('myprofile.address', 'Address', 'en')
        this.translateService.set('myprofile.address-line-1', 'Address Line 1', 'en')
        this.translateService.set('myprofile.address-line-1.placeholder', 'Street Number, Street Name', 'en')
        this.translateService.set('myprofile.city', 'City', 'en')
        this.translateService.set('myprofile.city.placeholder', 'City', 'en')
        this.translateService.set('myprofile.state', 'State', 'en')
        this.translateService.set('myprofile.state.placeholder', 'State', 'en')
        this.translateService.set('myprofile.zip-code', 'Zip Code', 'en')
        this.translateService.set('myprofile.zip-code.placeholder', 'Zip Code', 'en')
        this.translateService.set('myprofile.country', 'Country', 'en')
        this.translateService.set('myprofile.country.placeholder', 'Country', 'en')
        this.translateService.set('myprofile.cancel', 'Cancel', 'en')
        this.translateService.set('myprofile.business-categories', 'Business Categories', 'en')
        this.translateService.set('myprofile.add.business-categories', 'Add Business Categories', 'en')
        this.translateService.set('myprofile.select-category', 'Select Category', 'en')
        this.translateService.set('myprofile.update', 'Update', 'en')
        this.translateService.set('myprofile.business-types', 'Business Types', 'en')
        this.translateService.set('myprofile.add-business-types', 'Add Business Types', 'en')
        this.translateService.set('myprofile.select-type', 'Select Type', 'en')
        this.translateService.set('myprofile.add-location', 'Add Location', 'en')
        this.translateService.set('myprofile.location-name', 'Location Name', 'en')
        this.translateService.set('oops-we-did-not-find-any-locations-for-this-organization:(', 'Oops we did not find any locations for this Organization ', 'en')
        this.translateService.set('myprofile.manage-locations', 'Manage Locations ', 'en')
        this.translateService.set('myprofile.assets/images/member-directory/md/noresult.png', 'assets/images/member-directory/md-noresult.png', 'en')
        this.translateService.set('myprofile-please-add-new-location', 'Please add new Location', 'en')
        this.translateService.set('myprofile.manage-members', 'Manage Members', 'en')
        this.translateService.set('myprofile.search-by-member-name.placeholder', 'Search by member name', 'en')
        this.translateService.set('myprofile.search', 'Search ', 'en')
        this.translateService.set('myprofile.delete', 'Delete ', 'en')
        this.translateService.set('myprofile.edit', 'Edit ', 'en')
        this.translateService.set('myprofile.reset', 'Reset ', 'en')
        this.translateService.set('myprofile.loading', 'Loading... ', 'en')
        this.translateService.set('myprofile-oops-we-did-not-find-anything-that-matches-this-search:(', 'Oops we did not find anything that matches this search:(', 'en')
        this.translateService.set('myprofile.primary-contact', 'Primary Contact', 'en')
        this.translateService.set('myprofile.active', 'Active', 'en')
        this.translateService.set('myprofile.registered', 'Registered', 'en')
        this.translateService.set('myprofile.professional-matches', 'Professional Matches', 'en')
        this.translateService.set('myprofile.edit-services', 'Edit Services', 'en')
        this.translateService.set('myprofile.services-you-offer', 'Services You Offer', 'en')
        this.translateService.set('myprofile.select-category', 'Select Category', 'en')
        this.translateService.set('myprofile.add-services-you-offer', 'Add Services You Offer (you can add up to 25 Categories)', 'en')
        this.translateService.set('myprofile.services-you.need', 'Services You Need', 'en')
        this.translateService.set('myprofile.add-services-you-need', 'Add Services You Need (you can add up to 25 Categories)', 'en')
        this.translateService.set('myprofile.companies-interested-in-your-services', 'Companies Interested in your Services', 'en')
        this.translateService.set('myprofile.view-primary-contact', 'View Primary Contact', 'en')
        this.translateService.set('myprofile.companies-you-may-be-interested-in', 'Companies You May Be Interested In', 'en')
        this.translateService.set('myprofile.your-organizational-services-are-not-set-up-please-add-organizational-services-to-see-the-leads.', 'Your Organizational services are not set up. Please add Organizational Services to see the leads.', 'en')
        this.translateService.set('myprofile.sell-lead-text', '(Select what you sell. We will find you the leads.)', 'en')
        this.translateService.set('myprofile.need-the-companies', '(Select what you need. We will find you the companies which offer the services you need.)', 'en')
        this.translateService.set('myprofile.media', 'Media', 'en')
        this.translateService.set('myprofile.publications', 'Publications', 'en')
        this.translateService.set('myprofile.add-profile-categories', 'Add profile categories', 'en')
        this.translateService.set('myprofile.user-updated-successfully', 'User Details Updated Successfully', 'en')
        this.translateService.set('myprofile.updating', 'Updating...', 'en')
        this.translateService.set('myprofile.info-deleted-successfully', 'Media & Publications information deleted Successfully', 'en')
        this.translateService.set('myprofile.no-skills-added', 'No Skills Added', 'en')
        this.translateService.set('myprofile.no-interests-added', 'No Interests Added', 'en')
        this.translateService.set('myprofile.no-skills-found', 'No Skills Found', 'en')
        this.translateService.set('myprofile.profile-category-saved-successfully', 'Profile Category Saved Successfully', 'en')
        this.translateService.set('myprofile.saving', 'Saving...', 'en')
        this.translateService.set('myprofile.deleting', 'Deleting...', 'en')
        this.translateService.set('myprofile.user-skills-saved-successfully', 'User skills Saved Successfully', 'en')
        this.translateService.set('myprofile.user-interests-saved-successfully', 'User Interests Saved Successfully', 'en')
        this.translateService.set('myprofile.my-profile', 'My Profile', 'en')
        this.translateService.set('myprofile.info-updated-successfully', 'Media & Publications information Updated Successfully', 'en')
        this.translateService.set('myprofile.info-saved-successfully', 'Media & Publications information Saved Successfully', 'en')
        this.translateService.set('myprofile.sending-connection-request', 'Sending connection request...', 'en')
        this.translateService.set('myprofile.canceling-connection-request', 'Canceling connection request...', 'en')
        this.translateService.set('myprofile.processing-your-request', 'Processing your request...', 'en')
        this.translateService.set('myprofile.connect', 'Connect', 'en')
        this.translateService.set('myprofile.pending', 'Pending', 'en')
        this.translateService.set('myprofile.this-profile-is-private', 'This profile is private. Stay connected to see their profile.', 'en')
        this.translateService.set('myprofile.change-password', 'Change Password', 'en')
        this.translateService.set('myprofile.new-password', 'New Password', 'en')
        this.translateService.set('myprofile.enter-new-password.placeholder', 'New Password', 'en')
        this.translateService.set('myprofile.confirm-password', 'Confirm Password', 'en')
        this.translateService.set('myprofile.confirm-your-password.placeholder', 'Confirm Password', 'en')
        this.translateService.set('myprofile.it-is-better-to-have', 'It is better to have', 'en')
        this.translateService.set('myprofile.must-be-at-least-8-characters-long', 'Must be at least 8 characters long', 'en')
        this.translateService.set('myprofile-must-include-at-least-one-the-uppercase-letter', 'Must include at least one the uppercase letter', 'en')
        this.translateService.set('myprofile.must-include-at-least-one-the-lowercase-letter', 'Must include at least one the lowercase letter', 'en')
        this.translateService.set('myprofile.must-include-at-least-one-number', 'Must include at least one number', 'en')
        this.translateService.set('myprofile.must-include-at-least-one-non-alphanumeric-character', 'Must include at least one non-alphanumeric character', 'en')
        this.translateService.set('myprofile.password-changed-sucessfully', 'Password changed sucessfully', 'en')
        this.translateService.set('myprofile.ok', 'OK', 'en')
        this.translateService.set('myprofile.unable-to-change-password-please-try-again-later', 'Unable to change password, Please try again later', 'en')
        this.translateService.set('myprofile.password-should-be-contain-atleast-1-upper-case', 'Password should be contain atleast 1 upper case', 'en');
        this.translateService.set('myprofile.password-should-be-contain-atleast-1-lower-case', 'Password should be contain atleast 1 lower case', 'en');
        this.translateService.set('myprofile.password-should-be-contain-atleast-1-special-character', 'Password should be contain atleast 1 special character', 'en');
        this.translateService.set('myprofile.password-should-contain-atleast-1-digit', 'Password should contain atleast 1 digit', 'en');
        this.translateService.set('myprofile.not-match', 'the password and its confirm password is not same', 'en');
        this.translateService.set('myprofile.profile-completeness', 'Profile Completeness', 'en');
        this.translateService.set('myprofile.profile-categories', 'Profile Categories', 'en');
        this.translateService.set('myprofile.profile-picture', 'Profile Picture', 'en');
        this.translateService.set('myprofile.send-a-message', 'Send a message', 'en');
        this.translateService.set('myprofile.message', 'Message', 'en');
        this.translateService.set('myprofile.no-interests-found', 'No Interests Found', 'en');
        this.translateService.set('myprofile.sending-endorsement', 'Sending Endorsement...', 'en');
        this.translateService.set('myprofile.adding-notes', 'adding Notes...', 'en');
        this.translateService.set('myprofile.updating-notes', 'Updating Notes...', 'en');
        this.translateService.set('myprofile.deleting-notes', 'Deleting Notes...', 'en');
        this.translateService.set('myprofile.please-enter-valid-first-name', 'Please Enter Valid First Name', 'en');
        this.translateService.set('myprofile.please-enter-valid-last-name', 'Please Enter Valid Last Name', 'en');
        this.translateService.set('myprofile.please-enter-valid-job-title', 'Please Enter Valid Job Title', 'en');
        this.translateService.set('myprofile.please-enter-youtube-url-only', 'Please enter Youtube URL only', 'en');
        this.translateService.set('myprofile.facebook-url-is-invalid', 'Facebook URL is Invalid', 'en');
        this.translateService.set('myprofile.twitter-url-is-invalid', 'Twitter URL is Invalid', 'en');
        this.translateService.set('myprofile.linkedin-url-is-invalid', 'LinkedIn URL is Invalid', 'en');
        this.translateService.set('myprofile.instagram-url-is-invalid', 'Instagram URL is Invalid', 'en');
        this.translateService.set('myprofile.please-enter-valid-portfolio-link', 'Please enter valid Portfolio Link', 'en');
        this.translateService.set('myprofile.profile-updated-successfully', 'Profile details updated successfully', 'en');
        this.translateService.set('myprofile.unable-to-cancel-connection-request-please-try-again', 'Unable to cancel connection request, please try again', 'en');
        this.translateService.set('myprofile.unable-to-sent-connection-request-please-try-again', 'Unable to sent connection request, please try again', 'en');
        this.translateService.set('myprofile.connection-request-canceled-successfully', 'Connection request canceled successfully', 'en');
        this.translateService.set('myprofile.connection-request-sent-successfully', 'Connection request sent successfully', 'en');
        this.translateService.set('myprofile.connection-request-canceled-by-user', 'Connection request canceled by user', 'en');
        this.translateService.set('myprofile.unable-to-save-user-skills', 'Unable to Save User Skills', 'en');
        this.translateService.set('myprofile.unable-to-save-profile-category', 'Unable to Save Profile Category', 'en');
        this.translateService.set('myprofile.unable-to-update-portfolio', 'Unable to update Portfolio', 'en');
        this.translateService.set('myprofile.unable-to-delete-portfolio', 'Unable to delete Portfolio', 'en');
        this.translateService.set('myprofile.unable-to-save-portfolio', 'Unable to save Portfolio', 'en');
        this.translateService.set('myprofile.unable-to-update-user', 'Unable to update-user', 'en');
        this.translateService.set('myprofile.endorsements', 'Endorsements', 'en');
        this.translateService.set('myprofile.notes', 'Notes', 'en');
        this.translateService.set('myprofile.add-endorsements', 'Add Endorsements', 'en');
        this.translateService.set('myprofile.add-notes', 'Add Notes', 'en');
        this.translateService.set('myprofile.remainder-date', 'Reminder date', 'en');
        this.translateService.set('myprofile.set-a-remainder', 'Set a Reminder', 'en');
        this.translateService.set('myprofile.note', 'Note', 'en');
        this.translateService.set('myprofile.title', 'Title', 'en');
        this.translateService.set('myprofile.sending-endorsements', 'Sending Endorsements...', 'en');
        this.translateService.set('myprofile.send', 'Send', 'en');
        this.translateService.set('myprofile.content', 'Content', 'en');
        this.translateService.set('myprofile.there-are-no-endorsements-to-display', 'There are no endorsements to display', 'en');
        this.translateService.set('myprofile.there-are-no-categories-to-display', 'There are no profile categories display', 'en');
        this.translateService.set('myprofile.there-are-no-types-to-display', 'There are no categories to display', 'en');
        this.translateService.set('myprofile.there-are-no-portfolio-to-display', 'There are no media & publications to display', 'en');
        this.translateService.set('myprofile.there-are-no-notes-to-display', 'There are no notes to display', 'en');
        this.translateService.set('myprofile.there-are-no-skills-to-display', 'There are no skills to display', 'en');
        this.translateService.set('myprofile.there-are-no-interests-to-display', 'There are no interests to display', 'en');
        this.translateService.set('myprofile.there-are-no-bio-to-display', 'There are no professional summary to display', 'en');
        this.translateService.set('myprofile.notes-delete', 'notes delete', 'en');
        this.translateService.set('myprofile.endorsement-sent-successfully', 'Endorsement Sent Successfully', 'en');
        this.translateService.set('myprofile.unable-to-sent-endorsement', 'Unable to Sent Endorsement', 'en');
        this.translateService.set('myprofile.user-notes-added-successfully', 'User Notes Added Successfully', 'en');
        this.translateService.set('myprofile.unable-to-save-user-notes', 'Unable to Save User Notes', 'en');
        this.translateService.set('myprofile.user-notes-updated-successfully', 'User Notes Updated Successfully', 'en');
        this.translateService.set('myprofile.unable-to-update-user-notes', 'Unable to Update User Notes', 'en');
        this.translateService.set('myprofile.user-notes-deleted-successfully', 'User Notes deleted Successfully', 'en');
        this.translateService.set('myprofile.unable-to-delete-user-notes', 'Unable to Delete User Notes', 'en');
        this.translateService.set('myprofile.business-description.placeholder', 'Business description must be less than 500 characters long', 'en');
        this.translateService.set('myprofile.select-location', 'Select Location', 'en');
        this.translateService.set('myprofile.location-type', 'Location Type', 'en');
        this.translateService.set('myprofile.location-status', 'Location Status', 'en');
        this.translateService.set('myprofile.password', 'Password', 'en');
        this.translateService.set('myprofile.contact-type', 'Contact Type', 'en');
        this.translateService.set('myprofile.primary', 'Primary', 'en');
        this.translateService.set('myprofile.secondary', 'Secondary', 'en');
        this.translateService.set('myprofile.user-status', 'User Status', 'en');
        this.translateService.set('myprofile.active', 'Active', 'en');
        this.translateService.set('myprofile.suspended', 'Suspended', 'en');
        this.translateService.set('myprofile.add-member', 'Add Member', 'en');
        this.translateService.set('myprofile.inactive', 'InActive', 'en');
        this.translateService.set('myprofile.try-search-for-something', 'Try Search for something more general, change the filters.', 'en');
        this.translateService.set('myprofile.approve', 'Approve', 'en');
        this.translateService.set('myprofile.reject', 'Reject', 'en');
        this.translateService.set('myprofile.rejected', 'Rejected', 'en');
        this.translateService.set('myprofile.waiting-for-approval', 'Waiting for Approval', 'en');
        this.translateService.set('myprofile.no-matches-found', 'No Matches Found', 'en');
        this.translateService.set('myprofile.unfortunately-there-are-no-matches', 'Unfortunately there are no matches at this time. Check back soon!', 'en');
        this.translateService.set('myprofile.there-are-no-organizations-seeking-your-services', 'There are no Organizations seeking your services at this time. Check back soon and remember to keep your profile updated.', 'en');
        this.translateService.set('myprofile.organization-details', 'Organization Details', 'en');
        this.translateService.set('myprofile.please-enter-valid-location-name', 'Please enter valid Location name', 'en');
        this.translateService.set('myprofile.please-enter-valid-address', 'Please Enter Valid Address', 'en');
        this.translateService.set('myprofile.please-enter-valid-address-line-1', 'Please Enter Valid Address Line 1', 'en');
        this.translateService.set('myprofile.please-enter-valid-city', 'Please Enter Valid City', 'en');
        this.translateService.set('myprofile.please-enter-valid-state', 'Please Enter Valid State', 'en');
        this.translateService.set('myprofile.please-enter-valid-zipcode', 'Please Enter Valid ZipCode', 'en');
        this.translateService.set('myprofile.please-enter-valid-country', 'Please Enter Valid Country', 'en');
        this.translateService.set('myprofile.website-url-is-invalid', 'Website URL is Invalid', 'en');
        this.translateService.set('myprofile.change-password-tooltip', 'Change your password', 'en');
        this.translateService.set('myprofile.change-password-tooltip-disable-social-account', 'Change password is not available on social authentication', 'en');
        this.translateService.set('myprofile.newpassword', 'newPassword', 'en')
        this.translateService.set('myprofile.changepassword-image-1', 'assets/images/signup/change-password-01.png', 'en')
        this.translateService.set('myprofile.changepassword-image-2', 'assets/images/signup/change-password-02.png', 'en')
        this.translateService.set('myprofile.add-various-keywords', 'Add various keywords to describe yourself, i.e.,  Mentor, Entrepreneur, etc', 'en')
        this.translateService.set('myprofile.add-a-link-to-media-publications-about-you', 'Add a link to Media & Publications about you', 'en')
        this.translateService.set('myprofile.add-various-keywords-to-describe-your-skills', 'Add various keywords to describe your skills', 'en')
        this.translateService.set('myprofile.add-various-keywords-to-describe-your-interests', 'Add various keywords to describe your Interests', 'en')
        this.translateService.set('myprofile.see-members-whose-skills-and-interests-are-similar-matching-to-yours', 'See members whose skills and interests are similar/matching to yours', 'en')
        this.translateService.set('myprofile.address-line-2', 'Address Line 2', 'en')
         // latest changes
        this.translateService.set('myprofile.endorsement-image', 'assets/images/my-profile/endorsements.png', 'en')
        this.translateService.set('myprofile.notes-image', 'assets/images/my-profile/notes.png', 'en')
        this.translateService.set('myprofile.new-user-added-successfully', 'New User Added Successfully', 'en')
        this.translateService.set('myprofile.unable-to-add-new-user', 'Unable to Add New User', 'en')
        this.translateService.set('myprofile.unable-to-update-new-user', 'Unable to Update New User', 'en')
        this.translateService.set('myprofile.location-added-successfully', 'Location Added Successfully', 'en')
        this.translateService.set('myprofile.unable-to-add-location', 'Unable to Add Location', 'en')
        this.translateService.set('myprofile.location-updated-successfully', 'Organization Location Updated Successfully', 'en')
        this.translateService.set('myprofile.unable-to-update-location', 'Unable to Update Location', 'en')
        this.translateService.set('myprofile.unable-to-fetch-no-of-types-list', 'Unable to fetch no of business types list', 'en')
        this.translateService.set('myprofile.unable-to-fetch-no-of-business-category-list', 'Unable to fetch no of business category list', 'en')
        this.translateService.set('myprofile.unable-to-fetch-no-of-employee-list', 'Unable to fetch no of employee list', 'en')
        this.translateService.set('myprofile.close', 'Close', 'en')
        this.translateService.set('myprofile.please-enter-valid-organization-name', 'Please Enter Valid Organization Name', 'en')
        this.translateService.set('myprofile.error-while-loading-organization', 'Error while loading organization', 'en')
        this.translateService.set('myprofile.unable-to-fetch-organization-details', 'Unable to fetch organization details', 'en')
        this.translateService.set('myprofile.business-categories-added-successfully', 'Business Categories Added Successfully', 'en')
        this.translateService.set('myprofile.unable-add-business-categories', 'Unable to Add Business Categories', 'en')
        this.translateService.set('myprofile.business-types-added-successfully', 'Business types Added Successfully', 'en')
        this.translateService.set('myprofile.organization-info-updated-successfully', 'Organization information Updated Successfully', 'en')
        this.translateService.set('myprofile.unable-to-update-organization-info', 'Unable to Update Organization Info', 'en')
        this.translateService.set('myprofile.professional-matches-updated-successfully', 'Professional Matches Updated Successfully', 'en')
        this.translateService.set('myprofile.unable-to-update-professional-matches', 'Unable to Update Professional Matches', 'en')
        this.translateService.set('myprofile.user-approve-reject-successfully', 'User Approve/Reject Successfully', 'en')
        this.translateService.set('myprofile.unable-to-approve-reject-user', 'Unable to Approve/Reject User', 'en')
        // Latest changes
        this.translateService.set('myprofile.there-are-no-business-categories-to-display', 'There are no business types to display', 'en')
        this.translateService.set('myprofile.there-are-no-business-to-display', 'There are no business categories to display', 'en')
        this.translateService.set('my-profile.location-cannot-be-made-inactive', 'This location cannot be made inactive, as Contacts are mapped to this location.', 'en')
        this.translateService.set('myprofile.my-matches', 'My Matches', 'en')
        this.translateService.set('myprofile.matched-skills', 'Matched Skills', 'en')
        this.translateService.set('myprofile.matched-interests', 'Matched Interests', 'en')
        this.translateService.set('myprofile.registered','Registered', 'en');
        this.translateService.set('myprofile.matched-skills-not-found', 'Matched Skills Not Found', 'en')
        this.translateService.set('myprofile.matched-interests-not-found', 'Matched Interests Not Found', 'en')
        this.translateService.set('myprofile.you-can-choose-upto-business-categories', 'You can choose upto 25 business categories', 'en')
        this.translateService.set('myprofile.atleast-one-business-categories', 'At least one business category is required', 'en')
        this.translateService.set('myprofile.you-can-choose-business-categories', 'You can choose upto 5 business types', 'en')
        this.translateService.set('myprofile.accept', 'Accept', 'en')
        this.translateService.set('myprofile.unable-to-fetch-no-of-business', 'Unable to fetch no of business category list', 'en')
        this.translateService.set('myprofile.error-in-fetching-profile', 'Error in fetching profile category', 'en')
        this.translateService.set('myprofile.biography', 'biography', 'en')

        return this.localResourceService.loadLocalResource("myprofile").pipe(
            tap(resp => {
                resp.data.forEach(data => {
                    this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
                });
            })
        );
    }
}
