import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class HelpModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('help.frequently-asked-questions', 'Frequently Asked Questions', 'en');
    this.translateService.set('help.search-by-keywords.placeholder', 'Search by keywords', 'en');
    this.translateService.set('help.sorry', 'Sorry, we could not find any results for this search.', 'en');
    this.translateService.set('help.loading', 'Loading....', 'en');
    this.translateService.set('help.page-name-help', 'Help', 'en');

   // ----------------------------------------------------------------
    return this.localResourceService.loadLocalResource("help").pipe(
      tap(resp => {
          resp.data.forEach(data => {
              this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
          });
      })
  );
  }
}
