import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Subject, take, takeUntil } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { SelectDropdown } from 'src/app/models/common/listItem';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
@Component({
  selector: 'bzg-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MultiSelectComponent
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'customMatSelectOverlayPaneZ' },
    },
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class MultiSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor, MatFormFieldControl<string | string[]>, OnDestroy {
  static nextId = 0;
  // public selectCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public selectFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredSelect: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);




  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
  @Input('list') list: SelectDropdown[];
  @Input('multiple') multiple: boolean = false;
  @Input('maxSelection') maxSelection?: number;
  @Input('label') label: string = "Select";
  //@Input('control') control?: AbstractControl;
  protected _onDestroy = new Subject();
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'multiselect';
  id = `multi-select-custom-${MultiSelectComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };
  form: FormGroup = this._formBuilder.group({
    select: [null]
  });

  get empty() {
    const {
      value: { select },
    } = this.form;

    return !select;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;


  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | string[] | null {
    return this.form?.value.select || null;
  }
  set value(value: string | string[] | null) {
    this.form?.setValue({ select: value || null });
    this.stateChanges.next();
  }


  get elementName(): string {
    return this._elementRef.nativeElement.getAttribute("name");
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched && this.form.dirty;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private cd: ChangeDetectorRef,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }


  ngAfterViewInit(): void {
    let interval = setInterval(() => {
      if (this.list && this.value) {
        if (this.multiple) {
          this.selectedMultiple(this.value as string[]);
        } else {
          this.selectedSingle(this.value as string);
        }
        clearInterval(interval);
      }
    }, 100);
  }

  clearValue(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.ngControl.control.markAsDirty();
    this.ngControl.control.markAsTouched();
    this.value = null;
    this.onChange(this.value);
  }
  ngOnInit() {


    let ctrl = this.form.get('select');

    if (this.ngControl?.control.hasValidator(Validators.required)) {
      ctrl.addValidators(Validators.required);
      ctrl.updateValueAndValidity();
      this.required = true;
    }
    // load the initial bank list
    this.filteredSelect.next(this.list?.slice(0, 30));

    // listen for search field value changes
    this.selectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterList();
      });

    this.ngControl.control.statusChanges.pipe(takeUntil(this._onDestroy)).subscribe(status => {
      this.markControl();
    });
    this.ngControl.control.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
      this.value = value;
      this.markControl();
    });
  }

  markControl() {
    if (this.ngControl?.control.touched) {
      this.form.get('select').markAsTouched();
    }
    if (this.ngControl?.control.untouched) {
      this.form.get('select').markAsUntouched();
    }
    if (this.ngControl?.control.dirty) {
      this.form.get('select').markAsDirty();
    }
    if (this.ngControl?.control.pristine) {
      this.form.get('select').markAsPristine();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
    this._onDestroy.next(null);
    this._onDestroy.complete();
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      'mat-select',//'input', 
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    this.multiSelect.focus();
    event.preventDefault();
    event.stopPropagation();
    this.filterList();
  }

  writeValue(tel: string | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }
  /**
   * Write code on Method
   *
   * method logical code
   */

  selected(event: MatSelectChange) {
    if (this.multiple) {
      this.selectedMultiple(event.source.value);

    } else {
      this.selectedSingle(event.source.value);
    }
    this.filterList();
    this.onChange(this.value);
  }

  selectedMultiple(value: string[]) {
    if (this.list) {
      let currentSelectedItems = value;
      let currentSelectedItemsArray = this.list.filter(({ id: id }) => currentSelectedItems.some(id2 => id == id2));
      let unchecked = this.list.filter(({ id: id1 }) => !currentSelectedItems.some((id2) => id2 === id1));
      let currentIndex = this.list.findIndex(x => x.id === value[0]);
      let fromIndex = currentIndex - 15 < 0 ? 0 : currentIndex - 15;
      let toIndex = currentIndex + 15 > this.list.length ? this.list.length : currentIndex + 15;
      this.filteredSelect.next([...currentSelectedItemsArray, ...unchecked.slice(fromIndex, toIndex)]);
    }
  }

  selectedSingle(value: string) {
    if (this.list) {
      let currentIndex = this.list.findIndex(x => x.id === value);
      let fromIndex = currentIndex - 15 < 0 ? 0 : currentIndex - 15;
      let toIndex = currentIndex + 15 > this.list.length ? this.list.length : currentIndex + 15;
      this.filteredSelect.next(this.list.slice(fromIndex, toIndex));
    }
  }

  /**
   * Write code on Method
   *
   * method logical code
   */
  protected filterList() {

    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.selectFilterCtrl.value;
    if (!search) {
      if (this.value == "" || this.value == null) {
        this.filteredSelect.next(
          this.list.slice(0, 30)
        );
      } else {
        if (this.multiple) {
          this.selectedMultiple(this.value as string[]);
        } else {
          this.selectedSingle(this.value as string);
        }
      }
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSelect.next(
      this.list.filter(select => select?.text?.toLowerCase().indexOf(search) > -1).slice(0, 30)
    );
  }

}

