import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of,tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyTestimonialsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

  this.translateService.set('my-testimonials.dashboard', 'Dashboard', 'en');
  this.translateService.set('my-testimonials.my-testimonials', 'My Testimonials', 'en');
  this.translateService.set('my-testimonials.submit-testimonials', 'Submit Testimonial', 'en');
  this.translateService.set('my-testimonials.title', 'Title', 'en');
  this.translateService.set('my-testimonials.title.placeholder', 'Title', 'en');
  this.translateService.set('my-testimonials.enter-your-testimonials', 'Enter Your Testimonial Here..', 'en');
  this.translateService.set('my-testimonials.reset', 'RESET', 'en');
  this.translateService.set('my-testimonials.submit', 'SUBMIT', 'en');
  this.translateService.set('my-testimonials.submited-testimonials', 'Your Submitted Testimonials', 'en');
  this.translateService.set('my-testimonials.and', 'and', 'en');
  this.translateService.set('my-testimonials.waiting-for-approval', 'Waiting For Approval', 'en');
  this.translateService.set('my-testimonials.rejected-by-admin-on', 'Rejected by Admin On', 'en');
  this.translateService.set('my-testimonials.please-wait-while-processing-request', 'Please wait while we save the details...', 'en');
  this.translateService.set('my-testimonials.my-testimonials-page-name', 'My Testimonials', 'en');
  this.translateService.set('my-testimonials.title-is-required', 'Title is required', 'en');
  this.translateService.set('my-testimonials.your-testimonials-is-required', 'Testimonials is required', 'en');
  this.translateService.set('my-testimonials.your-testimonials', 'Testimonial', 'en');
  this.translateService.set('my-testimonials.testimonials-submitted-successfully', 'Your testimonial has been submitted and is under review', 'en');
  this.translateService.set('my-testimonials.something-went-wrong', 'Something went wrong. Please try again', 'en');
  this.translateService.set('my-testimonials.please-enter-valid-title', 'Please enter valid title', 'en');
  this.translateService.set('my-testimonials.you-have-not-submitted-any-testimonials-yet', 'You have not submitted any testimonials Yet', 'en');
   //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("my-testimonials").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
