import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(value: string): string {
        return value
            // inject space before the upper case letters
            .replace(/([A-Z])/g, function (match) {
                return " " + match;
            })
            // replace first char with upper case
            .replace(/^./, function (match) {
                return match.toUpperCase();
            });
    }

}
