import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { loadScript, OrderResponseBody } from "@paypal/paypal-js";
import { DialogData, MessageDailogComponent } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { PaymentMethod } from 'src/app/models/payment/payment-method';
declare var paypal: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'bzg-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

  @Input("amount") amount: number;
  @Input("refId") refId?: string;
  @Input("prePaymentUrl") prePaymentUrl?: string;
  @Input("prePaymentData") prePaymentData?: any;
  @Input("config") config: PaymentMethod;
  @Input("currency") currency: string;
  @Output("paymentSuccess") paymentSuccess = new EventEmitter<OrderResponseBody>();
  error: string[] = [];
  hasError: boolean = false;
  isLoading: boolean = false;
  @Input("beforeValidation") beforeValidation?: boolean = false;
  @ViewChild('paypalButton', { static: false }) paypalButton: ElementRef;
  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit() {
    console.log(this.config,'init config');
    const self = this;
    let clientId: string = "";
    if (this.config && this.config.configure) {
      let config = JSON.parse(this.config.configure) as ConfigItem[];
      let info = config.find(x => x.ControlID?.toLowerCase() == "txtclientid");
      console.log(info,'config');
      if (info == undefined || info.DefaultValue == "") {
        this.hasError = true;
        this.error.push("Client Id is not configured");
      } else {
        clientId = info.DefaultValue;
        console.log(clientId, 'clientid')
      }
    } else {
      this.hasError = true;
      this.error.push("Paypal is not configured");
    }


    if (this.currency == undefined || this.currency == "") {
      this.hasError = true;
      this.error.push("Currency is not configured");
    }

    if (!this.hasError) {
      try {

        var paypal = await loadScript(
          {
            "client-id": clientId,
            "currency": this.currency
          });
          
      } catch (error) {
        //debugger;
        console.log(paypal, 'paypal obj');
        this.hasError = true;
        this.error.push("failed to load the PayPal JS SDK script");
        if(this.prePaymentData.paymentPurpose == 'Test_Payment_purchase' && this.prePaymentData.isTA ){
          $('.modal-backdrop').remove();
          this.router.navigate(['admin/manage-payment-gateways/transactionFailed/'+"paymentgatewayconfigurationfailed" ]);
        }else if(this.prePaymentData.paymentPurpose == 'Test_Payment_purchase' && !this.prePaymentData.isTA){
          $('.modal-backdrop').remove();
          this.router.navigate(['admin/community/'+this.prePaymentData.communityId+'/manage-payment-gateways/'+this.prePaymentData.communityId+'/transactionFailed/'+"paymentgatewayconfigurationfailed"]);
        }
        console.error("failed to load the PayPal JS SDK script", error);
        console.log("failed to load the PayPal JS SDK script", error);
      }
    }

    if (paypal) {
      try {
        let payButton = await paypal.Buttons({
          style: {
            layout: 'horizontal',
            tagline: false
          },
          createOrder: async function (data, actions) {
            self.isLoading = true;
            let d = await self.getRefId();
            console.log("createOrder", data, actions, d, self.refId, self.currency);
            return actions.order.create({
              purchase_units: [{
                reference_id: self.refId,
                amount: {
                  currency_code: self.currency,
                  value: self.amount.toString()
                }
              }]
            });
          },
          onApprove: function (data, actions) {

            // This function captures the funds from the transaction.
            return actions.order.capture().then(function (details) {

              var event = new CustomEvent("paymentSuccess", { detail: details });
              document.dispatchEvent(event);
              self.paymentSuccess.emit(details);
              self.isLoading = false;
              // This function shows a transaction success message to your buyer.
              //alert('Transaction completed by ' + details.payer.name.given_name);
            });
          },
          onCancel: function (data) {
            console.log("onCancel", data);
            self.isLoading = false;
          },
          onError: function (err) {
            //debugger;
            // For example, redirect to a specific error page
            //console.log("An Error occured", err);
            self.isLoading = false;
            if (!(err.toString().includes('Window is closed') || err.toString().includes('Detected popup close'))) {
              payButton.close();
              self.matSnackBar.open("An error occured, please try again", "Close", { duration: 3000 });
            }
          },
          onInit: function () {
            console.log("on init");
          },
          onClick: async function (d, s) {
            console.log("on click before ", d, s);
            //await self.delay(2000);
            //throw new Error("Unable to complete");
            console.log("on click after delay");
          }
        });
        payButton.render("#paypal-container");
      } catch (error) {
        self.isLoading = false;
        console.error("failed to render the PayPal Buttons", error);
      }
    }
  }

  getRefId(): Promise<object> {
    console.log(this.prePaymentData);
    return new Promise((resolve, reject) => {
      if (this.prePaymentUrl) {
        this.http.post(this.prePaymentUrl, this.prePaymentData).subscribe({
          next: (data: any) => {
            if (this.beforeValidation) {
              if (data == true) {
                this.refId = this.refId;
              }
              else {
                let dailogData: DialogData = {
                  title: 'Error',
                  messege:
                    'It seems ticket(s) or applied promocode is not available now, please try again after sometime.',
                };
                let dailogRef = this.dialog.open(MessageDailogComponent, {
                  width: '400px',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  role: 'alertdialog',
                  disableClose: true,
                  data: dailogData,
                });

                dailogRef.afterClosed().subscribe((result) => {
                  window.location.reload();
                });
              }
            }
            else if (data.statusCode == 500) {
              Swal.fire({
                title: 'Transaction Failed',
                text: data.value,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }
            else if(data.value) {
              this.refId = data.value
            }else {
              this.refId = data;
            }
            resolve(data);
          },
          error: (err) => {
            this.refId = "";
            reject(err);
            if (err.status == 500 && this.prePaymentData.paymentPurpose == 'PROMOTION_PLAN_PURCHASE') {
              Swal.fire({
                title: '',
                text: err.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/advertisement/my-advertisements']);
                } else {

                }
              });
            }
            console.log(err);
          }
        })
      } else {
        resolve({});
      }
    });

  }
}

export interface ConfigItem {
  ControlID: string;
  DefaultValue: string;
}
