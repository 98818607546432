import { Component, OnInit, Inject, Optional } from '@angular/core';
import { DialogData } from '../message-dailog/message-dailog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bzg-confirm-dailog',
  templateUrl: './confirm-dailog.component.html',
  styleUrls: ['./confirm-dailog.component.scss']
})
export class ConfirmDailogComponent implements OnInit {
  yesButtonText: string = 'Yes';
  noButtonText: string = 'No';
  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmDailogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.noButtonText) {
      this.noButtonText = data.noButtonText;
    }
    if (data.yesButtonText) {
      this.yesButtonText = data.yesButtonText;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
  ngOnInit(): void {
  }


}
