<section class="content homePage gwc-home-page fullWidthMainContent">
    <!-- <section class="gwc--slider">
        <ng-container *ngIf="data | async as homepage">
            <div *ngIf="homepage.loading && homepage.loaded==false">
                <div class="text-center pt-5">
                    <h5 class="pt-5">
                        <i class="fa fa-spinner fa-spin"></i> Loading image...
                    </h5>
                </div>
            </div>
            <div *ngIf="homepage.loading==false && homepage.error !=null">
                <div class="text-center px-5">
                    <p class="text-danger">
                        <i class="fa fa-exclamation-triangle"></i> Unable to load image
                    </p>
                </div>
            </div>
            <ng-container *ngIf="homepage.loaded && homepage.data?.bannerSlides as banner">
      
                <div id="demo" class="carousel slide" data-bs-ride="carousel">
              
                    <div class="carousel-indicators">
                        <button *ngFor="let item of banner;let i=index;" type="button" data-bs-target="#demo"
                            [attr.data-bs-slide-to]="i" [ngClass]="i == 0? 'active':''"></button>
                    </div>

                 
                    <div class="carousel-inner">
                        <div *ngFor="let item of banner;let i=index;" class="carousel-item"
                            [ngClass]="i == 0? 'active':''">
                            <a *ngIf="item.linkUrl && item.linkUrl.length>0" [href]="item.linkUrl" [title]="item.name"
                                target="_blank">
                                <img [src]="item.logoFileName" [alt]="item.name">
                            </a>
                            <img *ngIf="item.linkUrl == null || item.linkUrl == ''" [src]="item.logoFileName"
                                [alt]="item.name">
                        </div>
                    </div>
                  
                    <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon"></span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                        <span class="carousel-control-next-icon"></span>
                    </button>
                </div>
            </ng-container>
        </ng-container>


    </section>
    <section class="gwc-tabs-section">
        <div class="container-md">
            <div class="row">
                <div class="col-md-12">
                    <ul class="nav nav-pills nav-justified" role="tablist">
                        <li class=""><a class="active" data-bs-toggle="pill" href="#tab-one">President Job Summary</a>
                        </li>
                        <li><a data-bs-toggle="pill" href="#tab-two">Diversegy: Energy Advisors</a></li>
                        <li><a data-bs-toggle="pill" href="#tab-three">W.B. Mason Program</a></li>
                    </ul>

                    <div class="tab-content">
                        <div id="tab-one" class="tab-pane active">

                            <div class="iframe-section">
                                <div class="col-md-12">
                                    <div class="advantages-section">
                                        <br>
                                        <p class="green-text" style="font-weight:bold">
                                            Gateway Regional Chamber was established in 1911 and is the largest business
                                            association in Northern New Jersey with over 1,200 members ranging from
                                            Fortune
                                            100 corporations to entrepreneurial businesses. The Chamber’s mission is to
                                            be a
                                            business organization which represents and advances the business interests
                                            of
                                            its members. With a focus on serving members in Union, Essex, Middlesex and
                                            Somerset Counties, the Chamber is the only multi-county association in
                                            Northern
                                            and Central New Jersey. The Gateway Regional Chamber has the unique
                                            structure of
                                            serving as an umbrella organization for over a dozen regional and local
                                            chambers
                                            and networking groups in its regional footprint. Organizations that join the
                                            Gateway Regional Chamber automatically gain membership in each affiliate.
                                            The
                                            Chamber presents over 180 events each year, including networking socials,
                                            educational business seminars, special interest group gatherings, gala award
                                            dinners, business expos, trade fairs, and local activities...
                                        </p>
                                        <p class="text-center"><a href="/pages/president-job-summary/139"
                                                class="btn btn-primary">Read More</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-two" class="tab-pane fade">
                            <br>
                            <h3>Gateway Members Exclusive Discount</h3>
                            <div class="iframe-section">
                                <div class="row">
                                    <div class="col-md-4">
                                        <iframe class="box_border" width="100%" height="280px"
                                            src="https://www.youtube.com/embed/6U8KTbcWGKU" frameborder="0"
                                            allowfullscreen=""></iframe>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="advantages-section">
                                            <p class="green-text" style="font-weight:bold">
                                                Through the Gateway Chamber Affinity Partnership, Members have access to
                                                discounted commercial natural gas and electricity rates.
                                            </p>
                                            <p class="green-text" style="font-weight:bold">
                                                A trusted advisor by many of the top brands in NJ (and
                                                nationwide),Diversegy
                                                helps businesses reduce costs, mitigate risk, and improve their bottom
                                                line.
                                                As fully owned subsidiary of publicly traded Genie Energy, Ltd
                                                (NYSE:GNE),
                                                Diversegy has a rich history in the deregulated energy space with a deep
                                                staff of industry experts. Diversegy manages over 500 million KWh each
                                                year
                                                and has an A+ business rating.
                                            </p>
                                            <p class="green-text" style="font-weight:bold">
                                                "Our dedicated team of energy experts work with a portfolio of 35+
                                                natural
                                                gas and electricity suppliers nationwide. They shop, compare, and
                                                procure
                                                energy supply rates with a variety of product structures. Our supplier
                                                base
                                                and vast clientele provide us leverage for exceptional pricing, ideal
                                                contract language, and superior customer service for our clients."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-three" class="tab-pane fade">
                            <br>
                            <h3 style="text-transform:uppercase;" align="center">"Buy right with W. B. Mason and enjoy
                                special savings through the Gateway Chamber Program."</h3>
                            <p class="text-center">W.B. guarantees ONE-STOP shopping convenience, Free Delivery, Local
                                Customer Service, and No Hassle ordering and returns. Save on Office Supplies,
                                Furniture,
                                Coffee/Break Room, Janitorial, Print, and Promo, everything you need to run your
                                business.
                                This program is for new business only, so if you don’t already use W. B. Mason, get in
                                touch
                                today to save at least 15%!</p>
                            <p class="text-center">
                                Your Contact:Vincent Nadal, 888-926-2766 Ext 1651,
                                <a href="mailto:vincent.nadal@wbmason.com">vincent.nadal@wbmason.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="gwc-events-section">
        <div class="container-md">
            <div class="row">
                <ng-container *ngIf="data | async as homepage">
                    <div *ngIf="homepage.loading && homepage.loaded==false"
                        class="col-md-6 col-sm-6 col-xs-12 d-flex align-items-center justify-content-center">
                        <h5>
                            <i class="fa fa-spinner fa-spin"></i> Loading Upcoming Events...
                        </h5>
                    </div>
                    <div *ngIf="homepage.loading==false && homepage.error !=null"
                        class="col-md-6 col-sm-6 col-xs-12 d-flex align-items-center justify-content-center">
                        <p class="text-danger">
                            <i class="fa fa-exclamation-triangle"></i> Unable to load event data
                        </p>
                    </div>
                    <ng-container *ngIf="homepage.loaded && homepage.data?.randomEvent">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <h4>Upcoming Events</h4>
                            <div class="gwc-events">
                                <img *ngIf="homepage.data.randomEvent.bannerTypeID == 1"
                                    [src]="homepage.data.randomEvent.imageName" class="img-responsive center-block" />
                                <div class="text-center">
                                    <video *ngIf="homepage.data.randomEvent.bannerTypeID == 2"
                                        class="img-responsive center-block" style="max-width:100%;max-height:250px;">
                                        <source [src]="homepage.data.randomEvent.imageName" type="video/mp4">
                                        <source [src]="homepage.data.randomEvent.imageName" type="video/ogg">
                                    </video>
                                </div>
                                <div class="gwc-event-details">
                                    <h5>{{homepage.data.randomEvent.eventName}}</h5>
                                    <address>
                                        <span>
                                            <i class="fas fa-map-marker-alt"></i>
                                            <ng-container
                                                *ngIf="homepage.data.randomEvent.address && homepage.data.randomEvent.address.length>0">
                                                {{homepage.data.randomEvent.address}}
                                            </ng-container>
                                            <span
                                                *ngIf="homepage.data.randomEvent.address == null || homepage.data.randomEvent.address == ''">
                                                Virtual
                                            </span>                                            
                                        </span>
                                        <br />
                                        <span>
                                            <i class="fas fa-clock"></i>
                                            <ng-container *ngIf="homepage.data.randomEvent.isEventEndsOnSameDay ==true">
                                                {{
                                                homepage.data.randomEvent.eventStartDate | date:'MMM d, YYYY hh:mm a'
                                                }}
                                                to
                                                {{ homepage.data.randomEvent.eventEndDate | date:'hh:mm a' }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="homepage.data.randomEvent.isEventEndsOnSameDay == false">
                                                {{
                                                homepage.data.randomEvent.eventStartDate | date:'MMM d, YYYY hh:mm a'
                                                }}
                                                to
                                                {{ homepage.data.randomEvent.eventEndDate | date:'MMM d, YYYY hh:mm a'
                                                }}
                                            </ng-container>
                                        </span>
                                        <br />
                                        <span>({{homepage.data.randomEvent.timeZone}})</span>
                                    </address>
                                    <a [routerLink]="['/event/details/',homepage.data.randomEvent.eventName | slug,homepage.data.randomEvent.eventID]"
                                        class="btn btn-primary center-block">Event
                                        Details</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="col-md-6 col-sm-6 col-xs-12" style="border-left:1px dashed #4b596b;">
                    <div class="gwc-event-iframe" align="center">
                        <iframe style="width:80%;height:315px"
                            src="https://player.vimeo.com/video/787961316?embedparameter=value" frameborder="0"
                            allowfullscreen=""></iframe>
                        <h5>110th Annual Awards Dinner </h5>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="gwc-featured-sponsor-section">

    </section>
    <section class="gwc-banner-section">
        <div class="container-fluid pt-3">
            <div class="">
                <img src="https://bizlig0xmbl1.blob.core.windows.net/tenantupoadcontentpub-prod/gatewaychamber/TenantLogos/Banner_Image.jpg"
                    class="img-fluid" />
            </div>
        </div>
        <div class="container gwc-featured-sponsor-section  gwc-container mt-45-gwc">
            <div class="gwc-sec-title">
                <h3> Featured Sponsor </h3>
            </div>
            <div class="position-relative text-center">
                <a href="https://www.elizabethtowngas.com/switchmybusiness" target="_blank" class="stretched-link"></a>
                <img src="https://gwcadmin.gatewaychamber.com/Content/Images/clientimgs/natural_gas.jpg"
                    class="img-fluid" />
            </div>

        </div>
        <div class="container  gwc-container mt-45-gwc">
            <div class="gwc-sec-title ">
                <h3> About Us </h3>
            </div>
            <div class="gwc-banner-section-content">

                <!-- <p>Welcome to the Gateway Regional Chamber of Commerce, where success thrives through collaboration. As
                    a member, you'll
                    join
                    2 dynamic network of entrepreneurs, industry leaders, and dedicated professionals who share a common
                    goal: achieving
                    mutual
                    success. Benefit from strategic networking, invaluable resources, and impactful advocacy to boost
                    growth. Enjoy 3 fun,
                    positive,
                    and welcoming environment while connecting with business professionals from Union County and beyond.
                    With a robust
                    calendar of events, we offer more opportunities than any other organization in the area, ensuring
                    maximum value for our
                    members.</p> -->
                <p class="w">Welcome to the Gateway Regional Chamber of Commerce, where collaborative success is the
                    cornerstone
                    of our mission.
                    Since 1911, the Gateway Regional Chamber has been a proud pillar of support for Union County and the
                    wider Gateway
                    Region, dedicated to fostering economic growth and fortifying the foundation of our local business
                    community.</p>
                <p>Our members form an essential part of our dynamic network, uniting entrepreneurs, industry leaders,
                    community figures,
                    and dedicated professionals, all sharing a common commitment to mutual success. What sets us apart
                    is our unique network
                    that extends beyond our organization, encompassing affiliate groups such as the Black Business
                    Alliance, Watchung
                    Chamber, Queen City Chamber, Gateway Warehouse Association, Governmental Affairs, and our Workforce
                    Education Committee.</p>
                <p class="mb-0">At the Gateway Chamber, we prioritize cultivating a vibrant, welcoming, and positive
                    environment for
                    all our members,
                    while providing an extensive calendar of over 100 annual networking opportunities, numerous
                    cost-saving programs for
                    essential business services, and access to state resources tailored to promote your growth and
                    prosperity. If you're not
                    currently an active member, we encourage you to contact us today to discover why the Gateway Chamber
                    of Commerce is the
                    ideal partner for your business's journey to success.</p>
            </div>
            <div class=" gwc-banner-section-icons">
                <div class="row row-cols-1 row-cols-sm-3 row-cols-md-3 g-xl-5 g-3">
                    <div class="col">
                        <div class="gwcIconCard">
                            <h4>Directory</h4>
                            <i class="fas fa-address-book"></i>
                            <a href="/member/directory"><span class="fas fa-chevron-circle-right"></span></a>
                        </div>
                    </div>
                    <div class="col">
                        <div class="gwcIconCard">
                            <h4>Join Today</h4>
                            <i class="fas fa-users"></i>
                            <a href="/auth/signup"><span class="fas fa-chevron-circle-right"></span></a>
                        </div>
                    </div>
                    <div class="col">
                        <div class="gwcIconCard">
                            <h4>Events</h4>
                            <i class="fas fa-calendar-day"></i>
                            <a href="/events"><span class="fas fa-chevron-circle-right"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="gwc-sponsors">
        <div class="container gwc-container">
            <div class="gwc-sponsors-title">
                <h3 class="text-center">gatewaychamber.com is sponsored by</h3>
            </div>
            <ng-container *ngIf="data | async as homepage">
                <div *ngIf="homepage.loading && homepage.loaded==false" class="col-md-4 col-sm-6 col-xs-12">
                    <div class="well testimonial d-flex align-items-center justify-content-center"
                        style="border-top:3px solid #e9a3ce;" align="center">
                        <h5>
                            <i class="fa fa-spinner fa-spin"></i> Loading sponsors...
                        </h5>
                    </div>
                </div>
                <div *ngIf="homepage.loading==false && homepage.error !=null" class="col-md-4 col-sm-6 col-xs-12">
                    <p class="text-danger">
                        <i class="fa fa-exclamation-triangle"></i> Unable to load sponsors
                    </p>
                </div>
                <ng-container *ngIf="homepage.loaded && homepage.data?.sponsors as sponsors">
                    <div class="sponsers-slider-main">
                        <div class="sponsers-slider">
                            <div *ngFor="let item of sponsors;let i=index; let last = last; trackBy: similarBusinessTrackByFn"
                                class="">
                                <div class="sponser-slider-item">
                                    <a [href]="item.linkUrl" class=" stretched-link" target="_blank"></a>
                                    <img [src]="item.logoFileName" [alt]="item.name" class="img-fluid" />
                                </div>
                                <span *ngIf="last">{{ initSlider() }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>
    <section class="testimonials">
        <div class="container gwc-container">
            <div class="row g-3 gx-xl-5">
                <div class="col-md-4 col-sm-6 col-xs-12 ">
                    <h4 class="testmonial-block-title">Business Spotlight</h4>
                    <a href="https://www.elizabethtowngas.com/switchmybusiness" target="_blank">
                        <div class="well text-center" style="border-top:3px solid #2eb4b3;">
                            <img src="https://gwcadmin.gatewaychamber.com/Content/Images/clientimgs/Employee_Health.jpg"
                                class="img-responsive center-block" />
                        </div>
                    </a>
                </div>
                <!-- <div class="col-md-4 col-sm-6 col-xs-12 ">
                    <h4 class="testmonial-block-title">Featured Event </h4>
                    <a href="https://www.elizabethtowngas.com/switchmybusiness" target="_blank">
                        <div class="well text-center" style="border-top: 3px solid #b4b764;">
                            <img src="https://gwcadmin.gatewaychamber.com/Content/Images/clientimgs/Employee_Health.jpg"
                                class="img-responsive center-block" />
                        </div>
                    </a>
                </div> -->
                <ng-container *ngIf="data | async as homepage">
                    <div *ngIf="homepage.loading && homepage.loaded==false" class="col-md-4 col-sm-6 col-xs-12">
                        <div class="well events-spotlight d-flex align-items-center justify-content-center"
                            style="border-top:3px solid #b4b764;">
                            <h5>
                                <i class="fa fa-spinner fa-spin"></i> Loading Spotlight Events...
                            </h5>
                        </div>
                    </div>
                    <div *ngIf="homepage.loading==false && homepage.error !=null" class="col-md-4 col-sm-6 col-xs-12">
                        <div class="well events-spotlight d-flex align-items-center justify-content-center"
                            style="border-top:3px solid #b4b764;">
                            <p class="text-danger">
                                <i class="fa fa-exclamation-triangle"></i> Unable to load spotlight event
                            </p>
                        </div>
                    </div>
                    <ng-container *ngIf="homepage.loaded && homepage.data?.spotlightEvent">
                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <h4 class="testmonial-block-title">Featured Event </h4>
                            <div class="well events-spotlight" style="border-top:3px solid #b4b764;">

                                <div>
                                    <img *ngIf="homepage.data.spotlightEvent.bannerTypeID == 1"
                                        [src]="homepage.data.spotlightEvent.imageName"
                                        class="img-responsive center-block" />
                                    <div class="text-center">
                                        <video *ngIf="homepage.data.spotlightEvent.bannerTypeID == 2"
                                            class="img-responsive center-block"
                                            style="max-width:100%;max-height:200px;">
                                            <source [src]="homepage.data.spotlightEvent.imageName" type="video/mp4">
                                            <source [src]="homepage.data.spotlightEvent.imageName" type="video/ogg">
                                        </video>
                                    </div>
                                    <div class="event-detailz" align="center">
                                        <h5>{{homepage.data.spotlightEvent.eventName}}</h5>
                                        <address>
                                            <span>
                                                <i class="fas fa-map-marker-alt"></i>
                                                <ng-container
                                                    *ngIf="homepage.data.spotlightEvent.address && homepage.data.spotlightEvent.address.length>0">
                                                    {{homepage.data.spotlightEvent.address}}
                                                </ng-container>
                                                <span
                                                    *ngIf="homepage.data.spotlightEvent.address == null || homepage.data.spotlightEvent.address == ''">
                                                    Virtual
                                                </span>
                                            </span>
                                            <br />
                                            <span>
                                                <i class="fas fa-clock"></i>
                                                <ng-container
                                                    *ngIf="homepage.data.spotlightEvent.isEventEndsOnSameDay ==true">
                                                    {{
                                                    homepage.data.spotlightEvent.eventStartDate
                                                    | date:'MMM d, YYYY hh:mm a'
                                                    }}
                                                    to
                                                    {{ homepage.data.spotlightEvent.eventEndDate | date:'hh:mm a' }}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="homepage.data.spotlightEvent.isEventEndsOnSameDay == false">
                                                    {{
                                                    homepage.data.spotlightEvent.eventStartDate
                                                    | date:'MMM d, YYYY hh:mm a'
                                                    }}
                                                    to
                                                    {{ homepage.data.spotlightEvent.eventEndDate
                                                    | date:'MMM d, YYYY hh:mm a'
                                                    }}
                                                </ng-container>
                                            </span>
                                            <br />
                                            <span>({{homepage.data.spotlightEvent.timeZone}})</span>
                                        </address>
                                        <a [routerLink]="['/event/details/',homepage.data.spotlightEvent.eventName | slug,homepage.data.spotlightEvent.eventID]"
                                            class="btn btn-primary center-block">
                                            Register
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="data | async as homepage">
                    <div *ngIf="homepage.loading && homepage.loaded==false" class="col-md-4 col-sm-6 col-xs-12">
                        <div class="well testimonial d-flex align-items-center justify-content-center"
                            style="border-top:3px solid #e9a3ce;" align="center">
                            <h5>
                                <i class="fa fa-spinner fa-spin"></i> Loading testimonial...
                            </h5>
                        </div>
                    </div>
                    <div *ngIf="homepage.loading==false && homepage.error !=null" class="col-md-4 col-sm-6 col-xs-12">
                        <div class="well testimonial d-flex align-items-center justify-content-center"
                            style="border-top:3px solid #e9a3ce;" align="center">
                            <p class="text-danger">
                                <i class="fa fa-exclamation-triangle"></i> Unable to load testimonial
                            </p>
                        </div>
                    </div>
                    <ng-container *ngIf="homepage.loaded && homepage.data?.testimonial">
                        <div class="col-md-4 col-sm-6 col-xs-12 ">
                            <h4 class="testmonial-block-title">Testimonials</h4>
                            <div class="well testimonial" style="border-top:3px solid #e9a3ce;" align="center">

                                <div>
                                    <ng-container *ngIf="(homepage.data?.testimonial.profilePicturePath == null 
                                        || homepage.data?.testimonial.profilePicturePath == '') 
                                        && homepage.data?.testimonial.fullName.length >0">
                                        <ngx-letters-avatar [avatarName]="homepage.data?.testimonial.fullName"
                                            [width]="150" [circular]="true" fontFamily="Maven Pro">
                                        </ngx-letters-avatar>
                                    </ng-container>
                                    <img *ngIf="homepage.data?.testimonial.profilePicturePath.length > 0"
                                        [src]="homepage.data?.testimonial.profilePicturePath"
                                        class="img-responsive center-block">
                                    <h6 class="pt-2">
                                        {{homepage.data?.testimonial.fullName}}
                                    </h6>
                                    <p class="designation ng-binding">
                                        {{homepage.data?.testimonial.organizationName}}
                                    </p>
                                    <p>
                                        {{homepage.data?.testimonial.details | stringLimitTo:200}}
                                    </p>
                                    <a routerLink="/testimonials" class="btn btn-primary center-block">Read More</a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </section>
    <section class="gwc-footer-banner" *ngIf="data | async as homepage">
        <div class="container gwc-container" *ngIf="homepage.loaded && homepage.data?.bannerSlides as banner">
            <div id="gwc-footer-bannerID" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button *ngFor="let item of banner;let i=index;" type="button" data-bs-target="#gwc-footer-bannerID"
                        [attr.data-bs-slide-to]="i" [ngClass]="i == 0? 'active':''">
                    </button>
                </div>
                <div class="carousel-inner">
                    <div *ngFor="let item of banner;let i=index;" class="carousel-item" [ngClass]="i == 0? 'active':''">
                        <a *ngIf="item.linkUrl && item.linkUrl.length>0" [href]="item.linkUrl" [title]="item.name"
                            target="_blank">
                            <img [src]="item.logoFileName" [alt]="item.name" class="d-block" style="width:100%">
                        </a>
                        <img *ngIf="item.linkUrl == null || item.linkUrl == ''" [src]="item.logoFileName"
                            [alt]="item.name" class="d-block" style="width:100%">
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>