import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonialsDynamicComponent } from './testimonials.component';
import { LettersAvatarModule } from 'ngx-letters-avatar';



@NgModule({
  declarations: [
    TestimonialsDynamicComponent
  ],
  imports: [
    CommonModule,
    LettersAvatarModule
  ],
  exports:[
    TestimonialsDynamicComponent
  ]
})
export class TestimonialsDynamicModule { }
