import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyMatchesModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('my-matches.dashboard', 'Dashboard', 'en');
    this.translateService.set('my-matches.my-matches', 'My Matches', 'en');
    this.translateService.set('my-matches.loading', 'Loading...', 'en');
    this.translateService.set('my-matches.connect', 'CONNECT', 'en');
    this.translateService.set('my-matches.skills', 'Skills', 'en');
    this.translateService.set('my-matches.more', 'More', 'en');
    this.translateService.set('my-matches.interests', 'Interests', 'en');
    this.translateService.set('my-matches.no-matches-found', 'No matches found...', 'en');
    this.translateService.set('my-matches.fill-you-profile-completely-to-improve-your-results', 'Fill you profile completely to improve your results', 'en');
    this.translateService.set('my-matches.sending-connection-request', 'Sending connection request...', 'en');
    this.translateService.set('my-matches.page-name-my-matches', 'My Matches (People With Similar Skills and Interests)', 'en');
    this.translateService.set('my-matches.connection-request-sent', 'Connection request sent', 'en');
    this.translateService.set('my-matches.unable-to-sent-connection-request-please-try-again', 'Unable to sent connection request, please try again', 'en');
    this.translateService.set('my-matches.connection-request-canceled-by-user', 'Connection request canceled by user', 'en');
    // latest changes
    this.translateService.set('my-matches.pending', 'Pending', 'en');
    this.translateService.set('my-matches.accept', 'Accept', 'en');
    this.translateService.set('my-matches.reject', 'Reject', 'en');
    this.translateService.set('my-matches.connection-request-is-pending', 'Connection request is pending', 'en');
    this.translateService.set('my-matches.message', 'Message', 'en');

    return this.localResourceService.loadLocalResource("my-matches").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
