import { Action, createReducer, on } from '@ngrx/store';
import { MyAdvertisementPageState } from '../state/my-advertisements';

import * as fromActions from './../action/my-advertisements.actions';
export const myAdvertisementsFeatureKey = 'myAdvertisements';


export const initialState: MyAdvertisementPageState = {
  myAdvertisement: {
    data: null,
    error: null,
    loaded: false,
    loading: false,
    loadingMore: false,
  }
};

export const MyAdvertisementreducer = createReducer<MyAdvertisementPageState>(
  initialState,
  on(fromActions.LoadMyAdvertisementInfo, (state, { query }) => ({
    ...state,
    myAdvertisement: {
      data: null,
      error: null,
      loaded: false,
      loadingMore: false,
      loading: true
    }
  })),
  on(fromActions.LoadMyAdvertisementInfoSuccess, (state, { Advertisementsinfo }) => ({
    ...state,
    myAdvertisement: {
      data: Advertisementsinfo,
      error: null,
      loaded: true,
      loadingMore: false,
      loading: false
    }
  })),
  on(fromActions.LoadMyAdvertisementInfoError, (state, { error }) => ({
    ...state,
    myAdvertisement: {
      data: null,
      error: error,
      loaded: false,
      loadingMore: false,
      loading: false
    }
  })),

  on(fromActions.LoadMoreMyAdvertisementInfo, (state, { query }) => ({
    ...state,
    myAdvertisement: {
      data: state.myAdvertisement.data,
      error: null,
      loaded: false,
      loadingMore: true,
      loading: true
    }
  })),
  on(fromActions.LoadMoreMyAdvertisementInfoSuccess, (state, { Advertisementsinfo }) => ({
    ...state,
    myAdvertisement: {
      data: {
        currentPage: Advertisementsinfo.currentPage,
        pageSize: Advertisementsinfo.pageSize,
        totalCount: Advertisementsinfo.totalCount,
        data: [...state.myAdvertisement?.data.data, ...Advertisementsinfo.data],
      },
      error: null,
      loadingMore: false,
      loaded: true,
      loading: false
    }
  })),
  on(fromActions.LoadMyAdvertisementInfoError, (state, { error }) => ({
    ...state,
    myAdvertisement: {
      data: null,
      error: error,
      loaded: false,
      loadingMore: false,
      loading: false
    }
  })),

  //promotion plans ///

  // on(fromActions.LoadPromotionPlansInfo, (state, { query }) => ({
  //   ...state,
  //   purchasePlans: {
  //     data: null,
  //     error: null,
  //     loaded: false,
  //     loading: true
  //   }
  // })),
  // on(fromActions.LoadPromotionPlansInfoSuccess, (state, { Promotionsinfo }) => ({
  //   ...state,
  //   purchasePlans: {
  //     data: Promotionsinfo,
  //     error: null,
  //     loaded: true,
  //     loading: false
  //   }
  // })),
  // on(fromActions.LoadPromotionPlansInfoError, (state, { error }) => ({
  //   ...state,
  //   purchasePlans: {
  //     data: null,
  //     error: error,
  //     loaded: false,
  //     loading: false
  //   }
  // })),

);
