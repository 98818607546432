<section class="content">
    <div class="container-fluid pt-2">
        <div class="row align-items-center">
            <div class="col-sm">
                <ul class="dashboard-breadcrum list-inline">
                    <li class="list-inline-item">
                        <a [routerLink]="['/dashboard']" class="text-theme-secondary">
                            {{'advertisement.dashboard' | translate}}
                        </a>
                    </li>
                    <li class="list-inline-item">&gt;</li>
                    <li class="list-inline-item">
                        {{'advertisement.my-advertisement' | translate}}
                    </li>
                </ul>
            </div>
            <div class="col-sm-auto">
                <div class="mb-3">
                    <a href="javascript:void(0)" [matTooltip]="'advertisement.tap-here-to-create-new-add' | translate"
                        class="btn btn-basic text-secondary"><i class="fas fa-info-circle"></i>
                    </a>
                    <button routerLink="/advertisement/create-ad" type="button" class="btn btn-theme btn-lg"><i
                            class="fas fa-plus-circle"></i> {{'advertisement.create-global-add' | translate}}
                    </button>
                </div>
            </div>
        </div>


        <div class="card myAdsMainCard">
            <div class="card-header myAdsFilters">
                <form (isValid)="searchAds($event)" [formGroup]="advertisementSearchFormGroup">
                    <div class="row g-2">
                        <div class="col-lg">
                            <div class="row g-2">
                                <div class="col-md">
                                    <div class="mb-2">
                                        <input type="text" formControlName="planName"
                                            [placeholder]="'advertisement.search-by-place-name.placeholder' | translate"
                                            class="form-control form-control-lg ">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="mb-2">
                                        <input type="text" formControlName="community"
                                            [placeholder]="'advertisement.search-by-community.placeholder' | translate"
                                            class="form-control form-control-lg ">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="mb-2">
                                        <input type="text" formControlName="title"
                                            [placeholder]="'advertisement.search-by-ad-title.placeholder' | translate"
                                            class="form-control form-control-lg ">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="mb-2">
                                        <input type="text" formControlName="duration"
                                            [placeholder]="'advertisement.search-by-duration.placeholder' | translate"
                                            class="form-control form-control-lg ">
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="mb-2">
                                        <select class="form-select form-control-lg" formControlName="status">
                                            <option value="" selected>{{'advertisement.search-by-status' | translate}}
                                            </option>
                                            <option value="2">{{'advertisement.waiting-for-approval' | translate}}
                                            </option>
                                            <option value="5">{{'advertisement.expired' | translate}}</option>
                                            <option value="3">{{'advertisement.approved' | translate}}</option>
                                            <option value="4">{{'advertisement.rejected' | translate}}</option>
                                            <option value="7">{{'advertisement.inactive' | translate}}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-sm-12 col-xl-auto pe-xl-1">
                            <div class="mb-2">
                                <button type="submit" class="btn btn-theme rounded-3 btn-lg">
                                    {{'advertisement.search' | translate}}
                                </button>
                                <button type="reset" class="btn ms-2 btn-outline-danger rounded-3 btn-lg"
                                    (click)="resetSearchResults()">
                                    {{'advertisement.reset' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ng-container *ngIf="MyAdvertisement$ | async as ads">
                <bzg-loading [show]="ads?.loading" [message]="'advertisement.loading' | translate"></bzg-loading>
                <div class="card-body custom-pad-card-body" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
                    <div class="row" *ngIf="ads?.data?.data?.length == 0">
                        <div class="noDataAds col-12">
                            <div class="">
                                <img src="/assets/images/my-ads/no_ads.png" class="img-flud mx-auto" />
                            </div>
                            <p class="text-center">
                                {{'advertisement.there-is-no-advertisement-for-you-as-of-now' | translate}}
                            </p>
                            <div>
                                <button routerLink="/advertisement/create-ad" type="button"
                                    class="btn btn-theme btn-lg"><i class="fas fa-plus-circle"></i>
                                    {{'advertisement.create-global-add' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" *ngFor=" let ad of ads?.data?.data">
                            <div class="card myAdsCard">
                                <div class="myAdsCard__header">
                                    <div class="row g-1 align-items-end">
                                        <div class="col">
                                            <p class="myAdsCard--label">{{'advertisement.ad-title' | translate}}</p>
                                            <h4>{{ad.planAds[0].name}}</h4>
                                        </div>
                                        <div class="col-auto pe-1" *ngIf="ad?.entityStatusID == 1">
                                            <button type="button" (click)="editAdvertisement(ad)"
                                                class="btn btn-basic text-theme px-0 py-0"><i
                                                    class="fas fa-pen"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="myAdsCard__body">
                                    <div class="row  myAdsCardGrid">
                                        <div class="col myAdsCardGrid_g1">
                                            <p class="myAdsCard--label">{{'advertisement.plan-name' | translate}}</p>
                                            <h5 class="myAdsCard--value">{{ad.promotionPlanName}}</h5>
                                        </div>
                                        <div class="col myAdsCardGrid_g2">
                                            <p class="myAdsCard--label">{{'advertisement.ad-location' | translate}}</p>
                                            <h5 class="myAdsCard--value">{{ad.adLocation}}</h5>
                                        </div>
                                        <div class="col myAdsCardGrid_g3">
                                            <p class="myAdsCard--label">{{'advertisement.ad-type' | translate}}</p>
                                            <h5 class="myAdsCard--value" *ngIf="ad.viewType == '1'">
                                                {{'advertisement.image' | translate}}</h5>
                                            <h5 class="myAdsCard--value" *ngIf="ad.viewType == '2'">
                                                {{'advertisement.video' | translate}}</h5>
                                        </div>
                                    </div>
                                    <div class="row  myAdsCardGrid">
                                        <div class="col myAdsCardGrid_g1">
                                            <p class="myAdsCard--label">{{'advertisement.plan-dates' | translate}}</p>
                                            <h5 class="myAdsCard--value">{{ad.planStartDate | clientConfigDate }} - {{ad.planEndDate
                                                | clientConfigDate}}</h5>
                                        </div>
                                        <div class="col myAdsCardGrid_g2">
                                            <p class="myAdsCard--label">{{'advertisement.duration' | translate}}</p>
                                            <h5 class="myAdsCard--value">{{ad.duration}}</h5>
                                        </div>
                                        <div class="col myAdsCardGrid_g3">
                                            <p class="myAdsCard--label">{{'advertisement.global' | translate}}</p>
                                            <h5 class="myAdsCard--value" *ngIf="ad.communityID == 0">
                                                {{'advertisement.yes' | translate}}</h5>
                                            <h5 class="myAdsCard--value" *ngIf="ad.communityID != 0">
                                                {{'advertisement.no' | translate}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="myAdsCard__footer">
                                    <div class="row g-2 align-items-center">
                                        <div class="col fw-500"><i *ngIf="ad.communityName" class="fas fa-users"></i>
                                            {{ad.communityName}}</div>
                                        <div class="col-auto pe-2">
                                            <button type="button" *ngIf="ad.entityStatusID == 1"
                                                class="btn btn-outline-theme-cyan btn-sm">{{ad.entityStatusText}}</button>
                                            <button type="button" *ngIf="ad.entityStatusID == 2"
                                                class="btn btn-outline-warning btn-sm">{{ad.entityStatusText}}</button>
                                            <button type="button" *ngIf="ad.entityStatusID == 3"
                                                class="btn btn-outline-success btn-sm">{{ad.entityStatusText}}</button>
                                            <button type="button" *ngIf="ad.entityStatusID == 4"
                                                class="btn btn-outline-danger btn-sm">{{ad.entityStatusText}}</button>
                                            <button type="button" *ngIf="ad.entityStatusID == 5"
                                                class="btn btn-outline-danger btn-sm">{{ad.entityStatusText}}</button>
                                            <button type="button" *ngIf="ad.entityStatusID == 7"
                                                class="btn btn-outline-danger btn-sm">{{ad.entityStatusText}}</button>
                                        </div>
                                        <!-- <div class="col-auto pe-2" *ngIf="!ad.isActive">
                                        <button type="button" class="btn btn-outline-danger btn-sm">In-Active</button>
                                    </div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-md-6">
                        <div class="card myAdsCard">
                            <div class="myAdsCard__header">
                                <div class="row g-1 align-items-end">
                                    <div class="col">
                                        <p class="myAdsCard--label">Ad Title</p>
                                        <h4>Gobal Summit - Wipro Tech Group</h4>
                                    </div>
                                    <div class="col-auto pe-1">
                                        <button type="button" class="btn btn-basic text-theme px-0 py-0"><i
                                                class="fas fa-pen"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__body">
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Name</p>
                                        <h5 class="myAdsCard--value">Platinum Membership</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Ad Location</p>
                                        <h5 class="myAdsCard--value">Home Page</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Ad Type</p>
                                        <h5 class="myAdsCard--value">Video</h5>
                                    </div>
                                </div>
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Dates</p>
                                        <h5 class="myAdsCard--value">03/04/2023- 19/04/2023</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Duration</p>
                                        <h5 class="myAdsCard--value">2 Week(s)</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Global</p>
                                        <h5 class="myAdsCard--value">No</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__footer">
                                <div class="row g-2 align-items-center">
                                    <div class="col fw-500"><i class="fas fa-users"></i> CISCO Web</div>
                                    <div class="col-auto pe-2">
                                        <button type="button" class="btn btn-outline-danger btn-sm">Expired</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card myAdsCard">
                            <div class="myAdsCard__header">
                                <div class="row g-1 align-items-end">
                                    <div class="col">
                                        <p class="myAdsCard--label">Ad Title</p>
                                        <h4>Workshop on Business Develop -NJ</h4>
                                    </div>
                                    <div class="col-auto pe-1">
                                        <button type="button" class="btn btn-basic text-theme px-0 py-0"><i
                                                class="fas fa-pen"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__body">
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Name</p>
                                        <h5 class="myAdsCard--value">Professional Membership</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Ad Location</p>
                                        <h5 class="myAdsCard--value">Home Page</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Ad Type</p>
                                        <h5 class="myAdsCard--value">Image</h5>
                                    </div>
                                </div>
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Dates</p>
                                        <h5 class="myAdsCard--value">03/04/2023- 09/04/2023</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Duration</p>
                                        <h5 class="myAdsCard--value">1 Week(s)</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Global</p>
                                        <h5 class="myAdsCard--value">Yes</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__footer">
                                <div class="row g-2 align-items-center">
                                    <div class="col fw-500"><i class="fas fa-users"></i> CISCO Web</div>
                                    <div class="col-auto pe-2">
                                        <button type="button" class="btn btn-outline-warning btn-sm">PAYMENT
                                            PENDING</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card myAdsCard">
                            <div class="myAdsCard__header">
                                <div class="row g-1 align-items-end">
                                    <div class="col">
                                        <p class="myAdsCard--label">Ad Title</p>
                                        <h4>Gobal Summit - Wipro Tech Group</h4>
                                    </div>
                                    <div class="col-auto pe-1">
                                        <button type="button" class="btn btn-basic text-theme px-0 py-0"><i
                                                class="fas fa-pen"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__body">
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Name</p>
                                        <h5 class="myAdsCard--value">Platinum Membership</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Ad Location</p>
                                        <h5 class="myAdsCard--value">Home Page</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Ad Type</p>
                                        <h5 class="myAdsCard--value">Video</h5>
                                    </div>
                                </div>
                                <div class="row  myAdsCardGrid">
                                    <div class="col myAdsCardGrid_g1">
                                        <p class="myAdsCard--label">Plan Dates</p>
                                        <h5 class="myAdsCard--value">03/04/2023- 19/04/2023</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g2">
                                        <p class="myAdsCard--label">Duration</p>
                                        <h5 class="myAdsCard--value">2 Week(s)</h5>
                                    </div>
                                    <div class="col myAdsCardGrid_g3">
                                        <p class="myAdsCard--label">Global</p>
                                        <h5 class="myAdsCard--value">No</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="myAdsCard__footer">
                                <div class="row g-2 align-items-center">
                                    <div class="col fw-500"><i class="fas fa-users"></i> CISCO Web</div>
                                    <div class="col-auto pe-2">
                                        <button type="button" class="btn btn-outline-danger btn-sm">Expired</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    </div>
                    <div class="col-12">
                        <p class="text-center p-3" *ngIf="noMoreResult">
                            {{'advertisement.no-more-records-available' | translate}}
                        </p>
                        <p class="text-center p-3" *ngIf="ads.loadingMore">
                            <i class="fa fa-spinner fa-spin"></i>
                            {{'advertisement.loading' | translate}}
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>