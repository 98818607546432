import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';
import { RouteInfo } from '../sidebar/sidebar.metadata';

@Component({
  selector: 'bzg-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('menuList') menuList: ElementRef;
  menuItems: RouteInfo[];

  constructor(private store: Store,
    private renderer: Renderer2) { }

  ngOnInit(): void {

    //this.store.dispatch(LoadMenuData());

    this.store.select(selectApplicationConfiguration).pipe(
      map(config => config.menuList),
      filter(menu => menu?.length > 0),
    ).subscribe(data => {
      this.menuItems = data;
      //console.log(this.menuItems);
      if (this.menuItems.length != 0) {
        this.ngAfterViewInit();
      }
    })
  }
  ngAfterViewInit() {
    // Calculate the half screen width
    const halfScreenWidth = window.innerWidth / 2;
    // Loop through menu items and check their position
    if (this.menuItems) {
      this.menuItems?.forEach((sidebarItem, index) => {

        const menuItemElement = this.menuList?.nativeElement.children[index];
        const menuItemRect = menuItemElement?.getBoundingClientRect();

        if (menuItemRect?.left + menuItemRect?.width / 2 >= halfScreenWidth) {
          // Add your class here to the menuItemElement
          this.renderer.addClass(menuItemElement, 'right-menu-link');
        }
      });
    }

  }
}
