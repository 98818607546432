import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggerService {
    private appInsights: ApplicationInsights;
    constructor(private router: Router) {
        var angularPlugin = new AngularPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router }
                }
            }
        });
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    private getMinimumLogLevel() {
        let logLevel: LogLevel;
        switch (environment.minLogLevel) {
            case "trace":
                logLevel = LogLevel.Trace;
                break;
            case "debug":
                logLevel = LogLevel.Debug;
                break;
            case "information":
                logLevel = LogLevel.Information;
                break;
            case "warning":
                logLevel = LogLevel.Warning;
                break;
            case "error":
                logLevel = LogLevel.Error;
                break;
            case "critical":
                logLevel = LogLevel.Critical;
                break;
            default:
                logLevel = LogLevel.None;
                break;
        }
        return logLevel;
    }

    private severityLevel(logLevel: LogLevel) {
        let severityLevel: SeverityLevel;
        switch (logLevel) {
            case LogLevel.Trace:
                severityLevel = SeverityLevel.Verbose;
                break;
            case LogLevel.Debug:
                severityLevel = SeverityLevel.Verbose;
                break;
            case LogLevel.Information:
                severityLevel = SeverityLevel.Information;
                break;
            case LogLevel.Warning:
                severityLevel = SeverityLevel.Warning;
                break;
            case LogLevel.Error:
                severityLevel = SeverityLevel.Error;
                break;
            case LogLevel.Critical:
                severityLevel = SeverityLevel.Critical;
                break;
            case LogLevel.None:
                severityLevel = SeverityLevel.Verbose;
                break;
            default:
                severityLevel = SeverityLevel.Verbose;
                break;
        }
        return severityLevel;
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, logLevel?: LogLevel, properties?: { [key: string]: any }) {
        if (logLevel >= this.getMinimumLogLevel()) {
            let severityLevel = this.severityLevel(logLevel);
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel, properties: properties });
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Trace >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Verbose }, properties);
        }
    }

    logDebug(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Debug >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Verbose }, properties);
        }
    }

    logInformation(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Information >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information }, properties);
        }
    }

    LogWarning(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Warning >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning }, properties);
        }
    }

    LogError(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Error >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Error }, properties);
        }
    }

    LogCritical(message: string, properties?: { [key: string]: any }) {
        if (LogLevel.Critical >= this.getMinimumLogLevel()) {
            this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Critical }, properties);
        }
    }

}

export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6
}