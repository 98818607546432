<div class="card">
    <div class="card-body">
        <div class="permissionDenied">
            <div class="">
                <img src="/assets/images/connections/23No_Connections.png"
                    class="img-flud mx-auto" />
            </div>
            <h5 class="text-nodata"><i class="fa fa-info-circle"></i>
                you don't have permission to access this page, contact your system admin.
                </h5>
        </div>
    </div>
</div>