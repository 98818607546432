import { Action } from '@ngrx/store';
import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { AdditionalInfo, additionalInfoData, AddPortfolio, IInterests, interestsData, IprofileSearch, ISearchAdditionalInfo, ISearchSkills, ISimilarSandIUserInfo, ISkills, IuserInfo, IUserProfileCompletenessInfo, SearchSkillsOrInterests, skillsData, SkillsOrInterestsState, skillsOrInterestsInfo, userInfoData, ISearchInterests, ISearchSimilarSandIUsersInfo, ISimilarSandIUserList } from '../state/profile';
import { BusinessCategory, BusinessType, ICategorySearch, IContactsList, IContactsSearch, ILocationList, IOrganization, IorganizationSearch, IProfessionalMatches, ISearchLocations, ISearchOrganization, ISearchProfessionalMatches } from '../state/organization';
import { IEndorsements, IEndorsementsandNotes, IendorsementsandnotesSearch, INotes, ISearchEndorsements, ISearchNotes } from '../state/endorsements';

// export enum ProfileActionTypes {
//   LoadProfiles = '[Profile] Load Profiles',


// }

// export class LoadProfiles implements Action {
//   readonly type = ProfileActionTypes.LoadProfiles;
// }


// export type ProfileActions = LoadProfiles;



export const LoadProfileData = createAction('[Profile Data] Load Profile Data', (query: IprofileSearch) => ({ query }));

export const LoadSkillsInfo = createAction('[Add Skills Data] Add Skills Data', (query: ISearchSkills) => ({ query }));
export const LoadSkillsDataSuccess = createAction('[Profile Data] Load profile data success', props<{ skills: ISkills[] }>());
export const LoadSkillsDataError = createAction('[Profile Data] Load profile data error', props<{ error: HttpErrorResponse }>());

export const AddPortfolioData = createAction('[Add Portfolio Data] Add Portfolio Data', (query: AddPortfolio) => ({ query }));
export const AddPortfolioDataSuccess = createAction('[Add Portfolio Data] Add Portfolio Data success', props<{ additionalInfo: AdditionalInfo }>());
export const AddPortfolioDataError = createAction('[Add Portfolio Data] Add Portfolio Data error', props<{ error: HttpErrorResponse }>());

export const LoadInterestsInfo = createAction('[load Interests Data] Add Skills Data', (query: ISearchInterests) => ({ query }));
export const LoadInterestsDataSuccess = createAction('[Interests Data] Load interest data success', props<{ interests: IInterests[] }>());
export const LoadInterestsDataError = createAction('[Interests Data] Load interest data error', props<{ error: HttpErrorResponse }>());

export const LoadAdditionalInfo = createAction('[Add Portfolio Data] Add Portfolio Data', (query: ISearchAdditionalInfo) => ({ query }));
export const LoadAdditionalInfoSuccess = createAction('[Additional Info] Load Additional Info data success', props<{ additionalInfo: AdditionalInfo }>());
export const LoadAdditionalInfoError = createAction('[Additional Info] Load Additional Info data error', props<{ error: HttpErrorResponse }>());
export const RemoveProfileCategory = createAction('[My Profile] Remove Profile category', props<{ name: string }>());

export const LoadProfileCompleteness = createAction('[profile completeness Info] profile completeness Info data', (query: ISearchAdditionalInfo) => ({ query }));
export const LoadProfileCompletenessSuccess = createAction('[profile completeness Info] profile completeness Info data success', props<{ profileCompletenessInfo: IUserProfileCompletenessInfo }>());
export const LoadProfileCompletenessError = createAction('[profile completeness Info] profile completeness Info data error', props<{ error: HttpErrorResponse }>());

export const LoadSimillarUsers = createAction('[Load Simillar skills and interests users Data] Load Simillar skills and interests users Data', (query: ISearchSimilarSandIUsersInfo) => ({ query }));
export const LoadSimillarUsersSuccess = createAction('[Simillar skills and interests users Info] Simillar skills and interests users Info data success', props<{ simillarSandIUsersInfo: ISimilarSandIUserList }>());
export const LoadSimillarUsersError = createAction('[Simillar skills and interests users Info] Simillar skills and interests users Info data error', props<{ error: HttpErrorResponse }>());

export const LoadMoreSimillarUsers = createAction('[Load More Simillar skills and interests users Data] Load More Simillar skills and interests users Data', (query: ISearchSimilarSandIUsersInfo) => ({ query }));
export const LoadMoreSimillarUsersSuccess = createAction('[More Simillar skills and interests users Info] More Simillar skills and interests users Info data success', props<{ simillarSandIUsersInfo: ISimilarSandIUserList }>());
export const LoadMoreSimillarUsersError = createAction('[More Simillar skills and interests users Info] More Simillar skills and interests users Info data error', props<{ error: HttpErrorResponse }>());

export const LoadUserInfo = createAction('[load User Data] Add User Data',(query: ISearchAdditionalInfo) => ({ query }));
export const LoadUserInfoSuccess = createAction('[user Info] Load user Info data success', props<{ userInfo: IuserInfo }>());
export const LoadUserInfoError = createAction('[user Info] Load user Info data error', props<{ error: HttpErrorResponse }>());

export const LoadSkillsOrInterests = createAction('[Load Skills Or Interests Data] Load Skills Or Interests Data', (query: SearchSkillsOrInterests) => ({ query }));
export const LoadSkillsOrInterestsSuccess = createAction('[Load Skills Or Interests Info] Load Skills Or Interests Info data success', props<{ skillsorinterestsinfo: skillsOrInterestsInfo[] }>());
export const LoadSkillsOrInterestsError = createAction('[Load Skills Or Interests Info] Load Skills Or Interests Info data error', props<{ error: HttpErrorResponse }>());
// organization info

export const LoadContactsData = createAction('[Contacts Data] Load contacts Data', (contactsquery: IContactsSearch) => ({ contactsquery }));
export const LoadContactsDataSuccess = createAction('[Contacts Data] Load contact data success', props<{ contacts: IContactsList[] }>());
export const LoadContactsDataError = createAction('[Contacts Data] Load contact data error', props<{ error: HttpErrorResponse }>());

export const LoadOraganizationData = createAction('[Organization Data] Load organization Data', (query: IorganizationSearch) => ({ query }));
export const LoadUpdatedOraganizationData = createAction('[Organization Data] Load updated organization Data', (query: ISearchOrganization) => ({ query }));

export const LoadBusinessCategories = createAction('[Business Categories] Load business Data', (query: ICategorySearch) => ({ query }));
export const RemoveBusinessCategories = createAction('[Business Categories] Remove business category ', props<{ businessCategoryId: number }>());
export const AddBusinessCategories = createAction('[Business Categories] Add business category ', props<{ businessCategory: BusinessCategory }>());
export const RemoveBusinessType = createAction('[Business Type] Remove business type ', props<{ businessTypeId: number }>());
export const AddBusinessType = createAction('[Business Categories] Add business type ', props<{ businessType: BusinessType }>());

export const LoadLocationsData = createAction('[Locations Data] Load locations Data', (query: ISearchLocations) => ({ query }));
export const LoadLocationsDataSuccess = createAction('[Location Data] Load location data success', props<{ locations: ILocationList[] }>());
export const LoadLocationsDataError = createAction('[Location Data] Load location data error', props<{ error: HttpErrorResponse }>());

export const LoadProfessionalMatches = createAction('[Professional Matches] Load professional matches', (query: ISearchProfessionalMatches) => ({ query }));
export const LoadProfessionalMatchesSuccess = createAction('[Professional Matches] Load Professional matches data success', props<{ professionamatches: IProfessionalMatches }>());
export const LoadProfessionalMatchesError = createAction('[Professiona lMatches] Load Professional matches data error', props<{ error: HttpErrorResponse }>());

export const LoadOraganizationDataSuccess = createAction('[Organization Data] Load Organization Data data success', props<{ organization: IOrganization }>());
export const LoadOraganizationDataError = createAction('[Organization Data] Load Organization Data data error', props<{ error: HttpErrorResponse }>());

// endorsements actions//
export const LoadEndorsementsandNotesData = createAction('[Endorsements and Notes Data] Load Endorsements and Notes Data', (query: IendorsementsandnotesSearch) => ({ query }));

export const LoadEndorsementsData = createAction('[Endorsements Data] Load Endorsements Data', (query: ISearchEndorsements) => ({ query }));
export const LoadEndorsementsSuccess = createAction('[Endorsements Data] Load Endorsements data success', props<{ endorsements: IEndorsements[] }>());
export const LoadEndorsementsError = createAction('[Endorsements Data] Load Endorsements data error', props<{ error: HttpErrorResponse }>());

export const LoadNotesData = createAction('[Notes Matches] Load Notes data', (query: ISearchNotes) => ({ query }));
export const LoadNotesSuccess = createAction('[Notes Matches] Load Notes data success', props<{ notes: INotes[] }>());
export const LoadNotesError = createAction('[Notes lMatches] Load Notes data error', props<{ error: HttpErrorResponse }>());


export const LoadEndorsementsandNotesDataSuccess = createAction('[Endorsements and Notes Data] Load Endorsements and Notes Data data success', props<{ endorsementsandnotes: IEndorsementsandNotes }>());
export const LoadEndorsementsandNotesDataError = createAction('[Endorsements and Notes Data] Load Endorsements and Notes Data data error', props<{ error: HttpErrorResponse }>());