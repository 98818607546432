<div class="topLevelMenu">
    <div class="container-fluid topLevelMenu__Container ">
        <div class="topNavbar">
            <ul class="topNavbar__menu" #menuList>
                <ng-container *ngFor="let sidebarItem of menuItems">
                    <!-- {{ sidebarItem | json}} -->

                    <li *ngIf="sidebarItem?.submenu?.length == 0">
                        <a [routerLink]="sidebarItem?.path" *ngIf="!sidebarItem?.isLink">
                            {{ sidebarItem?.title }}
                        </a>
                        <a [href]="sidebarItem?.path" *ngIf="sidebarItem?.isLink" target="_blank">
                            {{ sidebarItem?.title }}
                        </a>
                    </li>
                    <li *ngIf="sidebarItem?.submenu?.length != 0" class="topNavbar__subMenuLi">
                        <a>{{ sidebarItem?.title }} <i class="fas fa-angle-down"></i></a>

                        <ul class="TNB-direct-sub-menu">
                            <ng-container *ngFor="let sidebarSubItem of sidebarItem?.submenu">

                                <li *ngIf="sidebarSubItem?.submenu?.length == 0">
                                    <ng-container *ngIf="sidebarSubItem?.isLink">
                                        <a [href]="sidebarSubItem?.path" target="_blank">
                                            {{ sidebarSubItem?.title | translate }}
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="!sidebarSubItem?.isLink">
                                        <a [routerLink]="sidebarSubItem?.path">
                                            {{ sidebarSubItem?.title | translate }}
                                        </a>
                                    </ng-container>
                                </li>
                                <li class="has-menu" *ngIf="sidebarSubItem?.submenu?.length > 0">
                                    <a href="javascript.void(0)">
                                        {{ sidebarSubItem?.title }}
                                        <i class="fas fa-angle-right"></i>
                                    </a>
                                    <ul class="TNB-sub-sub-menu">
                                        <ng-container *ngFor="let sidebarSubsubItem of sidebarSubItem?.submenu">
                                            <li>
                                                <ng-container *ngIf="!sidebarSubsubItem?.isLink">
                                                    <a [routerLink]="sidebarSubsubItem?.path">
                                                        {{ sidebarSubsubItem?.title | translate }}
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="sidebarSubsubItem?.isLink">
                                                    <a [href]="sidebarSubsubItem?.path" target="_blank">
                                                        {{ sidebarSubsubItem?.title | translate }}
                                                    </a>
                                                </ng-container>
                                            </li>
                                            <!-- <li><a href="#"> {{ sidebarSubsubItem?.title | translate }}</a></li> -->
                                            <!-- <li class="has-menu">
                                            <a href="#"> {{ sidebarSubsubItem?.title | translate }} <i class="fas fa-angle-right"></i></a>
                                            <ul class="TNB-sub-sub-menu">
                                                <li><a href="#">Web Design</a></li>
                                                <li><a href="#">Graphic Design</a></li>
                                                <li><a href="#">Digital Marketing</a></li>
                                            </ul>
                                        </li> -->
                                        </ng-container>
                                    </ul>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>