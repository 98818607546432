<div class="sponsers-slider-main">
    <h4 class="text-center" *ngIf="sponsorLoading"> <i class="fa fa-spinner fa-spin"></i> Loading Sponsors...</h4>
    <p class="text-center" *ngIf="sponsorError">Unable to load Sponsors...</p>
    <div class="sponsers-slider">
        <div *ngFor="let item of sponsors;let i=index; let last = last; trackBy: similarBusinessTrackByFn" class="">
            <div class="sponser-slider-item">
                <a [href]="item.linkUrl" class=" stretched-link" target="_blank"></a>
                <img [src]="item.logoFileName" [alt]="item.name" class="img-fluid" />
            </div>
            <span *ngIf="last">{{ initSlider() }}</span>
        </div>
    </div>
</div>