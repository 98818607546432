import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { BusinessCategoryDropdown, BusinessTypeDropdown, MembershipPlans, SelectDropdown } from 'src/app/models/common/listItem';
import { GroupDropdown, Organization, OrganizationSelect, professionalServices, SaveUserOrganization, TenantBusinessType } from 'src/app/models/organization/organization';
import { SimilarBusiness } from 'src/app/module/dashboard/store/state/member-dashboard';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient
  ) { }

  getOrganizationsList() {
    const url = '/api/common/getorganizationslist';
    return this.http.get<Array<OrganizationSelect>>(url)
      .pipe(map(s => s.map(d => {
        let info: OrganizationSelect = {
          organizationName: d.organizationName,
          organizationId: d.organizationId,
          entityStatus: d.entityStatus,
          entityStatusId: d.entityStatusId
        };
        return info;
      }).filter(s => s.organizationName != null)));
  }

  searchOrganizationsList(q: string) {
    const url = '/api/common/SearchOrganizationsList';
    return this.http.get<Array<OrganizationSelect>>(url, { params: { q } })
      .pipe(map(s => s.map(d => {
        let info: OrganizationSelect = {
          organizationName: d.organizationName,
          organizationId: d.organizationId
        };
        return info;
      }).filter(s => s.organizationName != null)));
  }

  getOrganization(orgId: number) {
    const url = '/api/organization/getorgdatabyid';
    return this.http.get<Organization>(url, { params: { orgId } });
  }
  getGroupList() {
    const url = '/api/group/getallgroupsfordropdown';
    return this.http.get<Array<GroupDropdown>>(url);
  }
  getGroupListByCommunity(communityId:number) {
    const url = '/api/group/getallgroupsfordropdown';
    return this.http.get<Array<GroupDropdown>>(url,{params:{communityId}});
  }
  getBusinessCategoryList() {
    const url = '/api/common/GetBusinessCategoriesForDropdown';
    return this.http.get<Array<BusinessCategoryDropdown>>(url);
  }
  getMembershipPlansList() {
    const url = '/api/admin/organization/GetAdminMembershipPlans';
    return this.http.get<Array<MembershipPlans>>(url);
  }
  saveUserOrganization(organization: SaveUserOrganization) {
    const url = '/api/user/saveorganizationinfo';
    return this.http.post<boolean>(url, organization);
  }

  GetBusinessTypes() {
    const url = '/api/common/GetBusinessTypes';
    return this.http.get<Array<BusinessTypeDropdown>>(url);
  }

  getTenantBusinessType() {
    const url = '/api/common/gettenantbusinesstype';
    return this.http.get<TenantBusinessType>(url);
  }
  GetProfessionalServices(userId: any, orgId:number) {
    const url = '/api/user/GetProfessionalServices';
    return this.http.get<professionalServices>(url, { params: { userId, orgId } });
  }

  getSimilarBusiness(businessCategory: string = "", zipCode: string = "") {
    const url = '/api/organization/getsimillarbusinessdata';
    if (businessCategory == null) {
      businessCategory = "";
    }
    if (zipCode == null) {
      zipCode = "";
    }
    return this.http.get<SimilarBusiness[]>(url, { params: { businessCategory, zipCode } });
  }
}
