import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class EventDetailsModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    // Event-Details page
    
    // Attendees
    this.translateService.set('event-details.attendees', 'Attendees', 'en');
    this.translateService.set('event-details.view-all', 'View All', 'en');
    // Details
    this.translateService.set('event-details.home', 'Home', 'en');
    this.translateService.set('event-details.details', 'Details', 'en');
    this.translateService.set('event-details.agenda', 'Agenda', 'en');
    this.translateService.set('event-details.speakers', 'Speakers', 'en');
    this.translateService.set('event-details.sponsers', 'Sponsers', 'en');
    this.translateService.set('event-details.resources', 'Resources', 'en');
    this.translateService.set('event-details.Attendees', 'Attendees', 'en');
    this.translateService.set('event-details.register', 'REGISTER', 'en');
    this.translateService.set('event-details.attendees-list', 'Attendees List', 'en');
    this.translateService.set('event-details.name', 'Name', 'en');
    this.translateService.set('event-details.designation', 'Designation', 'en');
    this.translateService.set('event-details.company', 'Company', 'en');
    this.translateService.set('event-details.view-profile', 'View Profile', 'en');
    this.translateService.set('event-details.resend-event-mail', 'Resend event registration email', 'en');
    this.translateService.set('event-details.enter-mail.placeholder', 'Enter your email address with which you have registered for this event', 'en');
    this.translateService.set('event-details.cancel', 'Cancel', 'en');
    this.translateService.set('event-details.submit', 'Submit', 'en');
    this.translateService.set('event-details.confirm-booking', 'Please wait while we confirm your booking...', 'en');
    this.translateService.set('event-details.atteendee-details', 'Please wait while we save atteendee details...', 'en');
    this.translateService.set('event-details.processing-request', 'Please wait while processing your request...', 'en');
    this.translateService.set('event-details.upload-file', 'Please wait while uploading the file...', 'en');
    this.translateService.set('event-details.free', 'FREE', 'en');
    this.translateService.set('event-details.paid', 'PAID', 'en');
    this.translateService.set('event-details.person', 'In Person', 'en');
    this.translateService.set('event-details.online', 'Online', 'en');
    this.translateService.set('event-details.hybrid', 'Hybrid', 'en');
    this.translateService.set('event-details.show-on-map', 'Show on Map', 'en');
    this.translateService.set('event-details.donate', 'Donate', 'en');
    this.translateService.set('community-details.purpose-of-donation', 'Purpose Of Donation', 'en');
    // Resources
    this.translateService.set('event-details.resources', 'Resources', 'en');
    this.translateService.set('event-details.albums', 'Albums', 'en');
    this.translateService.set('event-details.Videos', 'Videos', 'en');
    this.translateService.set('event-details.Documents', 'Documents', 'en');
    // Speakers
    this.translateService.set('event-details.speakers', 'Speakers', 'en');
    // Sponsers
    this.translateService.set('event-details.sponsors', 'Sponsors', 'en');
    // Registration
    this.translateService.set('event-details.register', 'REGISTER', 'en');
    this.translateService.set('event-details.already.registered', 'Already Registered???', 'en');
    this.translateService.set('event-details.resend-confirmation', 'Resend Confirmation', 'en');
    this.translateService.set('event-details.cannot-buy-tickets', 'Sorry! You cannot buy the tickets for this event now', 'en');
    this.translateService.set('event-details.closed', 'This event is closed', 'en');
    this.translateService.set('event-details.private-event', 'This is a private event. Please login to see if you have access to attend the event', 'en');
    this.translateService.set('event-details.join', 'You must join the', 'en');
    this.translateService.set('event-details.attend', 'community to attend this event', 'en');
    this.translateService.set('event-details.must-join', 'You must join this community to attend this event', 'en');
    this.translateService.set('event-details.registration-closed', 'Registration for this event has been closed', 'en');
    this.translateService.set('event-details-members-only', 'Members Only', 'en');
    this.translateService.set('event-details.admins-only', 'Admins Only', 'en');
    this.translateService.set('event-details.sold-out', 'Sold Out', 'en');
    this.translateService.set('event-details.booking-starts-at', 'Booking Starts At...', 'en');
    this.translateService.set('event-details.booking-closed', 'Booking Closed.', 'en');
    this.translateService.set('event-details.buy-ticket', 'BUY TICKET', 'en');
    this.translateService.set('event-details.attendee-details', 'Attendee Details', 'en');
    this.translateService.set('event-details.organization-name.placeholder', 'Organization Nameeee', 'en');
    this.translateService.set('event-details.comments.placeholder', 'Comments', 'en');
    this.translateService.set('event-details.mark-me-as-a-payer', 'Mark me as a Payer', 'en');
    this.translateService.set('event-details.qty', 'QTY', 'en');
    this.translateService.set('event-details.total', 'Total', 'en');
    this.translateService.set('event-details.continue', 'CONTINUE', 'en');
    this.translateService.set('event-details.book-now', 'BOOK NOW', 'en');
    this.translateService.set('event-details.booking-information', 'Booking Information', 'en');
    this.translateService.set('event-details.make-payment', 'Make Payment', 'en');
    this.translateService.set('event-details.payer-information', 'Payer Information', 'en');
    this.translateService.set('event-details.ticket-summary', 'Ticket Summary', 'en');
    this.translateService.set('event-details.tickets', 'Tickets', 'en');
    this.translateService.set('event-details.price', 'Price', 'en');
    this.translateService.set('event-details.Quantity', 'Quantity', 'en');
    this.translateService.set('event-details.total-price', 'Total Price', 'en');
    this.translateService.set('event-details.enter-promo-code.placeholder', 'Enter promo code', 'en');
    this.translateService.set('event-details.apply', 'Apply', 'en');
    this.translateService.set('event-details.validating', 'Validating...', 'en');
    this.translateService.set('event-details.promo-code-applied', 'Congratulatons! The promo code has been applied and will be reflected in your total amount.', 'en');
    this.translateService.set('event-details.total-amount', 'Total Amount', 'en');
    this.translateService.set('event-details.promo-code', 'PROMO CODE', 'en');
    this.translateService.set('event-details.amount-to-be-paid', 'Amount to be paid', 'en');
    this.translateService.set('event-details.back', 'BACK', 'en');
    this.translateService.set('event-details.make-payment', 'MAKE PAYMENT', 'en');
    this.translateService.set('event-details.book-now', 'BOOK NOW', 'en');
    this.translateService.set('event-details.select-payment-method', 'Select Payment Method', 'en');
    this.translateService.set('event-details.your-tickets-have-processed', 'Your tickets have processed', 'en');
    this.translateService.set('event-details.receive-email', 'You will receive an email with the details', 'en');
    this.translateService.set('event-details.receive-email-join-meeting', ' You will receive an email with the details to join online meeting', 'en');
    this.translateService.set('event-details.add-to-calendar', ' Add to Caendar', 'en');
    this.translateService.set('event-details.join-url', ' Join URL', 'en');
    this.translateService.set('event-details.click-join', ' CLICK HERE TO JOIN', 'en');
    this.translateService.set('event-details.back-to-event', ' Back to Event', 'en');
    this.translateService.set('event-details.print-receipt', ' PRINT RECEIPT', 'en');
    this.translateService.set('event-details.booked', ' This is the booking confirmation for', 'en');
    this.translateService.set('event-details.event', ' Event', 'en');
    this.translateService.set('event-details.hi', ' Hi', 'en');
    this.translateService.set('event-details.receipt-payment', ' This is the receipt for a payment of', 'en');
    this.translateService.set('event-details.for', ' For', 'en');
    this.translateService.set('event-details.booking-id', ' Booking ID', 'en');
    this.translateService.set('event-details.booking-date', ' Booking Date', 'en');
    this.translateService.set('event-details.event-type', ' Event Type', 'en');
    this.translateService.set('event-details.virtual', ' Virtual', 'en');
    this.translateService.set('event-details.event-date-time', ' Event Date & Time', 'en');
    this.translateService.set('event-details.venue', 'Venue', 'en');
    this.translateService.set('event-details.payer', 'Payer', 'en');
    this.translateService.set('event-details.ticket', 'Ticket', 'en');
    this.translateService.set('event-details.quantity', 'Quantity', 'en');
    this.translateService.set('event-details.amount', 'Amount', 'en');
    this.translateService.set('event-details.discount', 'Discount', 'en');
    this.translateService.set('event-details.amount-paid', 'Amount Paid', 'en');
    this.translateService.set('event-details.your-booking-is-confirmed', 'Your booking is confirmed', 'en');
    this.translateService.set('event-details.close', 'Close', 'en');
    this.translateService.set('event-details.payment-completed-successfully', 'Payment completed successfully...', 'en');
    this.translateService.set('event-details.notvalid', 'Sorry! The promo code you have entered is not valid. Please try with a different code.', 'en');
    this.translateService.set('event-details.expired', 'Sorry! The promo code you have entered has expired. Please try with a different code.', 'en');
    this.translateService.set('event-details.exceeded-usage-limit', 'Sorry! The promo code you have entered has exceeded the usage limit. Please try with a different code.', 'en');
    this.translateService.set('event-details.Please-wait-while-processing-your-request', 'Please wait while processing your request', 'en');
    // Invite Guest
    this.translateService.set('event-details.invite-guests', 'Invite Guest', 'en');
    this.translateService.set('event-details.add-guest', 'Add Guest', 'en');
    this.translateService.set('event-details.guest-details', 'Guest Details', 'en');
    this.translateService.set('event-details.first-name.placeholder', 'First Name', 'en');
    this.translateService.set('event-details.last-name.placeholder', 'Last Name', 'en');
    this.translateService.set('event-details.e-mail.placeholder', 'E-Mail', 'en');
    this.translateService.set('event-details.joined', 'JOIN', 'en');
    this.translateService.set('event-details.registered' , 'Registered', 'en');
    this.translateService.set('event-details.email-sent' , 'Resend confirmation email has been sent successfully', 'en');
    this.translateService.set('event-details.email' , 'Email', 'en');
    this.translateService.set('event-details.first-name' , 'First Name', 'en');
    this.translateService.set('event-details.last-name' , 'Last Name', 'en');
    this.translateService.set('event-details.validation-error-message-email-not-in-list' , 'Email {0} is not found in the registrations list', 'en');
    this.translateService.set('event-details.extra-notes' , 'Extra Notes', 'en');
    this.translateService.set('event-details.please-enter-valid-first-name' , 'Please enter valid First Name', 'en');
    this.translateService.set('event-details.please-enter-valid-last-name' , 'Please enter valid last Name', 'en');
    this.translateService.set('event-details.please-enter-valid-company-name' , 'Please enter valid company Name', 'en');
    this.translateService.set('event-details.please-enter-valid-comments' , 'Please enter valid comments', 'en');
    //latest changes
    this.translateService.set('event-details.add-to-google-calendar' , 'Add to Google Calendar', 'en');
    this.translateService.set('event-details.add-to-calendar' , 'Add to Calendar', 'en');
    this.translateService.set('event-details.something-went-wrong' , 'Something went wrong!', 'en');
    this.translateService.set('event-details.something-went-wrong-please-try-again' , 'Something went wrong. Please try again', 'en');
    this.translateService.set('event-details.unable-to-load-payment-methods' , 'Unable to load payment methods', 'en');
    this.translateService.set('event-details.unable-to-update-payment' , 'Unable to update payment', 'en');
    this.translateService.set('event-details.failed-to-save-file' , 'Failed to save file', 'en');
    this.translateService.set('event-details.your-guest-invitation-has-been-sent' , 'Your guest invitation has been sent', 'en');
    this.translateService.set('event-details.attend-group' , 'group to attend this event', 'en');


    this.translateService.set('event-details.search.placeholder' , 'Search', 'en');
    
    
    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("event-details").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
