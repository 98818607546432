<ng-container *ngIf="data.termsAndConditions == null">
    <h4 mat-dialog-title>{{data.title}}</h4>
    <div mat-dialog-content>
        {{data.messege}}
        <br />
        <div mat-dialog-actions class="justify-content-end">
            <button color="warn" mat-raised-button mat-dialog-close>{{'communities.no' | translate}}</button>
            <button (click)="onYesClick()" color="primary" mat-raised-button>{{'communities.yes-proceed' | translate}}</button>
        </div>
    </div>

</ng-container>

<ng-container *ngIf="data.termsAndConditions">
    <form (isValid)="onYesClickWithTermsAndConditons($event)" [formGroup]="communityFormGroup">
        <h4 mat-dialog-title>{{data.title}}</h4>
        <div mat-dialog-content>
            {{data.messege}}
            <br />
            <br />
            <a class="fs-5 fw-500" href="javascript:void(0)">
                {{'communities.terms-and-conditions' | translate}}
            </a>
            <bzg-html [data]="data.termsAndConditions"></bzg-html>
            <mat-checkbox class="col-12 ps-3" [error]="customErr" formControlName="termsAndConditions">
                {{'communities.i-agree-to-terms-and-conditions' | translate}}
            </mat-checkbox>
            <mat-error #customErr></mat-error>
            <br />
            <div mat-dialog-actions class="justify-content-end">
                <button color="warn" mat-raised-button mat-dialog-close>{{'communities.no' | translate}}</button>
                <button type="submit" color="primary" mat-raised-button>{{'communities.yes-proceed' | translate}}</button>
            </div>
        </div>

    </form>
</ng-container>