import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  isFromProfile:boolean = false;
  constructor(private route:ActivatedRoute) { }

  ngOnInit() {
  this.route.queryParams.subscribe(a=>{
    if(a['page'] == 'Profile'){
      this.isFromProfile = true;
    }else{
      this.isFromProfile = false;
    }
   })
   
  }

}
