import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Html2Component } from './html2.component';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({
  declarations: [
    Html2Component
  ],
  imports: [
    CommonModule,
    DirectiveModule
  ],
  exports: [
    Html2Component
  ]
})
export class Html2Module {
}
