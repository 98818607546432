import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class TestimonialsModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet>{
    this.translateService.set('testimonials.loading', 'Loading...', 'en');
    this.translateService.set('testimonials.We-are-extremely-proud-to-share-some-of-our-current-experiences-in-their-own-words', 'We are extremely proud to share some of our current experiences in their own words', 'en');
    this.translateService.set('testimonials.testimonials-page-name', 'Testimonials', 'en');
    this.translateService.set('testimonials.we-are-extremely-proud-to-share', 'We are extremely proud to share some of our current experiences in their own words.', 'en');
    this.translateService.set('testimonials.no-testimonials-found', 'No Testimonials Found', 'en');
    return this.localResourceService.loadLocalResource("testimonials").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}