import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, map, of, switchMap, take, tap } from 'rxjs';
import { SlugPipe } from 'src/app/pipe/slug.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserInCommunityStatus } from 'src/app/services/community/community.service';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LoadMembershipStatuses, LoadUserStatusReload } from 'src/app/store/actions/app.action';
import { AcceptRejectCommunityUserDto, JoinCommunitiesDto } from 'src/app/store/state/app.state';
import { JoinOrLeaveCommunitySuccess, LoadCommunityInfo, LoadCommunityUserInfo } from '../../community-details/store/action/community-details.actions';
import { LoadChampions } from '../../community-details/store/action/community-home.actions';
import { SearchCommunityMember } from '../../community-details/store/action/community-member.actions';
import { ISearchMemberDirectory } from '../../member/store/state/member-directory';
import { CommunityDialogData, CommunityMessageDialogComponent } from '../../shared/community/community-message-dialog/community-message-dialog.component';
import { LoadCommunities, resetCommunities } from '../store/actions/communities.actions';
import { acceptRejectCommunityInvitationDto, CommunitiesResponseDto, CommunitiesSearchDto, CommunitiLogoDto, updateUserStatusDto } from '../store/state/communities';

@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private slugPipe: SlugPipe,
    private router: Router,
    private authService: AuthService,
    private matSnackbar: MatSnackBar,
    private store: Store,
    private translateService: TranslateService
  ) { }

  getCommunities(param: CommunitiesSearchDto) {
    return this.http.post<CommunitiesResponseDto>("/api/community/getallcommunities", param);
  }

  isMembershipPlanConfiguredForCommunity(communityId: number) {
    return this.http.get<boolean>(`/api/pricing/ismembershipplanconfigurebycommunity/${communityId}`);
  }

  updateUserStatusInCommunity(param: updateUserStatusDto) {
    return this.http.post<boolean>("/api/community/saveuserstatusincommunity", param).pipe(
      tap(s => {
        this.store.dispatch(LoadUserStatusReload());
        this.store.dispatch(LoadMembershipStatuses());
      })
    );
  }

  acceptOrRejectCommunityInvitation(param: acceptRejectCommunityInvitationDto) {
    return this.http.post<number>("/api/community/AcceptOrRejectPendingRequestsForInviteMember", param).pipe(
      tap(s => {
        this.store.dispatch(LoadUserStatusReload())
      })
    );
  }

  activateOrInactiveCommunity(communityId: number, status: boolean) {
    let param = {
      communityId: communityId,
      status: status
    }
    return this.http.post<boolean>("/api/community/ActivateOrInactivateCommunity", param);
  }

  joinCommunity(community: JoinCommunitiesDto) {
    return this.authService.ensureUserIsLoggedInWithRedirect().pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(false);
        }
        let joinConfirmTitle: string = this.translateService.instant('communities-service.do-you-want-to-join');
        joinConfirmTitle = joinConfirmTitle.replace("{0}", community.communityName);
        let title = joinConfirmTitle;
        let joinConfirmmessage: string = this.translateService.instant('communities-service.if-the-community-is-private');
        let message = joinConfirmmessage;

        let dailogData: CommunityDialogData = {
          title: title,
          messege: message,
          termsAndConditions: community.termsAndConditions
        };
        let dailogRef = this.dialog.open(CommunityMessageDialogComponent, {
          width: '600px',
          height: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          role: 'alertdialog',
          disableClose: true,
          hasBackdrop: true,
          data: dailogData,
        });
        var resultRef = dailogRef.afterClosed().pipe(
          map((result: Boolean) => {
            if (result) {
              if (community.communityTypeId == 1) {
                this.router.navigate(['/communities', this.slugPipe.transform(community.communityName), 'membership-plan', community.communityId]);
                return false;
              }
              return true;
            }
            return false;
          }),
          switchMap(shouldCall => {
            if (!shouldCall) {
              return of(true);
            }
            let communityIds = [community.communityId];
            let updateUserStatusDto = {
              communityIds: communityIds,
              statusId: 1,
              rejectReason: ""
            }
            console.log(updateUserStatusDto, "community join status");
            return this.updateUserStatusInCommunity(updateUserStatusDto).pipe(
              map(data => {
                if (data) {
                  let message = this.translateService.instant('communities-service.your-request-to-join-this-community-is-waiting');
                  this.matSnackbar.open(message, "", { duration: 5000, panelClass: 'bg-success' });
                  switch (community.pageName) {
                    case 'CommunityDetails':
                      this.store.dispatch(JoinOrLeaveCommunitySuccess({ status: UserInCommunityStatus.PENDING }))
                      break;
                    case 'CommunityList':
                      this.store.dispatch(resetCommunities());
                      break;
                  }
                  this.router.navigate(['/communities', 'stay-update']);

                }
                return data;
              }),
              switchMap(result => this.authService.getCurrentUser2().pipe(
                map(user => {
                  if (user != null && community.pageName == 'CommunityDetails') {
                    this.store.dispatch(LoadCommunityUserInfo({ communityId: community.communityId }));
                  }
                  return result;
                })
              ))
            );
          })
        );
        return resultRef
      })
    );

  }

  leaveCommunity(community: JoinCommunitiesDto) {
    return this.authService.ensureUserIsLoggedInWithRedirect().pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(false);
        }
        let leaveConfirmTitle: string = this.translateService.instant('communities-service.are-you-sure-want-to-leave-community')
        leaveConfirmTitle = leaveConfirmTitle.replace("{0}", community.communityName);
        let title = leaveConfirmTitle;
        let leaveConfirmMessage: string = this.translateService.instant('communities-service.you-will-be-removed-from-all-the-groups-of-this-community')
        let message = leaveConfirmMessage;

        let dailogData: CommunityDialogData = {
          title: title,
          messege: message
        };
        let dailogRef = this.dialog.open(CommunityMessageDialogComponent, {
          width: '600px',
          maxWidth: '100%',
          maxHeight: '100%',
          role: 'alertdialog',
          disableClose: true,
          hasBackdrop: true,
          data: dailogData,
        });
        var resultRef = dailogRef.afterClosed().pipe(
          switchMap(result => {
            if (!result) {
              return of(true);
            }
            let communityIds = [community.communityId];
            let updateUserStatusDto = {
              communityIds: communityIds,
              statusId: UserInCommunityStatus.REMOVED,
              rejectReason: ""
            }
            return this.updateUserStatusInCommunity(updateUserStatusDto).pipe(
              map(data => {
                if (data) {
                  let message = this.translateService.instant('communities-service.you-have-been-removed-from-the-community');
                  this.matSnackbar.open(message, "", { duration: 5000, panelClass: 'bg-success' });
                  switch (community.pageName) {
                    case 'CommunityDetails':
                      this.store.dispatch(JoinOrLeaveCommunitySuccess({ status: UserInCommunityStatus.REMOVED }))
                      this.store.dispatch(LoadMembershipStatuses());
                      break;
                    case 'CommunityList':
                      this.store.dispatch(resetCommunities());
                      break;
                  }

                }
                return data;
              }),
              switchMap(result => this.authService.getCurrentUser2().pipe(
                map(user => {
                  if (user != null && community.pageName == 'CommunityDetails') {
                    this.store.dispatch(LoadCommunityUserInfo({ communityId: community.communityId }));
                    this.store.dispatch(LoadChampions({ communityId: community.communityId }))
                    let memberData: ISearchMemberDirectory = {
                      pageNumber: 1,
                      pageSize: 50,
                      communitiesSearch: [community.communityId.toString()] as any
                    }
                    this.store.dispatch(SearchCommunityMember(memberData));
                    this.store.dispatch(LoadCommunityInfo({ communityId: community.communityId }));

                  } else if (user != null) {
                    let memberData: ISearchMemberDirectory = {
                      pageNumber: 1,
                      pageSize: 50,
                      communitiesSearch: [community.communityId.toString()] as any
                    }
                    this.store.dispatch(SearchCommunityMember(memberData));
                    this.store.dispatch(LoadCommunityInfo({ communityId: community.communityId }));
                  }
                  return result;
                })
              ))
            );
          })
        );
        return resultRef
      })
    );
  }


  cancelJoinCommunity(community: JoinCommunitiesDto) {
    return this.authService.ensureUserIsLoggedInWithRedirect().pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(false);
        }
        let cancelConfirmTitle = this.translateService.instant('communities-service.are-you-sure-want-to-cancel');
        let title = cancelConfirmTitle;
        let cancelConfirmMessage = this.translateService.instant('communities-service.your-join-request-to-community-canceled');
        cancelConfirmMessage = cancelConfirmMessage.replace("{0}", community.communityName);
        let message = cancelConfirmMessage;

        let dailogData: CommunityDialogData = {
          title: title,
          messege: message
        };
        let dailogRef = this.dialog.open(CommunityMessageDialogComponent, {
          width: '600px',
          maxWidth: '100%',
          maxHeight: '100%',
          role: 'alertdialog',
          disableClose: true,
          hasBackdrop: true,
          data: dailogData,
        });
        var resultRef = dailogRef.afterClosed().pipe(
          switchMap(result => {
            if (!result) {
              return of(true);
            }
            let communityIds = [community.communityId];
            let updateUserStatusDto = {
              communityIds: communityIds,
              statusId: UserInCommunityStatus.REMOVED,
              rejectReason: ""
            }
            return this.updateUserStatusInCommunity(updateUserStatusDto).pipe(
              map(data => {
                if (data) {
                  let message = this.translateService.instant('communities-service.your-request-is-canceled');
                  this.matSnackbar.open(message, "", { duration: 5000, panelClass: 'bg-success' });
                  switch (community.pageName) {
                    case 'CommunityDetails':
                      this.store.dispatch(JoinOrLeaveCommunitySuccess({ status: UserInCommunityStatus.REMOVED }))
                      break;
                    case 'CommunityList':
                      this.store.dispatch(resetCommunities());
                      break;
                  }

                }
                return data;
              }),
              switchMap(result => this.authService.getCurrentUser2().pipe(
                map(user => {
                  if (user != null && community.pageName == 'CommunityDetails') {
                    this.store.dispatch(LoadCommunityUserInfo({ communityId: community.communityId }));
                  }
                  return result;
                })
              ))
            );
          })
        );
        return resultRef
      })
    );
  }

  acceptRejectCommunityInvitation(community: AcceptRejectCommunityUserDto) {
    return this.authService.ensureUserIsLoggedInWithRedirect().pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return of(false);
        }
        let action = community.actionStatusID == 2 ? 'accept' : 'decline';
        let message = `Do you want to ${action} the invitation to join the ${community.communityName}?`;

        let dailogData: CommunityDialogData = {
          title: '',
          messege: message,
          termsAndConditions: community.termsAndConditions
        };
        let dailogRef = this.dialog.open(CommunityMessageDialogComponent, {
          width: '600px',
          height: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          role: 'alertdialog',
          disableClose: true,
          hasBackdrop: true,
          data: dailogData,
        });
        var resultRef = dailogRef.afterClosed().pipe(
          map((result: Boolean) => {
            if (result) {
              if (community.communityTypeId == 1) {
                this.router.navigate(['/communities', this.slugPipe.transform(community.communityName), 'membership-plan', community.communityId]);
                return false;
              }
              return true;
            }
            return false;
          }),
          switchMap(shouldCall => {
            if (!shouldCall) {
              return of(true);
            }
            let param = {
              actionStatusID: community.actionStatusID,
              communityID: community.communityId,
            }
            return this.acceptOrRejectCommunityInvitation(param).pipe(
              map(data => {
                if (data == 2) {
                  let message = community.actionStatusID == 2 ? this.translateService.instant('communities-service.you-have-added-to-the-community') : this.translateService.instant('communities-service.your-request-has-been-processed');
                  this.matSnackbar.open(message, "", { duration: 5000, panelClass: 'bg-success' });
                  switch (community.pageName) {
                    case 'CommunityDetails':
                      this.store.dispatch(JoinOrLeaveCommunitySuccess({ status: UserInCommunityStatus.REMOVED }));
                      break;
                    case 'CommunityList':
                      this.store.dispatch(resetCommunities());
                      break;
                  }

                }
                else {
                  let message = this.translateService.instant('communities-service.something-went-wrong');
                  this.matSnackbar.open(message, "", { duration: 5000 });
                }
                return data;
              }),
              switchMap(result => this.authService.getCurrentUser2().pipe(
                map(user => {
                  if (user != null && community.pageName == 'CommunityDetails') {
                    this.store.dispatch(LoadCommunityUserInfo({ communityId: community.communityId }));
                  }
                  return result;
                })
              ))
            );
          })
        );
        return resultRef
      })
    );

  }

  IsMembershipDetailsValid(communityId: number, paymentId: number, transactionId: string) {
    let params: any = { 'communityId': communityId, 'paymentId': paymentId, 'transactionId': transactionId }
    return this.http.get<boolean>("/api/common/ValidateMembershipDetails", { params: params });
  }

  getCommunityLogo(communityId: number) {
    return this.http.get<CommunitiLogoDto>(`/api/community/GetCommunityLogo/${communityId}`);
  }
}
