import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'bzg-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {

  transactionId: number;
  paymentId: number;
  communityId:number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show('paymentConfirmation');
    this.route.params.subscribe((route: any) => {
      this.transactionId = route.transactionId;
      this.paymentId = route.paymentId;
      this.communityId = route.communityId;

    });

    const navigationExtras: NavigationExtras = {
      state: {
        paymentId: this.paymentId,
        transactionNo: this.transactionId
      }
    };
    if(this.router.url.startsWith('/admin/advertisement')){
      this.router.navigate(['admin/advertisement/promotion-payment-receipt'], navigationExtras);
    }else if(this.router.url.startsWith('/admin/community/'+this.communityId+'/advertisement')){
      this.router.navigate(['admin/community/'+this.communityId+'/advertisement/promotion-payment-receipt'], navigationExtras);
    } else {
      this.router.navigate(['/advertisement/promotion-payment-receipt'], navigationExtras);
    }
    

  }

}
