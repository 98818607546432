import { createReducer, on } from '@ngrx/store';
import { ApplicationConfigurationState, CommonApplicationState, UserActiveState, UserMembershipPlansState, widgetsState } from '../state/app.state';
import * as fromActions from './../actions/app.action';


export const initialStateUserStatus: UserActiveState = {
    userStatus: {
        communityConfig: null,
        communityFeatureAccess: null,
        license: null,
        profileLandingPageId: null,
        communityLandingUrl: null,
        groupLandingUrl: null,

    },
    loggedInRedirect: false,
    loaded: false,
    reload: false,
};

export const reducerUserStatus = createReducer<UserActiveState>(
    initialStateUserStatus,
    on(fromActions.LoadUserStatusNotLoggedIn, (state) => ({
        ...state,
        userStatus: null,
        loaded: true
    })),
    on(fromActions.LoadUserStatusReload, (state) => ({
        ...state,
        userStatus: null,
        reload: false
    })),
    on(fromActions.LoadUserStatusReloadSccess, (state, { userStatus }) => ({
        ...state,
        userStatus: userStatus,
        loaded: true,
        reload: true
    })),
    on(fromActions.LoadUserStatusSuccess, (state, { userStatus }) => ({
        ...state,
        userStatus: userStatus,
        loaded: true,
        reload: false
    })),
    on(fromActions.LoadUserStatusError, (state, { error }) => ({
        ...state,
        userStatus: null,
        loaded: false,
        reload: true
    })),
    on(fromActions.userLoggedInRedirectionn, (state) => ({
        ...state,
        loggedInRedirect: true
    })),
);

export const initialState: ApplicationConfigurationState = {
    tenantBusinessType: null,
    tenant: null,
    logo: {
        icon: "assets/images/logo/bizligo-logo-icon.png",
        brand: "assets/images/logo/bizligo-logo-text.png",
        logo: "assets/images/logo/bizligo-logo.png",
        favicon: "favicon.ico",
    },
    tenantFeatureConfiguration: null,
    clientConfiguration: null,
    language: null,
    currentUserInfo: null,
    menuList: null,
    hasFeatureAccess: null,
    headerFooterConfiguration: null,
    communityFeatureConfiguration: null,
};
export const reducerAppConfig = createReducer<ApplicationConfigurationState>(
    initialState,
    on(fromActions.LoadBusinessTypeSuccess, (state, { businessType }) => ({
        ...state,
        tenantBusinessType: businessType
    })),
    on(fromActions.LoadBusinessTypeError, (state, { error }) => ({
        ...state,
        tenantBusinessType: null
    })),
    on(fromActions.LoadTenantIdSuccess, (state, { tenant }) => ({
        ...state,
        tenant: tenant
    })),
    on(fromActions.LoadTenantIdError, (state, { error }) => ({
        ...state,
        tenantId: null
    })),
    on(fromActions.LoadTenantFeatureConfigurationSuccess, (state, { config }) => ({
        ...state,
        tenantFeatureConfiguration: config
    })),
    on(fromActions.LoadTenantIdError, (state, { error }) => ({
        ...state,
        tenantFeatureConfiguration: null
    })),
    on(fromActions.LoadClientConfiguration, (state) => ({
        ...state,
    })),
    on(fromActions.LoadClientConfigurationSuccess, (state, { config }) => ({
        ...state,
        clientConfiguration: config
    })),
    on(fromActions.LoadClientConfigurationError, (state, { error }) => ({
        ...state,
    })),
    on(fromActions.LoadAvilableLanguagesSuccess, (state, { langs }) => ({
        ...state,
        language: langs
    })),
    on(fromActions.LoadAvilableLanguagesError, (state, { error }) => ({
        ...state,
        language: ['en']
    })),
    on(fromActions.LoadCurrentUserInfo, (state) => ({
        ...state,
    })),
    on(fromActions.LoadCurrentUserInfoSuccess, (state, { userInfo }) => ({
        ...state,
        currentUserInfo: userInfo
    })),
    on(fromActions.LoadCurrentUserInfoError, (state, { error }) => ({
        ...state,
    })),
    on(fromActions.LoadMenuData, (state) => ({
        ...state,
    })),
    on(fromActions.LoadMenuDataSuccess, (state, { menus }) => ({
        ...state,
        menuList: menus
    })),
    on(fromActions.LoadMenuDataError, (state, { error }) => ({
        ...state,
    })),
    on(fromActions.hasFeatureAccess, (state, { keyName, communityId }) => ({
        ...state,
        hasFeatureAccess: {
            keyName: keyName,
            communityId: communityId
        }
    })),
    on(fromActions.hasFeatureAccessResult, (state, { hasAccess }) => ({
        ...state,
        hasFeatureAccess: {
            ...state.hasFeatureAccess,
            hasAccess: hasAccess
        }
    })),
    on(fromActions.LoadHeaderFooterConfiguration, (state) => ({
        ...state,
    })),
    on(fromActions.LoadHeaderFooterConfigurationSuccess, (state, { hfconfig }) => ({
        ...state,
        headerFooterConfiguration: hfconfig
    })),
    on(fromActions.LoadHeaderFooterConfigurationError, (state, { error }) => ({
        ...state,
    })),
    on(fromActions.LoadTenantLogo, (state) => ({
        ...state,
    })),
    on(fromActions.LoadTenantLogoSuccess, (state, { logo }) => ({
        ...state,
        logo: logo
    })),
    on(fromActions.LoadTenantLogoError, (state, { error }) => ({
        ...state,
    })),
    on(fromActions.LoadCommunityFeatureConfigurationSuccess, (state, { config }) => ({
        ...state,
        communityFeatureConfiguration: config
    })),
    on(fromActions.LoadCommunityFeatureConfigurationError, (state, { error }) => ({
        ...state,
        communityFeatureConfiguration: null
    })),
);

export const initialStateCommonApplicationState: CommonApplicationState = {
    currentPageName: "Home",
    currentPagTitle: "Home",
    sideNavIsOpen: true,
    communityLogoUrl: null,
    link: null,
    displayFooter: true
};

export const reducerCommonApplicationState = createReducer<CommonApplicationState>(
    initialStateCommonApplicationState,
    on(fromActions.ChangePageName, (state, { pageName }) => ({
        ...state,
        currentPageName: pageName
    })),
    on(fromActions.ChangePageTitle, (state, { pageTitle }) => ({
        ...state,
        currentPagTitle: pageTitle
    })),
    on(fromActions.SideNavOpen, (state) => ({
        ...state,
        sideNavIsOpen: true
    })),
    on(fromActions.SideNavClose, (state,) => ({
        ...state,
        sideNavIsOpen: false
    })),
    on(fromActions.ChangeFooterVisibility, (state, { visible }) => ({
        ...state,
        displayFooter: visible
    })),
    on(fromActions.UpdateCommunityLogo, (state, { logoUrl, link }) => ({
        ...state,
        communityLogoUrl: logoUrl,
        link: link
    })),
);

export const widgetinitialState: widgetsState = {
    communitywidgets: null,

};


export const membershipstatussesinitialState: UserMembershipPlansState = {
    data: null,
    
  };

  export const membershipstatussesinitialStatereducer = createReducer<UserMembershipPlansState>(
    membershipstatussesinitialState,
    on(fromActions.LoadMembershipStatuses, (state) => ({
      ...state,
      data: {
        error: null,
        data: null,
        loaded: false,
        loading: true
      }
      
    })),
    on(fromActions.LoadMembershipStatusesSuccess, (state, { memberShipstatusses }) => ({
      ...state,
      data: {
        error: null,
        data: memberShipstatusses,
        loaded: true,
        loading: false
      }
    })),
    on(fromActions.LoadMembershipStatusesError, (state, { error }) => ({
      ...state,
      data: {
        error: error,
        data: null,
        loaded: true,
        loading: false
      }
    })),
    
  );