import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class BlogsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translate.set('blogs.search-blog-posts.placeholder', 'Search Blog Posts', 'en');
    this.translate.set('blogs.search-blog-News.placeholder', 'Search News', 'en');
    this.translate.set('blogs.search-category', 'Search By Categories', 'en');
    this.translate.set('blogs.search-communities', 'Search Communities', 'en');
    this.translate.set('blogs.it-industry', 'IT Industry', 'en');
    this.translate.set('blogs.communications', 'Communications', 'en');
    this.translate.set('blogs.search', 'SEARCH', 'en');
    this.translate.set('blogs.reset', 'RESET', 'en');
    this.translate.set('blogs.latest posts', 'Latest Posts', 'en');
    this.translate.set('blogs.categories', 'Categories', 'en');
    this.translate.set('blogs.tags', 'Tags', 'en');
    this.translate.set('blogs.no-blogs', 'No Blogs Found', 'en');
    this.translate.set('blogs.unable-to-load', 'Unable to Load Data', 'en');
    this.translate.set('blogs.searching', 'Searching...', 'en');
    this.translate.set('blogs.loading', 'Loading...', 'en');
    this.translate.set('blogs.read-more', 'Read more...', 'en');
    this.translate.set('blogs.blogs-page-name', 'Blogs', 'en');
    this.translate.set('blogs.blogs-details-page-name' , 'Blogs Details', 'en');
    this.translate.set('blogs.back-to-blogs' , 'BACK TO BLOGS', 'en');
    this.translate.set('blogs.no-latestposts-found' , 'No Latest Posts Found', 'en');
    this.translate.set('blogs.no-tags-found' , 'No Tags Found', 'en');
    this.translate.set('blogs.no-categories-found' , 'No Categories Found', 'en');
    // latest changes
    this.translate.set('blogs.blog-details-is-not-available', 'Blog details is not available', 'en');

    return this.localResourceService.loadLocalResource("blogs").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
