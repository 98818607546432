<bzg-loading [show]="isLoading" [ngClass]="isLoading?'loadingBackdrop':''" [message]="loadingText"></bzg-loading>
<p class="text-danger" *ngIf="scriptNotLoaded">
    <i class="fa fa-exclamation-triangle"></i> {{'paymentgateway.unable-to-load' | translate}}
</p>
<div class="text-danger" *ngIf="hasError">
    <b>
        <i class="fa fa-exclamation-triangle"></i>
        {{'paymentgateway.payment-is-not-configured-properly' | translate}}
    </b>
    <ul>
        <li *ngFor="let err of error">{{err}}</li>
    </ul>
</div>
<form *ngIf="!scriptNotLoaded">
    <div class="d-grid gap-2" id="authorizePaymentButtonId">
        <button type="button" class="AcceptUI btn btn-primary btn-lg p-3" [attr.data-apiLoginID]="apiLoginID"
            [attr.data-clientKey]="clientKey" data-acceptUIFormBtnTxt="Submit"
            data-billingAddressOptions='{"show":true, "required":false}' [attr.data-acceptUIFormHeaderTxt]="authorizeHeaderTxt || 'Card Information'"
            data-responseHandler="responseHandler">
            {{'paymentgateway.pay-now'| translate }}
        </button>
    </div>
</form>