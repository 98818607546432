<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul id="sidebarnav" class="list" [ngStyle]="{
          position: 'relative',
          'max-height': listMaxHeight + 'px',
          'max-width': listMaxWidth + 'px'
        }" [perfectScrollbar]>

        <li [ngClass]="showHomeColor?'homePageActive':''" class="sidebarListmainLi" [class.active]="
            level1Menu === sidebarItem?.moduleName &&
            sidebarItem?.submenu?.length != 0
          " *ngFor="let sidebarItem of sidebarItems | async"
          [routerLinkActive]="sidebarItem?.submenu?.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem?.groupTitle === true">
            {{ sidebarItem?.title | translate }}
          </div>
          <a [routerLink]="sidebarItem?.class === '' ? ['/' + sidebarItem?.path] : null"
            *ngIf="!sidebarItem?.groupTitle && !sidebarItem.isLink" [ngClass]="[sidebarItem?.class]"
            (click)="callLevel1Toggle($event, sidebarItem?.moduleName)" class="menu-top">
            <i-feather [matTooltip]="sidebarItem?.title | translate" [matTooltipPosition]="'above'"
              [name]="sidebarItem?.icon" class="sidebarIcon"></i-feather>
            <span class="hide-menu">{{ sidebarItem?.title | translate }} </span>
          </a>
          <a [attr.target]="sidebarItem?.submenu.length>0 ? '':'_blank'"
            [href]="sidebarItem?.class === '' ? sidebarItem?.path : 'javascript:void(0)' | safe:'url'"
            *ngIf="!sidebarItem?.groupTitle && sidebarItem.isLink" [ngClass]="[sidebarItem?.class]"
            (click)="callLevel1Toggle($event, sidebarItem?.moduleName)" class="menu-top">
            <i-feather [matTooltip]="sidebarItem?.title | translate" [matTooltipPosition]="'above'"
              [name]="sidebarItem?.icon" class="sidebarIcon"></i-feather>
            <span class="hide-menu">{{ sidebarItem?.title | translate }} </span>
          </a>
          <!-- First level menu -->
          <ul [attr.sidebar-list-title]="sidebarItem?.title | translate" class="ml-menu sidebarListmainLi--Submenu "
            *ngIf="sidebarItem?.submenu?.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem?.moduleName ? 'activeSub' : ''"
              *ngFor="let sidebarSubItem of sidebarItem?.submenu"
              [routerLinkActive]="sidebarSubItem?.submenu?.length > 0 ? '' : 'active'">
              <a *ngIf="!sidebarSubItem?.isLink"
                [routerLink]="sidebarSubItem?.submenu?.length > 0? null : ['/' + sidebarSubItem?.path]"
                (click)="callLevel2Toggle($event, sidebarSubItem?.moduleName)" [ngClass]="[sidebarSubItem?.class]">
                {{ sidebarSubItem?.title | translate }}
              </a>
              <a [attr.target]="sidebarSubItem?.submenu.length>0 ? '':'_blank'" *ngIf="sidebarSubItem?.isLink"
                [href]="sidebarSubItem?.submenu?.length == 0 ? sidebarSubItem?.path : 'javascript:void(0)' | safe:'url'"
                (click)="callLevel2Toggle($event, sidebarSubItem?.moduleName)" [ngClass]="[sidebarSubItem?.class]">
                {{ sidebarSubItem?.title | translate }}
              </a>

              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem?.submenu?.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem?.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem?.moduleName ? 'activeSubSub': ''"
                  [routerLinkActive]="sidebarSubsubItem?.submenu?.length > 0 ? '' : 'active'">
                  <a *ngIf="!sidebarSubsubItem?.isLink"
                    [routerLink]="sidebarSubsubItem?.submenu?.length > 0 ? null : ['/' + sidebarSubsubItem?.path]"
                    (click)="callLevel3Toggle($event, sidebarSubsubItem?.moduleName)"
                    [ngClass]="[sidebarSubsubItem?.class]">
                    {{ sidebarSubsubItem?.title | translate }}
                  </a>
                  <a target="_blank" *ngIf="sidebarSubsubItem?.isLink"
                    [href]="sidebarSubsubItem?.submenu?.length > 0 ? null : sidebarSubsubItem?.path"
                    (click)="callLevel3Toggle($event, sidebarSubsubItem?.moduleName)"
                    [ngClass]="[sidebarSubsubItem?.class]">
                    {{ sidebarSubsubItem?.title | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
<!-- <script>
  var cells = document.querySelectorAll(".sidebarListmainLi");
  for (var i = 0; i < cells.length; i++) {
    cells[i].addEventListener("mouseover", function () {
      this.getElementsByClassName('ml-menu')[0].style.top = this.offsetTop + "px";
      console.log(this.getElementsByClassName('ml-menu')[0], this.offsetTop)
    });
  }
    matTooltip="{{ sidebarItem?.title | translate }}" [matTooltipPosition]="'right'"
</script> -->