import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { ErrorStateMatcher, MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { DirectiveModule } from '../directive/directive.module';
import { PipeModule } from '../pipe/pipe.module';
import { FormErrorComponent } from './shared/components/form-error/form-error.component';
import { InternalServerErrorComponent } from './shared/components/internal-server-error/internal-server-error.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { OfflineComponent } from './shared/components/offline/offline.component';
import { PermissionDeniedComponent } from './shared/components/permission-denied/permission-denied.component';
import { SomethingWentWrongComponent } from './shared/components/something-went-wrong/something-went-wrong.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { ConfirmDailogComponent } from './shared/components/confirm-dailog/confirm-dailog.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DefaultErrorStateMatcher } from '../directive/validation/validation.directive';
import { SelectComponent } from './shared/components/select/select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImageCropperModule } from 'ngx-image-cropper';
//import { ImageCroppieComponent } from './shared/components/image-croppie/image-croppie.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPrintModule } from 'ngx-print';
//import { MultiSelectChipComponent } from './shared/components/multi-select-chip/multi-select-chip.component';
//import { HtmlComponent } from './shared/components/html/html.component';
import { Html2Component } from './shared/components/html2/html2.component';
import { MultiSelectModule } from './shared/components/multi-select/multi-select.module';
import { MultiSelectChipModule } from './shared/components/multi-select-chip/multi-select-chip.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HtmlModule } from './shared/components/html/html.module';
import { LoadingModule } from './shared/components/loading/loading.module';
import { IntlInputModule } from './shared/components/intl-input/intl-input.module';
import { ImageCroppieModule } from './shared/components/image-croppie/image-croppie.module';
import { CustomDateAdapter, CustomDateFormatsProvider } from '../services/common/mat-dateformat.service';
@NgModule({

  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    DirectiveModule,
    PipeModule,
    MatDialogModule,
    FormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ImageCropperModule,
    NgxSliderModule,
    MatTooltipModule,
    NgxPrintModule,
    HtmlModule,
    LoadingModule,
    IntlInputModule,
  ],
  declarations: [
    FormErrorComponent,
    //HtmlComponent,
    //Html2Component,
    InternalServerErrorComponent,
    //LoadingComponent,
    //MultiSelectChipComponent,
    PermissionDeniedComponent,
    OfflineComponent,
    SomethingWentWrongComponent,
    FileUploadComponent,
    ConfirmDailogComponent,
    SelectComponent,
    //ImageCroppieComponent,
  ],
  exports: [
    MatFormFieldModule,
    FormErrorComponent,
    InternalServerErrorComponent,
    //LoadingComponent,
    //HtmlComponent,
    //Html2Component,
    //MultiSelectChipComponent,
    PermissionDeniedComponent,
    OfflineComponent,
    SomethingWentWrongComponent,
    FileUploadComponent,
    SelectComponent,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    //AutocompleteLibModule,
    MatBottomSheetModule,
    PipeModule,
    PerfectScrollbarModule,
    DirectiveModule,
    NgxSpinnerModule,
    NgxPrintModule,
    MatMomentDateModule,
    MultiSelectModule,
    MultiSelectChipModule,
    HtmlModule,
    LoadingModule,
    IntlInputModule,
    ImageCroppieModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: ErrorStateMatcher, useClass: DefaultErrorStateMatcher },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }, // Set default locale
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CustomDateFormatsProvider,
    CurrencyPipe
  ],
})
export class MaterialModule { }
