import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { map } from 'rxjs';
import { ContentPage } from 'src/app/models/common/listItem';
import { CommunitySpotLightEvents } from 'src/app/module/community-details/store/state/community-home';
import { BannerSlide, GWCHomePageData, HomePageData, Sponsor } from 'src/app/module/home/store/state/home';
import { ITestimonial } from 'src/app/module/testimonials/store/state/testimonials';
import { TawlkToUrl } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  // Check for duplicate items
  checkforduplications(keyName: string, value: string) {
    return this.http.get<boolean>("/api/common/CheckforDuplications", { params: { keyName, value } });
  }

  getResourceLocalization(resourceSet: string) {
    const url = '/api/common/getresourcelocalization';
    return this.http.get<boolean>(url, { params: { resourceSet } });
  }
  getContentPage(id: number) {
    const url = '/api/common/GetContentPage';
    return this.http.get<ContentPage>(url, { params: { id } });
  }
  getTawkToUrl() {
    const url = '/api/common/gettawktourl';
    return this.http.get<TawlkToUrl>(url);
  }
  getTenantHomePage() {
    const url = '/api/common/gettenanthomepage';
    return this.http.get<HomePageData>(url);
  }
  getGWCHomePageData() {
    const url = '/api/home/getgwchomepagedata';
    return this.http.get<GWCHomePageData>(url);
  }

  saveCkeditorImages() {
    const url = '/api/common/saveCkeditorImages';
    return this.http.get<TawlkToUrl>(url);
  }

  getUrlPreview(data: any) {
    let params: any = { 'key': data.key, 'q': data.q }
    const url = 'https://api.linkpreview.net';
    return this.http.get<any>(url, { params: params });
  }

  checkRestrictedWords(data: any) {
    let params: any = { 'text': data }
    return this.http.post<any>('/api/common/checkRestrictedWords', { text: data });
  }

  getAllBannerImages() {
    const url = '/api/home/GetAllBannerImages';
    return this.http.get<BannerSlide[]>(url);
  }

  getAllSponsors() {
    const url = '/api/home/GetAllSponsors';
    return this.http.get<Sponsor[]>(url);
  }

  getAllSpotlightEvents(){
    const url = '/api/event/getallspotlightevent';
    return this.http.get<CommunitySpotLightEvents[]>(url).pipe(
      map(data => {
        data.map(item => {
          item.registrantInfoData = JSON.parse(item.registrantInfo);
          item.isSameDayEvent = moment(new Date(item.eventStartDate)).isSame(new Date(item.eventEndDate), 'date');
          return item;
        });
        return data;
      })
    );
  }

  getAllTestimonials() {
    const url = '/api/home/GetAllTestimonials';
    return this.http.get<ITestimonial[]>(url);
  }

  saveNewsLetter(emailId:string) {
   // /api/group/GetGroupEventsForCalendar?groupId=${groupId}`
    return this.http.post<boolean>(`/api/common/SaveNewsLetter?EmailId=${emailId}`,{})
  }

}
