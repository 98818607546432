<div mat-dialog-title *ngIf="data.title">
    <div class="row">
        <div class="col">
            <h3 class="mb-1 font-16 fw-500">{{ data.title }}</h3>
        </div>
    </div>
</div>

<mat-dialog-content *ngIf="data.description" class="mat-typography scrollbar">
    <div [innerHTML]="data.description"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button *ngIf="data.noText" class="btn btn-outline-danger px-4 msr-3" (click)="onNoClick()" type="button">
        {{ data.noText }}
    </button>
    <button *ngIf="data.yesText" class="btn btn-theme px-4" (click)="onYesClick()" type="button">
        {{ data.yesText }}
    </button>
</mat-dialog-actions>