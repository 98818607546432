<div class="newsletterWidgets">
    <div class="row align-items-center">
        <div class="col-lg-12 text-center news-letter-img">
            <img src="assets/images/admin/news-letter-icon.png" class="img-fluid" alt="news letter">
        </div>
        <div class="col-lg">

            <h4 class="text-theme-secondary mb-2 fw-600">Subscribe to Our Newsletter</h4>
            <p class="mb-0">Stay up to date with our latest news & products</p>
        </div>
        <div class="col-lg-auto">
            <form  [formGroup]="addNewsLetterForm">
                <div class="row g-2">
                    <div class="col">
                        <div class="">
                            <label for="" class="form-label">
                                <input type="text" [error]="email" class="form-control"  placeholder="Enter Your Email "
                                    formControlName="email" matInput>
                                    <mat-error #email></mat-error>
                            </label>
                        </div>
                    </div>
                    <div class="col-auto ps-1">
                        <button type="button" (click)="onSubmitEmail()" [disabled]="!addNewsLetterForm.valid" class="btn btn-theme">Subscribe</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>