import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class CommunitiesModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translate.set('communities.dashboard' , 'Dashboard', 'en');
    this.translate.set('communities.communities', 'Communities', 'en');
    this.translate.set('communities.search-by-community-name.placeholder', 'Search By Community Name update', 'en');
    this.translate.set('communities.search' , 'Search ', 'en');
    this.translate.set('communities.reset' , 'RESET', 'en');
    this.translate.set('communities.join' , 'JOIN', 'en');
    this.translate.set('communities.waiting-for-approval' , 'Waiting for approval', 'en');
    this.translate.set('communities.cancel' , 'Cancel', 'en');
    this.translate.set('communities.leave' , 'Leave', 'en');
    this.translate.set('communities.accept' , 'Accept', 'en');
    this.translate.set('communities.reject' , 'Reject', 'en');
    this.translate.set('communities.payment-pending'  , 'Payment Pending', 'en');
    this.translate.set('communities.category' , 'Category', 'en');
    this.translate.set('communities.processing-your-request'  , 'Please wait while processing your request...', 'en');
    this.translate.set('communities.loading'  , 'Loading...', 'en');
    this.translate.set('communities.communities-page-name'  , 'Communities', 'en');
    // choose plan page
    this.translate.set('communities.flexible-pricing-that-scales-with-our-business', 'Flexible Pricing that Scales with Your Business', 'en');
    this.translate.set('communities.processing', 'Processing...', 'en');
    this.translate.set('communities.loading-plans', 'Loading plans', 'en');
    this.translate.set('communities.membership-plans-not-available', 'Membership plans not available', 'en');
    this.translate.set('communities.members', 'members', 'en');
    this.translate.set('communities.selected-plan', 'Selected Plan', 'en');
    this.translate.set('communities.check-out', 'Check Out', 'en');
    this.translate.set('communities.membership-plans-page-name', 'Membership plans', 'en');
    // Payment receipt
    this.translate.set('communities.payment-receipt', 'Getting your payment receipt...', 'en');
    this.translate.set('communities.unable-to-load-payment-receipt', 'Unable to load payment receipt...', 'en');
    this.translate.set('communities.the-payment-receipt-page-cannot-be-accessed-through-the-url', 'We\'re sorry, but the payment receipt page cannot be accessed through the URL. Please return to the previous page or contact customer support if you need assistance', 'en');
    this.translate.set('communities.your-payment-processed-successfully', 'Your Payment Processed Successfully...', 'en');
    this.translate.set('communities.waiting-for-approval', 'Your Organization is Waiting for Approval', 'en');
    this.translate.set('communities.hi', 'Hello', 'en');
    this.translate.set('communities.this-is-the-payment-confirmation-of', 'This is the Payment confirmation of', 'en');
    this.translate.set('communities.plan-for', 'plan for', 'en');
    this.translate.set('communities.transaction-id', 'Transaction Id', 'en');
    this.translate.set('communities.payment-date', 'Payment Date', 'en');
    this.translate.set('communities.licence-valid-upto', 'Licence Valid Upto', 'en');
    this.translate.set('communities.plan-description', 'Plan Description', 'en');
    this.translate.set('communities.payment-summary', 'Payment Summary', 'en');
    this.translate.set('communities.plan-name', 'Plan Name', 'en');
    this.translate.set('communities.price', 'Price', 'en');
    this.translate.set('communities.amount-paid', 'Amount Paid', 'en');
    this.translate.set('communities.payment-receipt-page-name', 'Payment receipt', 'en');
    this.translate.set('communities.payment-completed', 'Payment completed successfully', 'en');
    this.translate.set('communities.unable-to-update-payment', 'Unable to update payment', 'en');
    this.translate.set('communities.unable-to-load-membership-plans', 'Unable to load membership plans', 'en');
    this.translate.set('communities.unable-to-load-payment-methods', 'Unable to load payment methods', 'en');
    this.translate.set('communities.membership-plan-purchase-successfully', 'Membership plan purchase successfully', 'en');
    this.translate.set('communities.unable-to-purchase-plan', 'Unable to purchase plan', 'en');
    this.translate.set('communities.something-went-wrong-please-try-again', 'Something went wrong. Please try again.', 'en');
    this.translate.set('communities.Choose-your-right-membership-plan-to-start-the-journey-with-the', 'Choose your right membership plan to start the journey with the', 'en');

    this.translate.set('communities.Access-to-the-community blogs', 'Access to the community blogs', 'en');
    this.translate.set('communities.Access-to-discussion-forums', 'Access to discussion forums', 'en');
    this.translate.set('communities.Early-access-to-tickets-and-private-events', 'Early access to tickets and private events', 'en');
    this.translate.set('communities.Exclusive-groups-access', 'Exclusive groups access', 'en');
    this.translate.set('communities.Exclusive-Access-to-the-community-jobs', 'Exclusive Access to the community jobs', 'en');
    this.translate.set('communities.Access-to-the-community-resources', 'Access to the community resources(Videos, Audios and documents)', 'en');
    this.translate.set('communities.Exclusive-access-to-community-directory', 'Exclusive access to community directory', 'en');
    this.translate.set('communities.Safe-&-Secured-private-messaging', 'Safe & Secured private messaging', 'en');
    this.translate.set('communities.Access-to-complete-member-directory', 'Access to complete member directory', 'en');
    this.translate.set('communities.Glance-of-updates-on-community-in-an-email', 'Glance of updates on community in an email', 'en');
    this.translate.set('communities.Get-rewarded-while-using-the-features', 'Get rewarded while using the features', 'en');
    this.translate.set('communities.Access-to-E-Learning-tool', 'Access to E-Learning tool', 'en');
    this.translate.set('communities.Access-to-Mobile-app', 'Access to Mobile app', 'en');
    this.translate.set('communities.Endorse-the-members-and-their-skills', 'Endorse the members and their skills', 'en');
    this.translate.set('communities.Timely-reminders', 'Timely reminders', 'en');
    this.translate.set('communities.Smart-way-to-get-promote-your-usiness', 'Smart way to get promote your business', 'en');
    this.translate.set('communities.no', 'No', 'en');
    this.translate.set('communities.yes-proceed', 'Yes, Proceed', 'en');
     // latest changes
     this.translate.set('communities.no-communities-found', 'No Communities Found', 'en');
     this.translate.set('communities.we-did-not-find-anything', 'Oops...we did not find anything that matches your search:(', 'en');
     this.translate.set('communities.stay-tuned-and-update', 'Stay tuned & Stay update', 'en');
     this.translate.set('communities.your-community-request-has-been-sent', 'Your community request has been sent to admin for review', 'en');
     this.translate.set('communities.you-can-complete-the-remaining-step', 'You can complete the remaining step after your request is approved', 'en');
     this.translate.set('communities.you-will-be-notified-with-an-email', 'You will be notified with an email after admin approves', 'en');
     this.translate.set('communities.no-rewnewals-allowed', 'You cannot renew using same membership plan, please select another plan to renew...', 'en');

    this.translate.set('communities.terms-and-conditions', 'Terms and Conditions:', 'en');
    this.translate.set('communities.i-agree-to-terms-and-conditions', 'I agree to terms and conditions', 'en');
    return this.localResourceService.loadLocalResource("communities").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
