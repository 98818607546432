import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { License } from 'src/app/models/accounts/user';

@Component({
  selector: 'bzg-account-suspended',
  templateUrl: './account-suspended.component.html',
  styleUrls: ['./account-suspended.component.scss']
})
export class AccountSuspendedComponent implements OnInit {
  status: License;
  constructor(
    private router: Router,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { isAllowed: boolean };
    if (!state.isAllowed != undefined) {
      if(!state.isAllowed){
        this.router.navigate(["/404-not-found"]);
      }
    }
    if(state == null){
      this.router.navigate(["/"]);
    }
  }

  ngOnInit(): void {
    let status = localStorage.getItem('account-status');
    if (status != null) {
      this.status = JSON.parse(status) as License;
    } else {
      console.log("account status", status)
    }
  }

}
