<section class="content homePage fullWidthMainContent">
    <section class="page-banner">
        <div class="page-banner-bg"></div>
        <div class="container-fluid">
            <div class="page-banner__inner">
                <h1>9th NATIONAL CONFERENCE OF RESIDENT WELFARE ASSOCIATIONS</h1>
                <p>Focal Theme : Drinking Water</p>
            </div>
        </div>
    </section>
    <section class="about-corwa">
        <div class="container-fluid">
            <div class="row flex">
                <div class="col-lg-5 bg-left text-center">
                    <div class="about-imageinfo">
                        <img src="https://corwaadmin.bizligo.com/Content/Images/totalentertainment/info1.png"
                            class="img-fluid center-block" />
                    </div>
                </div>
                <div class="col-lg-7 bg-grey">
                    <div class="about-text">
                        <h2>About CoRWA</h2>
                        <p>The Confederation of Resident Welfare Associations (CoRWA) is the Pan India National Apex
                            Body of
                            RWAs. Resident Welfare Associations are the finest expression of urban civil society
                            consensus:
                            an answer to many urban social problems, a tool for community building and for
                            self–management
                            and direct democracy. </p>
                        <p>The thrust of this forum has been to promote a new urban cosmopolitan culture in which RWAs
                            would
                            seek to empower the citizens and play an important role in improving the quality of life of
                            their residents by building smart, efficient and caring communities. They also work with the
                            senior citizens associations formed within the local area with One Voice in respect of major
                            common issues and concerns.</p>
                        <a href="/community/Confederation-Of-Resident-Welfare-Associations/19"
                            class="btn join-community">JOIN OUR COMMUNITY</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="ads-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 mx-md-auto">
                    <h2>Advertise Your Business</h2>
                    <!-- Carousel -->
                    <div id="CorwaAdCarosel" class="carousel slide" data-bs-ride="carousel">

                        <!-- Indicators/dots -->
                        <!--<div class="carousel-indicators">
        <button type="button" data-bs-target="#CorwaAdCarosel" data-bs-slide-to="0" class="active"></button>
        <button type="button" data-bs-target="#CorwaAdCarosel" data-bs-slide-to="1"></button>
        <button type="button" data-bs-target="#CorwaAdCarosel" data-bs-slide-to="2"></button>
      </div>-->

                        <!-- The slideshow/carousel -->
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/adv1.png"
                                    alt="Los Angeles" class="d-block" style="width:100%">
                            </div>
                            <div class="carousel-item">
                                <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/adv2.png"
                                    alt="Chicago" class="d-block" style="width:100%">
                            </div>

                        </div>

                        <!-- Left and right controls/icons -->
                        <button class="carousel-control-prev" type="button" data-bs-target="#CorwaAdCarosel"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon"></span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#CorwaAdCarosel"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon"></span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <section class="about-conference">
        <div class="container-fluid">
            <div class="row flex">
                <div class="col-lg-7 ">
                    <div class="about-text">
                        <h2>About the Conference </h2>
                        <p>Resident Welfare Associations are the finest expression of urban civil society consensus: an
                            answer to many urban social problems, a tool for community building and for self–management,
                            and
                            direct democracy.</p>
                        <a href="/community/Confederation-Of-Resident-Welfare-Associations/19" class="btn join-now">JOIN
                            OUR
                            COMMUNITY</a>
                    </div>
                </div>
                <div class="col-lg-5 bg-left text-center">
                    <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/info2.png"
                        class="img-fluid center-block" />
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="sponsor-logos slider">
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor1.png">
        </div>
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor2.png">
        </div>
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor3.png">
        </div>
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor4.png">
        </div>
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor5.png">
        </div>
        <div class="slide">
            <img src="https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor6.png">
        </div>
    </section> -->
    <section class="sponsor-logos">
        <div class="container">

            <div class="sponsers-slider-main">
                <div class="sponsers-corwa-slider">
                    <div *ngFor="let item of sponsors;let i=index; let last = last; trackBy: similarBusinessTrackByFn"
                        class="">
                        <div class="sponser-slider-item">
                            <!-- <a [href]="item.link" class=" stretched-link" target="_blank"></a> -->
                            <img [src]="item.image" class="img-fluid" />
                        </div>
                        <span *ngIf="last">{{ initSlider() }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="join-hands">
        <div class="container-fluid">
            <div class="row">
                <div class="mx-md-auto col-md-8">
                    <p>Join hands with us to extend your horizon at 9th National Conference of CoRWA.</p>
                    <a href="/auth/signup" class="btn join-now">JOIN NOW</a>
                </div>
            </div>
        </div>
    </section>
</section>