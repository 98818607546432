import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
   // --------------------------------------------------------------------------------
   // my-Advertisements
   this.translate.set('advertisement.tap-here-to-create-new-add' , 'To post advertisements in community, go to respective community page', 'en');
   this.translate.set('advertisement.create-global-add' , 'CREATE GLOBAL AD', 'en');
   this.translate.set('advertisement.search-by-place-name.placeholder' , 'Search By Plan Name', 'en');
   this.translate.set('advertisement.search-by-community.placeholder' , 'Search By Community', 'en');
   this.translate.set('advertisement.search-by-ad-title.placeholder' , 'Search By Ad Title', 'en');
   this.translate.set('advertisement.search-by-duration.placeholder' , 'Search By Duration', 'en');
   this.translate.set('advertisement.search-by-status' , 'Search By Status', 'en');
   this.translate.set('advertisement.waiting-for-approval' , 'Waiting For Approval', 'en');
   //this.translate.set('advertisement.expired' , 'Expired', 'en');
   this.translate.set('advertisement.approved' , 'Approved', 'en');
   this.translate.set('advertisement.rejected' , 'Rejected', 'en');
   this.translate.set('advertisement.inactive' , 'InActive', 'en');
   this.translate.set('advertisement.the-payment-receipt-page-cannot-be-accessed-through-the-url','We\'re sorry, but the payment receipt page cannot be accessed through the URL. Please return to the previous page or contact customer support if you need assistance','en')
   
   this.translate.set('advertisement.search' , 'Search', 'en');
   this.translate.set('advertisement.reset' , 'Reset', 'en');
   this.translate.set('advertisement.there-is-no-advertisement-for-you-as-of-now' , 'There is no advertisement for you as of now!', 'en');
   this.translate.set('advertisement.ad-title' , 'Ad Title', 'en');
   this.translate.set('advertisement.plan-name' , 'Plan Name', 'en');
   this.translate.set('advertisement.ad-location' , 'Ad Location', 'en');
   this.translate.set('advertisement.ad-type' , 'Ad Type', 'en');
   this.translate.set('advertisement.plan-dates' , 'Plan Dates', 'en');
   this.translate.set('advertisement.duration' , 'Duration', 'en');
   this.translate.set('advertisement.global' , 'Global', 'en');
   this.translate.set('advertisement.yes' , 'Yes', 'en');
   this.translate.set('advertisement.no' , 'No', 'en');
   // create ads
   this.translate.set('paymentgateway.pay-now', 'Pay Now', 'en');
   this.translate.set('advertisement.select-plan', 'Select Plan', 'en');
   this.translate.set('advertisement.loading', 'Loading...', 'en');
   this.translate.set('advertisement.location', 'Location', 'en');
   this.translate.set('advertisement.advertisement-type', 'Advertisement Type', 'en');
   this.translate.set('advertisement.image', 'Image', 'en');
   this.translate.set('advertisement.video', 'Video', 'en');
   this.translate.set('advertisement.choose-plan', 'CHOOSE PLAN', 'en');
   this.translate.set('advertisement.details', 'Details', 'en');
   this.translate.set('advertisement.duration', 'Duration', 'en');
   this.translate.set('advertisement.cancel', 'CANCEL', 'en');
   this.translate.set('advertisement.next', 'NEXT', 'en');
   this.translate.set('advertisement.create-ad', 'Create Ad', 'en');
   this.translate.set('advertisement.saving', 'Saving', 'en');
   this.translate.set('advertisement.loading', 'Loading...', 'en');
   this.translate.set('advertisement.advertisement-location', 'Advertisement Location', 'en');
   this.translate.set('advertisement.advertisement-type', 'Advertisement Type', 'en');
   this.translate.set('advertisement.advertisement-title', 'Advertisement Title', 'en');
   this.translate.set('advertisement.preferred-duration-start-date', 'Preferred Duration(Start Date)', 'en');
   this.translate.set('advertisement.start-date', 'Start Date', 'en');
   this.translate.set('advertisement.preferred-duration-end-date', 'Preferred Duration(End Date)', 'en');
   this.translate.set('advertisement.end-date', 'End Date', 'en');
   this.translate.set('advertisement.advertisement-image', 'Advertisement Image', 'en');
   this.translate.set('advertisement.advertisement-redirection-url', 'Advertisement Redirection URL', 'en');
   this.translate.set('advertisement.url-to-member-redirected', 'URL to where member will be redirected when clicking on advertisement', 'en');
   this.translate.set('advertisement.youtube-video-url', 'YouTube Video URL', 'en');
   this.translate.set('advertisement.video-url', 'Video URL', 'en');
   this.translate.set('advertisement.additional-instructions', 'Additional Instructions', 'en');
   this.translate.set('advertisement.back', 'BACK', 'en');
   this.translate.set('advertisement.save', 'SAVE', 'en');
   this.translate.set('advertisement.procees-to-payment', 'PROCEED TO PAYMENT', 'en');
   this.translate.set('advertisement.make-payment', 'Make Payment', 'en');
   this.translate.set('advertisement.order-summary', 'Order Summary', 'en');
   this.translate.set('advertisement.plan', 'Plan', 'en');
   this.translate.set('advertisement.price', 'Price', 'en');
   this.translate.set('advertisement.enter-promo-code.placeholder', 'Enter Promo Code', 'en');
   this.translate.set('advertisement.apply', 'Apply', 'en');
   this.translate.set('advertisement.total-amount', 'Total Amount', 'en');
   this.translate.set('advertisement.promo-code', 'Promo Code', 'en');
   this.translate.set('advertisement.amount-to-be-paid', 'Amount to be Paid', 'en');
   this.translate.set('advertisement.pay-using', 'Pay using', 'en');
   this.translate.set('advertisement.ad-details-saved-successfully', 'Ad details saved successfully', 'en');
   this.translate.set('advertisement.unable-to-save-ad-details', 'Unable to save Ad details', 'en');
   this.translate.set('advertisement.payment-completed', 'Payment completed successfully', 'en');
   //promotionpurchase-receipt
   this.translate.set('advertisement.payment-receipt', 'Payment Receipt', 'en');
   this.translate.set('advertisement.view-community', 'VIEW COMMUNITY', 'en');
   this.translate.set('advertisement.your-advertisementTA', 'Your advertisement will be sent to the Tenant Admin for review.', 'en');
   this.translate.set('advertisement.your-advertisementCA', 'Your advertisement will be sent to the Community Admin for review.', 'en');
   this.translate.set('advertisement.your-advertisement', 'Your advertisement will be published.', 'en');
   this.translate.set('advertisement.my-advertisement-page-name','My Advertisements')
   this.translate.set('advertisement.hi', 'Hi ', 'en');
   this.translate.set('advertisement.payment-confirmation', 'This is the payment confirmation', 'en');
   this.translate.set('advertisement.for', 'for', 'en');
   this.translate.set('advertisement.payment-id', 'Payment ID', 'en');
   this.translate.set('advertisement.payment-date', 'Payment Date', 'en');
   this.translate.set('advertisement.plan-start-date-end-date', 'Plan Start Date & End Date', 'en');
   this.translate.set('advertisement.payment-summary', 'Payment Summary', 'en');
   this.translate.set('advertisement.amount-paid', 'Amount Paid', 'en');
   this.translate.set('advertisement.payment-receipt-page-name', 'Payment Receipt', 'en');
   this.translate.set('advertisement.no-more-records-available', 'No More Records', 'en');
   this.translate.set('advertisement.loading', 'Loading...', 'en');

   this.translate.set('advertisement.sorry', 'Sorry!', 'en');
   this.translate.set('advertisement.no-plans-available', 'No Plans Available', 'en');
   this.translate.set('advertisement.url-to-where-member-will-be-redirected-when-clicking-on-advertisement', 'URL to where member will be redirected when clicking on advertisement', 'en');
   this.translate.set('advertisement.short-description', 'Short Description', 'en');
   this.translate.set('advertisement.placeholder', '/', 'en');
   this.translate.set('advertisement.for-optimal-display', 'For Optimal display , upload the image of type (jpg, jpeg, png, bmp or gif) with 500px X250px dimensions (Max 5MB)', 'en');
   this.translate.set('advertisement.promo-code-applied', 'Congratulations! The promo code has been applied and will be reflected in your total amount.', 'en');
   this.translate.set('advertisement.promo-code', 'PROMO CODE', 'en');
   this.translate.set('advertisement.validating', 'Validating', 'en');
   this.translate.set('advertisement.submit', 'Submit', 'en');
   this.translate.set('advertisement.payment-processing', 'Payment Processing', 'en');
   this.translate.set('advertisement.not-valid', 'Sorry! The promo code you have entered is not valid. Please try with a different code.', 'en');
   this.translate.set('advertisement.promo-code-expired', 'Sorry! The promo code you have entered has expired. Please try with a different code.', 'en');
   this.translate.set('advertisement.exceeded-usage-limit', 'Sorry! The promo code you have entered has exceeded the usage limit. Please try with a different code.', 'en');

   this.translate.set('advertisement.please-enter-valid-first-name', 'Please enter valid first name', 'en');
   this.translate.set('advertisement.please-enter-valid-redirection-url', 'Please enter valid redirection Url', 'en');
   this.translate.set('advertisement.please-enter-valid-youtube-url', 'Please enter valid youtube Url', 'en');
   this.translate.set('advertisement.unable-to-load-payment-methods', 'Unable to load payment methods', 'en');
   this.translate.set('advertisement.dashboard', 'Dashboard', 'en');
   this.translate.set('advertisement.my-advertisement', 'My Advertisements', 'en');
   this.translate.set('advertisement.image-is-required', 'Image is required.', 'en');
   this.translate.set('advertisement.updated-ad', 'Update Ad', 'en');
   this.translate.set('advertisement.please-enter-valid-job-title', 'Please enter valid title', 'en');
    this.translate.set('advertisement.payment-receipt','Getting your payment receipt...', 'en');

   // --------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("advertisement").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
