import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import * as moment from 'moment';
import { CommunityInfoDetails, CommunityWidgetsDto, CommuntyUserStatusDto, MemberShipPlanDetails } from 'src/app/module/community-details/store/state/community-details';
import { Champions, CommunitySpotLightEvents } from 'src/app/module/community-details/store/state/community-home';
import { CalendarEvents, CommunityEventsDto, CommunityEventSearch, MostHappeningEvents, SuggestedEvents } from 'src/app/module/community-details/store/state/community-event';
import { SimilarPeople } from 'src/app/module/community-details/store/state/community-member';
import { CommunityResource, CommunityResourceQuery } from 'src/app/module/community-details/store/state/community-resources';
import { CommunitiusersInfo, CommunityDiscussionComments, CommunityDiscussionCommentsResult, CommunityDiscussionCommentsSearch, CommunityDiscussionDetails, CommunityDiscussionDetailsInfo, CommunityDiscussionLikesSearch, CommunityDiscussionSearch, CommunityDiscussionsLikes, CommunityUsers, CommunityUserSearch, CommuntyUser, MostSubscribedDiscussions } from 'src/app/module/community-details/store/state/community-discussions';
import { discussionData, like } from 'src/app/module/shared/discussions/discussions-list/discussions-list.component';
import { GroupInfoDto } from 'src/app/module/my-groups/store/state/my-groups';
import { CommunityGroupInfo, CommunityGroupInfoResult, CommunityGroupSearch, TrendingGroup } from 'src/app/module/community-details/store/state/community-groups';
import { ApplyJobDto, JobPosts, JobPostsInfo, JobPostsParam, MostAppliedJobs, YourAppliedJobs } from 'src/app/module/community-details/store/state/community-careers';
import { ICommunityDonation, IDonationPaymentSummary, IMemberDonationPaymentTransaction, SaveOrUpdateMemberDonationPaymentTransactionResult } from 'src/app/module/community-details/store/state/community-donation';
import { IPaymentInfo } from 'src/app/models/payment/payment-method';
import { SelectDropdown, SelectMembershipPlanDropdown } from 'src/app/models/common/listItem';


@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  communityId:BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private http: HttpClient) { }

  getCommunitiesList() {
    return this.http.get<Array<CommunityDropdownList>>('/api/community/getcommunities');
  }

  getCommunityFeatures(keyName: string) {
    return this.http.get<CommunityFeature>('/api/common/GetCommunityFeatures', { params: { keyName } });
  }

  joinOrLeaveCommunity(communityId: number, statusId: UserInCommunityStatus) {
    return this.http.post<boolean>('/api/community/SaveUserStatusInCommunity', {
      communityIds: [communityId],
      statusId: statusId,
      rejectReason: null
    });
  }

  getCommunintyInfo(communityId: number) {
    return this.http.get<CommunityInfoDetails>(`/api/community/getcommunityinfo/${communityId}`);
  }

  getCommunintyWidgetsInfo(communityId: number) {
    return this.http.get<CommunityWidgetsDto[]>('/api/community/GetCommunityWidgets', { params: { communityId } });
  }

  getCommunitySpotlightEvents(communityId: number) {
    return this.http.get<CommunitySpotLightEvents[]>(`/api/community/GetSpotLightEvents/${communityId}`).pipe(
      map(data => {
        data.map(item => {
          item.registrantInfoData = JSON.parse(item.registrantInfo);
          item.isSameDayEvent = moment(new Date(item.eventStartDate)).isSame(new Date(item.eventEndDate), 'date');
          return item;
        });
        return data;
      })
    );
  }

  getCommunintychampions(communityId: number) {
    return this.http.get<Champions[]>(`/api/community/getCommunintyChampions/${communityId}`).pipe(
      map(data => {
        data.map(item => {
          item.fullName = `${item.firstName} ${item.lastName}`;
          return item;
        });
        return data;
      })
    );
  }

  getMemberShipPlanDetails(communityId: number) {
    return this.http.get<MemberShipPlanDetails>(`/api/community/getmembershipplandetails/${communityId}`);
  }

  getCommunityEvents(param: CommunityEventSearch) {
    return this.http.post<CommunityEventsDto>("/api/community/getcommunityevents", param).pipe(
      map(data => {
        data.data.map(item => {
          item.isSameDayEvent = moment(new Date(item.eventStartDate)).isSame(new Date(item.eventEndDate), 'date');
          return item;
        });
        return data;
      })
    );
  }

  getSimilarPeople() {
    return this.http.get<Array<SimilarPeople>>(`/api/user/getsimilarinterestusers`);
  }

  getMostHappeningEvents(communityId: number) {
    return this.http.get<MostHappeningEvents[]>(`/api/community/getmosthappeningevents?communityId=${communityId}`);
  }

  getMostHappeningEventsByGroupId(groupId: number) {
    return this.http.get<MostHappeningEvents[]>(`/api/community/GetMostHappeningEventsByGroupId?groupId=${groupId}`);
  }

  getSuggestedEvents(communityId: number) {
    return this.http.get<SuggestedEvents[]>(`/api/community/getyoumayinterestedevents?communityId=${communityId}`);
  }

  getSuggestedEventsByGroupId(groupId: number) {
    return this.http.get<SuggestedEvents[]>(`/api/community/GetYouMayInterestedEventsByGroupId?groupId=${groupId}`);
  }

  getCommunityResources(query: CommunityResourceQuery) {
    return this.http.get<CommunityResource[]>(`/api/community/getcommunityresources?communityId=${query.communityId}&resourceTypeId=${query.resourceTypeId || 0}&resourceSearch=${query.search || ''}`);
  }
  getCommunitiesDiscussionsList(data: CommunityDiscussionSearch) {
    let params: any = { 'CommunityId': data.communityId, 'SearchKeyword': data.searchKeyword, 'RecentTopDiscussionID': data.recentTopDiscussionID, 'PageNumber': data.pageNumber, 'PageSize': data.pageSize, 'CommunityDiscussionID': data.communityDiscussionID }
    return this.http.get<CommunityDiscussionDetailsInfo>('/api/community/GetDiscussionList', { params: params });
  }
  getCommunitiesDiscussionsCommentsList(data: CommunityDiscussionCommentsSearch) {
    let params: any = { 'CommunityDiscussionID': data.communityDiscussionID, 'PageNumber': data.pageNumber, 'PageSize': data.pageSize }
    return this.http.get<CommunityDiscussionCommentsResult>('/api/community/DiscussionComments', { params: params });
  }
  getCommunitiesDiscussionsLikes(data: CommunityDiscussionLikesSearch) {
    let params: any = { 'CommunityDiscussionId': data.CommunityDiscussionId, 'CommentId': data.CommentId }
    return this.http.get<CommunityDiscussionsLikes[]>('/api/community/getcommunitydiscussionlikes', { params: params });
  }
  GetMostSubscribedDiscussions(data: number) {
    let params: any = { 'communityId': data, }
    return this.http.get<MostSubscribedDiscussions[]>('/api/community/GetMostSubscribedDiscussions', { params: params });
  }
  GetUsertSubscribedDiscussions(data: number) {
    let params: any = { 'communityId': data, }
    return this.http.get<MostSubscribedDiscussions[]>('/api/community/GetUserSubscribedDiscussions', { params: params });
  }
  LikeDiscussion(data: like) {
    return this.http.post<boolean>("/api/community/SaveCommunityDiscussionLikes", data);
  }
  SaveCommunityPostSubscribeOrUnsubscribe(data: discussionData) {
    return this.http.post<boolean>("/api/community/SaveCommunityPostSubscribeOrUnsubscribe", data);
  }
  PopularDiscussions(data: number) {
    let params: any = { 'communityId': data, }
    return this.http.get<MostSubscribedDiscussions[]>('/api/community/GetMostCommentedDiscussions', { params: params });
  }
  GetCommunityGroups(param: CommunityGroupSearch): Observable<CommunityGroupInfoResult> {
    return this.http.post<CommunityGroupInfoResult>("/api/group/allgroupsdata", param);
  }

  GetTrendingGroups(communityId: number): Observable<TrendingGroup[]> {
    return this.http.get<TrendingGroup[]>(`/api/group/getmosttrendinggroups/${communityId}`);
  }
  deleteCommunityDiscussion(param: DeleteDiscussionDto): Observable<boolean> {
    return this.http.post<boolean>("/api/community/DeleteCommunityDiscussion", param);
  }
  deleteCommunityDiscussionComment(param: DeleteDiscussionCommentDto): Observable<boolean> {
    console.log(param);
    return this.http.post<boolean>("/api/community/DeleteCommunityDiscussionComment", param);
  }
  GetCommunityUsers(data: CommunityUserSearch) {
    let params: any = { 'UserId': data.userId, 'CommunityId': data.communityId, 'Name': data.name, 'PageNumber': data.pageNumber, 'PageSize': data.pageSize, 'IsEmailPrivate': data.isEmailPrivate }
    return this.http.get<CommunitiusersInfo>('/api/community/GetCommunityUsers', { params: params });
  }
  saveComment(data: any): Observable<boolean> {
    return this.http.post<boolean>("/api/community/SaveCommunityDiscussionComment", data);
  }
  saveDiscussion(data: any): Observable<boolean> {
    return this.http.post<boolean>("/api/community/SaveCommunityDiscussion", data);
  }

  searchJobPost(param: JobPostsParam) {
    return this.http.post<JobPosts>("/api/career/getjobpostsinfo", param);
  }

  mostAppliedJob(communityId: number) {
    return this.http.get<MostAppliedJobs[]>("/api/community/getmostappliedjobs", { params: { communityId } });
  }

  getJobDetails(jobId: number) {
    return this.http.get<JobPostsInfo>("/api/career/getjobdetails", { params: { jobId } });
  }
  getCommunityUserDetails(communityId: number) {
    return this.http.get<CommuntyUserStatusDto>("/api/community/GetCommunityUserDetails", { params: { communityId } });
  }

  applyJob(param: ApplyJobDto) {
    return this.http.post<number>("/api/career/applyjob", param);
  }
  readNotifications(data: any): Observable<boolean> {
    return this.http.post<boolean>("/api/community/UpdateReadTagNotification", data);
  }
  yourAppliedJob(communityId: number) {
    return this.http.get<YourAppliedJobs[]>("/api/community/getuserappliedjobs", { params: { communityId } });
  }


  getCommunityEventsForCalendar(param: CommunityEventSearch) {
    return this.http.post<CalendarEvents[]>("/api/community/getcommunityeventsforcalendar", param).pipe(
      map(data => {
        data.map(item => {
          item.startDate = new Date(item.startDate);
          item.endDate = new Date(item.endDate);
          return item;
        });
        return data;
      })
    );;
  }

  getCommunintyDonationConfigInfo(communityId: number) {
    return this.http.get<ICommunityDonation>("/api/community/getcommunitydonationconfiginfo", { params: { communityId } });
  }

  SaveOrupdateCommunityDonationPyamentInfo(DonationPyamentInfo: IPaymentInfo) {
    return this.http.post<SaveOrUpdateMemberDonationPaymentTransactionResult>("/api/payment/SaveOrUpdateMemberDonationPaymentTransaction", DonationPyamentInfo);
  }

  getCommunityDonationPaymentInfo(paymentId: number) {
    return this.http.get<IDonationPaymentSummary>("/api/payment/getdonationpaymentsummary", { params: { paymentId } });
  }

  getMembershipPlansByCommunityId(communityId: number) {
    return this.http.get<SelectMembershipPlanDropdown[]>(`/api/admin/community/getmembershipplansbycommunityid/${communityId}`);
  }

  IsCommunityAdmin(communityId: number) {
    return this.http.get<boolean>("/api/admin/community/IsCommunityAdmin/", { params: { communityId } });
  }
}



export interface CommunityDropdownList {
  id: number;
  name: string;
}
export interface CommunityFeature {
  keyName: string;
  name: string;
  enable: boolean;
  communityAdmin: string;
  member: string;
}
export interface DeleteDiscussionDto {
  DiscussionId: number;
}
export interface DeleteDiscussionCommentDto {
  DiscussionId: number;
  CommentId: number;
}
export enum UserInCommunityStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  INVITED = 4,
  REMOVED = 5,
  PAYMENTPENDING = 6,
}
