import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MultiSelectChipComponent } from './multi-select-chip.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [
    MultiSelectChipComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    DirectiveModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatChipsModule
  ],
  exports:[
    MultiSelectChipComponent
  ]
})
export class MultiSelectChipModule { }
