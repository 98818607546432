<section class="content" id="advertisement-member-admin-section">
    <div class="container-fluid pt-3">
        <div class="card  stayUpdateCard">
            <div class="card-body">
                <img src="assets/images/community-details/stay-update.png " class="img-fluid" alt="" />
                <h3>The transaction has been {{paymentStatus}}</h3>
                <p>Transaction ID: <b>{{transactionId}}</b></p>
                <p>Payment ID: <b>{{paymentId}}</b></p>
            </div>
        </div>
    </div>
</section>