import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Notificationsstate, ProfileSettingsstate } from '../state/layout';

export const profileSettingsFeatureKey = 'myprofilesettings';
export const notificationsFeatureKey = 'notificationsdata'

export const loadProfileSettings = createFeatureSelector<ProfileSettingsstate>(
    profileSettingsFeatureKey,
);

export const loadNotifications = createFeatureSelector<Notificationsstate>(
    notificationsFeatureKey,
);

export const loadProfileSettingsdata = createSelector(
    loadProfileSettings,
    (state: ProfileSettingsstate) => state
);

export const loadNotificationsdata = createSelector(
    loadNotifications,
    (state: Notificationsstate) => state
);