import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TinySliderInstance, TinySliderSettings, tns } from 'tiny-slider/src/tiny-slider';
import { CommunitySpotLightEvents } from '../../../community-details/store/state/community-home';

@Component({
  selector: 'bzg-spotlight-event',
  templateUrl: './spotlight-event.component.html',
  styleUrls: ['./spotlight-event.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotlightEventComponent implements OnInit, OnChanges, OnDestroy {

  @Input('spotlightEvents') spotLightEvents: CommunitySpotLightEvents[];
  @Input('displayCount') displayCount?: number;

  isSliderInitialized: boolean = false;
  slider: TinySliderInstance;
  settings: TinySliderSettings = {
    container: '.spotlight-events',
    items: 1,
    slideBy: 1,
    mouseDrag: true,
    autoplay: true,
    autoplayButtonOutput: false,
    nav: false,
    gutter: 15,
    loop: true,
    edgePadding: 10,
    controlsText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    responsive: {
      640: {
        items: 2,
      },
      1200: {
        items: 3,
      }
    }
  }

  eventMode = [
    '',
    'In Person',
    'Online',
    'Hybrid',
  ];

  constructor() { }

  ngOnInit(): void {



    if (this.displayCount == null) {
      this.displayCount = 3;
    }

    switch (this.displayCount) {
      case 1:
        this.settings.responsive = {
          640: {
            items: 1,
          },
          1200: {
            items: 1,
          }
        }
        break;
      case 2:
        this.settings.responsive = {
          640: {
            items: 2,
          },
          1200: {
            items: 2,
          }
        }
        break;
      case 3:
        this.settings.responsive = {
          640: {
            items: 2,
          },
          1200: {
            items: 3,
          }
        }
        break;
      case 4:
        this.settings.responsive = {
          640: {
            items: 2,
          },
          1200: {
            items: 4,
          }
        }
        break;
      default:
        break;
    }

  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  spotLightTrackByFn(index: number, item: CommunitySpotLightEvents) {
    return item.eventId;
  }

  initSlider() {
    if (this.isSliderInitialized) return;
    this.isSliderInitialized = true;
    if (this.slider) {
      this.slider.destroy();
    }
    setTimeout(() => {
      this.slider = tns(this.settings)
    }, 10);
    //console.log("init slider completed")
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isSliderInitialized = false;
  }
}
