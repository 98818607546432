import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { settings } from '../../settings/settings';


@Injectable({
  providedIn: 'root'
})
export class CustomValidatorService {
  constructor(
    private http: HttpClient,
    //private commonService: CommonService
  ) { }



  phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let patt = new RegExp("^[6-9][0-9]{9}$");
    let valid = patt.test(control.value);
    if (!valid) {
      return { 'phone': 'is not valid' }
    }
    return null;
  }


  dropdownRequired(control: AbstractControl): ValidationErrors | null {

    if (control.value == "" || control.value?.length == 0) {
      return { 'required': true }
    }
    return null;
  }

  longitudeValidator(control: AbstractControl): ValidationErrors | null {

    if (control.value == "" || control.value == null) return null;
    if (!isNaN(control.value) && (control.value >= -180 && control.value <= 180)) {
      return null;
    } else {
      return { 'latLon': 'is not valid' };
    }
  }

  latitudeValidator(control: AbstractControl): ValidationErrors | null {

    if (control.value == "" || control.value == null) return null;
    if (!isNaN(control.value) && (control.value >= -90 && control.value <= 90)) {
      return null;
    } else {
      return { 'latLon': 'is not valid' };
    }
  }

  checkboxRequired(control: AbstractControl): ValidationErrors | null {

    if (control.value == "") {
      return { 'checkboxRequired': "must be checked" }
    }
    return null;
  }

  positiveNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let valid = !isNaN(parseFloat(control.value)) && isFinite(control.value);
    if (valid) {
      if (control.value > 0) {
        return null;
      } else {
        return { 'positive': 'must be positive' }
      }
    } else {
      return null;
    }
  }

  ensureInteger(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    var regex = /^([+-]?[1-9]\d*|0)$/;
    let valid = !isNaN(parseFloat(control.value)) && regex.test(control.value);
    if (!valid) {
      return { 'integer': 'is not valid' }
    }
    return null;
  }

  number(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let valid = !isNaN(parseFloat(control.value)) && isFinite(control.value);
    if (!valid) {
      return { 'number': 'is not valid' }
    }
    return null;
  }

  email(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;

    const trimmedValue = control.value.trim();

    if (trimmedValue !== control.value) {
      control.setValue(trimmedValue); // Set the trimmed value to the control only if it has changed.
    }

    if (trimmedValue === "") return null; // Return null if the trimmed value is empty after trimming.

    let emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let valid = emailPattern.test(trimmedValue);

    if (!valid) {
      return { 'email': 'is not valid' }
    }

    return null;
  }

  websiteUrl(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    //let patt = new RegExp(/^(?:(?:https?):\/\/)?(?:www\.)?([^\s\/]+\.[a-zA-Z0-9-]{2,})(?:\/[^\s]*)?$/i);
    let patt = new RegExp(/^(https?):\/\/(?:www\.)?([^\s\/]+\.[a-zA-Z0-9-]{2,})(?:\/[^\s]*)?$/i);
    let valid = patt.test(control.value);
    if (!valid) {
      return { 'website': 'is not valid' }
    }
    return null;
  }

  ageRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
        return { 'ageRange': true };
      }
      return null;
    };
  }

  compareString(fieldNameToCompareWith: string, validationMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.parent && control.value) {
        let compare = control.parent.controls as any;
        let compareField = compare[fieldNameToCompareWith] as AbstractControl;
        if (control.value == compareField.value) {
          return null;
        } else {
          return { 'compareString': validationMessage };
        }
      } else {
        return null;
      }
    }
  }

  compareStringCustomValidationMessaage(fieldNameToCompareWith: string, validationMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.parent && control.value) {
        let compare = control.parent.controls as any;
        let compareField = compare[fieldNameToCompareWith] as AbstractControl;
        if (control.value == compareField.value) {
          return null;
        } else {
          return { 'custom': validationMessage };
        }
      } else {
        return null;
      }
    }
  }

  regex(regex: RegExp, validationMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      let valid = regex.test(control.value);
      if (!valid) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }

  alphanumeric(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let patt = new RegExp(/^[a-zA-Z0-9]+$/);
    let valid = patt.test(control.value);
    if (!valid) {
      return { 'custom': 'Please enter a value containing only letters and numbers.' }
    }
    return null;
  }
  onlySpaceIsNotAllowed(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let patt = new RegExp(/^(?=\s*\S)[\s\S]*\s*$/);
    let valid = patt.test(control.value);
    if (!valid) {
      return { 'custom': 'only space is not allowed' }
    }
    return null;
  }

  ckEditorOnlySpaceIsNotAllowed(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let patt = new RegExp(/^(?=\s*\S)[\s\S]*\s*$/);
    const parser = new DOMParser();
    const doc = parser.parseFromString(control.value, 'text/html');
    var value = doc.body.textContent.replace("\n", "") || '';
    let valid = patt.test(value);
    if (!valid) {
      return { 'custom': 'Only space is not allowed' }
    }
    return null;
  }

  ckEditorRequired(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    //var value = control.value.innerText.replace("\n","");
    const parser = new DOMParser();
    const doc = parser.parseFromString(control.value, 'text/html');
    var value = doc.body.textContent.replace("\n", "") || '';
    if (value.length < 1) {
      return { 'required': true }
    }
    return null;
  }

  ckEditorMinLength(length: number, validationMessage: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      //var value = control.value.innerText.replace("\n","");
      const parser = new DOMParser();
      const doc = parser.parseFromString(control.value, 'text/html');
      var value = doc.body.textContent.replace("\n", "") || '';
      if (value.length < length) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }
  ckEditorMaxLength(length: number, validationMessage: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      //var value = control.value.innerText.replace("\n","");
      const parser = new DOMParser();
      const doc = parser.parseFromString(control.value, 'text/html');
      var value = doc.body.textContent.replace("\n", "") || '';
      if (value.length > length) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }

  ckEditorRegex(regex: RegExp, validationMessage: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      //var value = control.value.innerText.replace("\n","");
      const parser = new DOMParser();
      const doc = parser.parseFromString(control.value, 'text/html');
      //console.log("doc:  ", doc);
      var value = doc.body.textContent.replace("\n", "") || '';
      //console.log("value: ", value)
      let valid = regex.test(value);
      if (!valid) {
        return { 'regex': validationMessage }
      }
      return null;
    }

    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      let valid = regex.test(control.value);
      if (!valid) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }

  noWhiteSpace(validationMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;
      const isWhitespace = (control.value || '').trim().length === 0;
      if (isWhitespace) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }

  containsOnlyZero(validationMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control.value == "" || control.value == null) return null;

      if (control.value == 0) {
        return { 'regex': validationMessage }
      }
      return null;
    }
  }
  // profanity(data: string):  ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: string } | null => {
  //     if (control.value == "" || control.value == null) return null;
  //     var validationMessage = "Restricted Words:"+ data;
  //       return { 'profanity': validationMessage }

  //   }
  // }

  showErrorWnenDisabled(control: AbstractControl): ValidationErrors | null {
    return null;
  }
  whiteSpaceNotAllowed(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let value = control.value.trim();
    if (value == "") {
      return { 'custom': 'White space are not allowed' }
    }
    return null;
  }

  dateRangeValidator(min: Date, max: Date, minValidationMessege: string, maxValidationMessege: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {

      if (control.value == "" || control.value == null) return null;

      let value = moment(new Date(control.value)).toDate();

      if (value.toString() == "Invalid Date") {
        return { 'dateRange': ' is not valid' };
      }

      if (minValidationMessege == null || minValidationMessege == "" || maxValidationMessege == null || maxValidationMessege == "") {
        throw new Error("Validation Paramiter Required");
      }

      if (control.value != undefined && control.value != "" && value.toString() != "Invalid Date" && value > max) {
        return { 'dateRange': minValidationMessege };
      }

      if (control.value != undefined && control.value != "" && value.toString() != "Invalid Date" && (value < min)) {
        return { 'dateRange': maxValidationMessege };
      }

      return null;
    };
  }

  isAvailable(remoteUrl: string, validationMessage: string): ValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value) {
        let requestUrl = `${remoteUrl}?q=${control.value}`;
        return this.http.get<boolean>(requestUrl)
          .pipe(
            debounceTime(300),
            map(isAvailable => {
              if (isAvailable) {
                return null;
              } else {
                control.markAsTouched();
                control.markAsDirty();
                return of({ 'available': validationMessage });
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status == 0) {
                control.root.setErrors({ message: settings.httpErrorMessage.httpFailure });
              } else {
                control.root.setErrors({ message: error.message });
              }
              return of({ 'failed': settings.httpErrorMessage.remoteValidationFailed });
            })
          );

      } else {
        return null;
      }
    }
  }
  /*
   * Remote Url <b>https://example.com/user/validate/{0}/{1}</b>
   * where{0}=planName and {1} existing plan id
   */
  isAvailableWithId(remoteUrl: string, validationMessage: string, controlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value) {
        let requestUrl = `${remoteUrl.replace("{0}", encodeURIComponent(control.value))}`;
        let existingId = control.parent.get(controlName).value;
        if (existingId) {
          requestUrl = `${requestUrl.replace("{1}", existingId)}`;
        } else {
          requestUrl = `${requestUrl.replace("{1}", '')}`;
        }
        return this.http.get<boolean>(requestUrl)
          .pipe(
            debounceTime(300),
            map(isAvailable => {
              if (isAvailable) {
                return null;
              } else {
                control.markAsTouched();
                control.markAsDirty();
                return { 'available': validationMessage };
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status == 0) {
                control.root.setErrors({ message: settings.httpErrorMessage.httpFailure });
              } else {
                control.root.setErrors({ message: error.message });
              }
              return of({ 'failed': settings.httpErrorMessage.remoteValidationFailed });
            })
          );

      } else {
        return null;
      }
    }
  }

  /*
  * Remote Url <b>https://example.com/user/validate/{0}</b>
  * where{0}=userEmail or value of control
  */
  isDuplicate(remoteUrl: string, validationMessage: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value) {
        let requestUrl = `${remoteUrl.replace("{0}", encodeURIComponent(control.value))}`;
        return this.http.get<boolean>(requestUrl)
          .pipe(
            map(isExist => {
              if (isExist) {
                return null;
              } else {
                control.markAsTouched();
                control.markAsDirty();
                return { 'duplicate': validationMessage };
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status == 0) {
                control.root.setErrors({ message: settings.httpErrorMessage.httpFailure });
              } else {
                control.root.setErrors({ message: error.message });
              }
              return of({ 'failed': settings.httpErrorMessage.remoteValidationFailed });
            })
          );

      } else {
        return null;
      }
    }
  }

  isExist(remoteUrl: string, locId: number, validationMessage: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      console.log("Inside validaltion function");
      if (control.value == false) {
        let requestUrl = `${remoteUrl.replace("{0}", encodeURIComponent(locId))}`;
        return this.http.get<boolean>(requestUrl)
          .pipe(
            map(isExist => {
              if (!isExist) {
                return null;
              } else {
                control.markAsTouched();
                control.markAsDirty();
                return { 'custom': validationMessage };
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status == 0) {
                control.root.setErrors({ message: settings.httpErrorMessage.httpFailure });
              } else {
                control.root.setErrors({ message: error.message });
              }
              return of({ 'failed': settings.httpErrorMessage.remoteValidationFailed });
            })
          );

      } else {
        console.log("Inside validaltion skiping validation");
        return of(null);
      }
    }
  }

  fileUploadTypeValidator(allowedExtensions: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      // Enter to validation only if has value or it's not undefined
      if (control.value !== undefined && isNaN(control.value)) {
        const file = control.value;
        // Get extension from file name
        const ext = file.substring(file.lastIndexOf('.') + 1);
        // Find extension file inside allowed extensions array
        if (allowedExtensions.includes(ext.toLowerCase())) {
        } else {
          //return { extensionFile: true };
          return { 'custom': `Unsupported File! Allowed formats are ${allowedExtensions.join(", ")}` };
        }
      }
      return null;
    };
  }

  fileUploadSizeValidator(file: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      // Enter to validation only if has value or it's not undefined
      if (control.value !== undefined && isNaN(control.value)) {
        const fileValue = control.value;

        if (fileValue && file.size < 5242880) {
        } else {
          return { 'custom': 'Max file upload size is 5 MB' };
        }
      }
      return null;
    };
  }

  reminderDateValidator(startControlName: string, endControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      let parent = control?.parent?.controls as any;
      if (parent) {
        let startDateCtrl = parent[startControlName] as AbstractControl;

        let dueDateCtrl = parent[endControlName] as AbstractControl;

        if (startDateCtrl.value && dueDateCtrl.value && control.value) {
          let startDate = new Date(startDateCtrl.value);
          let dueDateVal = new Date(dueDateCtrl.value);
          let dueDate = new Date(dueDateVal.setDate(dueDateVal.getDate() + 1));
          let reminderDate = new Date(control.value);

          if (!startDate || !dueDate || !reminderDate) {
            // if any date is not provided
            return { required: true };
          }

          if (reminderDate < startDate || reminderDate > dueDate) {
            // if the reminder date is not in between the start and due dates
            return { 'custom': 'Reminder Date should be in between Start Date and Due Date' };
          }
        }
      }
      return null;
    };
  }

  startDateEndDateValidator(startControlName: string, endControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      let parent = control?.parent?.controls as any;
      if (parent) {
        let startDateCtrl = parent[startControlName] as AbstractControl;

        let dueDateCtrl = parent[endControlName] as AbstractControl;

        if (startDateCtrl.value && dueDateCtrl.value && control.value) {
          let startDate = new Date(startDateCtrl.value);
          let dueDate = new Date(dueDateCtrl.value);

          if (!startDate || !dueDate) {
            // if any date is not provided
            return { required: true };
          }

          if (startDate > dueDate) {
            // if the start date is greater than or equal to the due date
            return { 'custom': 'Due Date should be greater than Start Date' };
          }
        }
      }
      return null;
    };
  }

  // multiCheckboxRequiredValidator(control: AbstractControl): ValidationErrors | null {
  //   if (control.value == "" || control.value == null) return null;
  //   let valid = control.value.indexOf(true);
  //   console.log("hello", valid);
  //   if (valid == -1) {
  //     return { 'custom': 'is not valid' }
  //   }
  //   return null;
  // }

  isDateRangeSameValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDate = control.value[0];
      const endDate = control.value[1];

      if (moment(startDate).isSame(endDate)) {
        return { customDatePickerError: errorMessage };
      }

      return null;
    };
  }

  StartEndDateMustValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDate = control.value[0];
      const endDate = control.value[1];

      if (!startDate || !endDate) {
        return { customDatePickerError: errorMessage };
      }

      return null;
    };
  }


  fileSizeValidator(maxSizeInMB: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {

      console.log("file", control);
      if (control.value !== undefined && control.value instanceof File) {
        const file = control.value;


        // Check file size
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to Bytes
        if (file.size > maxSizeInBytes) {
          return { 'custom': `File size exceeds the maximum limit of ${maxSizeInMB} MB` };
        }
      }

      return null;
    };
  }

  maxThreeDigitNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value == "" || control.value == null) return null;
    let patt = new RegExp("^\\d{1,3}$");
    let valid = patt.test(control.value);
    if (!valid) {
      return { 'custom': 'Please enter a valid number' }
    }
    return null;
  }

  minMaxLengthValidator(minValue: number, maxValue: number, errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (control.value == "" || control.value == null) return null;

      if (control.value >= minValue && control.value <= maxValue) {
        return null;
      }
      else {
        return { 'custom': errorMessage };
      }
    };
  }

  removeLeadingTrailingSpaces(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == "" || control.value == null) return null;

    const trimmedValue = control.value.trim();

    if (trimmedValue !== control.value) {
      return { 'custom': 'Leading and Trailing Spaces are not allowed' };
    }

    return null;
  }
}
