import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisementComponent } from './advertisement.component';
import { SharedModule } from 'src/app/common/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/common/material.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ApplicationCommonModule } from 'src/app/common/application-common/application-common.module';

@NgModule({
  declarations: [
    AdvertisementComponent
  ],
  imports: [
    CommonModule,
    // SharedModule,
    TranslateModule.forChild(),
    // MaterialModule
    MatTooltipModule,
    PipeModule,
    ApplicationCommonModule
  ],
  exports: [
    AdvertisementComponent
  ]
})
export class AdvertisementModule { }