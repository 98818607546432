<h4 mat-dialog-title>{{data.title}}</h4>
<div class="mb-2" mat-dialog-content>{{data.messege}}</div>
<div class="text-end">
    <div mat-dialog-actions>
        <button (click)="onNoClick()" mat-raised-button mat-dialog-close>
            {{noButtonText}}
        </button> &nbsp;
        <button (click)="onYesClick()" color="primary" mat-raised-button mat-dialog-close>
            {{yesButtonText}}
        </button>
    </div>
</div>