import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { BehaviorSubject, filter, map, Observable, Subject, takeUntil } from 'rxjs';
import { LoadPromotionPlansInfo, removeSelectedPlan, selectPlan } from '../../store/action/create-ads.actions';
import { selectPromotionPlanDetail } from '../../store/selector/create-ads.selectors';
import { Advertisement, AppliedDiscountCouponInfo, CreateAdStatePageState, IPromotionPaymentInfo, PromotionPaymentTransactionsDto, PromotionPlanDto, PromotionPlanSearchDto, saveUserPromotionDto } from '../../store/state/create-ads';
import { Options } from '@angular-slider/ngx-slider';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { CurrencyPipe, DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { AdvertisementService } from 'src/app/services/advertisement/advertisement.service';
import { MatStepper } from '@angular/material/stepper';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { IPaymentInfo, PaymentMethod, payUPaymentRequestData } from 'src/app/models/payment/payment-method';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientConfiguration } from 'src/app/models/configuration/configuration';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';
import { Claims } from 'src/app/models/accounts/token';
import { OrderResponseBody } from '@paypal/paypal-js';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IDiscountCouponResult } from 'src/app/module/event-details/models/event-details';
import { UserPromotionDto } from '../../store/state/my-advertisements';
import { SlugPipe } from 'src/app/pipe/slug.pipe';
import { info, log } from 'console';
import { environment } from 'src/environments/environment';
import { UserInfo } from 'src/app/models/accounts/user';
import Swal from 'sweetalert2';
import { CustomValidatorService } from 'src/app/services/validation/custom-validator.service';
import { connectionsModuleFeatureKey } from 'src/app/module/connections/store/selectors/connections-module.selectors';
import { ChangePageName } from 'src/app/store/actions/app.action';
import { CommunityService } from 'src/app/services/community/community.service';

@Component({
  selector: 'bzg-create-ads',
  templateUrl: './create-ads.component.html',
  styleUrls: ['./create-ads.component.scss'],
  providers: [
    TranslatePipe
  ]
})
export class CreateAdsComponent implements OnInit, OnDestroy {
  isLinear = true;
  scale = 1;
  min: number = 128;
  selected: boolean = false;
  selectedData: PromotionPlanDto;
  PromotionPlans$: Observable<CreateAdStatePageState>;
  state: PromotionPlanDto[];
  currentStepIndex: number = 3;
  destroy$ = new Subject<void>();
  currentUser: Claims;
  purchasedPlanId: number;
  HFormGroup1: UntypedFormGroup;
  HFormGroup2: UntypedFormGroup;
  VFormGroup1: UntypedFormGroup;
  VFormGroup2: UntypedFormGroup;
  discountForm: UntypedFormGroup;
  showCropper = false;
  editedVideo: string;
  transform: ImageTransform = {};
  tenantConfig: ClientConfiguration;
  paymentMethodList: PaymentMethod[];
  promotionPlanId: number;
  edit: boolean = false;
  freePlanEnable: boolean = false;
  showVideoPreview: boolean = false;
  img: any;
  viewType: number;
  disablePromoApply: boolean = true;
  hasChange: boolean = false;
  hideDiscountBtn: boolean = false;
  authorizeNetInitialized: boolean = false;
  authorizeNetInitializedDelay = 500;
  savePaymentInfoData: IPromotionPaymentInfo;
  enablePaypalPaymentOption: boolean = false;
  availablePaypalPaymentOption: boolean = false;
  enableAuthorizedNetPaymentOption: boolean = false;
  availableAuthorizedNetPaymentOption: boolean = false;
  adDetailsSaved: boolean = false;
  enablePayUPaymentOption: boolean = false;
  availablePayUPaymentOption: boolean = false;
  isPymentProcessing: boolean = false;
  totalAmountToBePaid: number;
  showPromoCodeLoader: boolean;
  value: number = 0.5;
  imageform: UntypedFormGroup;
  selectedPaymentGatewayId: number = 0;
  authorizeHeaderTxt: string;
  isImage: boolean = false;
  isVideo: boolean = false;
  proceedToNextStepCreateAd: boolean = false;
  errormessage: string = '';
  formats: string = ' ';
  isFormSaving: boolean = false;
  showPromoCodeError: boolean = false;
  promoCodeErrMsg: string;
  format: string;
  minDate: Date;
  options: Options = {
    floor: 0,
    ceil: 2,
    step: 0.1
  };
  discountPrice: number;
  discountAmount: number;
  editLable: string = this.translate.transform('advertisement.create-ad');
  communityId: number = 0;
  communityUrl: string = '';
  communityName: string;
  discountCouponResult: IDiscountCouponResult = {
    discountCouponID: 0,
    isValid: false,
    errorCode: 0,
    errorMessage: '',
    discountType: 0,
    discountValue: 0,
    discountCoupon: ''
  };
  previous_value: number;
  selectPlanCardStaus: boolean = false;
  @ViewChild('stepper') stepper: MatStepper
  @ViewChild("HFormGroup2E1") HFormGroup2E1: NgForm;
  brodCastChnage: BehaviorSubject<any> = new BehaviorSubject(null);
  isTenantAdminPage: boolean = false;
  isCommunityAdminPage: boolean = false;
  entityStatusId: number;
  currentUserRole: string;
  isCommunityAdmin: boolean = false;
  isLoading: boolean;
  currentURL: URL;
  updateEndDate: Date;
  minUpdateStartDate: string;
  minUpdateEndDate: string;
  adLocation: string;
  isActive: any;
  endDatebefore: moment.Moment;
  startDateBefore: moment.Moment;
  startDateLocal: string;
  endDateLocal: string;
  selectPlanCardClickEvent() {
    this.selectPlanCardStaus = !this.selectPlanCardStaus;
  }
  breadscrums = [
    {
      title: 'Form Wizard',
      items: ['Forms'],
      active: 'Form Wizard'
    }
  ];

  payuPaymentInfo: payUPaymentRequestData = {
    action: environment.payUPaymentUrl,
    key: '',
    txnid: '',
    productInfo: '',
    amount: '',
    email: '',
    firstName: '',
    lastName: '',
    successUrl: environment.apiUrl + '/api/payment/payu-response-promotion-plan-purchase',
    failureUrl: environment.apiUrl + '/api/payment/payu-response-promotion-plan-purchase',
    phone: '',
    hash: ''
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store, private paymentService: PaymentService,
    private service: AdvertisementService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private translate: TranslatePipe,
    private matSnackBar: MatSnackBar,
    private customValidator: CustomValidatorService,
    private slugPipe: SlugPipe,
    private communityService: CommunityService,
    private el: ElementRef,
    private renderer: Renderer2,
    private datePipe: DatePipe
  ) {

    const navigation = this.router.getCurrentNavigation();
    console.log("Navigation: ", navigation);
    const state = navigation?.extras?.state as { communityId: number, communityName: string };
    if (state) {
      this.communityId = state.communityId;
      this.communityName = state.communityName;
      this.savePaymentInfoData.communityId = this.communityId;
      this.communityUrl = '/community/' + this.slugPipe.transform(this.communityName) + '/' + this.communityId;
    }

    this.route.params.subscribe(async (route: any) => {
      if (route.planId > 0) {
        this.promotionPlanId = route.planId;
        this.edit = true;
        this.editLable = this.translate.transform('advertisement.updated-ad');
        this.currentStepIndex = 2;
        this.loadInitialData(this.promotionPlanId);

      }
      if (route.communityName != null) {
        this.communityId = route.CommunityId;
        this.communityName = route.communityName;
        this.communityUrl = '/community/' + this.slugPipe.transform(this.communityName) + '/' + this.communityId;
        this.checkCommunityUser(this.communityId);
      }
      if (route.communityId > 0) {
        this.communityId = parseInt(route.communityId);
        var search: PromotionPlanSearchDto = {
          communityId: this.communityId != null ? this.communityId : 0,
          IsAccessingFromCA: this.communityId > 0 ? true : false,
        }
        this.loadPromotionPlans(search);
      } else if (route.CommunityId > 0) {
        this.communityId = parseInt(route.CommunityId);
        var search: PromotionPlanSearchDto = {
          communityId: this.communityId != null ? this.communityId : 0,
          IsAccessingFromCA: false,
        }
        this.loadPromotionPlans(search);
      } else {
        var search: PromotionPlanSearchDto = {
          communityId: 0,
          IsAccessingFromCA: false
        }
        this.loadPromotionPlans(search);
      }
    })

  }
  ngOnInit() {
    this.minDate = new Date();
    var currentURL = new URL(window.location.toString());
    this.currentURL = currentURL;
    if (currentURL.pathname.startsWith("/admin") && this.communityId == 0) {
      this.isTenantAdminPage = true;
    } else if (this.communityId > 0 && currentURL.pathname.startsWith("/admin/community")) {
      this.isCommunityAdminPage = true;
      this.isCommunityAdmin = true;
    }
    this.isTenantAdminPage || this.isCommunityAdminPage ? this.store.dispatch(ChangePageName({ pageName: this.translate.transform('Manage Member Advertisements') })) : this.store.dispatch(ChangePageName({ pageName: this.translate.transform('advertisement.my-advertisement-page-name') }));
    console.log("currentUrl: ", currentURL);
    this.PromotionPlans$ = this.store.select(selectPromotionPlanDetail);
    this.store.select(selectPromotionPlanDetail).pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.state = state.promotionPlansDetails.data;

    });
    this.HFormGroup1 = this.formBuilder.group({
      firstName: ['', Validators.required],
      // lastName: ['', Validators.required]
    });
    var youtubeRegex = '(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})/i'
    this.HFormGroup2 = this.formBuilder.group({
      AdvertisementLocation: ['', [Validators.required]],
      PlanName: ['', [Validators.required]],
      AdvertisementTitle: ['', [Validators.required, Validators.minLength(1),
      Validators.maxLength(50),
      this.customValidator.regex(new RegExp(/^[^<>;]+$/), this.translate.transform('advertisement.please-enter-valid-job-title'))
      ]],
      AdvertisementImage: [''],
      AdvertisementRedirectionURL: ['', [this.customValidator.regex(new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/), this.translate.transform('advertisement.please-enter-valid-redirection-url'))]],
      videoUrl: ['', [this.customValidator.regex(new RegExp(/http(?:s)?:\/\/((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i), this.translate.transform('advertisement.please-enter-valid-youtube-url'))]],
      additionalInstructions: [''],
      viewType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      updateStartDate: [''],
      updateEndDate: [''],
      description: [''],
      id: [],
      currentStatus: [true],
      duration: ['', [Validators.required]],
      section: ['1']
    });
    this.discountForm = this.formBuilder.group({
      discountCoupon: ['']
    })
    this.VFormGroup1 = this.formBuilder.group({
    });
    this.VFormGroup2 = this.formBuilder.group({
      
    });
    this.store.select(selectApplicationConfiguration)
      .pipe(
        takeUntil(this.destroy$),
        filter(s => s.clientConfiguration != null),
        map(config => config.clientConfiguration)
      ).subscribe(
        state => {
          this.tenantConfig = state;
        }
      );
    this.HFormGroup2.valueChanges.subscribe(value => {
      if(this.HFormGroup2.dirty){
        this.hasChange = true;
      }
    });

    this.HFormGroup2.get('currentStatus').valueChanges.subscribe(value => {
      if (value && this.entityStatusId == 7) {
        this.entityStatusId = 3 // approved(3) if its inactive(7) and made it active
      } else if (!value && this.entityStatusId == 3) {
        this.entityStatusId = 7 // means inactive 
      }
    });

    this.HFormGroup2.get('currentStatus').valueChanges.subscribe(value => {
        this.isActive = value;
    });
  }

  ngAfterViewInit(): void {
    
    if (this.isTenantAdminPage || this.isCommunityAdminPage) {
      // Replace 'your-element-id' with the actual ID of the element you want to modify
      const element = this.el.nativeElement.querySelector('#advertisement-member-admin-section');

      // Replace 'your-class' with the class you want to remove
      this.renderer.removeClass(element, 'content');
    }
    
  }

  toggleActive(clickedCard: any) {

    this.adDetailsSaved = false;
    if (this.selectedData && this.selectedData.id != clickedCard.id) {
      this.resetAddForm();
    }
    this.selected = true;
    this.selectedData = clickedCard;
    this.HFormGroup1.get('firstName').setValue('test');
    this.store.dispatch(selectPlan({ planId: clickedCard.id }));
    this.fillform(this.selectedData)
  }


  async fillform(data: PromotionPlanDto) {
    this.currentUser = await this.authService.getCurrentUser();
    this.currentUserRole = this.currentUser.extension_Role;
    if (data.viewType == 1) {
      this.isImage = true;
      this.isVideo = false;
      if (!this.edit) {
        this.HFormGroup2.get('AdvertisementImage').addValidators([Validators.required]);
        this.HFormGroup2.get('AdvertisementImage').updateValueAndValidity();
      }

      this.HFormGroup2.get('videoUrl').removeValidators([Validators.required]);
      this.HFormGroup2.get('videoUrl').updateValueAndValidity();

    } else {
      this.isVideo = true;
      this.isImage = false;

      this.HFormGroup2.get('AdvertisementImage').removeValidators([Validators.required]);
      this.HFormGroup2.get('AdvertisementImage').updateValueAndValidity();
      this.HFormGroup2.get('videoUrl').addValidators([Validators.required]);
      this.HFormGroup2.get('videoUrl').updateValueAndValidity();
    }
    this.HFormGroup2.get('startDate').updateValueAndValidity();
    this.HFormGroup2.get('endDate').updateValueAndValidity();
    this.HFormGroup2.get('AdvertisementTitle').updateValueAndValidity();
    this.HFormGroup2.get('AdvertisementLocation').setValue(data.adLocation);
    this.adLocation = data.adLocation;
    this.HFormGroup2.get('duration').setValue(data.duration);
    this.HFormGroup2.controls['duration'].disable();
    var name = data.promotionPlanName + "(" + data.duration + ")";
    this.HFormGroup2.get('PlanName').setValue(name);
    this.HFormGroup2.get('viewType').setValue(data.viewType.toString());
    this.HFormGroup2.controls['AdvertisementLocation'].disable();
    this.HFormGroup2.controls['PlanName'].disable();
    this.HFormGroup2.controls['viewType'].disable();

    this.authorizeHeaderTxt = "Payment of " + this.currencyPipe.transform(this.selectedData?.price, this.tenantConfig?.currencyCode);
    this.authorizeNetFormReset();
    this.totalAmountToBePaid = this.selectedData.price;
    this.fillPaymentDetails(data)

    if (this.communityId) {
      this.paymentService.getPaymentMethodsByCommunityId(this.communityId).subscribe({
        next: (data) => {
          this.paymentMethodList = data;
          this.authorizeNetInitialized = true;
          this.availablePaypalPaymentOption = data.find((x) => x.id == 1) ? true : false;
          this.availableAuthorizedNetPaymentOption = data.find((x) => x.id == 2) ? true : false;
          this.availablePayUPaymentOption = data.find((x) => x.id == 8) ? true : false;
        },
        error: (err) => {
          this.matSnackBar.open(this.translate.transform('advertisement.unable-to-load-payment-methods'), '', {
            duration: 3000,
          });
        },
      });
    } else {
      this.paymentService.getPaymentMethods().subscribe({
        next: (data) => {
          this.paymentMethodList = data;
          this.availablePaypalPaymentOption = data.find((x) => x.id == 1) ? true : false;
          this.availableAuthorizedNetPaymentOption = data.find((x) => x.id == 2) ? true : false;
          this.availablePayUPaymentOption = data.find((x) => x.id == 8) ? true : false;
        },
        error: (err) => {
          this.matSnackBar.open(this.translate.transform('advertisement.unable-to-load-payment-methods'), '', {
            duration: 3000,
          });
        },
      });
    }

  }

  async stepChanged(
    event: { previouslySelectedIndex: number, selectedIndex: number },
    stepper: MatStepper
  ) {
    if (event.previouslySelectedIndex == 0 && event.selectedIndex == 1) {
      if (this.HFormGroup1.valid) {
        stepper.selected.interacted = true;
        if (this.HFormGroup2.value.PlanName == null && !this.edit) {
          this.fillform(this.selectedData);
        }
      }
      else {

      }
    }

    if (event.previouslySelectedIndex == 1 && event.selectedIndex == 0) {
      if (!this.edit && !this.adDetailsSaved) {
        this.resetAddForm()

      }

      stepper.selected.interacted = false;  
    }
    if (event.previouslySelectedIndex == 1 && event.selectedIndex == 2) {

      this.adDetailsSaved = true;
      stepper.selected.interacted = false;
    }

    switch (event.previouslySelectedIndex) {
      case 0:

        break;
      case 1:
        stepper.selected.interacted = false;
        break;
      case 2:
        stepper.selected.interacted = false;
        break;
      default:
    }
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';
  resetAddForm() {
    this.HFormGroup2.reset();
    this.HFormGroup2E1.resetForm();
    this.HFormGroup2.updateValueAndValidity();
    this.HFormGroup2.controls['endDate'].enable();
    this.croppedImage = '';
    this.isImage = false;
    this.isVideo = false;
    this.showCropper = false;
    this.errormessage = '';
    this.editedVideo = '';
    this.imageChangedEvent = '';
    if (!this.adDetailsSaved) {
      this.store.dispatch(removeSelectedPlan({ planId: this.selectedData.id }));
      this.selected = false;
      this.selectedData = null;
      this.HFormGroup1.reset();
    }
  }
  loadPromotionPlans(data: PromotionPlanSearchDto) {
    this.store.dispatch(LoadPromotionPlansInfo(data));
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  fileChangeEvent(event: any) {
    if (event.target.files.length == 0) {
      this.showCropper = false;
      //this.crop
    } else if (!this.validateFile(event.target.files[0].name)) {
      this.errormessage = 'Unsupported File! allowed formats are .jpg, .jpeg, .bmp, .png';
      this.HFormGroup2.get('AdvertisementImage').setValue('');
      return false;
    } else if (event.target.files[0].size > 5e+6) {
      this.errormessage = 'Unsupported File! Max size is 5MB and allowed formats are .jpg, .jpeg, .bmp, .png';
      this.HFormGroup2.get('AdvertisementImage').setValue('');
      return false;
    }

    this.errormessage = '';
    this.imageChangedEvent = event;
    return true;
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      this.format = 'jpg';
      return true;
    }
    if (ext.toLowerCase() == 'jpeg') {
      this.format = 'jpeg';
      return true;
    }
    if (ext.toLowerCase() == 'bmp') {
      this.format = 'bmp';
      return true;
    }
    if (ext.toLowerCase() == 'png') {
      this.format = 'png';
      return true;
    }
    if (ext.toLowerCase() == 'gif') {
      this.format = 'gif';
      return true;
    }
    else {
      return false;
    }
  }

  submit(isValid: boolean) {
    if (!this.edit && this.HFormGroup2.value.AdvertisementImage == '' || this.HFormGroup2.value.AdvertisementImage == null) {
      this.errormessage = this.translate.transform('advertisement.image-is-required');
    }
    if (isValid) {
      this.saveAdvertisement();
    }
  }
  saveAdvertisement(next?: boolean, entityStatusID?: number) {
    this.isFormSaving = true;
    let startDate = '';
    let endDate = '';
    if ((this.isTenantAdminPage || this.isCommunityAdminPage) && this.edit ) {
      // const stringifiedStart = JSON.stringify(this.HFormGroup2.value.updateStartDate);
      // startDate = stringifiedStart.substring(1, 11);
      this.startDateBefore = this.HFormGroup2.get('updateStartDate').value ? moment.utc(this.HFormGroup2.get('updateStartDate').value).local() : null;
      startDate = (this.startDateBefore != null) ? this.startDateBefore.format('YYYY-MM-DD') : null;
      console.log("startDateLocal: ",startDate);
      //const date = JSON.stringify(this.HFormGroup2.value.updateEndDate);
      // moment(this.HFormGroup2.value.updateEndDate, 'DD/MM/YYYY');
      //endDate = date.format('YYYY-MM-DD');
      //endDate = this.clientConfigDate.transform(new Date(date.substring(1, 11)));
      //endDate = this.HFormGroup2.value.updateEndDate;
      this.endDatebefore = this.HFormGroup2.getRawValue().updateEndDate ? moment.utc(this.HFormGroup2.getRawValue().updateEndDate).local() : null;
    let endDateLocal = (this.endDatebefore != null) ? this.endDatebefore.format('YYYY-MM-DD') : null;
    endDate = endDateLocal;

    } else {
      console.log("startDate: ",this.HFormGroup2.get('startDate').value);

      this.startDateBefore = this.HFormGroup2.get('startDate').value ? moment.utc(this.HFormGroup2.get('startDate').value).local() : null;
      startDate = (this.startDateBefore != null) ? this.startDateBefore.format('YYYY-MM-DD') : null;
      console.log("startDateLocal: ",startDate);
      this.endDatebefore = this.HFormGroup2.getRawValue().endDate ? moment.utc(this.HFormGroup2.getRawValue().endDate).local() : null;
      endDate = (this.endDatebefore != null) ? this.endDatebefore.format('YYYY-MM-DD') : null;
      console.log("endDateLocal: ",endDate);
    }
    
    var array: Advertisement[] = [];
    var planData: Advertisement = {
      planId: 0,
      name: this.HFormGroup2.getRawValue().AdvertisementTitle,
      image: this.croppedImage,
      linkUrl: this.HFormGroup2.value.AdvertisementRedirectionURL,
      videoUrl: this.HFormGroup2.value.videoUrl,
      additionalInstructions: this.HFormGroup2.getRawValue().additionalInstructions,
      description: this.HFormGroup2.getRawValue().description,
      section: this.HFormGroup2.get('section').value
    }
    console.log("planData: " ,planData)
    
    array.push(planData);
    
    var data: saveUserPromotionDto = {
      id: this.HFormGroup2.value.id != null ? this.HFormGroup2.value.id : 0,
      communityID: this.communityId != null ? this.communityId : 0,
      promotionPlanID: this.selectedData.id,
      promotionPlanName: this.selectedData.promotionPlanName,
      price: this.totalAmountToBePaid,
      planAds: array,
      planStartDate: startDate,
      planEndDate: endDate,
      entityStatusID: entityStatusID == null ? this.entityStatusId : entityStatusID,
      userRole: this.currentUserRole,
      isActive: this.HFormGroup2.get('currentStatus').value

    }
    this.service.saveAdvertisements(data).subscribe({
      next: async (data) => {
        this.purchasedPlanId = data;
        this.HFormGroup2.get('id').setValue(data);

        this.isFormSaving = false;
        this.matSnackBar.open(this.translate.transform('advertisement.ad-details-saved-successfully'), "", {
          duration: 3000,
          panelClass: 'bg-success'
        });
        if (next) {
          this.stepper.next();
          this.proceedToNextStepCreateAd = true;
        } else {
          if (this.isTenantAdminPage) {
            this.router.navigate(['/admin/advertisement']);
          } else if (this.isCommunityAdminPage) {
            const redirectURL = `/admin/community/${this.communityId}/advertisement`;
            this.router.navigate([redirectURL]);
          }
          else {
            this.router.navigate(['/advertisement/my-advertisements']);
          }
        }
      },
      error: (err) => {
        this.matSnackBar.open(this.translate.transform('advertisement.unable-to-save-ad-details'), "", {
          duration: 3000,
          panelClass: 'bg-danger'
        });
        if (err.status == 500) {
          Swal.fire({
            title: '',
            text: err.error,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: 'No',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if (this.isCommunityAdminPage) {
                const redirectURL = `/admin/community/${this.communityId}/advertisement`;
                this.router.navigate([redirectURL]);
              } else if (this.isTenantAdminPage) {
                this.router.navigate(['admin/advertisement']);
              } else {
                this.router.navigate(['/advertisement/my-advertisements']);
              }
            }
          });
        }
        this.isFormSaving = false;
      }
    })


  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropper = true;

  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };

  }

  zoomIn() {

    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  updateSlider(event: any) {
    let value = event;
    if (this.previous_value > value) {
      this.scale -= .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    } else if (this.previous_value < value) {
      this.scale += .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
    this.previous_value = value;


  }
  addEvent(event: MatDatepickerInputEvent<Date>) {
    
    
    const stringified = JSON.stringify(event.value);
    console.log("event.value: ", event.value);
    // const startDate = new Date(stringified.substring(1, 11));
    const startDate = new Date(this.HFormGroup2.get('startDate').value);
    //var DateDurationTypes = ['year(s)', 'month(s)', 'week(s)', 'day(s)'];
    //let index = this.selectedData.duration.indexOf(' ');
    //var dateDuration = this.selectedData.duration.slice(index);
    if (this.selectedData.durationTypeID == 1) {//for years
      const modifiedDate = new Date(
        startDate.getFullYear() + this.selectedData.durationValue,
        startDate.getMonth(),
        startDate.getDate()
      );

    console.log("this.tenantConfig: ", this.tenantConfig);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      
      // Format the date
      const formattedDate = new Intl.DateTimeFormat(this.tenantConfig.culture, options).format(modifiedDate);
      
      console.log("formattedDate: ",formattedDate);


      console.log("modified date: ", modifiedDate)
      // const stringifiedEnd = JSON.stringify(modifiedDate);
      // const endDate = stringifiedEnd.substring(1, 11);
      // let utcNow = moment(endDate).format('DD/MM/YYYY');
      // this.HFormGroup2.get('endDate').setValue(utcNow);
      this.HFormGroup2.get('endDate').setValue(modifiedDate);
      
      this.HFormGroup2.get('endDate').disable();
    }
    else if (this.selectedData.durationTypeID == 2) {//for months
      startDate.setMonth(startDate.getMonth() + this.selectedData.durationValue,);
      // const stringifiedEnd = JSON.stringify(startDate);
      // const endDate = stringifiedEnd.substring(1, 11);
      // let utcNow = moment(endDate).format('DD/MM/YYYY');

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      
      // Format the date
      const formattedDate = new Intl.DateTimeFormat(this.tenantConfig.culture, options).format(startDate);
      
      console.log("formattedDate: ",formattedDate);

      this.HFormGroup2.get('endDate').setValue(startDate);
      this.HFormGroup2.get('endDate').disable();
     
    }
    else if (this.selectedData.durationTypeID == 3) {//for weeks
      startDate.setDate(startDate.getDate() + (this.selectedData.durationValue * 7));
      // const stringifiedEnd = JSON.stringify(startDate);
      // const endDate = stringifiedEnd.substring(1, 11);
      // let utcNow = moment(endDate).format('DD/MM/YYYY');
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      
      // Format the date
      const formattedDate = new Intl.DateTimeFormat(this.tenantConfig.culture, options).format(startDate);
      
      console.log("formattedDate: ",formattedDate);

      this.HFormGroup2.get('endDate').setValue(startDate);
      this.HFormGroup2.get('endDate').disable();

    }
    else if (this.selectedData.durationTypeID == 4) {// for days
      console.log("startDate Before : ", startDate);
      startDate.setDate(startDate.getDate() + this.selectedData.durationValue);
      console.log("startDate after : ", startDate);
      
      // const stringifiedEnd = JSON.stringify(startDate);
      // const endDate = stringifiedEnd.substring(1, 11);
      // let utcNow = moment(endDate).format('DD/MM/YYYY');
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      
      // Format the date
      const formattedDate = new Intl.DateTimeFormat(this.tenantConfig.culture, options).format(startDate);
      
      console.log("formattedDate: ",formattedDate);

      this.HFormGroup2.get('endDate').setValue(startDate); 
      this.HFormGroup2.get('endDate').disable();

    }
    else {}

  }

  fillPaymentDetails(data: any) {
    this.savePaymentInfoData = {
      userId: 0,
      PromotionPlanId: 0,
      paymentMethodId: 0,
    };
  }
  proceedToPayment() {
    this.saveAdvertisement(true);
  }
  validateDiscount(isValid: Boolean) {
    if (isValid) {
      var data: AppliedDiscountCouponInfo = {
        userId: this.currentUser?.extension_BzUserId,
        emailId: '',
        discountCoupon: this.discountForm.value.discountCoupon,
        appliesValue: this.selectedData.id,
        appliesType: 4,
        communityId: this.communityId != null ? this.communityId : 0,
      }
      this.showPromoCodeLoader = true;
      this.service.validateDiscountCoupon(data, this.selectedData.price).subscribe({
        next: async (result) => {
          if (result != null) {
            this.discountCouponResult = result;
            if (this.discountCouponResult.isValid == false) {
              this.showPromoCodeError = true;
              if (this.discountCouponResult.errorCode == 1 || this.discountCouponResult.errorCode == 3) {
                this.promoCodeErrMsg = this.translate.transform('advertisement.not-valid');
                //this.translate.transform('event-details.notvalid');
              }
              else if (this.discountCouponResult.errorCode == 2) {
                this.promoCodeErrMsg = this.translate.transform('advertisement.promo-code-expired');
               }
              else if (this.discountCouponResult.errorCode == 4 || this.discountCouponResult.errorCode == 6) {
                this.promoCodeErrMsg = this.translate.transform('advertisement.exceeded-usage-limit');
              }
              else {
                this.promoCodeErrMsg = this.translate.transform('advertisement.promo-code-expired');
              }
             }
            this.getTotlaDiscountValue();
          }
          this.showPromoCodeLoader = false;
        }
        ,
        error: (err) => {
          this.showPromoCodeLoader = false;

        }
      })
    }
  }

  getTotlaDiscountValue() {
    if (this.discountCouponResult.isValid === true) {
      if (this.discountCouponResult.discountType == 1) {
        this.discountPrice = (this.selectedData.price / 100) * this.discountCouponResult.discountValue;
        this.totalAmountToBePaid = this.selectedData.price - ((this.selectedData.price / 100) * this.discountCouponResult.discountValue);
        this.hideDiscountBtn = true
        this.discountAmount = this.discountPrice;
        this.savePaymentInfoData.discountAmount = this.discountAmount;
        this.savePaymentInfoData.discountCoupon = this.discountCouponResult.discountCoupon;
        this.savePaymentInfoData.discountCouponID = this.discountCouponResult.discountCouponID;
        this.authorizeHeaderTxt = "Payment of " + this.currencyPipe.transform(this.totalAmountToBePaid, this.tenantConfig?.currencyCode);
        this.authorizeNetFormReset();
        if (this.totalAmountToBePaid == 0) {
          this.freePlanEnable = true;
        }
      }
      else if (this.discountCouponResult.discountType == 2) {
        this.discountPrice = this.discountCouponResult.discountValue;
        this.totalAmountToBePaid = (this.selectedData.price - this.discountCouponResult.discountValue) > 0 ? this.selectedData.price - this.discountCouponResult.discountValue : 0;
       this.hideDiscountBtn = true
        this.discountAmount = this.discountPrice;
        this.savePaymentInfoData.discountAmount = this.discountAmount;
        this.savePaymentInfoData.discountCoupon = this.discountCouponResult.discountCoupon;
        this.savePaymentInfoData.discountCouponID = this.discountCouponResult.discountCouponID;
        if (this.totalAmountToBePaid == 0 || this.totalAmountToBePaid < 0) {
          this.freePlanEnable = true;
        }
      }
    }
  }
  removePromoCode() {
    this.totalAmountToBePaid = this.selectedData.price;
    this.authorizeHeaderTxt = "Payment of " + this.currencyPipe.transform(this.totalAmountToBePaid, this.tenantConfig?.currencyCode);
    this.authorizeNetFormReset();
    this.discountForm.get('discountCoupon')?.setValue('');
    this.discountCouponResult.isValid = false;
    this.showPromoCodeError = false;
    this.freePlanEnable = false;
    this.hideDiscountBtn = false;
    this.promoCodeErrMsg = '';
    this.discountCouponResult.discountCoupon = '';
    this.discountCouponResult.discountCouponID = 0;
    this.discountCouponResult.discountType = 0;
    this.discountCouponResult.discountValue = 0;
    this.discountCouponResult.errorCode = 0;
    this.savePaymentInfoData.discountAmount = 0;
    this.savePaymentInfoData.discountCoupon = '';
    this.savePaymentInfoData.discountCouponID = null;
    this.discountCouponResult.errorMessage = '';
  }
  selectPaymentGateway(gatewayId: number) {
    if (this.communityId != null) {
      this.savePaymentInfoData.communityId = this.communityId;
    }
    this.selectedPaymentGatewayId = gatewayId;
    switch (gatewayId) {
      case 1:
        if (this.availablePaypalPaymentOption == true) {
          this.enablePaypalPaymentOption = true;
          this.enableAuthorizedNetPaymentOption = false;
          this.enablePayUPaymentOption = false;
        }
        break;
      case 2:
        if (this.availableAuthorizedNetPaymentOption == true) {
          this.authorizeHeaderTxt = "Payment of " + this.currencyPipe.transform(this.totalAmountToBePaid, this.tenantConfig?.currencyCode);
          this.authorizeNetFormReset();
          this.enablePaypalPaymentOption = false;
          this.enableAuthorizedNetPaymentOption = true;
          this.enablePayUPaymentOption = false;
        }
        break;
      case 8:
        if (this.availablePayUPaymentOption == true) {
          this.enablePaypalPaymentOption = false;
          this.enableAuthorizedNetPaymentOption = false;
          this.savePaymentInfoData.PromotionPlanId = this.selectedData?.id;
          this.savePaymentInfoData.userId = this.currentUser?.extension_BzUserId;
          this.savePaymentInfoData.paymentMethodId = gatewayId;
          this.savePaymentInfoData.planPurchaseID = this.purchasedPlanId;
          this.savePaymentInfoData.paymentPurpose = 'PROMOTION_PLAN_PURCHASE';
          let currentUserStr = localStorage.getItem('currentUser');
          let currentUserInfo: UserInfo = currentUserStr ? JSON.parse(currentUserStr) as UserInfo : null;
          this.payuPaymentInfo.amount = this.totalAmountToBePaid.toFixed(2);
          this.payuPaymentInfo.email = currentUserInfo?.email ? currentUserInfo?.email : this.currentUser?.email;
          this.payuPaymentInfo.firstName = currentUserInfo?.firstName ? currentUserInfo?.firstName : this.currentUser?.given_name;
          this.payuPaymentInfo.lastName = currentUserInfo?.lastName ? currentUserInfo?.lastName : this.currentUser?.family_name;
          this.payuPaymentInfo.phone = currentUserInfo?.phone ? currentUserInfo?.phone : "9876543210";
          this.payuPaymentInfo.productInfo = 'PROMOTION_PLAN_PURCHASE';
          this.payuPaymentInfo.udf1 = this.communityId > 0 ? this.communityId.toString() : '0';
          this.payuPaymentInfo.udf2 = this.selectedData?.id.toString();
          this.payuPaymentInfo.udf3 = this.currentUser?.extension_BzUserId.toString();
          this.payuPaymentInfo.udf4 = window.location.href;
          this.payuPaymentInfo.udf5 = this.purchasedPlanId?.toString();
          this.enablePayUPaymentOption = true;
        }
        break;
      default:
        this.enablePaypalPaymentOption = false;
        this.enableAuthorizedNetPaymentOption = false;
        this.enablePayUPaymentOption = false;
        break;
    }
    this.savePaymentInfoData.PromotionPlanId = this.selectedData.id;
    this.savePaymentInfoData.userId = this.currentUser?.extension_BzUserId;
    this.savePaymentInfoData.paymentMethodId = gatewayId;
    this.savePaymentInfoData.planPurchaseID = this.purchasedPlanId;
    this.savePaymentInfoData.paymentPurpose = 'PROMOTION_PLAN_PURCHASE';

  }

  paymentSuccess(data: OrderResponseBody) {
    let info: PromotionPaymentTransactionsDto = {
      userId: this.currentUser.extension_BzUserId,
      promotionPlanId: this.selectedData.id,
      paymentMethodId: this.selectedPaymentGatewayId,
      paymentId: parseInt(data.purchase_units[0].reference_id),
      paymentStatus: data.status,
      totalAmount: parseFloat(data.purchase_units[0].amount.value),
      actualAmount: this.selectedData.price,
      currency: data.purchase_units[0].amount.currency_code,
      discountAmount: 0,
      discountCouponID: 0,
      jsonString: JSON.stringify(data),
      paymentPurpose: "PROMOTION_PLAN_PURCHASE",
      transactionDate: data.update_time,
      transationId: data.purchase_units[0]?.payments?.captures[0]['id']?.toString(),
      planPurchaseID: this.purchasedPlanId
    }
    this.updatePayment(info);
  }
  paymentSuccessAuthorizeNet(data: any) {
    this.matSnackBar.open(this.translate.transform('advertisement.payment-completed'), '', {
      duration: 3000,
    });

    const navigationExtras: NavigationExtras = {
      state: {
        paymentId: data.paymentId,
        transactionNo: data.transationId
      }
    };
    console.log("this.isTenantAdminPage: ", this.isTenantAdminPage);
    this.currentUserRole == "EVENTMANAGER" && this.currentURL.pathname.startsWith('/admin') && this.communityId == 0 ? this.router.navigate(['admin/advertisement/promotion-payment-receipt'], navigationExtras) : this.isCommunityAdminPage ? this.router.navigate(['admin/community/'+ this.communityId +'/advertisement/promotion-payment-receipt'], navigationExtras) : this.router.navigate(['/advertisement/promotion-payment-receipt'], navigationExtras);
  }
  updatePayment(info: PromotionPaymentTransactionsDto) {
    this.isPymentProcessing = true;
    this.paymentService.updatePromotionPlanPurchaseInfo(info).subscribe({
      next: (data) => {

        const navigationExtras: NavigationExtras = {
          state: {
            paymentId: data,
            transactionNo: info.transationId
          }
        };
        this.isPymentProcessing = false;

        console.log("this.isTenantAdminPage: ", this.isTenantAdminPage);
        this.currentUserRole == "EVENTMANAGER" && this.currentURL.pathname.startsWith('/admin') && this.communityId == 0 ? this.router.navigate(['admin/advertisement/promotion-payment-receipt'], navigationExtras) : this.isCommunityAdminPage && this.communityId > 0 ? this.router.navigate(['/admin/community/'+ this.communityId + '/advertisement/promotion-payment-receipt'], navigationExtras) : this.router.navigate(['/advertisement/promotion-payment-receipt'], navigationExtras);
        this.isPymentProcessing = false;
         this.matSnackBar.open(this.translate.transform('communities.payment-completed'), '', {
          duration: 3000, verticalPosition: 'top'
        });
       },
      error: (err) => {
        this.isPymentProcessing = false;
         this.matSnackBar.open(this.translate.transform('communities.unable-to-update-payment'), '', {
          duration: 3000,
        });
      }
    })
  }


  //////////////////////// edit ////////////////////////////

  loadInitialData(id: number) {
    
    this.isLoading = true;
    this.service.getPromotionPlanById(id).subscribe({
      next: async (data) => {
        if (data != null) {
          this.isLoading = false;
          this.entityStatusId = data.entityStatusID;

          if (data.communityID > 0) {
            this.communityId = data.communityID;
          }
          this.currentUser = await this.authService.getCurrentUser()

          this.currentUserRole = this.currentUser.extension_Role;

          console.log("this.currentUser: ", this.currentUser)
          console.log("this.isCommunityAdmin", this.isCommunityAdmin);
          if (data.userID != this.currentUser.extension_BzUserId && this.currentUser.extension_Role != "EVENTMANAGER" && !this.isCommunityAdmin) {
            Swal.fire({
              title: '',
              text: 'you dont have permission to edit this advertisement.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
              cancelButtonText: 'No',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/advertisement/my-advertisements']);
              } else {

              }
            });

          } else {
            if (data.viewType == "1") {
              this.isImage = true;
              this.isVideo = false;
              if (!this.edit) {
                this.HFormGroup2.get('AdvertisementImage').addValidators([Validators.required]);
             }

              this.HFormGroup2.get('videoUrl').removeValidators([Validators.required]);

            } else {
              this.isVideo = true;
              this.isImage = false;
              this.HFormGroup2.get('endDate').addValidators(this.customValidator.isDateRangeSameValidator('validity dates cannot be same'))
              this.HFormGroup2.get('videoUrl').addValidators([Validators.required]);
              this.HFormGroup2.get('AdvertisementImage').removeValidators([Validators.required]);
            }
            this.HFormGroup2.get('additionalInstructions').setValue(data.planAds[0].additionalInstructions);
            this.HFormGroup2.get('description').setValue(data.planAds[0].description);
            this.HFormGroup2.get('AdvertisementLocation').setValue(data.adLocation);
            this.adLocation = data.adLocation;
            this.isTenantAdminPage || this.isCommunityAdminPage ? this.HFormGroup2.get('section').setValue('1') : this.HFormGroup2.controls['section'].disable();
            var name = data.promotionPlanName + '(' + data.duration + ')';
            this.HFormGroup2.get('PlanName').setValue(name);
            if( data.adLocation != "GLOBAL MEMBERS"){
              this.HFormGroup2.controls['section'].disable();  
            }
            this.HFormGroup2.get('viewType').setValue(data.viewType.toString());
            this.HFormGroup2.controls['AdvertisementLocation'].disable();
            this.HFormGroup2.controls['duration'].disable();
            if((this.isTenantAdminPage || this.isCommunityAdminPage) && this.edit){
              this.HFormGroup2.controls['description'].disable();
            this.HFormGroup2.controls['additionalInstructions'].disable();
            }
             
           this.HFormGroup2.controls['PlanName'].disable();
            this.HFormGroup2.controls['viewType'].disable();
            data.entityStatusID == 7 ? data.isActive = false : data.isActive = true;
            this.HFormGroup2.get('currentStatus').setValue(data.isActive);
            this.authorizeHeaderTxt = "Payment of " + this.currencyPipe.transform(data.price, this.tenantConfig?.currencyCode);
           this.authorizeNetFormReset();
            this.totalAmountToBePaid = data.price;

            this.fillPaymentDetails(data)
            if (data.communityID > 0) {
              this.paymentService.getPaymentMethodsByCommunityId(data.communityID).subscribe({
                next: (data) => {
                  this.paymentMethodList = data;
                  this.availablePaypalPaymentOption = data.find((x) => x.id == 1) ? true : false;
                  this.availableAuthorizedNetPaymentOption = data.find((x) => x.id == 2) ? true : false;
                  this.availablePayUPaymentOption = data.find((x) => x.id == 8) ? true : false;
                },
                error: (err) => {
                  this.matSnackBar.open(this.translate.transform('advertisement.unable-to-load-payment-methods'), '', {
                    duration: 3000,
                  });
                },
              });
            } else {
              this.paymentService.getPaymentMethods().subscribe({
                next: (data) => {
                  this.paymentMethodList = data;
                  this.availablePaypalPaymentOption = data.find((x) => x.id == 1) ? true : false;
                  this.availableAuthorizedNetPaymentOption = data.find((x) => x.id == 2) ? true : false;
                  this.availablePayUPaymentOption = data.find((x) => x.id == 8) ? true : false;
                },
                error: (err) => {
                 },
              });
            }
           
            this.HFormGroup2.get('startDate').setValue(data.planStartDate);
            
            this.HFormGroup2.get('endDate').setValue(data.planEndDate);

            this.HFormGroup2.get('updateStartDate').setValue(data.planStartDate);

            this.HFormGroup2.get('updateEndDate').setValue(data.planEndDate);
            this.minUpdateStartDate = data.planStartDate;
            this.minUpdateEndDate = data.planEndDate;
           console.log("endDate: ", this.HFormGroup2.get('endDate').value)
            this.HFormGroup2.get('AdvertisementTitle').setValue(data.planAds[0].name);
            this.HFormGroup2.get('AdvertisementRedirectionURL').setValue(data.planAds[0].linkUrl);
            this.HFormGroup2.get('videoUrl').setValue(data.planAds[0].videoUrl);
            data.planAds[0].section == null ? this.HFormGroup2.get('section').setValue('1') : this.HFormGroup2.get('section').setValue(data.planAds[0].section);
            this.HFormGroup2.get('duration').setValue(data.duration);
            this.editedVideo = data.planAds[0].videoUrl;
            if (data.viewType == '2' && this.editedVideo) {
              this.getYoutubePreview(this.editedVideo);
            }
           
            this.HFormGroup2.get('id').setValue(data.id);
            this.HFormGroup2.get('AdvertisementImage').removeValidators([Validators.required]);
            this.img = data.planAds[0].image;
            var promoData: any = {
              id: data.promotionPlanID,
              promotionPlanName: data.promotionPlanName,
              price: data.price,
              details: data.details,
              adLocation: data.adLocation,
              duration: data.duration,
              planStartDate: data.planStartDate,
              planEndDate: data.planEndDate,
              planConfiguration: data.planConfiguration,
              durationValue: data.durationValue,
              viewType: data.viewType
            }
            this.selectedData = promoData;
          }

         }
        this.HFormGroup2.markAsPristine();
        this.HFormGroup2.markAsUntouched();
        this.hasChange = false;
      },
      error: (err) => {
      },
    });
  }

  authorizeNetFormReset() {
    var background = document.getElementById("AcceptUIBackground");
    if (background) {
      background.remove();
    }
    var container = document.getElementById("AcceptUIContainer");
    if (container) {
      container.remove();
    }
    this.authorizeNetInitialized = false;
    setTimeout(() => {
      this.authorizeNetInitialized = true;
    }, this.authorizeNetInitializedDelay);
  }

  purchaseForFree() {
    var data: IPromotionPaymentInfo = {
      PromotionPlanId: this.selectedData.id,
      userId: this.currentUser?.extension_BzUserId,
      communityId: this.communityId > 0 ? this.communityId : 0,
      discountCoupon: this.savePaymentInfoData.discountCoupon,
      discountAmount: this.savePaymentInfoData.discountAmount,
      discountCouponID: this.savePaymentInfoData.discountCouponID,
      planPurchaseID: this.purchasedPlanId,
      paymentPurpose: 'PROMOTION_PLAN_PURCHASE',
    }
    this.isPymentProcessing = true;
    this.service.PurchasePromotionPlanWith100PerDiscountCoupon(data).subscribe({
      next: async (data) => {
        this.isPymentProcessing = false;
        const navigationExtras: NavigationExtras = {
          state: {
            paymentId: data.value,
            transactionNo: 0
          }
        };
        console.log("this.isTenantAdminPage: ", this.isTenantAdminPage);
        this.currentUserRole == "EVENTMANAGER" && this.currentURL.pathname.startsWith('/admin') && this.communityId == 0 ? this.router.navigate(['admin/advertisement/promotion-payment-receipt'], navigationExtras) : this.router.navigate(['/advertisement/promotion-payment-receipt'], navigationExtras);
      }
    })
  }

  back() {
   
    if(this.HFormGroup2.dirty){
      
      this.hasChange = true;
    }

    if (this.hasChange) {
      Swal.fire({
        title: '',
        text: 'Do you want to discard the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Proceed',
        cancelButtonText: 'No',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.isCommunityAdminPage) {
            this.isTenantAdminPage ? this.router.navigate(['admin/advertisement']) : this.router.navigate(['/advertisement/my-advertisements']);
          } else {
            const redirectURL = `/admin/community/${this.communityId}/advertisement`;
            this.router.navigate([redirectURL]);
          }
        } else {

        }
      });

    }
    else {
      if (!this.isCommunityAdminPage) {
        this.isTenantAdminPage ? this.router.navigate(['admin/advertisement']) : this.router.navigate(['/advertisement/my-advertisements']);
      } else {
        const redirectURL = `/admin/community/${this.communityId}/advertisement`;
        this.router.navigate([redirectURL]);
      }
    }

  }

  keyPress(event: any) {
    if (this.discountForm.value.discountCoupon != '') {
      this.disablePromoApply = false;
    } else {
      this.disablePromoApply = true;
    }
  }

  showVideoUrlPreview() {
    var regex: RegExp = /http(?:s)?:\/\/((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i;
    let valid = regex.test(this.HFormGroup2.value.videoUrl);
    if (valid) {
      this.editedVideo = this.HFormGroup2.value.videoUrl;
      this.getYoutubePreview(this.HFormGroup2.value.videoUrl);
    } else {
      this.showVideoPreview = false;
    }
    //his.HFormGroup2.value.videoUrl
  }
  getYoutubePreview(data: any) {

    this.showVideoPreview = true;
    let YTplayerParam = '?mute=1&rel=0&showinfo=0&enablejsapi=1&widgetid=1';
    if (this.editedVideo.startsWith("https://youtu.be/")) {
      this.editedVideo = `https://www.youtube.com/embed/${this.editedVideo.split("https://youtu.be/")[1]}${YTplayerParam}`;
    } else if (this.editedVideo.startsWith("https://www.youtube.com/watch?v=")) {
      this.editedVideo = `https://www.youtube.com/embed/${this.editedVideo.split("?")[1].split("=")[1]}${YTplayerParam}`;
    } else {
      console.log("Video is not supported");
    }
  }
  checkCommunityUser(communityId: number) {
    this.communityService.getCommunintyInfo(communityId).subscribe({
      next: (data) => {
        if (data.communityUserStatus != 2) {
          Swal.fire({
            title: '',
            text: 'Sorry! You do not have privileges to purchase advertisements in this community. Please join this community and try again.',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'GO To Home',
            cancelButtonText: 'No',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/']);
            } else {

            }
          });
        }
      }
    })

  }

  approveAdvertisement() {
    this.entityStatusId = 3;//means approved
    this.saveAdvertisement(null, this.entityStatusId);
  }

  rejectAdvertisement() {

    this.entityStatusId = 4;//means rejected
    this.saveAdvertisement(null, this.entityStatusId);
  }

  paymentSecreenback(){
    this.removePromoCode();
  }

}
