import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoadMoreMyAdvertisementInfo, LoadMyAdvertisementInfo } from '../../store/action/my-advertisements.actions';
import { selectAdvertisementsDetails } from '../../store/selector/my-advertisements.selectors';
import { AdvertisementsSearchDto, MyAdvertisementState, UserPromotionDto } from '../../store/state/my-advertisements';
import { DialogData, MessageDailogComponent } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDailogComponent } from 'src/app/common/shared/components/confirm-dailog/confirm-dailog.component';
import Swal from 'sweetalert2';
import { ChangePageName } from 'src/app/store/actions/app.action';
import { TranslatePipe } from '@ngx-translate/core';
@Component({
  selector: 'bzg-my-advertisements',
  templateUrl: './my-advertisements.component.html',
  styleUrls: ['./my-advertisements.component.scss'],
  providers: [
    TranslatePipe
  ]
})
export class MyAdvertisementsComponent implements OnInit,OnDestroy {
  MyAdvertisement$:Observable<MyAdvertisementState>;
  state :MyAdvertisementState;
  destroy$ = new Subject<void>();
  noMoreResult: boolean = false;
  advertisementSearchFormGroup: FormGroup;
  constructor(
    private store:Store,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router:Router,
    private translate: TranslatePipe,
  ) { 
    var search:AdvertisementsSearchDto = {
      pageNumber:1,
      pageSize:5
    }
    this.loadAdvertisements(search);
  }

  ngOnInit(): void {
    this.store.dispatch(ChangePageName({ pageName: this.translate.transform('advertisement.my-advertisement-page-name') }));
    this.MyAdvertisement$ = this.store.select(selectAdvertisementsDetails);

    this.MyAdvertisement$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      this.state = state;
      console.log(this.state,'state');
      console.log(this.state);
    });
    this.advertisementSearchFormGroup = this.fb.group({
      planName: [''],
      community: [''],
      title: [''],
      duration: [''],
      status:[''],      

    });
  }
  loadAdvertisements(data:AdvertisementsSearchDto){
    console.log(data,"data");
    if(data.pageNumber == 1){
      this.noMoreResult = false;
      this.store.dispatch(LoadMyAdvertisementInfo(data));
    }
    else{
      this.store.dispatch(LoadMoreMyAdvertisementInfo(data));
    }
  }
  searchAds(isValid: boolean){
    if(isValid){
      let planName = this.advertisementSearchFormGroup.value.planName ? this.advertisementSearchFormGroup.value.planName : null;
      let community = this.advertisementSearchFormGroup.value.community ? this.advertisementSearchFormGroup.value.community : null;
      let title = this.advertisementSearchFormGroup.value.title ? this.advertisementSearchFormGroup.value.title : null;
      let duration = this.advertisementSearchFormGroup.value.duration ? this.advertisementSearchFormGroup.value.duration : null;
      let status = this.advertisementSearchFormGroup.value.status ? this.advertisementSearchFormGroup.value.status : null;
      if (planName || community || title || duration || status) {
        let param: AdvertisementsSearchDto = {
          planName: planName,
          pageNumber: 1,
          pageSize: 5,
          community: community,
          title : title,
          duration: duration,
          status: status
        };
        this.loadAdvertisements(param);
      }
    }
  }
  onScroll() {
    if (!this.state.loading) {

      if (this.state.data.data != null) {
        let planName = this.advertisementSearchFormGroup.value.planName ? this.advertisementSearchFormGroup.value.planName : null;
        let community = this.advertisementSearchFormGroup.value.community ? this.advertisementSearchFormGroup.value.community : null;
        let title = this.advertisementSearchFormGroup.value.title ? this.advertisementSearchFormGroup.value.title : null;
        let duration = this.advertisementSearchFormGroup.value.duration ? this.advertisementSearchFormGroup.value.duration : null;
        let status = this.advertisementSearchFormGroup.value.status ? this.advertisementSearchFormGroup.value.status : null;
        console.log(this.state.data,"state data")
        let currentRecordCount = this.state.data.currentPage * this.state.data.pageSize;
        console.log(currentRecordCount);
        if (currentRecordCount < this.state.data.totalCount) {
          var search:AdvertisementsSearchDto = {
            pageNumber:this.state.data.currentPage + 1,
            pageSize:5, 
            planName: planName,
            community: community,
            title : title,
            duration: duration,
            status: status     
          }
          this.loadAdvertisements(search);
        } else {
          this.noMoreResult = true;
        }
      }
    }
  }
  resetSearchResults(){
    this.advertisementSearchFormGroup.reset();
    var search:AdvertisementsSearchDto = {
      pageNumber:1,
      pageSize:5
    }
    this.loadAdvertisements(search);
  }
  editAdvertisement(data:any){
    if(data.isActive){
      const navigationExtras: NavigationExtras = {
        state: {
          iD: data.iD,
          viewType: data.viewType,
          result:data
        }
      };
      console.log(data);
      //this.router.navigate(['/advertisement/edit-ad/'], navigationExtras);
      this.router.navigate(['/advertisement/edit-ad/', data.id]);
    }else{
      
      Swal.fire({
        title: '',
        text: 'The plan you have selected is inactive, please select another plan.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
         // window.location.reload();
        }
      });
    }
   
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
