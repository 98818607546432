import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PromotionPlanDto, PromotionPlanSearchDto, PromotionPlanState } from '../state/create-ads';

export const LoadPromotionPlansInfo = createAction('[Promotion Plans] Promotion Plans Info', (query: PromotionPlanSearchDto) => ({ query }));
export const LoadPromotionPlansInfoSuccess = createAction('[Promotion Plans] Promotion Plans Success', props<{ Promotionsinfo: PromotionPlanDto[] }>());
export const LoadPromotionPlansInfoError = createAction('[Promotion Plans] Promotion Plans Error', props<{ error: HttpErrorResponse }>());

export const selectPlan = createAction('[select Plan] select promotion plan',props<{ planId: number }>())

export const removeSelectedPlan = createAction('[Remove select Plan] Remove selected promotion plan',props<{ planId: number }>())



