import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Announcements, Articles, DashboardCount, DashboardUserCommunities, LatestDiscussion, MyEvents, UserConnection, YourFeedDetails } from 'src/app/module/dashboard/store/state/member-dashboard';
import { GWCHomePageData } from '../state/home';

// export const LoadDashboardUserCommunities = createAction('[Dashboard] Load Dashboard user communities');
// export const LoadDashboardUserCommunitiesSuccess = createAction('[Dashboard] Load Dashboard user communities success', props<{ communities: DashboardUserCommunities[] }>());
// export const LoadDashboardUserCommunitiesError = createAction('[Dashboard] Load Dashboard user communities error', props<{ error: HttpErrorResponse }>());

// export const LoadYourFeed = createAction('[Dashboard] Load Your Feed');
// export const LoadYourFeedSuccess = createAction('[Dashboard] Load Your Feed success', props<{ feed: YourFeedDetails[] }>());
// export const LoadYourFeedError = createAction('[Dashboard] Load Your Feed error', props<{ error: HttpErrorResponse }>());

// export const LoadLatestDiscussion = createAction('[Dashboard] Load Latest Discussion');
// export const LoadLatestDiscussionSuccess = createAction('[Dashboard] Load Latest Discussion success', props<{ discussion: LatestDiscussion[] }>());
// export const LoadLatestDiscussionError = createAction('[Dashboard] Load Latest Discussion error', props<{ error: HttpErrorResponse }>());

// export const LoadLatestEvent = createAction('[Dashboard] Load Latest Events');
// export const LoadLatestEventSuccess = createAction('[Dashboard] Load Latest Events success', props<{ events: MyEvents[] }>());
// export const LoadLatestEventError = createAction('[Dashboard] Load Latest Events error', props<{ error: HttpErrorResponse }>());

// export const LoadConnections = createAction('[Dashboard] Load Connections');
// export const LoadConnectionsSuccess = createAction('[Dashboard] Load Connections Success', props<{ connections: UserConnection[] }>());
// export const LoadConnectionsError = createAction('[Dashboard] Load Connections Error', props<{ error: HttpErrorResponse }>());

// export const LoadDashboardCount = createAction('[Dashboard] Load DashboardCount');
// export const LoadDashboardCountSuccess = createAction('[Dashboard] Load DashboardCount Success', props<{ dashboardcount: DashboardCount }>());
// export const LoadDashboardCountError = createAction('[Dashboard] Load DashboardCount Error', props<{ error: HttpErrorResponse }>());

// export const LoadAnnouncements = createAction('[Dashboard] Load Announcements');
// export const LoadAnnouncementsSuccess = createAction('[Dashboard] Load Announcements Success', props<{ announcements: Announcements[] }>());
// export const LoadAnnouncementsError = createAction('[Dashboard] Load Announcements Error', props<{ error: HttpErrorResponse }>());

// export const LoadArticle = createAction('[Dashboard] Load Article');
// export const LoadArticleSuccess = createAction('[Dashboard] Load Article Success', props<{ article: Articles[] }>());
// export const LoadArticleError = createAction('[Dashboard] Load Article Error', props<{ error: HttpErrorResponse }>());

export const LoadHomepageData = createAction('[Home] Load Homepage data');
export const LoadHomepageDataSuccess = createAction('[Dashboard] Homepage data Success', props<{ html: string }>());
export const LoadHomepageDataError = createAction('[Dashboard] Homepage data Error', props<{ error: HttpErrorResponse }>());

export const LoadGWCHomepageData = createAction('[Home] GWC Load Homepage data');
export const LoadGWCHomepageDataSuccess = createAction('[Home] GWC Homepage data Success', props<{ data: GWCHomePageData }>());
export const LoadGWCHomepageDataError = createAction('[Home] GWC Homepage data Error', props<{ error: HttpErrorResponse }>());
