import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Champions } from '../state/community-home';


export const LoadSpotlightEvents = createAction('[CommunityHome] Load SpotlightEvents', props<{ communityId: number }>());
export const LoadSpotlightEventsSuccess = createAction('[CommunityHome] Load SpotlightEvents Success', props<{ spotlightevents: any }>());
export const LoadSpotlightEventsError = createAction('[CommunityHome] Load SpotlightEvents Error', props<{ error: HttpErrorResponse }>());

export const LoadChampions = createAction('[CommunityHome] Load Champions', props<{ communityId: number }>());
export const LoadChampionsSuccess = createAction('[CommunityHome] Load Champions Success', props<{ champions: Champions[] }>());
export const LoadChampionsError = createAction('[CommunityHome] Load Champions Error', props<{ error: HttpErrorResponse }>());



