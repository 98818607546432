import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { GlobalErrorHandler } from './services/logging/globalErrorHandler';




// --------------- module import ------------------------------

import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MomentModule } from 'ngx-moment';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

import { LayoutComponent } from './common/layout/layout.component';
import { FooterComponent } from './common/layout/footer/footer.component';
import { HeaderComponent } from './common/layout/header/header.component';
import { EmptyLayoutComponent } from './common/empty-layout/empty-layout.component';
import { HomeComponent } from './module/home/home.component';
import { TokenInterceptor } from './services/auth/interceptor';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DefaultErrorStateMatcher } from './directive/validation/validation.directive';
import { SidebarComponent } from './common/layout/sidebar/sidebar.component';
import { SharedModule } from './common/shared/shared.module';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { WINDOW_PROVIDERS } from './core/service/window.service';
import { RightSidebarComponent } from './common/layout/right-sidebar/right-sidebar.component';
import { CoreModule } from './core/core.module';
import { SlimLayoutComponent } from './common/slim-layout/slim-layout.component';
import { SlimHeaderComponent } from './common/slim-layout/slim-header/slim-header.component';
import { SlimFooterComponent } from './common/slim-layout/slim-footer/slim-footer.component';
import { AutoCompleteMultiSelectComponent } from './module/shared/auto-complete-multi-select/auto-complete-multi-select.component';
import { LayoutEffects } from './common/layout/store/effect/layout.effects';
import { notificationsreducer, profileSettingsreducer } from './common/layout/store/reducer/layout.reducer';
import { notificationsFeatureKey, profileSettingsFeatureKey } from './common/layout/store/selector/layout.selectors';
import { PageNotFoundComponent } from './common/shared/components/page-not-found/page-not-found.component';
import { LettersAvatarModule } from 'ngx-letters-avatar';
import { MaterialModule } from './common/material.module';
import { BaseComponent } from './common/base/base.component';
import { CommunityLayoutComponent } from './common/community-layout/community-layout.component';
import { CommunityHeaderComponent } from './common/community-layout/community-header/community-header.component';
import { CommunityFooterComponent } from './common/community-layout/community-footer/community-footer.component';
import { appConfigKey, userActiveStatusKey, commonApplicationStateKey, userStateKey, membershipStatusKey } from './store/selectors/app.selector';
import { reducerAppConfig, reducerUserStatus, reducerCommonApplicationState, membershipstatussesinitialStatereducer } from './store/reducers/app.reducer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppEffects } from './store/effects/app.effect';
import { HomeEffects } from './module/home/store/effect/home.effects';
import { reducer } from './module/my-profile/store/reducer/profile.reducer';
import { AdvertisementModule } from './module/shared/advertisement/advertisement.module';
import { homeFeatureKey } from './module/home/store/selector/home.selectors';
import { homeReducer } from './module/home/store/reducer/home.reducer';
import { UnsubscribeEmailComponent } from './module/unsubscribe-email/pages/unsubscribe-email/unsubscribe-email.component';
import { GwcTemplateComponent } from './module/home/pages/gwc-template/gwc-template.component';
import { AdminLayoutComponent } from './admin/layout/admin-layout/admin-layout.component';
import { AdminSidebarComponent } from './admin/layout/admin-sidebar/admin-sidebar.component';
import { TopMenuComponent } from './common/layout/top-menu/top-menu.component';
import { AccountSuspendedComponent } from './module/home/pages/account-suspended/account-suspended.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BannerImagesModule } from './module/shared/banner-images/banner-images.module';
import { SponsorModule } from './module/shared/sponsor/sponsor.module';
import { SpotlightEventModule } from './module/shared/spotlight-event/spotlight-event.module';
import { TestimonialsDynamicModule } from './module/shared/testimonials/testimonials.module';
import { Html2Module } from './common/shared/components/html2/html2.module';
import { Html3Module } from './common/shared/components/html3/html3.module';
import { SuperAdminLayoutComponent } from './superAdmin/layout/super-admin-layout/super-admin-layout.component';
import { SuperAdminSidebarComponent } from './superAdmin/layout/super-admin-sidebar/super-admin-sidebar.component';
import { DatePipe } from '@angular/common';
import { ClientConfigDatePipe } from './pipe/client-config-date.pipe';
import { ClientConfigDateTimePipe } from './pipe/client-config-date-time.pipe';
import { CustomDateAdapter, CustomDateFormatsProvider } from './services/common/mat-dateformat.service';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    LayoutComponent,
    FooterComponent,
    HeaderComponent,
    EmptyLayoutComponent,
    SlimLayoutComponent,
    SlimHeaderComponent,
    SlimFooterComponent,
    SidebarComponent,
    RightSidebarComponent,
    AdminLayoutComponent,
    AdminSidebarComponent,
    HomeComponent,
    AutoCompleteMultiSelectComponent,
    PageNotFoundComponent,
    CommunityLayoutComponent,
    CommunityHeaderComponent,
    CommunityFooterComponent,
    UnsubscribeEmailComponent,
    GwcTemplateComponent,
    TopMenuComponent,
    AccountSuspendedComponent,
    SuperAdminLayoutComponent,
    SuperAdminSidebarComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule,
    NgxSpinnerModule,
    TranslateModule.forRoot(),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59,
        'ss': 3
      }
    }),
    SharedModule,
    CoreModule,
    PerfectScrollbarModule,
    LettersAvatarModule,
    AdvertisementModule,
    BannerImagesModule,
    SponsorModule,
    SpotlightEventModule,
    TestimonialsDynamicModule,
    Html2Module,
    Html3Module,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(appConfigKey, reducerAppConfig),
    StoreModule.forFeature(userActiveStatusKey, reducerUserStatus),
    StoreModule.forFeature(commonApplicationStateKey, reducerCommonApplicationState),
    StoreModule.forFeature(profileSettingsFeatureKey, profileSettingsreducer),
    StoreModule.forFeature(notificationsFeatureKey, notificationsreducer),
    StoreModule.forFeature(membershipStatusKey, membershipstatussesinitialStatereducer),
    StoreModule.forFeature(userStateKey, reducer),
    StoreModule.forFeature(homeFeatureKey, homeReducer),
    EffectsModule.forFeature([LayoutEffects, AppEffects, HomeEffects]),
    //EntityDataModule.forRoot(entityConfig),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    DatePipe,
    ClientConfigDatePipe,
    ClientConfigDateTimePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: ErrorStateMatcher, useClass: DefaultErrorStateMatcher },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    WINDOW_PROVIDERS,
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }, // Set default locale
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CustomDateFormatsProvider,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
