import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SelectClientConfiguration } from '../store/selectors/app.selector';
import { Subject, filter, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'clientConfigDateTime'
})
export class ClientConfigDateTimePipe implements PipeTransform {

  destroy = new Subject<void>();
  dateFormat: string;
  culture: string = "en-US";
  timeZone: string = "UTC";
  constructor(
    private datePipe: DatePipe,
    private store: Store
  ) {

    this.store.select(SelectClientConfiguration).pipe(
      filter(config => config != null),
      takeUntil(this.destroy)
    ).subscribe(config => {

      if (config.culture.length) {
        this.culture = config.culture;
        if (config.timeZoneAbbr != null && config.timeZoneAbbr.length > 0) {
          this.timeZone = config.timeZoneAbbr;
        }
      }

      let format = config.dateFormat;
      if (format == null || format.csharp == null || format.csharp.length == 0) {
        console.warn("Client Config for Date format is not set");
      }
      this.dateFormat = `${this.getAngularDateFormat(format.csharp)} hh:mm a`;
    })
  }


  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  transform(value: Date): string {

    if (value == null || value.toString() == "") {
      return "N/A";
    }

    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    console.log(this.culture, this.dateFormat, this.timeZone);
    const datePipe = new DatePipe(this.culture, this.timeZone);
    return datePipe.transform(date, this.dateFormat);
  }

  getAngularDateFormat(format: string) {

    if (format == null || format.length == 0) {
      return "short";
    }

    format = format.toLowerCase()
      .replace(/m/g, 'M');
    return format;
  }

}
