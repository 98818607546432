import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class GroupDetailsModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translate.set('group-details.sending-request', 'Sending request...', 'en');
    this.translate.set('group-details.canceling-request', 'Canceling request...', 'en');
    this.translate.set('group-details.processing-request', 'Processing...', 'en');
    this.translate.set('group-details-searching', 'Searching...', 'en');
    this.translate.set('group-details.end-result', 'End Result', 'en');
    this.translate.set('group-details.not-find', 'no result found', 'en');
    this.translate.set('group-details.try-search', 'try search again', 'en');
    this.translate.set('group-details.advanced-search', 'Advanced Search', 'en');
    this.translate.set('group-details.search-by-name.placeholder', 'Search by member name', 'en');
    this.translate.set('group-details.create-new-post', 'CREATE NEW POST', 'en');
    this.translate.set('group-details.loading', 'Loading...', 'en');
    this.translate.set('group-details.saving-discussion', 'Saving Discussion...', 'en');
    this.translate.set('group-details.updating-discussion', 'Updating Discussion...', 'en');
    this.translate.set('group-details.recent-group-discussions', 'Recent Group Discussions', 'en');
    this.translate.set('group-details.unable-to-load-recent-discussions', 'Unable To Load Recent Discussions', 'en');
    this.translate.set('group-details.no-recent-discussions', 'No Discussions happen so far. Start a discussion by clicking on "Create New post"', 'en');
    this.translate.set('group-details.trending-group-discussions', 'Trending Group Discussions', 'en');
    this.translate.set('group-details.unable-to-load-trending-discussions', 'Unable To Load Trending Discussions', 'en');
    this.translate.set('group-details.no-trending-discussions', 'No Discussions happen so far. Start a discussion by clicking on "Create New post"', 'en');
    this.translate.set('group-details.group-users-loaded-succesfully', 'Group users loaded Succesfully', 'en');
    this.translate.set('group-details.unable-to-load-group-users', 'Unable to load group users', 'en');
    this.translate.set('group-details.update-disscusion', 'updateDisscusion', 'en');
    this.translate.set('group-details.save-disscusion', 'saveDisscusion', 'en');
    this.translate.set('group-details.saved-disscussion-successfully', 'Saved disscussion successfully', 'en');
    this.translate.set('group-details.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translate.set('group-details.group-users-loaded-succesfully', 'Group users loaded Succesfully', 'en');
    this.translate.set('group-details.unable-to-load-group-users', 'Unable to load group users', 'en');
    this.translate.set('group-details.you-subscribe-or-unsubscribe-discussion-succesfully', 'You Subscribe or Unsubscribe Discussion Succesfully', 'en');
    this.translate.set('group-details.unable-to-subscribe-or-unsubscribe-discussion', 'Unable to Subscribe or Unsubscribe discussion', 'en');
    this.translate.set('group-details.you-liked-disscussion', 'You liked disscussion', 'en');
    this.translate.set('group-details.unable-to-like-discussion', 'Unable to like discussion', 'en');
    this.translate.set('group-details.you-liked-disscussion-comment', 'You liked disscussion Comment', 'en');
    this.translate.set('group-details.unable-to-like-discussion-comment', 'Unable to like discussion Comment', 'en');
    this.translate.set('group-details.discussion-deleted-succesfully', 'Discussion Deleted Succesfully', 'en');
    this.translate.set('group-details.unable-to-delete-discussion', 'Unable to Delete discussion', 'en');
    this.translate.set('group-details.discussion-comment-deleted-succesfully', 'Discussion Comment Deleted Succesfully', 'en');
    this.translate.set('group-details.unable-to-delete-discussion-comment', 'Unable to Delete discussion Comment', 'en');
    this.translate.set('group-details.you-commented-discussion', 'You commented disscussion', 'en');
    this.translate.set('group-details.unable-to-commented-discussion', 'Unable to commented discussion', 'en');
    this.translate.set('group-details.saved-disscussion-successfully', 'Saved disscussion successfully', 'en');
    this.translate.set('group-details.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translate.set('group-details.saved-disscussion-successfully', 'Saved disscussion successfully', 'en');
    this.translate.set('group-details.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translate.set('group-details.members', 'Members', 'en');
    this.translate.set('group-details.discussions', 'Discussions', 'en');
    this.translate.set('group-details.aboutGroup', 'About', 'en');
    this.translate.set('group-details.about-group', 'About - Group', 'en');
    this.translate.set('group-details.events', 'Events', 'en');
    this.translate.set('group-details.resources', 'Resources', 'en');
    this.translate.set('group-details.login', 'Login', 'en');
    this.translate.set('group-details.home', 'Home', 'en');
    this.translate.set('group-details.member', 'if you are already a member', 'en');
    this.translate.set('group-details.the-group-or', 'the group or', 'en');
    this.translate.set('group-details.champions', 'Champions', 'en');
    this.translate.set('group-details.processing-your-request', 'Processing your request', 'en');
    this.translate.set('group-details.no-discussions-found', 'There is no discussions for you as of now!', 'en');
    this.translate.set('group-details.sorry-no-results-found-matching-your-search-criteria', 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('group-details.discussions-page-name', 'Discussions', 'en');
    // latest changes
    this.translate.set('group-details.join', 'Join', 'en');
    this.translate.set('group-details.leave', 'Leave', 'en');
    this.translate.set('group-details.pending', 'Pending', 'en');
    this.translate.set('group-details.cancel', 'Cancel', 'en');
    this.translate.set('group-details.sorry-this-content-is-not-available-right-now.', 'Sorry,this content is not available right now.', 'en');
    this.translate.set('group-details.the-link-that-you-have-followed-may-have-changed', 'The link that you have followed may have changed or the post might have been removed.', 'en');
    this.translate.set('group-details.click-on-continue-below-to-go-to-the-main-discussion-page', 'Click on continue below to go to the main discussion page.', 'en');
    this.translate.set('group-details.continue', 'Continue', 'en');
    this.translate.set('group-details.please-login-to-continue', 'Please login to continue', 'en');
    this.translate.set('group-details.successfully-joined', 'You have successfully joined the Group', 'en');
    this.translate.set('group-details.ok', 'Ok', 'en');
    this.translate.set('group-details.advanced-search', 'Advance Search', 'en');
    this.translate.set('group-details.this-group-is-no-longer-available', 'This group is no longer available', 'en');
    this.translate.set('group-details.view-groups', 'My Groups', 'en');
    this.translate.set('group-details.successfully-approved', 'Join request approved successfully', 'en');
    this.translate.set('group-details.successfully-rejected', 'Join request rejected successfully', 'en');
    this.translate.set('group-details.unable-to-approve-request', 'Unable to Approve join request', 'en');
    this.translate.set('group-details.unable-to-reject-request', 'Unable to Reject join request', 'en');
    this.translate.set('group-details.groups-joining-loading', 'Joining...', 'en');
    this.translate.set('group-details.groups-leaving-loading', 'Leaving...', 'en');

    this.translate.set('group-details.the-group-to-view-community', 'the group to view the group information.', 'en');
    this.translate.set('group-details.the-request-sent', 'Your request is sent and waiting for approval.', 'en');

    this.translate.set('group-details.oops-we-did-not-find-anything-that-matches-this-search:(', 'Oops we did not find anything that matches this search:(', 'en');
    this.translate.set('group-details.sorry', 'Sorry !', 'en');
    this.translate.set('group-details.join-request', 'Join Requests', 'en');
    this.translate.set('group-details.there-are-not-any-group-members-right-now', 'There are not any Group Members right now', 'en');
    this.translate.set('group-details.search-by-events.placeholder', 'Search By Event Name', 'en');
    this.translate.set('group-details.search-by-event-date.placeholder', 'Search By Event Date', 'en');
    this.translate.set('group-details.search', 'SEARCH', 'en');
    this.translate.set('group-details.reset', 'RESET', 'en');
    this.translate.set('group-details.loading', 'Loading...', 'en');
    this.translate.set('group-details.no-events-image', '/assets/images/community-details/no_events.png', 'en');
    this.translate.set('group-details.there-is-no-events-for-you-as-of-now', 'There are no Events for you as of now!', 'en');
    this.translate.set('group-details.sorry-no-results-found-matching-your-search-criteria', 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('group-details.no-events-found', 'No Events Found.', 'en');
    this.translate.set('group-details.please', 'Please', 'en');
    this.translate.set('group-details.login', 'Login', 'en');
    this.translate.set('group-details.to-view', 'to view', 'en');
    this.translate.set('group-details.most-popular-events', 'Most Popular Events', 'en');
    this.translate.set('group-details.suggested-events', 'Suggested Events', 'en');

    this.translate.set('group-details.search-by-keyword.placeholder', 'Search By Keyword/Title/Description', 'en');
    this.translate.set('group-details.videos', 'Videos', 'en');
    this.translate.set('group-details.image', 'Images', 'en');
    this.translate.set('group-details.documents', 'Documents', 'en');
    this.translate.set('group-details.audios', 'Audios', 'en');
    this.translate.set('group-details.no-video-found', 'There is no video resources for you as of now', 'en');
    this.translate.set('group-details.no-audio-found', 'There is no audio resources for you as of now', 'en');
    this.translate.set('group-details.no-documents-found', 'There is no document resources for you as of now', 'en');
    this.translate.set('group-details.no-image-found', 'There is no image resources for you as of now', 'en');
    this.translate.set('group-details.no-resources-image', '/assets/images/community-details/no_resources.png', 'en');
    this.translate.set('group-details.video-image', '/assets/images/community-details/videos.png', 'en');
    this.translate.set('group-details.audio-image', '/assets/images/community-details/audios.png', 'en');
    this.translate.set('group-details.document-image', '/assets/images/community-details/documents.png', 'en');
    this.translate.set('group-details.there-is-no-resources-for-you-as-of-now', 'There is no resources for you as of now!', 'en');
    this.translate.set('group-details.sorry-no-results-found-matching-your-search-criteria', 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('group-details.events-calendar', 'Events Calendar', 'en');

    return this.localResourceService.loadLocalResource("group-details").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
