import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class ConnectionsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('connections.dashboard', 'Dashboard', 'en');
    this.translateService.set('connections.connections', 'Connections', 'en');
    this.translateService.set('connections.my-connections', 'My Connections', 'en');
    this.translateService.set('connections.new-connection-requests', 'New Connection Requests', 'en');
    this.translateService.set('connections.loading', 'Loading...', 'en');
    this.translateService.set('connections.message', 'Message', 'en');
    this.translateService.set('connections.new-requests', 'New Requests', 'en');
    this.translateService.set('connections.accept', 'Accept', 'en');
    this.translateService.set('connections.reject', 'Reject', 'en');
    this.translateService.set('connections.cancel', 'Cancel', 'en');
    this.translateService.set('connections.please-wait-while-processing-your-request', 'Please wait while processing your request...', 'en');
    this.translateService.set('connections.connection-requested-is-accepted', 'Connection Requested is Accepted', 'en');
    this.translateService.set('connections.connection-requested-is-rejected', 'Connection Requested is Rejected', 'en');
    this.translateService.set('connections.connection-requested-cancelled', 'Connection request cancelled.', 'en');
    this.translateService.set('connections.something-went-wrong', 'Something went wrong. Please try again', 'en');
    this.translateService.set('connections.my-connections', 'My Connections', 'en');
    // Latest changes
    this.translateService.set('connections.accept-request', 'Accept Request', 'en');
    this.translateService.set('connections.reject-request', 'Reject Request', 'en');
    this.translateService.set('connections.connection-request-is-pending', 'Connection request is pending', 'en');
    this.translateService.set('connections.pending', 'Pending', 'en');
    this.translateService.set('connections.no-connections-yet', 'You do not have any connections yet.', 'en');

    return this.localResourceService.loadLocalResource("connections").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
