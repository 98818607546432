import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    // Convert the first character of the input string to uppercase
    // if it is not already uppercase.
    if (value.charAt(0) !== value.charAt(0).toUpperCase()) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    // Use the replace method to search for the start of each sentence
    // (i.e. the first character after a period, exclamation mark, or question mark)
    // and convert it to uppercase.
    return value.replace(/([.!?])\s*(\w)/g, (txt) => txt.toUpperCase());
  }

}
