import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerImagesComponent } from './banner-images.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    BannerImagesComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports:[
    BannerImagesComponent
  ]
})
export class BannerImagesModule { }
