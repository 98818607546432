import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, Observable, map, switchMap, take, concatMap, of, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { ICreateUser, ISaveUser } from 'src/app/module/auth/store/state/auth.state';
import { MembershipPlan } from 'src/app/module/communities/store/state/membership-plans';
import { Feature, UserInfo, UserInitialData } from 'src/app/models/accounts/user';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Claims } from 'src/app/models/accounts/token';
import { msalConfig, silentRequest } from 'src/app/auth-config';
import { ApplicationConfigurationState, MsalRefreshToken } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { selectApplicationConfiguration, selectCommunityFeatureAccess, SelectTenantFeatureConfiguration, SelectCommunityFeatureConfiguration } from 'src/app/store/selectors/app.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDailogComponent } from 'src/app/common/shared/components/confirm-dailog/confirm-dailog.component';
import { DialogData } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { RefreshFooterVisibility } from 'src/app/store/actions/app.action';
import { SaveLoyaltyPoints } from 'src/app/state/app.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private store: Store<ApplicationConfigurationState>,
    private matDailog: MatDialog
    //private commonService: CommonService,
    //private userService: UserService
  ) {

  }


  getCurrentUser(): Promise<Claims> {
    return new Promise((resolve, reject) => {
      this.broadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          take(1)
        )
        .subscribe((s) => {
          let loggedIn = this.msalService.instance.getAllAccounts().length > 0;
          let account = this.msalService.instance.getActiveAccount();
          if (loggedIn && account) {
            let claims =
              this.msalService.instance.getActiveAccount().idTokenClaims;
            let currentUser = JSON.parse(JSON.stringify(claims)) as Claims;
            resolve(currentUser);
          } else {
            resolve(null);
          }
        });
    });
  }

  getCurrentUser2(): Observable<Claims> {
    return this.broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      take(1),
      map((s) => {
        let loggedIn = this.msalService.instance.getAllAccounts().length > 0;
        let account = this.msalService.instance.getActiveAccount();
        if (loggedIn && account) {
          let claims =
            this.msalService.instance.getActiveAccount().idTokenClaims;
          let currentUser = JSON.parse(JSON.stringify(claims)) as Claims;
          return currentUser;
        } else {
          return null;
        }
      })
    );
  }

  /**
   * @deprecated Use `getAccessToken2()` instead.
   */
  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.broadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          take(1)
        )
        .subscribe(async (s) => {
          try {
            let userClaims = await this.getCurrentUser();
            this.msalService
              .acquireTokenSilent({
                scopes: silentRequest.scopes,
                authority: `${environment.b2cAuthorityUrl}/${userClaims.tfp}`,
              })
              .subscribe({
                next: (s) => {
                  resolve(s.accessToken);
                },
                error: (e) => {
                  resolve(null);
                },
              });
          } catch {
            resolve(null);
          }
        });
    });
  }

  getAccessToken2() {
    return this.broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      take(1),
      switchMap(() => {
        let loggedIn = this.msalService.instance.getAllAccounts().length > 0;
        let account = this.msalService.instance.getActiveAccount();
        if (loggedIn && account) {
          let claims =
            this.msalService.instance.getActiveAccount().idTokenClaims;
          let userClaims = JSON.parse(JSON.stringify(claims)) as Claims;
          return this.msalService
            .acquireTokenSilent({
              scopes: silentRequest.scopes,
              authority: `${environment.b2cAuthorityUrl}/${userClaims?.tfp}`,
            })
            .pipe(map((s) => s.accessToken));
        } else {
          return of(null);
        }
      }),
      catchError((err) => of(null))
    );
  }

  getAccessToken3() {
    return this.broadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      take(1),
      concatMap((s) => this.getCurrentUser2()),
      concatMap((userClaims) => {
        return this.msalService
          .acquireTokenSilent({
            scopes: silentRequest.scopes,
            authority: `${environment.b2cAuthorityUrl}/${userClaims?.tfp}`,
          })
          .pipe(map((s) => s.accessToken));
      })
    );
  }

  ensureUserIsLoggedIn() {
    return this.getAccessToken2().pipe(
      take(1),
      map((token) => {
        if (token == null) {
          let data: DialogData = {
            title: `Login required to continue...`,
            messege: `You must login to continue this action, are you sure ?`,
            yesButtonText: 'Yes, Login now',
            noButtonText: 'No, not now',
          };
          let ans = this.matDailog.open(ConfirmDailogComponent, {
            width: '600px',
            maxWidth: '100%',
            maxHeight: '100%',
            panelClass: 'view-resource-dailog',
            role: 'alertdialog',
            disableClose: true,
            hasBackdrop: true,
            data: data,
          });

          ans
            .afterClosed()
            .pipe(take(1))
            .subscribe((data) => {
              if (data) {
                this.loginRedirect();
              }
            });
          return false;
        } else {
          return true;
        }
      })
    );
  }

  ensureUserIsLoggedInWithRedirect() {
    return this.getAccessToken2().pipe(
      take(1),
      map((token) => {
        if (token == null) {
          this.loginRedirect();
          return false;
        } else {
          return true;
        }
      })
    );
  }

  createUser(user: ICreateUser): Observable<UserInfo> {
    const requestUrl = '/api/User/RegisterUser';
    return this.http.post<UserInfo>(requestUrl, user);
  }

  saveUser(user: ISaveUser): Observable<UserInfo> {
    const requestUrl = '/api/User/SaveUser';
    return this.http.post<UserInfo>(requestUrl, user);
  }

  completeSignUp() {
    const requestUrl = '/api/User/CompleteSignUp';
    return this.http.post(requestUrl, null);
  }

  isBizligoAccountExist(email: string) {
    const requestUrl = '/api/user/isbizligoaccountexist';
    return this.http.get<boolean>(requestUrl, { params: { email } });
  }

  sendEmailConfirmation(email: string) {
    const requestUrl = '/api/user/sendverifycodetouser';
    return this.http.post(
      requestUrl,
      { email: email, isEmailSelected: true },
      { observe: 'response', responseType: 'text' }
    );
  }

  verifyEmail(email: string, verificationCode: string) {
    const requestUrl = '/api/user/verifycode';
    return this.http.post<void>(requestUrl, {
      email: email,
      verificationCode: verificationCode,
    });
  }

  getMemberShipPlans(communityId: number) {
    const requestUrl = '/api/account/GetMembershipPlansInfo';
    return this.http.get<Array<MembershipPlan>>(requestUrl, {
      params: { communityId },
    });
  }
  getUserByEmail(email: string) {
    const requestUrl = '/api/user/getUserByEmail';
    return this.http.get<UserInfo>(requestUrl, { params: { email } });
  }
  checkSignInUser() {
    const requestUrl = '/api/user/CheckSignInUser';
    return this.http.get<UserInitialData>(requestUrl);
  }
  saveLoyaltyPoints(query: SaveLoyaltyPoints) {
    //let params: any = { 'pageNumber': pageNumber, 'pageSize': pageSize }
    return this.http.post<boolean>('/api/common/SaveLoyaltyPoints', query);
  }

  loginRedirect() {
    this.store.select(selectApplicationConfiguration).pipe(
      filter((config: ApplicationConfigurationState) => config.tenantBusinessType != null),
      take(1),
      map((config: ApplicationConfigurationState) => config.tenantBusinessType),
      switchMap(s => this.getCurrentUser2().pipe(map(user => {
        return { isLoggedIn: user != null, businessType: s.businessType };
      })))
    ).subscribe(s => {
      let extraQueryParameters = { 'tenantBusinessType': s.businessType, 'tenant': '' };
      if (environment.production == false && environment.ENV == 'DEV') {
        extraQueryParameters.tenant = this.getCurrentTenant();
      }
      if (s.isLoggedIn) {
        this.msalService.logoutPopup({ popupWindowAttributes: { popupPosition: { left: 0, top: 0 }, popupSize: { height: 0, width: 0 } } }).subscribe(l => {
          this.msalService.loginRedirect({
            scopes: silentRequest.scopes,
            authority: environment.b2cPolicies.signIn.authorities,
            extraQueryParameters: extraQueryParameters
          })
        })
      } else {
        this.msalService.loginRedirect({
          scopes: silentRequest.scopes,
          authority: environment.b2cPolicies.signIn.authorities,
          extraQueryParameters: extraQueryParameters
        })
      }

    })
  }

  getCurrentTenant(): string {
    let currentHost = window.location.host;
    let tenants = [
      { url: 'localhost:4200', tenant: 'smacaps' },
      //{ 'url': 'localhost:4200', 'tenant': 'pamten' },
      { url: 'b2b.bizligotest.com', tenant: 'b2b' },
      { url: 'b2c.bizligotest.com', tenant: 'b2c' },
    ];

    let tenant = tenants.find((t) => t.url === currentHost);
    if (tenant) {
      return tenant.tenant;
    }
    return 'b2c';
  }

  hasFeatureAccess(keyName: string, communityId?: number): Observable<boolean> {
    let permissionExclude: string[] = ['BLOG'];
    return this.store.select(SelectTenantFeatureConfiguration).pipe(
      filter((s) => s.length > 0),
      switchMap((featureConfiguration) => {
        return this.getAccessToken2().pipe(
          switchMap((token) => {
            return this.store.select(SelectCommunityFeatureConfiguration).pipe(
              filter((cf) => cf && cf.length > 0),
              switchMap((communityFeatureConfiguration) => {
                return this.store.select(selectCommunityFeatureAccess).pipe(
                  map((userAccess) => {
                    let hasFeatureAccess = false;
                    let feature = featureConfiguration?.find(
                      (s) => s.keyName == keyName
                    );
                    let communityfeature = communityFeatureConfiguration?.find(
                      (s) => s.keyName == keyName
                    );
                    if (feature == null) {
                      return false;
                    }
                    if (feature.enable == true) {
                      hasFeatureAccess = true;
                      if (
                        communityfeature &&
                        communityfeature.enable == false
                      ) {
                        hasFeatureAccess = false;
                      }
                    }
                    if (hasFeatureAccess && token && userAccess) {
                      let permission: Feature[];
                      if (communityId > 0) {
                        permission = userAccess.find(
                          (s) => s.communityId == communityId
                        )?.features;
                      } else {
                        permission = userAccess.flatMap((s) => s.features);
                      }
                      if (permission) {
                        if (communityId > 0) {
                          var isFeaatureEnable = permission.find(
                            (s) => s.keyName == keyName
                          );
                          if (isFeaatureEnable) {
                            if (isFeaatureEnable.value == true) {
                              hasFeatureAccess = true;
                            } else {
                              hasFeatureAccess = false;
                            }
                          } else {
                            hasFeatureAccess = true;
                          }
                        } else {
                          if (!permissionExclude.includes(keyName)) {
                            var hasFeaturesAccess = permission.filter(
                              (s) => s.keyName == keyName
                            );
                            if (hasFeaturesAccess.length > 0) {
                              if (
                                hasFeaturesAccess.filter((s) => s.value == true)
                                  .length > 0
                              ) {
                                hasFeatureAccess = true;
                              } else {
                                hasFeatureAccess = false;
                              }
                            } else if (keyName == 'LMS') {
                              hasFeatureAccess = false;
                            } else {
                              hasFeatureAccess = true;
                            }
                          }
                        }
                      }
                    } else if (
                      (feature.nonMember != true &&
                        keyName == 'BUSINESSDIRECTORY') ||
                      (feature.nonMember != true && keyName == 'ORGDIRECTORY')
                    ) {
                      hasFeatureAccess = false;
                      this.loginRedirect();
                    }
                    if (environment.ENV == 'DEV') {
                      //console.log(`${hasFeatureAccess ? 'has access to' : 'don\'t have access to'} ${keyName} `,);
                    }
                    return hasFeatureAccess;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  updateUserUsedForgotPassword(email: string) {
    return this.http.post(`/api/common/updateforceresetuserpaassword`, {});
  }

  navigateToMobileApp() {
    this.getAccessToken2()
      .pipe(take(1))
      .subscribe((token) => {
        if (token != null) {
          var refreshToken: MsalRefreshToken = JSON.parse(
            localStorage.getItem(
              Object.keys(localStorage).find((s) => s.includes('refreshtoken'))
            )
          );
          localStorage.removeItem('navigateToMobile');
          this.store.dispatch(RefreshFooterVisibility());
          localStorage.clear();
          window.location.href = `msauth.com.pamten.bizligo://auth?token=${token}&refreshToken=${refreshToken.secret}`;
        }
      });
  }

  shouldReturnToMobileApp() {
    return localStorage.getItem('navigateToMobile') == '1';
  }
}

export interface TokenResponse {
  code: string;
}

export interface Token {
  access_token: string;
  token_type: string;
  expires_in: string;
  refresh_token: string;
}
