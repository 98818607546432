import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import { map, mergeMap, catchError, exhaustMap, } from 'rxjs/operators';
import { MyprofileService } from '../../../../module/my-profile/services/myprofile.service';
import * as fromActions from '../action/layout.actions';


@Injectable()
export class LayoutEffects {



  constructor(private actions$: Actions, private myprofileservice:MyprofileService) {}

  loadProfileSettingsdata$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadProfileSettings),
    mergeMap((param) =>
      this.myprofileservice.GetProfileSettingsData(param.query).pipe(
        map(data => {
          
          return fromActions.LoadProfileSettingsDataSuccess({ profilesettings: data })
        }),
        catchError(err => of(fromActions.LoadProfileSettingsDataError({ error: err })))
      )
    )
  ));

  loadNotificationsdata$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadNotifications),
    mergeMap((param) =>
      this.myprofileservice.GetNotificationsData().pipe(
        map(data => {
          
          return fromActions.LoadNotificationsDataSuccess({ notifications: data })
        }),
        catchError(err => of(fromActions.LoadProfileSettingsDataError({ error: err })))
      )
    )
  ));
  
}
