import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './../action/create-ads.actions';
import { mergeMap, map, catchError, of } from 'rxjs';
import { AdvertisementService } from 'src/app/services/advertisement/advertisement.service';


@Injectable()
export class CreateAdsEffects {


  constructor(private actions$: Actions,private service : AdvertisementService) {}
  LoadPromotionPlansInfo$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadPromotionPlansInfo),
    mergeMap(param => this.service.getPromotionPlans(param.query).pipe(
      map(data => {
        data.forEach(s => {
          s.active = false;
        })
        return fromActions.LoadPromotionPlansInfoSuccess({ Promotionsinfo: data })
      }),
      catchError(err => of(fromActions.LoadPromotionPlansInfoError({ error: err })))
    )))
  );
}
