import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringLimitTo'
})
export class StringLimitToPipe implements PipeTransform {

  transform(value: string, hideAfter: number): string {
    if (value && value.length > 0) {
      return value.length > hideAfter ? `${value.slice(0, hideAfter)}..` : value;
    }
    return value;
  }

}
