import { Injectable } from '@angular/core';

import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';



@Injectable()
export class AuthModuleLanguageResolver implements Resolve<LocaleResourceSet> {

    constructor(
        private translateService: TranslateService,
        private localResourceService: LocalResourceService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
        //this.dataService.loadLocalResource().subscribe(s => console.log(s,"completed"));
        // page name
        this.translateService.set('login', 'Login', 'en');
        this.translateService.set('register.first-name', 'First Name', 'en');
        this.translateService.set('login', 'Login', 'en');
        this.translateService.set('login', 'Login', 'en');
        //------------------------------------------------------------
        // SignUp Page
        this.translateService.set('signup.have-an-account', 'Have an account ?', 'en');
        this.translateService.set('signup.personal-information', 'Personal Information', 'en');
        this.translateService.set('signup.first-name.placehoder' , 'First name', 'en');
        this.translateService.set('signup.last-name.placehoder', 'Last name', 'en');
        this.translateService.set('signup.email-address.placeholder', 'Please enter email address ', 'en');
        this.translateService.set('signup.phone-number.placeholder', 'phone number', 'en');
        this.translateService.set('signup.enter-password.placeholder', 'Atleast with 8 characters', 'en');
        this.translateService.set('signup.confirm-your-password.placeholder', 'Re-enter your password', 'en');
        this.translateService.set('signup.auto-fill', 'Auto Fill', 'en');
        this.translateService.set('signup.Next', 'Next', 'en');
        this.translateService.set('signup.image-logo', '/assets/images/bizligo-logo.png', 'en');
        this.translateService.set('signup.apple-logo', 'fab fa-apple', 'en');
        this.translateService.set('signup.windows-logo', 'fab fa-windows', 'en');
        this.translateService.set('signup.google-logo', 'fab fa-google', 'en');
        this.translateService.set('signup.linkedin-in-logo', 'fab fa-linkedin-in', 'en');
        this.translateService.set('signup.image', 'assets/images/bizligo-register.png', 'en');
        this.translateService.set('signup.enter-organization-information', 'Organization Information', 'en');
        this.translateService.set('signup.organization-name', 'Organization Name', 'en');
        this.translateService.set('signup.enter-organization-name.placeholder', 'Enter Organization name', 'en');
        this.translateService.set('signup.select-business-category', 'Select Business Category', 'en');
        this.translateService.set('signup.select-category-with-max-limit-of-25*.placeholder', 'Business Category', 'en');
        this.translateService.set('signup.number-of-employees', 'number of employees', 'en');
        this.translateService.set('signup.select-number-of-eployees', 'select number of employees', 'en');
        this.translateService.set('signup.website-url', ' website URL', 'en');
        this.translateService.set('signup.enter-website-url.placeholder', 'Enter website URL', 'en');
        this.translateService.set('signup.select-location', 'Select Location', 'en');
        this.translateService.set('signup.street-no-name', 'Street no & Name', 'en');
        this.translateService.set('signup.street-no-name.placeholder', '', 'en');
        this.translateService.set('signup.corporate-address.placeholder', '', 'en');
        this.translateService.set('signup.corporate-address', 'Corporate Address', 'en');
        this.translateService.set('signup.appartment-suite-floor', 'Appartment / site / Floor', 'en');
        this.translateService.set('signup.appartment-suite-floor.placeholder', '', 'en');
        this.translateService.set('signup-city', 'City', 'en');
        this.translateService.set('signup-city.placeholder', '', 'en');
        this.translateService.set('signup-state', 'State', 'en');
        this.translateService.set('signup-state.placeholder', '', 'en');
        this.translateService.set('signup-zipcode', 'Zip Code', 'en');
        this.translateService.set('signup-zipcode.placeholder', '', 'en');
        this.translateService.set('signup-country', 'Country', 'en');
        this.translateService.set('signup-country.placeholder', '', 'en');
        this.translateService.set('signup.business-description', 'Business Description', 'en');
        this.translateService.set('signup.provide-information-about-your-business', 'provide information about your business', 'en');
        this.translateService.set('signup.back', 'Go Back', 'en');
        this.translateService.set('signup.save-continue', 'Save & Continue', 'en');
        this.translateService.set('signup.email-verification', 'Email Verification', 'en');
        this.translateService.set('signup.email-image', "assets/images/signup/register-bz-email.png", 'en');
        this.translateService.set('signup.verify-account', 'Please Verify account', 'en');
        this.translateService.set('signup.enter-the-six-digit-code', 'Enter the six-digit code we sent to your email address to verify your new Bizligo account', 'en');
        this.translateService.set('signup.resend-code', 'Resend code', 'en');
        this.translateService.set('signup.verify', 'Verify code', 'en');
        this.translateService.set('signup.email-verified-successfully', 'E-mail Verified Successfully', 'en');
        this.translateService.set('signup.verified-mail.image', "assets/images/signup/register-bz-verified-mail.png", 'en');
        this.translateService.set('signup.email-verified-successfully', 'E-mail Verified Successfully', 'en');
        this.translateService.set('signup.please-login-to-continue', 'Please login to continue', 'en');
        this.translateService.set('signup.login', 'Login', 'en');
        this.translateService.set('signup.login-in-to-your-account', 'Login  your Account', 'en');
        this.translateService.set('signup.not-a-member?', 'Not a Member', 'en');
        this.translateService.set('signup.or', 'OR', 'en');
        this.translateService.set('signup.home', 'Home', 'en');
        this.translateService.set('signup.directory', 'Directory', 'en');
        this.translateService.set('signup.member-directory', 'Member Directory', 'en');
        this.translateService.set('signup.business-directory', 'Business Directory', 'en');
        this.translateService.set('signup.global-events', 'Global Events', 'en');
        this.translateService.set('signup.events-list', 'Events list', 'en');
        this.translateService.set('signup.others', 'Others', 'en');
        this.translateService.set('signup.global-communities', 'Global Communities', 'en');
        this.translateService.set('signup.careers', 'Careers', 'en');
        this.translateService.set('signup.blog', 'Blog', 'en');
        this.translateService.set('signup.contact-us', 'Contact Us', 'en');
        this.translateService.set('signup.help', 'Help', 'en');
        this.translateService.set('signup.please-wait', 'Please wait while we are creating an account for you...', 'en');
        this.translateService.set('signup.login-to-continue', 'Signup is successful, Please login to continue', 'en');
        this.translateService.set('signup.sign-up', 'Sign Up ', 'en');
        this.translateService.set('signup.email-address', 'Email Address', 'en');
        this.translateService.set('signup.password', 'Password', 'en');
        this.translateService.set('signup.keep-me-signed-in', 'Keep me signed in ', 'en');
        this.translateService.set('signup.forgot-password-?', 'Forgot Password ? ', 'en');
        this.translateService.set('signup.agree', 'By creating an account you agree to our', 'en');
        this.translateService.set('signup.terms-of-conditions', 'Terms and Conditions', 'en');
        this.translateService.set('signup.privacy-policy', 'Privacy Policy', 'en');
        this.translateService.set('signup.confirm-password', 'Confirm Password', 'en');
        this.translateService.set('signup.first-name', 'First Name', 'en');
        this.translateService.set('signup.last-name', 'Last Name', 'en');
        this.translateService.set('signup.saving' , 'Saving...', 'en');
        this.translateService.set('signup.loading', 'Loading...', 'en');
        this.translateService.set('signup.business-description-max-characters', 'Max 500 characters', 'en');
        this.translateService.set('signup.sending-email' , 'Sending email...', 'en');
        this.translateService.set('signup.organization-information-saved-successfully', 'Organization information saved successfully', 'en');
        this.translateService.set('signup.email-otp-is-not-valid', 'Email OTP is not valid, please try again', 'en');
        this.translateService.set('signup.verification-code', 'A verification code is sent to your email', 'en');
       // this.translateService.set('signup.payment-completed', 'Payment completed successfully', 'en');
        this.translateService.set('signup.close', 'Close', 'en');
        this.translateService.set('signup.verifying', 'Verifying...', 'en');
        this.translateService.set('signup.phone-number', 'Phone number', 'en');
        this.translateService.set('signup.email', 'email', 'en');
        this.translateService.set('signup.address-already-exists', 'address already exists. Please enter a new Email address', 'en');
        this.translateService.set('signup.password-should-be-contain-atleast-1-upper-case', 'Password should be contain atleast 1 upper case', 'en');
        this.translateService.set('signup.password-should-be-contain-atleast-1-lower-case', 'Password should be contain atleast 1 lower case', 'en');
        this.translateService.set('signup.password-should-be-contain-atleast-1-special-character', 'Password should be contain atleast 1 special character', 'en');
        this.translateService.set('signup.password-should-contain-atleast-1-digit', 'Password should contain atleast 1 digit', 'en');
        this.translateService.set('signup.not-match', 'not match', 'en');
        this.translateService.set('signup.unable-to-fetch-organization-details', 'Unable to fetch organization details', 'en');
        this.translateService.set('signup.unable-to-fetch-no-of-employee-list', 'Unable to fetch no of employee list', 'en');
        this.translateService.set('signup.error-while-loading-business-category', 'Error while loading business category', 'en');
        this.translateService.set('signup.ok', 'Ok', 'en');
        this.translateService.set('signup.error-while-loading-organization', 'Error while loading organization', 'en');
        this.translateService.set('signup.email-is-already-verified', 'Email is already verified', 'en');
        this.translateService.set('signup.unable-to-send-email-verification-code-please-try-again', 'Unable to send email verification code, please try again', 'en');
        this.translateService.set('signup.signup-is-successful-please-login-to-continue', 'Signup is successful, Please login to continue', 'en');
        this.translateService.set('signup.email-verified-successfully', 'Email verified successfully', 'en');
        this.translateService.set('signup.please-fill-all-the-required-fields', 'Please fill all the required fields', 'en');
        this.translateService.set('signup.dynamic-forms', 'Dynamic Forms', 'en');
        this.translateService.set('signup.add', 'Add', 'en');
        this.translateService.set('signup.submit', 'Submit', 'en');
        this.translateService.set('signup.remove', 'Remove', 'en');
        this.translateService.set('signup.please-enter-valid-first-name', 'Please Enter Valid First Name', 'en');
        this.translateService.set('signup.please-enter-valid-last-name', 'Please Enter Valid Last Name', 'en');
        this.translateService.set('signup.please-enter-valid-organization-name', 'Please Enter Valid Organization Name', 'en');
        this.translateService.set('signup.please-enter-valid-website-url', 'Please enter valid website URL', 'en');
        // Latest changes
        this.translateService.set('signup.address-already-exists-enter-a-new-website', 'address already exists, Please enter a new website', 'en');
        this.translateService.set('signup.your-account-registered-with-bizligo', 'Your account has already been registered with Bizligo, Please use the same credentials.', 'en');
        this.translateService.set('signup.discovering-bizligo-account', 'Discovering Bizligo account...', 'en');
        this.translateService.set('signup.for-more-information', 'for more information', 'en');
        

        //------------------------------------------------------------
        return this.localResourceService.loadLocalResource("signup").pipe(
            tap(resp => {
                resp.data.forEach(data => {
                    this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
                });
            })
        );
    }
}
