import { createFeatureSelector, createSelector } from "@ngrx/store";
import { HomePageModuleState } from "../state/home";

export const homeFeatureKey = 'homeModule';

export const selectHomeModule = createFeatureSelector<HomePageModuleState>(
    homeFeatureKey,
);

export const selectHomePageData = createSelector(
    selectHomeModule,
    (state: HomePageModuleState) => state.homePage
);

export const selectGWCHomePageData = createSelector(
    selectHomeModule,
    (state: HomePageModuleState) => state.gwcHomePageData
);