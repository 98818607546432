import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class PagesModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('pages.loading', 'Loading....', 'en');
    this.translateService.set('pages.unable-to-load', 'Unable to load Requested Page', 'en');

   // ----------------------------------------------------------------
    return this.localResourceService.loadLocalResource("pages").pipe(
      tap(resp => {
          resp.data.forEach(data => {
              this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
          });
      })
  );
  }
}
