import { Action, createReducer, on } from '@ngrx/store';
import { CreateAdStatePageState, PromotionPlanDto } from '../state/create-ads';
import * as fromActions from './../action/create-ads.actions';



export const initialState: CreateAdStatePageState = {
  promotionPlansDetails:{
    data:null,
    error:null,
    loaded:false,
    loading:false
  }
};

export const createAdsReducer = createReducer<CreateAdStatePageState>(
  initialState,
 on(fromActions.LoadPromotionPlansInfo, (state, { query }) => ({
    ...state,
    promotionPlansDetails: {
      data: null,
      error: null,
      loaded: false,
      loading: true
    }
  })),
  on(fromActions.LoadPromotionPlansInfoSuccess, (state, { Promotionsinfo }) => ({
    ...state,
    promotionPlansDetails: {
      data: Promotionsinfo,
      error: null,
      loaded: true,
      loading: false
    }
  })),
  on(fromActions.LoadPromotionPlansInfoError, (state, { error }) => ({
    ...state,
    promotionPlansDetails: {
      data: null,
      error: error,
      loaded: false,
      loading: false
    }
  })),

  on(fromActions.selectPlan, (state, { planId }) => {
  let existingList = state.promotionPlansDetails.data.filter(s => s.id != planId);
    let position = state.promotionPlansDetails.data.findIndex(s => s.id == planId);
    let currentPlan = state.promotionPlansDetails.data.find(s => s.id == planId);
    let promotionPlans: PromotionPlanDto = { ...currentPlan, active: true };
    const updatedPlans = existingList.map(plan => ({
      ...plan,
      active: plan.id === planId,
    }));
    updatedPlans.splice(position, 0, promotionPlans);
    return ({
      ...state,
      promotionPlansDetails: {
        ...state.promotionPlansDetails,
        data:updatedPlans
        
      }
    })
  }),

  on(fromActions.removeSelectedPlan, (state, { planId }) => {
    let existingList = state.promotionPlansDetails.data.filter(s => s.id != planId);
      let position = state.promotionPlansDetails.data.findIndex(s => s.id == planId);
      let currentPlan = state.promotionPlansDetails.data.find(s => s.id == planId);
      let promotionPlans: PromotionPlanDto = { ...currentPlan, active: false };
      const updatedPlans = existingList.map(plan => ({
        ...plan,
        active: plan.id === planId,
      }));
      updatedPlans.splice(position, 0, promotionPlans);
      return ({
        ...state,
        promotionPlansDetails: {
          ...state.promotionPlansDetails,
          data:updatedPlans
          
        }
      })
    }),
);
