import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChangePageName } from 'src/app/store/actions/app.action';

@Component({
  selector: 'permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    this.store.dispatch(ChangePageName({ pageName:'' }));
  }

}
