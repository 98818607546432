import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SponsorComponent } from './sponsor.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    SponsorComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports: [
    SponsorComponent
  ]
})
export class SponsorModule { }
