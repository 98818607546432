import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class SurveyModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translate.set('survey.loading', 'Loading...', 'en');
    this.translate.set('survey.select-the-value', 'Select the Value', 'en');
    this.translate.set('survey.yes', 'Yes', 'en');
    this.translate.set('survey.no', 'No', 'en');
    this.translate.set('survey.enter-text.placeholder', 'Enter Text', 'en');
    this.translate.set('survey.cancel', 'Cancel', 'en');
    this.translate.set('survey.save', 'Save', 'en');
    this.translate.set('survey.survey-page-name', 'Event Survey', 'en');
    this.translate.set('survey.survey_submitted_successfully', 'survey submitted successfully', 'en');
    this.translate.set('survey.strongly-disagree', 'Strongly Disagree', 'en');
    this.translate.set('survey.disagree', 'Disagree', 'en');
    this.translate.set('survey.neutral', 'Neutral', 'en');
    this.translate.set('survey.agree', 'Agree', 'en');
    this.translate.set('survey.strongly-agree', 'Strongly Agree', 'en');
    this.translate.set('survey.thisfield', 'ThisField ', 'en');
    this.translate.set('survey.survey-submitted', 'This survey has been submitted or inactivated', 'en');
    this.translate.set('survey.saving', 'Saving...', 'en');
    
    return this.localResourceService.loadLocalResource("survey").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
