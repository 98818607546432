import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, tap, filter, exhaustMap, } from 'rxjs/operators';
import { MenuItems } from 'src/app/models/configuration/configuration';
import { CommunitiesService } from 'src/app/module/communities/service/communities-service.service';
import { MyprofileService } from 'src/app/module/my-profile/services/myprofile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfigurationService } from 'src/app/services/config/configuration.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { SelectClientConfiguration, SelectTenantInfo } from '../selectors/app.selector';



import * as fromActions from './../actions/app.action';
import { SlugPipe } from 'src/app/pipe/slug.pipe';

@Injectable()
export class AppEffects {

    constructor(private actions$: Actions,
        private organizationService: OrganizationService,
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private router: Router,
        private store: Store,
        private titleService: Title,
        private slguPipe: SlugPipe,
        private matSnackBar: MatSnackBar,
        private communitiesService: CommunitiesService,
        private myprofileservice: MyprofileService) { }

    loadBusinessType$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadBusinessType),
        mergeMap((param) => this.organizationService.getTenantBusinessType().pipe(
            map(data => {
                return fromActions.LoadBusinessTypeSuccess({ businessType: data })
            }),
            catchError(err => of(fromActions.LoadBusinessTypeError({ error: err })))
        ))
    ));

    loadUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadCurrentUserInfo),
        exhaustMap((param) =>
            this.myprofileservice.GetUserInfo(param.query).pipe(
                map(data => {
                    return fromActions.LoadCurrentUserInfoSuccess({ userInfo: data })
                }),
                catchError(err => of(fromActions.LoadCurrentUserInfoError({ error: err })))
            ))
    ));

    loadMenuInfo$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadMenuData),
        mergeMap((param) =>
            this.configurationService.getMenuList().pipe(
                map(result => {
                    var data = result.sort(s => s.displayOrder);
                    const nodesById: { [id: number]: MenuItems } = {};
                    data.forEach(node => (nodesById[node.menuId] = node));

                    const rootNodes: MenuItems[] = [];
                    data.forEach(node => {
                        const parent = nodesById[node.parentId];
                        if (parent) {
                            if (!parent.submenu) {
                                parent.submenu = [];
                            }
                            parent.submenu.push(node);
                            parent.submenu.sort(s => s.displayOrder);
                        } else {
                            rootNodes.push(node);
                            rootNodes.sort(s => s.displayOrder);
                        }
                    });

                    var result = data.sort(s => s.parentId);
                    // var parentMenu: MenuItems[] = data.filter(s => s.parentId == 0 );
                    // var childMenu: MenuItems[] = data.filter(s => s.parentId > 0);
                    // var menuList = parentMenu.map(function (x) {
                    //     var result = childMenu.filter(a1 => a1.parentId == x.menuId);
                    //     if (result.length > 0) { x.submenu = [...x.submenu, ...result].sort(s => s.displayOrder), x.class = "menu-toggle" }
                    //     return x
                    // })
                    // menuList.sort((s1, s2) => {
                    //     return s1.displayOrder - s2.displayOrder;
                    // });
                    // var order = menuList.sort((a, b) => a.displayOrder == b.displayOrder ? -1 : 0).filter(s => s.status == true);
                    // console.log("menu order",order);
                    rootNodes.forEach((parent) => {
                        if (parent.submenu.length > 0) {
                            parent.class = "menu-toggle";
                            parent.submenu.forEach((child) => {
                                if (child.submenu.length > 0) {
                                    child.class = "ml-sub-menu";
                                    child.submenu.forEach((subchild) => {
                                        if (subchild.submenu.length > 0) {
                                            subchild.class = "ml-sub-sub-menu";
                                        }
                                    })
                                }

                            });
                        }
                    });

                    var order = rootNodes.filter(s => s.status == true);
                    var menu = order.map(item => ({
                        ...item,
                        submenu: item.submenu.length > 0 ? [...item.submenu].sort((a, b) => a.displayOrder - b.displayOrder) : []
                    }));
                    menu = menu.sort((a, b) => a.displayOrder - b.displayOrder);
                    return fromActions.LoadMenuDataSuccess({ menus: menu })
                }),
                catchError(err => of(fromActions.LoadMenuDataError({ error: err })))
            ))
    ));
    loadTenantId$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadTenantId),
        mergeMap((param) => this.configurationService.getTenantId().pipe(
            map(data => {
                return fromActions.LoadTenantIdSuccess({ tenant: data })
            }),
            catchError(err => of(fromActions.LoadTenantIdError({ error: err })))
        ))
    ));

    loadTenantFeatureConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadTenantFeatureConfiguration),
        mergeMap((param) => this.configurationService.getTenantAllFeatures().pipe(
            map(data => {
                return fromActions.LoadTenantFeatureConfigurationSuccess({ config: data })
            }),
            catchError(err => of(fromActions.LoadTenantFeatureConfigurationError({ error: err })))
        ))
    ));

    loadClientConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadClientConfiguration),
        mergeMap((param) => this.configurationService.getClientConfiguration().pipe(
            map(data => {
                return fromActions.LoadClientConfigurationSuccess({ config: data })
            }),
            catchError(err => of(fromActions.LoadClientConfigurationError({ error: err })))
        ))
    ));

    loadTenantLogo$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadTenantLogo),
        mergeMap((param) => this.configurationService.getTenantLogo().pipe(
            map(data => {
                return fromActions.LoadTenantLogoSuccess({ logo: data })
            }),
            catchError(err => of(fromActions.LoadTenantLogoError({ error: err })))
        ))
    ));


    loadHeaderFooterConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadHeaderFooterConfiguration),
        mergeMap((param) => this.configurationService.getHeaderFooterConfig().pipe(
            map(data => {
                return fromActions.LoadHeaderFooterConfigurationSuccess({ hfconfig: data })
            }),
            catchError(err => of(fromActions.LoadHeaderFooterConfigurationError({ error: err })))
        ))
    ));

    loadUserStatus$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadUserStatus),
        mergeMap((param) => this.authService.getAccessToken2().pipe(
            switchMap((token) => {
                if (token) {
                    return this.authService.checkSignInUser().pipe(
                        map(status => fromActions.LoadUserStatusSuccess({ userStatus: status })),
                        catchError((err: HttpErrorResponse) => {
                            if (err.status == 403) {
                                return of(fromActions.LoadUserStatusNotLoggedIn())
                            }
                            return of(fromActions.LoadUserStatusError({ error: err }))
                        })
                    );
                } else {
                    return of(fromActions.LoadUserStatusNotLoggedIn())
                }
            }),
            catchError(err => {
                return of(fromActions.LoadUserStatusNotLoggedIn())
            })
        ))
    ));

    loadUserStatusReload$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadUserStatusReload),
        mergeMap((param) => this.authService.getAccessToken2().pipe(
            switchMap((token) => {
                if (token) {
                    return this.authService.checkSignInUser().pipe(
                        map(status => fromActions.LoadUserStatusReloadSccess({ userStatus: status })),
                        catchError(err => {
                            return of(fromActions.LoadUserStatusError({ error: err }))
                        })
                    );
                } else {
                    return of(fromActions.LoadUserStatusNotLoggedIn())
                }
            }),
            catchError(err => {
                return of(fromActions.LoadUserStatusNotLoggedIn())
            })
        ))
    ));

    loadAvailableLanguage$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadAvilableLanguages),
        mergeMap((param) => this.configurationService.getAvailableLanguage().pipe(
            map(data => {
                return fromActions.LoadAvilableLanguagesSuccess({ langs: data })
            }),
            catchError(err => of(fromActions.LoadAvilableLanguagesError({ error: err })))
        ))
    ));

    hasFeatureAccess$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.hasFeatureAccess),
        mergeMap((param) => this.authService.hasFeatureAccess(param.keyName, param.communityId).pipe(
            map(hasAccessFeaature => {
                if (!hasAccessFeaature) {
                    this.router.navigate(['/common/featurenotfound']);
                }
                return fromActions.hasFeatureAccessResult({ hasAccess: hasAccessFeaature })
            })
        ))
    ));

    changePageName$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ChangePageName),
        mergeMap((param) => {
            return of(fromActions.ChangePageTitle({ pageTitle: param.pageName }))
        })
    ));

    changePageTitle$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ChangePageTitle),
        mergeMap((param) => {
            return this.store.select(SelectClientConfiguration).pipe(
                filter(d => d != null),
                map(s => s.clientName),
                map(tenantName => {
                    if (param.pageTitle && param.pageTitle.length > 0) {
                        this.titleService.setTitle(`${param.pageTitle} - ${tenantName}`);
                    } else {
                        this.titleService.setTitle(`${tenantName}`);
                    }
                    return fromActions.ChangePageTitleSucess()
                }),
            )
        })
    ));

    joinCommunity$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.joinCommunity),
        mergeMap((param) => this.communitiesService.joinCommunity(param.community).pipe(
            map(success => fromActions.joinCommunityNavigateToMemberShipPlan())
        ))
    ));

    leaveCommunity$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.leaveCommunity),
        mergeMap((param) => this.communitiesService.leaveCommunity(param.community).pipe(
            map(success => fromActions.leaveCommunitySuccess()),
            catchError((err: HttpErrorResponse) => {
                console.log("Error ", err);
                if (err.status == 406) {
                    this.matSnackBar.open(`Please make another member as community admin`, '', { duration: 5000, panelClass: ['bg-danger', 'text-white'] });

                    return of(fromActions.leaveCommunityError({ error: err }))
                } else {
                    this.matSnackBar.open(`Failed to Leave community, please try again`, '', { duration: 5000, panelClass: ['bg-danger', 'text-white'] });
                    return of(fromActions.leaveCommunityError({ error: err }))
                }
            })
        ))
    ));
    cancelJoinCommunity$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.cancelJoinCommunity),
        mergeMap((param) => this.communitiesService.cancelJoinCommunity(param.community).pipe(
            map(success => fromActions.cancelJoinCommunitySuccess()),
        ))
    ));

    acceptCommunityInvitation$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.acceptCommunityInvitation),
        mergeMap((param) => this.communitiesService.acceptRejectCommunityInvitation(param.community).pipe(
            map(success => fromActions.acceptCommunityInvitationSuccess())
        ))
    ));

    rejectCommunityInvitation$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.rejectCommunityInvitation),
        mergeMap((param) => this.communitiesService.acceptRejectCommunityInvitation(param.community).pipe(
            map(success => fromActions.rejectCommunityInvitationSuccess())
        ))
    ));

    footerVisibility$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.RefreshFooterVisibility),
        map(() => {
            let footerVisibility = !this.authService.shouldReturnToMobileApp();
            //console.log("footer", footerVisibility);
            return fromActions.ChangeFooterVisibility({ visible: footerVisibility })
        })
    ));

    changeAdminCommunityLogo$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ChangeAdminCommunityLogo),
        mergeMap((param) => this.communitiesService.getCommunityLogo(param.communityId).pipe(
            map(data => {
                if (data == null) {
                    return fromActions.UpdateCommunityLogo({ link: '/', logoUrl: null });
                }
                return fromActions.UpdateCommunityLogo({ link: `/community/${this.slguPipe.transform(data.name)}/${data.id}`, logoUrl: data.logoPath })
            }),
            catchError((err: HttpErrorResponse) => {
                return of(fromActions.UpdateCommunityLogo({ link: '/', logoUrl: null }));
            })
        ))
    ));

    loadCommunityFeatureConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadCommunityFeatureConfiguration),
        mergeMap((param) => this.configurationService.getCommunityAllFeatures().pipe(
            map(data => {
                return fromActions.LoadCommunityFeatureConfigurationSuccess({ config: data })
            }),
            catchError(err => of(fromActions.LoadCommunityFeatureConfigurationError({ error: err })))
        ))
    ));

    loadMembershipStatusses$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LoadMembershipStatuses),
        mergeMap((param) => this.configurationService.getUserMembershipStatuses().pipe(
            map(data => {
                return fromActions.LoadMembershipStatusesSuccess({ memberShipstatusses: data })
            }),
            catchError(err => of(fromActions.LoadMembershipStatusesError({ error: err })))
        ))
    ));
}
