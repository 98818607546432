// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  ENV:"QA" as ENV,
  appVersion: require('../../package.json').version + ' (QA)',
  production: true,
  sessionTimeout: 15,
  apiUrl: 'https://tenant2.bizligotest.com',
  acceptJsUrl: 'https://jstest.authorize.net/v3/AcceptUI.js',
  payUPaymentUrl: 'https://test.payu.in/_payment',
  b2cAuthorityUrl: 'https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com',
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
      editProfile: "b2c_1_edit_profile_v2"
    },
    signIn: {
      authorities: "https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
    },
    signInSignUp: {
      authority: "https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
    },
    signUp: {
      authority: "https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
    },
    editProfile: {
      authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2"
    },
    resetPassword: {
      authority: "https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com/B2C_1_resetpwd"
    },
    changePassword: {
      authority: "https://bizligodev.b2clogin.com/bizligodev.onmicrosoft.com/B2C_1A_PasswordChange"
    },
    authorityDomain: "bizligodev.b2clogin.com"
  },
  clientId: 'a7aecd94-0e21-4017-883e-176a482fbf17',
  appInsights: {
    instrumentationKey: '305643a7-6413-41cc-b970-af2966058add'
  },
  scopes: ["openid", "profile", "offline_access", "https://bizligodev.onmicrosoft.com/05f4e9df-5e4d-433d-b5ae-75588aef739a/access_as_user"],
  minLogLevel: 'trace'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
