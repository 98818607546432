import { Directive, Self, OnDestroy, OnInit, ElementRef, Input, Renderer2, AfterViewInit, OnChanges, EventEmitter, Injectable } from '@angular/core';
import { NgControl, UntypedFormControl, FormGroupDirective, NgForm, AbstractControl, ValidationErrors } from '@angular/forms';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatError } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { CapitalizePipe } from './../../pipe/capitalize.pipe';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CustomValidatorService } from 'src/app/services/validation/custom-validator.service';
import { TranslatePipe } from '@ngx-translate/core';
//import { TranslatePipe } from '@ngx-translate/core';
@Injectable()
export class DefaultErrorStateMatcher implements ErrorStateMatcher {
  constructor(private validation: CustomValidatorService) { }
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    let isValid: boolean = false;
    //console.log("directive ");
    if (control?.enabled) {
      isValid = !!(control && control.invalid && ((control.dirty && control.touched) || isSubmitted));
      //console.log("enable : ", isValid);
    } else if (control?.disabled) {
      let showErrorWhenDisabled = control.hasValidator(this.validation.showErrorWnenDisabled)
      if (showErrorWhenDisabled) {
        control.enable();
        control.updateValueAndValidity({ emitEvent: false });
        isValid = !!(control && control.invalid && control.errors != null || isSubmitted);
        control.disable();
        control.updateValueAndValidity({ emitEvent: false });
        //console.log("disable : ", showErrorWhenDisabled, isValid);
      }
    } else {
      isValid = !!(control && control.invalid && ((control.dirty && control.touched) || isSubmitted));
    }
    return isValid;
  }
}


@Directive({
  selector: '[formControl], [formControlName]',
  providers: [
    TranslatePipe
  ]
})

export class ValidationDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('refreshRequired') required: EventEmitter<void>;
  @Input('error') matErrorRef: MatError;
  private destroy = new Subject<void>();
  private subscription: Subscription;

  constructor(@Self() private control: NgControl,
    private elementRef: ElementRef,
    private capitalize: CapitalizePipe,
    private renderer: Renderer2,
    private customValidator: CustomValidatorService,
    private translatePipe: TranslatePipe
  ) {

  }

  ngAfterViewInit() {
    this.markIfRequired();
  }

  markIfRequired() {

    if (this.control?.control?.validator) {
      const validator = this.control.control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        const input = <HTMLElement>this.elementRef.nativeElement;
        const label = input.parentElement.querySelector('mat-label');
        if (label) {
          this.renderer.addClass(label, 'required');
        }
      } else {
        const input = <HTMLElement>this.elementRef.nativeElement;
        const label = input.parentElement.querySelector('mat-label');
        if (label) {
          this.renderer.removeClass(label, 'required');
        }
      }
    }
  }


  ngOnInit() {

    if (this.required) {
      this.subscription = this.required.subscribe(() => {
        this.markIfRequired();
      });
    }

    const input = <HTMLElement>this.elementRef.nativeElement;
    const errorElement = this.matErrorRef as HTMLElement;
    if (this.control) {

      if (errorElement) {
        combineLatest([this.control.statusChanges, this.control.valueChanges]).pipe(
          takeUntil(this.destroy),
          debounceTime(300),
          // distinctUntilChanged()
        ).subscribe((status) => {

          let ctrl = this.control.control;
          let err: ValidationErrors = null;
          if (this.control.disabled) {
            let needToShowWnenDisabled = ctrl.hasValidator(this.customValidator.showErrorWnenDisabled);
            if (needToShowWnenDisabled) {
              ctrl.enable();
              ctrl.updateValueAndValidity({ emitEvent: false })
              err = ctrl.errors;
              if (err) {
                console.log("disabled err =>", err)
              }
              ctrl.disable();
              ctrl.updateValueAndValidity({ emitEvent: false })
            }

          } else {
            err = this.control.errors;
          }
          const errors = err;

          if (errors) {
            let propertyName = input.getAttribute('name');
            let customPropertyName = input.getAttribute('data-name');
            if (propertyName == undefined || propertyName == "") {              
              if (customPropertyName != null) {
                propertyName = customPropertyName;
              }
              else {
                propertyName = this.capitalize.transform(this.control.name.toString());
              }
            } else {
              if (customPropertyName != null) {
                propertyName = customPropertyName;
              }
              else {
                propertyName = this.capitalize.transform(propertyName);
              }
            }

            if (!environment.production && errors) {
              console.log(`propertyName : ${propertyName}`, this.control, errors);
            }

            //console.log(errors);
            let errorMeassage = "";
            if (errors['required']) {
              // {0} is required {1}
              let requiredValidationMessage: string = this.translatePipe.transform('validation.required-validation-error-message');
              requiredValidationMessage = requiredValidationMessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${requiredValidationMessage}`;
            }
            if (errors['email']) {
              let emailValidationMessage: string = this.translatePipe.transform('validation.is-not-valid-error-message');
              emailValidationMessage = emailValidationMessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${emailValidationMessage}`;
            }
            if (errors['phone']) {
              let phoneValidationMessage: string = this.translatePipe.transform('validation.phone');
              phoneValidationMessage = phoneValidationMessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${phoneValidationMessage}`;
            }
            if (errors['number']) {
              let numberValidationMessage: string = this.translatePipe.transform('validation.is-not-valid-error-message');
              numberValidationMessage = numberValidationMessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${numberValidationMessage}`;
            }
            if (errors['min']) {
              let minValidationMessage: string = this.translatePipe.transform('validation.minimum-number');
              minValidationMessage = minValidationMessage.replace(/\{0\}/g, propertyName);
              minValidationMessage = minValidationMessage.replace(/\{1\}/g, errors['min']['min']);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${minValidationMessage}`;
            }
            if (errors['max']) {
              let maxValidationMessage: string = this.translatePipe.transform('validation.maximum-number');
              maxValidationMessage = maxValidationMessage.replace(/\{0\}/g, propertyName);
              maxValidationMessage = maxValidationMessage.replace(/\{1\}/g, errors['max']['max']);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${maxValidationMessage}`;
            }
            if (errors['positive']) {
              let positiveValidationmessage: string = this.translatePipe.transform('validation.positive-number-allowed');
              positiveValidationmessage = positiveValidationmessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${positiveValidationmessage}`;
            }
            if (errors['matDatepickerMin']) {
              let matDatepickerMinValidationmessage: string = this.translatePipe.transform('validation.minimum-datepicker');
              matDatepickerMinValidationmessage = matDatepickerMinValidationmessage.replace(/\{0\}/g, propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${matDatepickerMinValidationmessage}`;
            }
            if (errors['integer']) {
              let integerValidationMessage: string = this.translatePipe.transform('validation.should-be-integer');
              integerValidationMessage = integerValidationMessage.replace(/\{0\}/g,propertyName);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${integerValidationMessage}`;
            }
            if (errors['custom']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${errors['custom']}`;
            }
            if (errors['regex']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${errors['regex']}`;
            }
            if (errors['compareString']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i>  ${propertyName} ${errors['compareString']}`;
            }
            if (errors['duplicate']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i>  ${propertyName} ${errors['duplicate']}`;
            }
            if (errors['available']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i>  ${propertyName} ${errors['available']}`;
            }
            if (errors['website']) {
              errorMeassage = `<i class="fa fa-exclamation-circle"></i>  ${propertyName} ${errors['website']}`;
            }
            if (errors['minlength']) {
              let minlengthValidationMessage: string = this.translatePipe.transform('validation.must-be-long')
              minlengthValidationMessage = minlengthValidationMessage.replace(/\{0\}/g, propertyName);
              minlengthValidationMessage = minlengthValidationMessage.replace(/\{1\}/g, errors['minlength']['requiredLength']);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${minlengthValidationMessage}`;
            }
            if (errors['maxlength']) {
              let maxlengthValidationMessage: string = this.translatePipe.transform('validation.max-upto-long')
              maxlengthValidationMessage = maxlengthValidationMessage.replace(/\{0\}/g, propertyName);
              maxlengthValidationMessage = maxlengthValidationMessage.replace(/\{1\}/g, errors['maxlength']['requiredLength']);
              errorMeassage = `<i class="fa fa-exclamation-circle"></i> ${maxlengthValidationMessage}`;
            }
            this.renderer.setProperty(errorElement, 'innerHTML', errorMeassage);
            //errorElement.innerHTML = errorMeassage;

          } else {
            errorElement.innerText = "";
          }
        });
      }

    } else {
      throw Error("Validation is not working");
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
