import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { map } from "rxjs";
import { AppliedDiscountCouponInfo, DiscountCouponFinalInfo, IPromotionPaymentInfo, PromotionPlanDto, PromotionPlanSearchDto, PromotionPlanState, saveUserPromotionDto } from "src/app/module/advertisement/store/state/create-ads";
import { AdvertisementInfoDto, AdvertisementsSearchDto,  } from "src/app/module/advertisement/store/state/my-advertisements";
import { IDiscountCouponResult } from "src/app/module/event-details/models/event-details";


@Injectable({
    providedIn: 'root',
})
export class AdvertisementService {

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {

    }

    getAdvertisements(location: string, communityId?: number) {
        let param: string = `Location=${location}`;
        if (communityId > 0) {
            param += `&communityId=${communityId}`;
        }
        return this.http.get<Advertisement[]>(`/api/common/GetPromotions?${param}`).pipe(
            map(list => list.map(data => {
                data.adType = data.videoUrl?.length > 0 ? 'video' : 'image';
                
                if (data.adType == 'video' && data.videoUrl &&
                    data.videoUrl.length > 0) {
                    let YTplayerParam = '?mute=1&rel=0&showinfo=0&enablejsapi=1&widgetid=1';
                    if (data.videoUrl.startsWith("https://youtu.be/")) {
                        data.videoUrl = `https://www.youtube.com/embed/${data.videoUrl.split("https://youtu.be/")[1]}${YTplayerParam}`;
                    } else if (data.videoUrl.startsWith("https://www.youtube.com/watch?v=")) {
                        data.videoUrl = `https://www.youtube.com/embed/${data.videoUrl.split("?")[1].split("=")[1]}${YTplayerParam}`;
                    } else {
                        console.log("Video is not supported");
                    }
                }
                return data;
            }))
        )
    }

    getMyAdvertisements(query:AdvertisementsSearchDto){
        //let params: any = { 'pageNumber': pageNumber, 'pageSize': pageSize }
        return this.http.post<AdvertisementInfoDto>('/api/advertisement/GetUserPromotionDetails', query);
    }

    getPromotionPlans(query:PromotionPlanSearchDto){
        let params: any = { 'communityId': query.communityId, 'IsAccessingFromCA': query.IsAccessingFromCA }
        return this.http.get<PromotionPlanDto[]>('/api/advertisement/GetPromotionPlans', {params:params});
    }

    saveAdvertisements(query:saveUserPromotionDto){
        //let params: any = { 'pageNumber': pageNumber, 'pageSize': pageSize }
        return this.http.post<number>('/api/advertisement/SaveUserPromotion', query);
    }
    
    validateDiscountCoupon(discountCouponInfo: AppliedDiscountCouponInfo, actualAmount:number) {
        console.log(discountCouponInfo,'discountCouponInfo')
        return this.http.post<IDiscountCouponResult>("/api/event/validatediscountcoupon", discountCouponInfo);
    }

    getPromotionPlanById(planId:number){
        let params: any = { 'planId': planId }
        return this.http.get<saveUserPromotionDto>('/api/advertisement/GetUserPromotionPlanById', {params:params});
    }

    PurchasePromotionPlanWith100PerDiscountCoupon(data: IPromotionPaymentInfo) {
       // console.log(discountCouponInfo,'discountCouponInfo')
        return this.http.post<any>("/api/payment/PurchasePromotionPlanWith100PerDiscountCoupon", data);
    }


}

export interface Advertisement {
    adType: 'video' | 'image',
    planId: number;
    name: string;
    image: string;
    linkUrl: string;
    section?: string;
    videoUrl: string;
    additionalInstructions: string;
}

export interface AdvertisementList {
    key?: string;
    value?: Advertisement[]
}