import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { debounceTime, distinctUntilChanged,  Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Directive({
    selector: '[ngAuthorize]'
})
export class AuthorizeDirective implements OnInit, OnDestroy {
    data: AuthorizeData;
    @Input('ngAuthorize') set ngAuthorize(param: AuthorizeData) {
        this.data = param;
    }
    isVisible = false;
    private destroy = new Subject<void>();
    constructor(private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private authService: AuthService
    ) { }

    ngOnInit() {
        if (this.data && this.data.keyName) {
            this.authService.hasFeatureAccess(this.data.keyName, this.data.communityId)
                .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.destroy))
                .subscribe(hasFeatureAccess => {
                    if (hasFeatureAccess) {
                        this.isVisible = true;
                        this.viewContainerRef.clear();
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                    } else {
                        this.isVisible = false;
                        this.viewContainerRef.clear();
                    }
                });
        } else {
            throw new Error("AuthorizeName is not defined");
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }
}

export interface AuthorizeData {
    keyName: string;
    communityId?: number;
}