import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfileState } from "src/app/module/my-profile/store/state/profile";
import { ApplicationConfigurationState, CommonApplicationState, UserActiveState, UserMembershipPlansState, widgetsState } from "../state/app.state";


export const appConfigKey = 'applicationConfiguration';

export const selectApplicationConfiguration = createFeatureSelector<ApplicationConfigurationState>(
    appConfigKey,
);

export const selectAvailableLanguage = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.language
);

export const SelectCurrentUserInfo = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.currentUserInfo
);

export const SelectTenantLogo = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.logo
);

export const SelectClientConfiguration = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.clientConfiguration
);

export const SelectTenantFeatureConfiguration = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.tenantFeatureConfiguration
);

export const SelectTenantInfo = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.tenant
);


export const userActiveStatusKey = 'userActiveStatus';

export const selectUserActiveStatus = createFeatureSelector<UserActiveState>(
    userActiveStatusKey,
);

export const selectUserLicense = createSelector(
    selectUserActiveStatus,
    (state: UserActiveState) => state.userStatus.license
);

export const selectCommunityConfig = createSelector(
    selectUserActiveStatus,
    (state: UserActiveState) => state.userStatus.communityConfig
);


export const selectCommunityFeatureAccess = createSelector(
    selectUserActiveStatus,
    (state: UserActiveState) => state.userStatus?.communityFeatureAccess
);

export const commonApplicationStateKey = 'commonApplicationState';

export const selectCommonApplicationState = createFeatureSelector<CommonApplicationState>(
    commonApplicationStateKey,
);

export const userStateKey = 'userState';

export const selectUserState = createFeatureSelector<ProfileState>(
    userStateKey,
);

export const SelectCommunityFeatureConfiguration = createSelector(
    selectApplicationConfiguration,
    (state: ApplicationConfigurationState) => state.communityFeatureConfiguration
);

export const membershipStatusKey = 'membershipstatusses';

export const selectMembershipStatusses = createFeatureSelector<UserMembershipPlansState>(
    membershipStatusKey,
);

export const loadMembershipStatussesData = createSelector(
    selectMembershipStatusses,
    (state: UserMembershipPlansState) => state
);
