import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class GroupsResolver implements Resolve<LocaleResourceSet>{

  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translate.set('groups.inactive', 'Inactive', 'en');
    this.translate.set('groups.waiting-for-approval', 'Waiting for approval', 'en');
    this.translate.set('groups.cancel', 'Cancel', 'en');
    this.translate.set('groups.leave', 'Leave', 'en');
    this.translate.set('groups.join', 'JOIN', 'en');
    this.translate.set('groups.pending', 'PENDING', 'en');
    this.translate.set('groups.rejected', 'Rejected', 'en');
    this.translate.set('groups.community', 'Community', 'en');
    // Latest changes
    this.translate.set('groups.you-need-to-join-this-community', 'You need to join this community first', 'en');
    this.translate.set('groups.please-login-to-continue', 'Please login to continue', 'en');
    this.translate.set('groups.ok', 'OK', 'en');
    this.translate.set('groups.have-been-removed-from-group', 'You have been removed from the group', 'en');
    this.translate.set('groups.your-request-has-been-processed', 'Your request has been processed', 'en');
    this.translate.set('groups.are-you-sure-you-want-to-leave-from-the-group', 'Are you sure? You want to leave from the group', 'en');

    return this.localResourceService.loadLocalResource("groups").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
