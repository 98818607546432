<bzg-loading [show]="isLoading" [ngClass]="isLoading?'loadingBackdrop':''" message="Loading..."></bzg-loading>
<div class="text-danger" *ngIf="hasError">
    <b><i class="fa fa-exclamation-triangle"></i> Paypal Payment Gateway is not configured
        properly</b>
    <ul>
        <li *ngFor="let err of error">{{err}}</li>
    </ul>
</div>
<div id="paypal-container" >

</div>