import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_LOCALE } from '@angular/material/core';
import { DefaultErrorStateMatcher } from 'src/app/directive/validation/validation.directive';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomDateAdapter, CustomDateFormatsProvider } from 'src/app/services/common/mat-dateformat.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatInputModule
  ],
  exports: [

  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: ErrorStateMatcher, useClass: DefaultErrorStateMatcher },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    CurrencyPipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }, // Set default locale
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CustomDateFormatsProvider,
  ],
})
export class ApplicationCommonModule { }
