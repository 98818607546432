import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class EndorsementsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('endorsements.dashboard', 'Dashboard', 'en');
    this.translateService.set('endorsements.my-endorsements', 'My Endorsements', 'en');
    this.translateService.set('endorsements.received', 'Received', 'en');
    this.translateService.set('endorsements.sent', 'Sent', 'en');
    this.translateService.set('endorsements.loading', 'Loading...', 'en');
    this.translateService.set('endorsements.approve', 'APPROVE', 'en');
    this.translateService.set('endorsements.reject', 'REJECT', 'en');
    this.translateService.set('endorsements.accepted', 'Accepted', 'en');
    this.translateService.set('endorsements.rejected', 'Rejected', 'en');
    this.translateService.set('endorsements.please-wait-while-processing-your-request', 'Please wait while processing your request...', 'en');
    this.translateService.set('endorsements.endorsement-is-accepted', 'Endorsement is accepted.', 'en');
    this.translateService.set('endorsements.endorsement-is-rejected', 'Endorsement is Rejected.', 'en');
    this.translateService.set('endorsements.something-went-wrong-please-try-again', 'Something went wrong. Please try again', 'en');
    // latest changes
    this.translateService.set('endorsements.not-received-endorsements', 'You have not received any endorsements yet.', 'en');
    this.translateService.set('endorsements.not-endorsed-any-members', 'You have not endorsed any members. Would you like to?', 'en');
    this.translateService.set('endorsements.page-name-my-endorsements', 'My Endorsements', 'en');

    return this.localResourceService.loadLocalResource("endorsements").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
