import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UserInitialData } from 'src/app/models/accounts/user';
import { ClientConfiguration, CommunityFeatureConfiguration, HeaderFooterConfig, MenuItems, TenantFeatureConfiguration, TenantInfoDetails, TenantLogo } from 'src/app/models/configuration/configuration';
import { TenantBusinessType } from 'src/app/models/organization/organization';
import { ISearchAdditionalInfo, IuserInfo, UserMembershipPlansStatus } from 'src/app/module/my-profile/store/state/profile';
import { AcceptRejectCommunityUserDto, JoinCommunitiesDto } from '../state/app.state';


//export const SearchMemberDirectory = createAction('[Member Directory] Search member directory', (query: ISearchMemberDirectory) => ({ query }));
export const LoadBusinessType = createAction('[Load Configuration] Load Tenant Business Type B2B / B2C');
export const LoadBusinessTypeSuccess = createAction('[Load Configuration] Load Tenant Business Type B2B / B2C success', props<{ businessType: TenantBusinessType }>());
export const LoadBusinessTypeError = createAction('[Load Configuration] Load Tenant Business Type B2B / B2C error', props<{ error: HttpErrorResponse }>());

export const LoadMenuData = createAction('[Load Menu Data] Load Menu Data');
export const LoadMenuDataSuccess = createAction('[Load Menu Data] Load Menu Data success', props<{ menus: MenuItems[] }>());
export const LoadMenuDataError = createAction('[Load Menu Data] Load Menu Data error', props<{ error: HttpErrorResponse }>());


export const LoadCurrentUserInfo = createAction('[Load currentUserInfo] Load current User Info', (query: ISearchAdditionalInfo) => ({ query }));
export const LoadCurrentUserInfoSuccess = createAction('[Load currentUserInfo] Load current User Info success', props<{ userInfo: IuserInfo }>());
export const LoadCurrentUserInfoError = createAction('[Load currentUserInfo] Load current User Info error', props<{ error: HttpErrorResponse }>());

export const LoadTenantId = createAction('[Load Configuration] Load tenant Id');
export const LoadTenantIdSuccess = createAction('[Load Configuration] Load tenant Id success', props<{ tenant: TenantInfoDetails }>());
export const LoadTenantIdError = createAction('[Load Configuration] Load tenant Id error', props<{ error: HttpErrorResponse }>());

export const LoadTenantFeatureConfiguration = createAction('[Load Configuration] Load tenant Feature Configuration');
export const LoadTenantFeatureConfigurationSuccess = createAction('[Load Configuration] Load tenant Feature Configuration success', props<{ config: Array<TenantFeatureConfiguration> }>());
export const LoadTenantFeatureConfigurationError = createAction('[Load Configuration] Load tenant Feature Configuration error', props<{ error: HttpErrorResponse }>());

export const LoadClientConfiguration = createAction('[Load Client Configuration] Load tenant Feature Configuration');
export const LoadClientConfigurationSuccess = createAction('[Load Client Configuration] Load tenant Feature Configuration success', props<{ config: ClientConfiguration }>());
export const LoadClientConfigurationError = createAction('[Load Client Configuration] Load tenant Feature Configuration error', props<{ error: HttpErrorResponse }>());

export const LoadAvilableLanguages = createAction('[Load Available Languages] Load Available Languages');
export const LoadAvilableLanguagesSuccess = createAction('[Load Available Languages] Load Available Languages success', props<{ langs: string[] }>());
export const LoadAvilableLanguagesError = createAction('[Load Available Languages] Load Available Languages error', props<{ error: HttpErrorResponse }>());

export const LoadUserStatus = createAction('[Load User Status] Load User');
export const LoadUserStatusReload = createAction('[Load User Status] Reload');
export const LoadUserStatusNotLoggedIn = createAction('[Load User Status] User not logged IN');
export const LoadUserStatusSuccess = createAction('[Load User Status] Load User Status success', props<{ userStatus: UserInitialData }>());
export const LoadUserStatusReloadSccess = createAction('[Load User Status] Reload Load User Status success', props<{ userStatus: UserInitialData }>());
export const LoadUserStatusError = createAction('[Load Configuration] Load User Status error', props<{ error: HttpErrorResponse }>());

export const ChangePageName = createAction('[Application Common] Change Page Name', props<{ pageName: string }>());
export const ChangePageTitle = createAction('[Application Common] Change Page Title', props<{ pageTitle: string }>());
export const ChangePageTitleSucess = createAction('[Application Common] Change Page Title sucess');
export const UpdateCommunityLogo = createAction('[Application Common] Update Community Logo', props<{ logoUrl: string, link: string }>());
export const SideNavOpen = createAction('[Application Common] Sidenav Open');
export const SideNavClose = createAction('[Application Common] Sidenav Close');

export const ChangeAdminCommunityLogo = createAction('[Application Common] Update Admin Community Logo', props<{ communityId: number }>());

export const hasFeatureAccess = createAction('[Application Common] Ensure user has access to feature ', props<{ keyName: string, communityId?: number }>());
export const hasFeatureAccessResult = createAction('[Application Common] Ensure user has access to feature result  ', props<{ hasAccess: boolean }>());
export const userLoggedInRedirectionn = createAction('[Application Common] User Login success ');

export const joinCommunity = createAction('[Application Common] Join Community', props<{ community: JoinCommunitiesDto }>());
export const joinCommunityNavigateToMemberShipPlan = createAction('[Application Common] Join Community navigate to membership plann page');
export const joinCommunitySuccessCommunityDetails = createAction('[Application Common] Join Community success on community details page');
export const joinCommunitySuccessCommunityList = createAction('[Application Common] Join Community success on community list page');

export const leaveCommunity = createAction('[Application Common] Leave Community', props<{ community: JoinCommunitiesDto }>());
export const leaveCommunitySuccess = createAction('[Application Common] Leave Community success');
export const leaveCommunityError = createAction('[Application Common] Leave Community Error', props<{ error: HttpErrorResponse }>());
export const cancelJoinCommunity = createAction('[Application Common] Cancel Join Community', props<{ community: JoinCommunitiesDto }>());
export const cancelJoinCommunitySuccess = createAction('[Application Common] Cancel Join Community success');

export const LoadHeaderFooterConfiguration = createAction('[Load Header Footer Configuration] Load Header Footer Configuration');
export const LoadHeaderFooterConfigurationSuccess = createAction('[Load Header Footer Configuration] Load Header Footer Configuration success', props<{ hfconfig: HeaderFooterConfig }>());
export const LoadHeaderFooterConfigurationError = createAction('[Load Header Footer Configuration] Load Header Footer Configuration error', props<{ error: HttpErrorResponse }>());

export const acceptCommunityInvitation = createAction('[Application Common] Accept Community Invitation', props<{ community: AcceptRejectCommunityUserDto }>());
export const acceptCommunityInvitationSuccess = createAction('[Application Common]Accept Community Invitation success');
export const rejectCommunityInvitation = createAction('[Application Common] Reject Community Invitation', props<{ community: AcceptRejectCommunityUserDto }>());
export const rejectCommunityInvitationSuccess = createAction('[Application Common] Reject Community Invitation success');

export const LoadTenantLogo = createAction('[Load TenantLogo] Load Tenant Logo');
export const LoadTenantLogoSuccess = createAction('[Load Load TenantLogo] Load Tenant Logo success', props<{ logo: TenantLogo }>());
export const LoadTenantLogoError = createAction('[Load Load TenantLogo] Load Tenant Logo error', props<{ error: HttpErrorResponse }>());

export const RefreshFooterVisibility = createAction('[Refresh Footer Visibility ] Refresh Footer visibility');
export const ChangeFooterVisibility = createAction('[Show / Hide Footer] change Footer visibility', props<{ visible: boolean }>());

export const LoadCommunityFeatureConfiguration = createAction('[Load Community Configuration] Load community Feature Configuration');
export const LoadCommunityFeatureConfigurationSuccess = createAction('[Load Community Configuration] Load community Feature Configuration success', props<{ config: Array<CommunityFeatureConfiguration> }>());
export const LoadCommunityFeatureConfigurationError = createAction('[Load Community Configuration] Load community Feature Configuration error', props<{ error: HttpErrorResponse }>());

export const LoadMembershipStatuses = createAction('[Load Membership Statuses] Load Membership Statuses');
export const LoadMembershipStatusesSuccess = createAction('[Load Membership Statuses] Load Membership Statuses success', props<{ memberShipstatusses: Array<UserMembershipPlansStatus> }>());
export const LoadMembershipStatusesError = createAction('[Load Membership Statuses] Load Membership Statuses error', props<{ error: HttpErrorResponse }>());
