import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageCroppieComponent } from "./image-croppie.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ImageCropperModule } from "ngx-image-cropper";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    ImageCroppieComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxSliderModule,
    ImageCropperModule,
    MatButtonModule
  ],
  exports: [
    ImageCroppieComponent
  ]
})
export class ImageCroppieModule { }