import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class DiscussionSharedModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('discussion.search-discussions.placeholder', 'Search Discussions...', 'en');
    this.translateService.set('discussion.create-new-post', 'Create New Post', 'en');
    this.translateService.set('discussion.discussion-title.placeholder', 'Discussion Title', 'en');
    this.translateService.set('discussion.discard', 'Discard', 'en');
    this.translateService.set('discussion.post', 'Post', 'en');
    this.translateService.set('discussion.un', 'Un', 'en');
    this.translateService.set('discussion.subscribe', 'subscribe', 'en');
    this.translateService.set('discussion.copy-post-link', 'Copy Post Link', 'en');
    this.translateService.set('discussion.edit', 'Edit', 'en');
    this.translateService.set('discussion.delete', 'Delete', 'en');
    this.translateService.set('discussion.see-more', 'See more', 'en');
    this.translateService.set('discussion.like', 'Like', 'en');
    this.translateService.set('discussion.likes', 'Likes', 'en');
    this.translateService.set('discussion.comment', 'Comment', 'en');
    this.translateService.set('discussion.comments', 'Comments', 'en');
    this.translateService.set('discussion.community-users-loaded-succesfully', 'Community users loaded Successfully', 'en');
    this.translateService.set('discussion.unable-to-load-community-users', 'Unable to load community users', 'en');
    this.translateService.set('discussion.you-commented-discussion', 'You commented discussion', 'en');
    this.translateService.set('discussion.unable-to-comment-discussion', 'Unable to comment discussion', 'en');
    this.translateService.set('discussion.saved-discussion-successfully', 'Saved discussion successfully', 'en');
    this.translateService.set('discussion.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translateService.set('discussion.you-liked-discussion', 'You liked discussion', 'en');
    this.translateService.set('discussion.unable-to-like-discussion', 'Unable to like discussion', 'en');
    this.translateService.set('discussion.you-liked-discussion-comment', 'You liked discussion Comment', 'en');
    this.translateService.set('discussion.unable-to-like-discussion-comment', 'Unable to like discussion Comment', 'en');
    this.translateService.set('discussion.subscribe-unsubscribe-discussion', 'You Subscribe or Unsubscribe Discussion Succesfully', 'en');
    this.translateService.set('discussion.unable-to-subscribe-unsubscribe-discussion', 'Unable to Subscribe or Unsubscribe discussion', 'en');
    this.translateService.set('discussion.discussion-deleted-successfully', 'Discussion Deleted Succesfully', 'en');
    this.translateService.set('discussion.unable-to-delete-discussion', 'Unable to Delete discussion', 'en');
    this.translateService.set('discussion.discussion-comment-deleted-successfully', 'Discussion Comment Deleted Succesfully', 'en');
    this.translateService.set('discussion.unable-to-delete-discussion-comment', 'Unable to Delete discussion Comment', 'en');
    this.translateService.set('discussion.saved.discussion-successfully', 'Saved discussion successfully', 'en');
    this.translateService.set('discussion.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translateService.set('discussion.most-discussion', 'Most Popular Discussions', 'en');
    this.translateService.set('discussion.loading', 'Loading...', 'en');
    this.translateService.set('discussion.unable-to-load-popular-discussions', 'Unable To Load Popular Discussions', 'en');
    this.translateService.set('discussion.no-popular-discussions', 'No Popular Discussions', 'en');
    this.translateService.set('discussion.subscribed-discussions', 'Subscribed Discussions', 'en');
    this.translateService.set('discussion.unable-to-load-subscribed-discussions', 'Unable To Load Subscribed Discussions', 'en');
    this.translateService.set('discussion.no-subscribed-discussions', 'No Subscribed Discussions', 'en');
    this.translateService.set('discussion.most-subscribed-discussions', 'Most Subscribed Discussions', 'en');
    this.translateService.set('discussion.unable-to-load-most-subscribed-discussions', 'Unable To Load Most Subscribed Discussions', 'en');
    this.translateService.set('discussion.no-most-subscribed-discussions', 'No Most Subscribed Discussions', 'en');
    this.translateService.set('discussion.no-more-results', 'No More Results', 'en');
    this.translateService.set('discussion.load-more-comments', 'Load More Comments', 'en');
    this.translateService.set('discussion.title', 'Title', 'en');
    this.translateService.set('discussion.discussion-title.placeholder', 'Discussion Title', 'en');
    this.translateService.set('discussion.unable-to-unsubscribe-discussion', 'Unable to Unsubscribe discussion', 'en');
    this.translateService.set('discussion.success-unsubscribed-you-will-no-longer-receive-notifications-for-this-post', 'Success! Unsubscribed. You will no longer receive notifications for this post.', 'en');
    this.translateService.set('discussion.success-you-will-receive-notifications-if-any-user-adds-the-comments', 'Success! You will receive notifications if any user adds the comments.', 'en');
    this.translateService.set('discussion.unable-to-unlike-discussion-comment', 'Unable to unlike discussion Commment', 'en');
    this.translateService.set('dicsussion.you-unliked-discussion-comment', 'You Unliked Discussion Comment', 'en');
    this.translateService.set('discussion.you-unliked-the-discussion', 'You Unliked the Discussion', 'en');
    this.translateService.set('discussion.unable-to-unlike-discussion', 'Unable to unlike discussion', 'en');
    this.translateService.set('discussion.share-something-here', 'Share something here...', 'en');
    // latest changes 
    this.translateService.set('discussion.unable-to-subscribe-discussion', 'Unable to Subscribe discussion', 'en');
    this.translateService.set('discussion.unable-to-un-subscribe-discussion', 'Unable to Un-Subscribe discussion', 'en');
    //--------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("discussion").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
