import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Html3Component } from './html3.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { BannerImagesModule } from 'src/app/module/shared/banner-images/banner-images.module';
import { SponsorModule } from 'src/app/module/shared/sponsor/sponsor.module';
import { SpotlightEventModule } from 'src/app/module/shared/spotlight-event/spotlight-event.module';
import { AdvertisementModule } from 'src/app/module/advertisement/advertisement.module';
import { NewsletterModule } from 'src/app/module/shared/newsletter/newsletter.module';



@NgModule({
  declarations: [
    Html3Component
  ],
  imports: [
    CommonModule,
    DirectiveModule,
    BannerImagesModule,
    SponsorModule,
    SpotlightEventModule,
    AdvertisementModule,
    NewsletterModule
  ],
  exports: [
    Html3Component
  ]
})
export class Html3Module {
}
