import { createFeatureSelector } from "@ngrx/store";
import { MyAdvertisementModule } from "../state";

export const advertisementModuleFeatureKey = 'myAdvertisementModule';

export const selectAdvertisementModule = createFeatureSelector<MyAdvertisementModule>(
    advertisementModuleFeatureKey,
);

// // promotion plans //
