import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, switchMap, take } from 'rxjs';
import { UserInitialData } from './models/accounts/user';
import { AuthService } from './services/auth/auth.service';
import { CommunityService, UserInCommunityStatus } from './services/community/community.service';
import { selectUserActiveStatus } from './store/selectors/app.selector';
import { UserActiveState } from './store/state/app.state';
import { SlugPipe } from './pipe/slug.pipe';

@Injectable({
  providedIn: 'root'
})
//CanActivateChild, CanLoad
export class ActiveUserGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private store: Store<UserActiveState>,
    private communityService: CommunityService,
    private slugPipe: SlugPipe
  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.getCurrentUser2().pipe(
      map(claims => {
        if (claims == null) {
          return true;
        } else {
          if (claims.extension_BzTenantId == "" || claims.extension_BzUserId == 0) {
            this.router.navigate(['/auth/signup']);
            return true;
          } else {
            return true;
          }
        }
      }),
      switchMap(s => this.store.select(selectUserActiveStatus).pipe(
        filter(s => s.loaded == true),
        map(st => st.userStatus),
        take(1),
        map(status => {
          if (status == null) {
            console.log("status license", status);
            this.store.select(selectUserActiveStatus).pipe(
              map(st => st.userStatus),
              filter(s => s?.license != null),
              take(1),
            ).subscribe(st => {
              this.process(st, state.url);
            })
            return true;
          }
          return this.process(status, state.url);
        })
      ))
    );
  }

  process(status: UserInitialData, currentUrl: string) {

    if (status && status.license && !status.license.issuccess) {
      if (status.license.reDirectTo > 0) {
        switch (status.license.reDirectTo) {
          case 1:            
            if (!currentUrl.toLowerCase().startsWith('/communities')) {
              let community = status.communityConfig;
              if (community && community.hasMultipleCommunity) {
                this.router.navigate(['/communities']);
              }
              if (community && community.defaultCommunityId > 0) {
                let url = `/communities/${this.slugPipe.transform(community.communityName)}/membership-plan/${community.defaultCommunityId}`;
                this.router.navigate([url]);
              }              
              this.matSnackBar.open("Please purchase a membership plan to continue", '', { duration: 5000 });
            }
            return false;
            break;
          case 2:
            if (currentUrl.toLowerCase() != '/auth/signup') {
              this.router.navigate(['/auth/signup'], { state: status.license });
              this.matSnackBar.open("Please complete signup", '', { duration: 5000 });
            } else {
              this.matSnackBar.open("Please complete signup", '', { duration: 5000 });
              return false;
            }
            break;
        }
      } else {
        if (currentUrl.toLowerCase() == "/auth/signup") {
          this.router.navigateByUrl("/");
        } else {
          this.router.navigateByUrl(this.router.url);
          this.matSnackBar.open(status.license.message, "OK", { duration: 5000, panelClass: ['text-warning'] });
        }
      }
    }
    return true;
  }
  // canActivateChild(
  //   childRoute: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
}
