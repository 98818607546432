/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: environment.b2cPolicies.names.signUpSignIn,
        editProfile: environment.b2cPolicies.names.editProfile
    },
    signIn: {
        authorities: environment.b2cPolicies.signIn.authorities
    },
    signUp: {
        authority: environment.b2cPolicies.signUp.authority
    },
    editProfile: {
        authority: environment.b2cPolicies.editProfile.authority
    }
    ,
    authorityDomain: environment.b2cPolicies.authorityDomain
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.signIn.authorities, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: window.location.origin, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.        
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Warning,
            piiLoggingEnabled: false
        }
    }
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: environment.scopes
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: environment.scopes
        },
        loginFailedRoute: "/",
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string> | null>();
    //protectedResourceMap.set('https://localhost:44334', ['openid', 'https://bizligodev.onmicrosoft.com/05f4e9df-5e4d-433d-b5ae-75588aef739a/access_as_user']);
    //protectedResourceMap.set('https://localhost:44334', ['openid']);
    protectedResourceMap.set(environment.apiUrl + '/api/User/CompleteSignUp', silentRequest.scopes);
    //protectedResourceMap.set(environment.apiUrl + '/api/user/GetUserProfileInfo', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/CheckSignInUser', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/UpdateUserProfile', silentRequest.scopes);
    // protectedResourceMap.set(environment.apiUrl + '/api/user/GetUserSkillsOrInterests', silentRequest.scopes);
    // protectedResourceMap.set(environment.apiUrl + '/api/user/GetUserAdditionalInfo', silentRequest.scopes);
    // protectedResourceMap.set(environment.apiUrl + '/api/user/GetSimilarInterestUsers', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveUserSkills', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveUserInterests', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/AddUserToOrganization', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/UpdateUserFormProfile', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveUpdateLocation', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveBusinessCategories', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveOrgBusinessTypes', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveOrganizationServices', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/user/SaveORUpdateOrgInfo', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/community/getmembershipplandetails', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/blog/getblogcommunities', silentRequest.scopes);
    protectedResourceMap.set(environment.apiUrl + '/api/group/joinorleavegroup', silentRequest.scopes);

    protectedResourceMap.set(environment.apiUrl + '/api/user/getmyconnections', silentRequest.scopes);


    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}