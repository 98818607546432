import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { BannerSlide } from '../../home/store/state/home';

@Component({
  selector: 'bzg-banner-images',
  templateUrl: './banner-images.component.html',
  styleUrls: ['./banner-images.component.scss']
})
export class BannerImagesComponent implements OnInit {
  bannerImages: BannerSlide[];
  isLoading: boolean = false;
  hasError: boolean = false;
  static nextId = 0;
  id = `bannerImages-${BannerImagesComponent.nextId++}`;
  @ViewChild('nextButton', { static: true }) nextButton: ElementRef;
  isInit: boolean = false;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.commonService.getAllBannerImages().subscribe({
      next: (data) => {
        this.bannerImages = data;
        this.isLoading=false;
      },
      error: (err) => {
        this.isLoading=false;
        this.hasError=true;
      }
    })
  }

  init() {
    if (this.isInit == false) {
      this.isInit = true;
      this.nextButton.nativeElement.click();
    }
  }

}
