import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MemberSharedModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('member.search-by-name', 'Search By Member Name', 'en');
    this.translateService.set('member.search-by-name.placeholder', 'Search By Member Name', 'en');
    this.translateService.set('member.profile-category', 'Profile Category (Upto 10)', 'en');
    this.translateService.set('member.skills-and-interests', 'Skills and Interests (Upto 10)', 'en');
    this.translateService.set('member.interests', 'Interests (Upto 10)', 'en');
    this.translateService.set('member.skills', 'Skills ', 'en');
    this.translateService.set('member.organization-name', 'Organization Name', 'en');
    this.translateService.set('member.communities-upto', 'Communities (Upto 3)', 'en');
    this.translateService.set('member.business-category-upto', 'Business Category (Upto 5)', 'en');
    this.translateService.set('member.select-groups', 'Select Groups (Limit 3)', 'en');
    this.translateService.set('member.search', 'Search', 'en');
    this.translateService.set('member.reset', 'Reset', 'en');
    this.translateService.set('member.default', 'Default', 'en');
    this.translateService.set('member.message', 'Message', 'en');
    this.translateService.set('member.connected', 'Connected', 'en');
    this.translateService.set('member.connect', 'Connect', 'en');
    this.translateService.set('member.pending', 'Pending', 'en');
    this.translateService.set('member.cancel', 'Cancel', 'en');
    this.translateService.set('member.send', 'Send', 'en');
    this.translateService.set('member.hi.placeholder', 'hi.....', 'en');
    this.translateService.set('member.send-connection-request', 'Send Conection request', 'en');
    this.translateService.set('member.connection-request-is-pending', 'Connection request is pending', 'en');
    this.translateService.set('member.connection-request', 'Connection Request', 'en');
    this.translateService.set('member.send-connection-request-to', 'Sending Connection request to', 'en');
    this.translateService.set('member.connection-request-sent-successfully', 'Connection request sent successfully', 'en');
    this.translateService.set('member.connection-request-canceled-successfully', 'Connection request canceled successfully', 'en');
    this.translateService.set('member.sending-connection-request', 'Sending connection request...', 'en');
    this.translateService.set('member.canceling-connection-request', 'Canceling connection request...', 'en');
    this.translateService.set('member.processing-your-request', 'Processing your request...', 'en');
    this.translateService.set('member.loading', 'Loading...', 'en');
    this.translateService.set('member.loading-spotlight-member', 'Loading spotlight member...', 'en');
    this.translateService.set('member.spotlight-members', 'Spotlight Members', 'en');
    this.translateService.set('member.reject-connection-request', 'Reject Connection request', 'en');
    this.translateService.set('member.reject', 'Reject', 'en');
    this.translateService.set('member.accept-connection-request', 'Accept Connection request', 'en');
    this.translateService.set('member.accept', 'Accept', 'en');
    this.translateService.set('member.send-a-message', 'Send a message', 'en');
    this.translateService.set('member.send-connection-request', 'Send Connection request', 'en');
    this.translateService.set('member.cancel-connection-request', 'Cancel Connection request', 'en');
    this.translateService.set('member.connection-request-canceled-by-user', 'Connection request canceled by user', 'en');
    this.translateService.set('member.location', 'Location', 'en');
    this.translateService.set('member.unable-to-sent-connection-request-please-try-again', 'Unable to sent connection request, please try again', 'en');
    this.translateService.set('member.unable-to-cancel-connection-request-please-try-again', 'Unable to cancel connection request, please try again', 'en');
    // latest changes
    this.translateService.set('member.error-fetching-in-skills-and-interests', 'Error in fetching skills and interests', 'en');
    this.translateService.set('member.error-fetching-communities', 'Error in fetching communities', 'en');
    this.translateService.set('member.error-fetching-groups', 'Error in fetching groups', 'en');
    this.translateService.set('member.error-fetching-profile-category', 'Error in fetching profile category', 'en');
    this.translateService.set('member.error-fetching-business-category', 'Error in fetching business category', 'en');
    this.translateService.set('member.online', 'Online', 'en');
    this.translateService.set('member.away' , 'Away', 'en');
    this.translateService.set('member.offline' , 'Offline', 'en');
    
    //--------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("member").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
