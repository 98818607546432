<mat-form-field appearance="outline">
    <mat-select (selectionChange)="selected($event)" [formControl]="selectCtrl" placeholder="Select" #singleSelect>
        <mat-option>
            <ngx-mat-select-search [formControl]="selectFilterCtrl" placeholderLabel="search"
                noEntriesFoundLabel="'no matching found'">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let dd of filteredSelect | async" [value]="dd.id">{{ dd.name }}</mat-option>
    </mat-select>
</mat-form-field>



<!-- <mat-form-field appearance="outline">
    <mat-select (selectionChange)="selected($event)" [formControl]="selectCtrl" placeholder="Select" [multiple]="true"
        #multiSelect>
        <mat-option>
            <ngx-mat-select-search [formControl]="selectFilterCtrl" placeholderLabel="search"
                noEntriesFoundLabel="'no matching found'">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let dd of filteredSelect | async" [value]="dd.groupId">{{ dd.groupName }}</mat-option>
    </mat-select>
</mat-form-field> -->