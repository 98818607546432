import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaypalComponent } from './paypal/paypal.component';
import { AuthorizenetComponent } from './authorizenet/authorizenet.component';
import { GooglepayComponent } from './googlepay/googlepay.component';
import { StripeComponent } from './stripe/stripe.component';
import { PaymentComponent } from './payment/payment.component';
import { MaterialModule } from 'src/app/common/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PayuComponent } from './payu/payu.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { LoadingModule } from 'src/app/common/shared/components/loading/loading.module';
import { ApplicationCommonModule } from 'src/app/common/application-common/application-common.module';


@NgModule({
  declarations: [
    PaypalComponent,
    AuthorizenetComponent,
    GooglepayComponent,
    StripeComponent,
    PaymentComponent,
    PayuComponent
  ],
  imports: [
    CommonModule,
    // MaterialModule,
    LoadingModule,
    ApplicationCommonModule,
    TranslateModule.forChild(),
  ],
  exports: [
    PaypalComponent,
    AuthorizenetComponent,
    PayuComponent
  ]
})
export class PaymentGatewayModule { }