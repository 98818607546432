import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MemberModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    // Latest Changes Member Directory
    this.translateService.set('member.search-by-name', 'Search By Member Name', 'en');
    this.translateService.set('member.search-by-name.placeholder', 'Search By Member Name', 'en');
    this.translateService.set('member.search', ' Search', 'en');
    this.translateService.set('member.location', 'Location ', 'en');
    this.translateService.set('member.zoom_in', 'zoom_in', 'en');
    this.translateService.set('member.advanced-search', 'Advanced Search', 'en');
    this.translateService.set('member.organization-name', 'Organization Name', 'en');
    this.translateService.set('member.member-directory-page-name', 'Member Directory', 'en');
    this.translateService.set('member.reset', ' Reset', 'en');
    this.translateService.set('member.no-more-records-available', 'no more records available ', 'en');
    this.translateService.set('member.oops-we-did-not-find-anything-that-matches-this-search', 'Oops...we did not find anything that matches this search:(', 'en');
    this.translateService.set('member.try-search-for-something-more-general-change-the-filters', 'Try Search for something more general, change the filters.', 'en');
    this.translateService.set('member.sending-connection-request', 'Sending connection request', 'en');
    this.translateService.set('member.canceling-connection-request', 'Canceling connection request', 'en');
    this.translateService.set('member.processing-your-request', 'Processing your request', 'en');
    // Latest changes
    this.translateService.set('member.error-in-fetching-skills-and-interests', 'Error in fetching skills and interests', 'en');
    this.translateService.set('member.error-in-fetching-communities', 'Error in fetching communities', 'en');
    this.translateService.set('member.error-in-fetching-groups', 'Error in fetching groups', 'en');
    this.translateService.set('member.error-in-fetching-profile-category', 'Error in fetching profile category', 'en');
    this.translateService.set('member.error-in-fetching-business-category', 'Error in fetching business category', 'en');
      //-----------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("member").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
