import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input',
  exportAs: 'isFocus'
})
export class FocusDirective {
  isFocused: boolean = false;
  constructor() {
  }

  private calculateWidth() {
  }

  @HostListener('keypress', ['$event']) onKeypress() {
    this.isFocused = true;
  }
  @HostListener('focus', ['$event']) onFocus() {
    this.isFocused = true;
  }
  @HostListener('blur', ['$event']) onblur() {
    setTimeout(() => {
      this.isFocused = false;
    }, 150)
  }
}
