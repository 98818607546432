import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { HeaderFooterConfig } from 'src/app/models/configuration/configuration';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { LoadHeaderFooterConfiguration, RefreshFooterVisibility } from 'src/app/store/actions/app.action';
import { selectApplicationConfiguration, selectAvailableLanguage, selectCommonApplicationState } from 'src/app/store/selectors/app.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bzg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input("options") opt: FooterOptions;
  year: number;
  options: FooterOptions = {
    background: "#394050",
    isFullWidth: true
  };
  currentApplicationVersion: string;
  langs: Observable<string[]>;
  isTawkToEnabled: boolean;
  tawkToUrl: string;
  headerFooterConfig: Observable<HeaderFooterConfig>;
  showEnv: boolean = false;
  displayFooter: boolean;
  destroy = new Subject<void>();
  constructor(
    private store: Store,
    private translateService: TranslateService,
    private commonService: CommonService,
    private authService: AuthService
  ) {
    if (this.opt) {
      if (this.opt.background) this.options.background = this.opt.background;
      if (this.opt.isFullWidth) this.options.isFullWidth = this.opt.isFullWidth;
    }
    this.year = new Date().getFullYear();
    this.currentApplicationVersion = environment.appVersion;
    this.store.select(selectApplicationConfiguration).pipe(
      filter(s => s.tenantFeatureConfiguration != null),
      map(config => config.tenantFeatureConfiguration),
      map(featureConfiguration => {
 
        var feature = featureConfiguration?.find(s => s.keyName == "TAWKTO");
        if (feature.enable == true) {
          this.isTawkToEnabled = true;
        }
      })
      // map(config => config.find(s => s.keyName == "TAWKTO")),
      // map(tawkToFeature => tawkToFeature.enable)
    );
    this.store.select(selectApplicationConfiguration).subscribe(data => {
      if (data.tenantFeatureConfiguration != null) {
        var feature = data?.tenantFeatureConfiguration?.find(s => s.keyName == "TAWKTO");
        if (feature.enable == true) {
          this.isTawkToEnabled = true;
        }
      }
      //console.log(data,"data");
    })
    this.headerFooterConfig = this.store.select(selectApplicationConfiguration).pipe(
      filter(s => s.headerFooterConfiguration != null),
      map(config => config.headerFooterConfiguration),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(RefreshFooterVisibility());
    this.store.dispatch(LoadHeaderFooterConfiguration());

    this.langs = this.store.select(selectAvailableLanguage).pipe(
      filter(s => s && s.length > 0)
    );

    if (this.isTawkToEnabled) {
      this.getTawkToUrl();
    }

    let v = environment.appVersion.toLowerCase();
    this.showEnv = v.includes("qa") || v.includes("dev");


    this.store.select(selectCommonApplicationState).pipe(
      map(config => config.displayFooter),
      takeUntil(this.destroy)
    ).subscribe(isVisible => {
      this.displayFooter = isVisible;
    });

  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }


  langChange(event: any) {
    this.translateService.use(event.target.value)
  }

  copy() {

    let [major, minor, patch] = environment.appVersion.split('.').map((x: string) => parseInt(x));

    patch += 1;

    let version = `Version ${major}.${minor}.${patch} (QA)`;

    navigator.clipboard.writeText(version).then(function () {
      console.log('Copying to clipboard', version);
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  tawkToTemplate = `
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
    var s1=document.createElement("script"),
    s0=document.querySelector("head");
    s1.async=true;
    s1.src='https://embed.tawk.to/5e86ef2335bcbb0c9aad525a/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
})();
`

  getTawkToUrl() {
    this.commonService.getTawkToUrl().subscribe(
      {
        next: (data) => {
          //this.tawkToUrl = data.url;          
          var s1 = document.createElement("script"),
            s0 = document.querySelector("head");
          s1.async = true;
          s1.charset = 'UTF-8';
          s1.src = data.url;
          //s1.src ='https://embed.tawk.to/5e86ef2335bcbb0c9aad525a/default';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        },
        error: (err) => {
          console.log("error", err);
        }
      }
    );
  }
}

export interface FooterOptions {
  isFullWidth: boolean;
  background: string;
}