import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './capitalize.pipe';
import { MomentModule } from 'ngx-moment';
import { CurrencyFormatterPipe } from './currency-formtter.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { HighlightPipe, HtmlPipe } from './html.pipe';
import { DefaultImagePipe } from './default-image.pipe';
import { FilterArrayPipe } from './filter-array.pipe';
import { SafePipe } from './safe.pipe';
import { SpecialCharsToStrPipe } from './special-chars-to-str.pipe';
import { SlugPipe } from './slug.pipe';
import { StringLimitToPipe } from './string-limit-to.pipe';
import { NameInitialPipe } from './name-initial.pipe';
import { SortByPipe } from './sort-by.pipe';
import { TimezoneInitialPipe } from './timezone-initial.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { SearchPipe } from './search.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { ClientConfigDatePipe } from './client-config-date.pipe';
import { ClientConfigDateTimePipe } from './client-config-date-time.pipe';
import { HasFeatureAccessPipe } from './has-feature-access.pipe';

@NgModule({
  declarations: [
    CapitalizePipe,
    CurrencyFormatterPipe,
    HtmlPipe,
    HighlightPipe,
    DefaultImagePipe,
    FilterArrayPipe,
    SafePipe,
    SpecialCharsToStrPipe,
    SlugPipe,
    StringLimitToPipe,
    NameInitialPipe,
    SortByPipe,
    TimezoneInitialPipe,
    StripHtmlPipe,
    SentenceCasePipe,
    SearchPipe,
    PhoneFormatPipe,
    ClientConfigDatePipe,
    ClientConfigDateTimePipe,
    HasFeatureAccessPipe
  ],
  imports: [
    CommonModule,
    MomentModule
  ],
  exports: [
    CapitalizePipe,
    HtmlPipe,
    HighlightPipe,
    MomentModule,
    CurrencyFormatterPipe,
    DefaultImagePipe,
    FilterArrayPipe,
    SafePipe,
    SpecialCharsToStrPipe,
    SlugPipe,
    StringLimitToPipe,
    NameInitialPipe,
    TimezoneInitialPipe,
    SortByPipe,
    StripHtmlPipe,
    SentenceCasePipe,
    SearchPipe,
    PhoneFormatPipe,
    ClientConfigDatePipe,
    ClientConfigDateTimePipe,
    HasFeatureAccessPipe
  ],
  providers:[
    SlugPipe,
    SortByPipe
  ]
})
export class PipeModule { }
