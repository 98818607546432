import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class DashboardModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    // Event-Details page
    // Agenda
    this.translateService.set('dashboard.welcome', 'Welcome', 'en');
    this.translateService.set('dashboard.edit-profile', 'Edit Profile', 'en');
    this.translateService.set('dashboard.loading', 'Loading...', 'en');
    this.translateService.set('dashboard.my-communities', 'My Communities', 'en');
    this.translateService.set('dashboard.my-advertisements', 'My Advertisements', 'en');
    
    this.translateService.set('dashboard.my-messages', 'My Messages', 'en');
    this.translateService.set('dashboard.my-reminders', 'My Reminders', 'en');
    this.translateService.set('dashboard.my-groups', 'My Groups', 'en');
    this.translateService.set('dashboard.my-applications', 'My Applications', 'en');
    this.translateService.set('dashboard.my-endorsements', 'My Endorsements', 'en');
    this.translateService.set('dashboard.my-connections', 'My Connections', 'en');
    this.translateService.set('dashboard.my-events', 'My Events', 'en');
    this.translateService.set('dashboard.your-feed', 'Your Feed', 'en');
    this.translateService.set('dashboard.no-feed-yet', 'No Feed, Yet', 'en');
    this.translateService.set('dashboard.new-connection', 'You have received a new connection request from', 'en');
    this.translateService.set('dashboard.and', 'And', 'en');
    this.translateService.set('dashboard.others', 'Others', 'en');
    this.translateService.set('dashboard.endorsement-from', 'You have received an endorsement from', 'en');
    this.translateService.set('dashboard.you-have-received', 'You have received ', 'en');
    this.translateService.set('dashboard.new-message(s)-from', 'new message(s) from', 'en');
    this.translateService.set('dashboard.discussion', 'Discussion', 'en');
    this.translateService.set('dashboard.advertisements', 'Advertisements', 'en');
    this.translateService.set('dashboard.events', 'Events', 'en');
    this.translateService.set('dashboard.not-registered-for-any-event', 'You have not registered for any event yet.', 'en');
    this.translateService.set('dashboard.registered', 'Registered', 'en');
    this.translateService.set('dashboard.latest-discussion', 'Latest Discussion', 'en');
    this.translateService.set('dashboard.to', 'To', 'en');
    this.translateService.set('dashboard.join', 'Join', 'en');
    this.translateService.set('dashboard.no-discussions-right-now!', 'no discussions right now!', 'en');
    this.translateService.set('dashboard.by', 'By', 'en');
    this.translateService.set('dashboard.any-connections-yet', 'You do not have any connections yet', 'en');
    this.translateService.set('dashboard.articles', 'Articles', 'en');
    this.translateService.set('dashboard.no-articles', 'No articles are available right now', 'en');
    this.translateService.set('dashboard.no-announcements', 'There are no announcements right now!', 'en');
    this.translateService.set('dashboard.similar-businesses', 'Similar Businesses', 'en');
    this.translateService.set('dashboard.no-similar-businesses', 'There are no similar businesses as of now!', 'en');
    this.translateService.set('dashboard.dashboard-page-name', 'Dashboard', 'en');
    this.translateService.set('dashboard.communities', 'Communities', 'en');
    this.translateService.set('dashboard.connections', 'Connections', 'en');
    this.translateService.set('dashboard.announcements', 'Announcements', 'en');
    this.translateService.set('dashboard.unable-to-load-data', ' Unable to load data', 'en');
    this.translateService.set('dashboard.zip-code.placeholder', ' Zip Code', 'en');
    this.translateService.set('dashboard.search-business-category', ' Search Business Category', 'en');
    this.translateService.set('dashboard.send-a-message', ' Send a message', 'en');
    this.translateService.set('dashboard.new-connection-request', 'New Connection Request', 'en');
    this.translateService.set('dashboard.new-endorsement', 'New Endorsement', 'en');
    this.translateService.set('dashboard.unread-message', 'Unread Message', 'en');
    this.translateService.set('dashboard.new-discussion', 'New Discussion', 'en');
    this.translateService.set('dashboard.view-connection', 'View Connection', 'en');
    this.translateService.set('dashboard.view-endorsement', 'View Endorsement', 'en');
    this.translateService.set('dashboard.view-message', 'View Message', 'en');
    this.translateService.set('dashboard.view', 'View', 'en');
    this.translateService.set('dashboard.view-all-events', 'View all events', 'en');
    this.translateService.set('dashboard.my-matches', 'My Matches', 'en');
    // Latest changes
    this.translateService.set('dashboard.nodata-your-feed-image', '/assets/images/dashboard/nodata-your-feed.png', 'en');
    this.translateService.set('dashboard.nodata-event-image', '/assets/images/dashboard/nodata-event.png', 'en');
    this.translateService.set('dashboard.nodata-latest-discussion-image', '/assets/images/dashboard/nodata-latest-discussions.png', 'en');
    this.translateService.set('dashboard.likes', 'Likes', 'en');
    this.translateService.set('dashboard.comments', 'Comments', 'en');
    this.translateService.set('dashboard.nodata-connections-image', '/assets/images/dashboard/nodata-connections.png', 'en');
    this.translateService.set('dashboard.nodata-articles-image', '/assets/images/dashboard/nodata-articles.png', 'en');
    this.translateService.set('dashboard.nodata-announcements-image', '/assets/images/dashboard/nodata-announcements.png', 'en');
    this.translateService.set('dashboard.nodata-similar-business-image', '/assets/images/dashboard/nodata-similar-business.png', 'en');
    this.translateService.set('dashboard.please-wait-while-we-loading-business-category', 'please wait while we loading business category', 'en');
    this.translateService.set('dashboard.join-now', 'JOIN NOW', 'en');
    this.translateService.set('dashboard.connect-now', 'CONNECT NOW', 'en');
    this.translateService.set('dashboard.purchase-community-membership', 'Purchase community membership plan to join', 'en');
    this.translateService.set('dashboard.okay', 'Okay', 'en');
    this.translateService.set('dashboard.success', 'Success', 'en');
    this.translateService.set('dashboard.my-testimonials', 'My Testimonials', 'en');

    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("dashboard").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
