import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { SlugPipe } from 'src/app/pipe/slug.pipe';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as fromActions from './../action/home.actions';

@Injectable()
export class HomeEffects {


  constructor(private actions$: Actions, private commonService: CommonService, private slug: SlugPipe) { }


  loadHomepageData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadHomepageData),
    mergeMap(param => this.commonService.getTenantHomePage().pipe(
      map(data => fromActions.LoadHomepageDataSuccess({ html: data.html })),
      catchError(err => of(fromActions.LoadHomepageDataError({ error: err })))
    )))
  )

  loadGWCHomepageData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadGWCHomepageData),
    mergeMap(param => this.commonService.getGWCHomePageData().pipe(
      map(data => fromActions.LoadGWCHomepageDataSuccess({ data: data })),
      catchError(err => of(fromActions.LoadGWCHomepageDataError({ error: err })))
    )))
  )

}
