import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImageCroppedEvent, ImageTransform, LoadedImage } from 'ngx-image-cropper';


@Component({
  selector: 'bzg-image-croppie',
  templateUrl: './image-croppie.component.html',
  styleUrls: ['./image-croppie.component.scss']
})
export class ImageCroppieComponent implements OnInit {
  scale = 1;
  min: number = 128;
  @Output() submit = new EventEmitter<any>();
  maxWidth:number;
  maxLength:number;
  minHeight:number;
  minWidth:number;
  aspectRatio:number;
  title:string;
  roundCropper:boolean;
  showCropper = false;
  transform: ImageTransform = {};
  value: number = 0.5;
  imageform: UntypedFormGroup;
  errormessage: string = '';
  formats: string = ' ';
  options: Options = {
    floor: 0,
    ceil: 2,
    step: 0.1
  };
  previous_value: number;
  constructor(
    public dialogRef: MatDialogRef<ImageCroppieComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    console.log(this.data,'data');
    // this.maxWidth = 240;
    //   this.maxLength = 240;
      this.aspectRatio = 3 / 3;
      this.roundCropper = true;
      this.title = this.data?.title;
    if(this.data?.maxWidth != null){
      this.maxWidth = this.data.maxWidth;
      this.maxLength = this.data.maxLength;
      this.aspectRatio = this.data.aspectRatio;
      this.roundCropper = this.data.roundCropper;
    }else{
      
    }
    this.imageform = this.fb.group({
      uploadfile: [],
    })
    // this.format = this.data.format;
    // console.log(this.format);

    if(this.data?.minWidth)
    {
      this.minWidth = this.data?.minWidth;
    }

    if(this.data?.minHeight)
    {
      this.minHeight = this.data?.minHeight;
    }
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any) {
    this.imageform.get('uploadfile').setValidators([
      Validators.required,
    ]);
    if (!this.validateFile(event.target.files[0].name)) {
      this.errormessage = 'Unsupported File! allowed formats are .jpg, .jpeg, .bmp, .png';
      return false;
    } else if (event.target.files[0] > 2e+6) {
      this.errormessage = 'Unsupported File! Max size is 2MB and allowed formats are .jpg, .jpeg, .bmp, .png';
      return false;
    }
    this.imageChangedEvent = event;
    return true;
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      return true;
    }
    if (ext.toLowerCase() == 'jpeg') {
      return true;
    }
    if (ext.toLowerCase() == 'bmp') {
      return true;
    }
    if (ext.toLowerCase() == 'png') {
      return true;
    }
    else {
      return false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  Submit() {
    this.submit.emit(this.croppedImage);
  }
  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  zoomIn() {

    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  
  updateSlider(event: any) {
    let value = event;
    if (this.previous_value > value) {
      this.scale -= .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    } else if (this.previous_value < value) {
      this.scale += .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
    this.previous_value = value;


    //   if(event ++){
    //     console.log("++")
    //     this.scale -= .1;
    //     this.transform = {
    //         ...this.transform,
    //         scale: this.scale
    //     };
    //   }
    //  else{
    //   this.scale += .1;
    //   this.transform = {
    //       ...this.transform,
    //       scale: this.scale
    //   };
    //  }



  }

}

export interface DialogDataForCroppie {
  format: string;

}
