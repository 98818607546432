import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { findIndex, Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { SelectDropdown } from 'src/app/models/common/listItem';

@Component({
  selector: 'bzg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  noOfEmployeeList: Observable<SelectDropdown[]>;
   //protected banks: Bank[] = [];

  
  /** control for the selected bank */
  public selectCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public selectFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredSelect: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
  @Input('list') list: any[];
  @Input('maxSelection') maxSelection?: number;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  constructor() { }

  ngOnInit(): void {
    console.log(this.list);
    if (this.list.length) {
      this.selectCtrl.setValue([this.list[0]]);
    }

    // load the initial bank list
    this.filteredSelect.next(this.list.slice(0, 30));

    // listen for search field value changes
    this.selectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });

  }
 selected(event: MatSelectChange) {
    console.log(event.source.value);

    let currentIndex = this.list.findIndex(x => x.id === event.source.value);
    let fromIndex = currentIndex - 15 < 0 ? 0 : currentIndex - 15;
    let toIndex = currentIndex + 15 > this.list.length ? this.list.length : currentIndex + 15;
    this.filteredSelect.next(
      this.list.slice(fromIndex, toIndex)
    );
  }


  protected filterBanks() {

    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.selectFilterCtrl.value;
    if (!search) {
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSelect.next(
      this.list.filter(select => select.name.toLowerCase().indexOf(search) > -1).slice(0, 30)
    );
  }

}

export interface select {
  id: string;
  name: string;
}

export interface selectGroup {
  groupName: string;
  banks: select[];
}
