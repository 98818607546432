export const settings = {
  appVersion: '4.0.0.0.1',
  minLogLevel: "trace",
  currentUser: "CurrentUser",
  userToken: "Token",
  instrumentationKey: "e72516fd-143a-4921-bc04-dd3f13f4383e",
  httpErrorMessage: {
    remoteValidationFailed: "failed to validate",
    httpFailure: "Unable to reach the server right now",
    internalServerError: "Internal Server Error",
    badRequest: "Ops... something went wrong !"
  },
  defaultAvatarUrl: "dist/img/img1.jpg"
}
