import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AdvertisementInfoDto, AdvertisementsSearchDto } from '../state/my-advertisements';


export const LoadMyAdvertisementInfo = createAction('[My Advertisement] My Advertisement Details Info', (query: AdvertisementsSearchDto) => ({ query }));
export const LoadMyAdvertisementInfoSuccess = createAction('[My Advertisement] My Advertisement Details Success', props<{ Advertisementsinfo: AdvertisementInfoDto }>());
export const LoadMyAdvertisementInfoError = createAction('[My Advertisement] My Advertisement Details Error', props<{ error: HttpErrorResponse }>());

export const LoadMoreMyAdvertisementInfo = createAction('[My Advertisement] Load more My Advertisement', (query: AdvertisementsSearchDto) => ({ query }));
export const LoadMoreMyAdvertisementInfoSuccess = createAction('[My Advertisement] Load more My Advertisement success', props<{ Advertisementsinfo: AdvertisementInfoDto }>());
export const LoadMoreMyAdvertisementInfoError = createAction('[My Advertisement] Load more My Advertisement error', props<{ error: HttpErrorResponse }>());

// Promotion Plans///////




