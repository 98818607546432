import { DatePipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectClientConfiguration } from '../store/selectors/app.selector';
import { Subject, filter, map, take, takeUntil } from 'rxjs';

@Pipe({
  name: 'clientConfigDate'
})
export class ClientConfigDatePipe implements PipeTransform, OnDestroy {
  destroy = new Subject<void>();
  dateFormat: string;
  culture: string = "en-US";

  constructor(
    private datePipe: DatePipe,
    private store: Store
  ) {

    this.store.select(SelectClientConfiguration).pipe(
      filter(config => config != null),
      takeUntil(this.destroy)
    ).subscribe(config => {

      if (config.culture.length) {
        this.culture = config.culture;
      }

      let format = config.dateFormat;
      if (format == null || format.csharp == null || format.csharp.length == 0) {
        console.warn("Client Config for Date format is not set");
      }
      this.dateFormat = this.getAngularDateFormat(format.csharp);
    })
  }


  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  transform(value: Date, isTimeRequired?: boolean): string {
    if (value == null || value.toString() == "") {
      return "N/A";
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    if (isTimeRequired !== undefined) {
      this.dateFormat = this.dateFormat + " " + "hh:mm a"
    }

    const datePipe = new DatePipe(this.culture);
    return datePipe.transform(date, this.dateFormat);
  }

  getAngularDateFormat(format: string) {

    if (format == null || format.length == 0) {
      return "short";
    }

    format = format.toLowerCase()
      .replace(/m/g, 'M');
    return format;
  }
}
