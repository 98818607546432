import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class RemindersHistoryModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('reminders-history.dashboard', 'Dashboard', 'en');
    this.translateService.set('reminders-history.reminders-history', 'Reminders History', 'en');
    this.translateService.set('reminders-history.my-reminders', 'My Reminders', 'en');
    this.translateService.set('reminders-history.loading', 'Loading...', 'en');
    this.translateService.set('reminders-history.task-due-date', 'Task Due Date', 'en');
    this.translateService.set('reminders-history.reminder-date', 'Reminder Date', 'en');
    // Latest changes
    this.translateService.set('reminders-history.reminders-history-page-name', 'Reminders History', 'en');
    this.translateService.set('reminders-history.no-reminders-found', 'No Reminders Found', 'en');

    return this.localResourceService.loadLocalResource("reminders-history").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
