import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { map, Observable, of, take, tap } from "rxjs";
import { LocaleResource, LocaleResourceSet, LocaleResourceStore } from "src/app/store/state/app.state";
import { CapitalizePipe } from "./../../pipe/capitalize.pipe";



@Injectable({
    providedIn: 'root'
})

export class LocalResourceService {
    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        //private translatePipe: TranslatePipe,
        private capitalizePipe: CapitalizePipe,
    ) {

    }

    loadLocalResource(resourceSet: string): Observable<LocaleResourceSet> {
        let resource: LocaleResourceSet[] = [];
        let lang = this.translateService.getBrowserCultureLang();
        let lang1 = this.translateService.getBrowserLang();

        //console.log("lang browser", lang, lang1);
        if (localStorage.getItem('localeResource')) {
            resource = JSON.parse(localStorage.getItem('localeResource')) as LocaleResourceSet[];
            let dataFromCache = resource.find(s => s.resourceSet == resourceSet);          
           
            if (dataFromCache) {
                let now = new Date();
                let pre = new Date(dataFromCache.updatedOn);
                //calculate the cache hour
                var diff = Math.round((now.getTime() - pre.getTime()) / (3600 * 1000))
                //console.log("cach data more than", diff);
                //console.log("Data got from local storage", dataFromCache);
                if (diff > 12) {
                    //console.log("updating cache for ", resourceSet);
                    this.loadLocalResourceHttp(resourceSet).subscribe({
                        next: (data) => {
                            //console.log("Receive new data & updating cache", resourceSet);
                            this.updateLocaleResource(data.data);
                        }
                    });
                }
                return of(dataFromCache);
            }
        }
        return this.loadLocalResourceHttp(resourceSet);        
    }

    private loadLocalResourceHttp(resourceSet: string): Observable<LocaleResourceSet> {
        let resource: LocaleResourceSet[] = [];
        return this.http.get<Array<LocaleResource>>("/api/common/getresourcelocalization", { params: { resourceSet } }).pipe(
            map(data => {
                if (localStorage.getItem('localeResource')) {
                    resource = JSON.parse(localStorage.getItem('localeResource')) as LocaleResourceSet[];
                }

                let existing = resource.filter(s => s.resourceSet != resourceSet);
                let resp: LocaleResourceSet = {
                    data: data.map(s => {
                        let value: LocaleResource = s;
                        value.localeId = s.localeId || 'en'
                        return value;
                    }),
                    resourceSet: resourceSet,
                    updatedOn: new Date()
                };
                existing.push(resp);
                localStorage.setItem('localeResource', JSON.stringify(existing));
                //console.log("Data got from HTTP", resp);
                return resp;
            })
        );
    }

    updateLocaleResource(data: Array<LocaleResource>) {
        data.forEach(item => {
            this.translateService.set(item.resourceKey, item.resourceValue, item.localeId);
        })
    }

    // formatErrorMessage(property: string, errorType: string, error: any) {
    //     let errorMessageString = "";
    //     switch (errorType) {
    //         case "required":
    //             errorMessageString = this.translatePipe.transform("Required");
    //             errorMessageString = errorMessageString.replace(RegExp("\\{propertyName\\}", "gi"), this.capitalizePipe.transform(property));
    //             break;
    //         case "minlength":
    //             errorMessageString = this.translatePipe.transform("MinLength");
    //             errorMessageString = errorMessageString.replace(RegExp("\\{propertyName\\}", "gi"), this.capitalizePipe.transform(property))
    //                 .replace(RegExp("\\{0\\}", "gi"), error.requiredLength);
    //             break;
    //         case "maxlength":
    //             errorMessageString = this.translatePipe.transform("MaxLength");
    //             errorMessageString = errorMessageString.replace(RegExp("\\{propertyName\\}", "gi"), this.capitalizePipe.transform(property))
    //                 .replace(RegExp("\\{0\\}", "gi"), error.requiredLength);
    //             break;
    //         default:
    //             break;
    //     }

    //     return errorMessageString;
    // }
}