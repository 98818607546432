<section class="content">
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="col-md-4 offset-md-4">
                    <img class="img-fluid" src="/assets/images/error/account-suspended.jpg" alt="account suspended">
                    
                </div>
                <h3 class="text-center pb-5">{{status?.message}}</h3>
            </div>

        </div>
    </div>
</section>