import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MessageDailogComponent } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';

@Component({
  selector: 'bzg-community-message-dialog',
  templateUrl: './community-message-dialog.component.html',
  styleUrls: ['./community-message-dialog.component.scss']
})
export class CommunityMessageDialogComponent implements OnInit {
  communityFormGroup: FormGroup;
  isTermsandConditionsEnabled: boolean;
  constructor(public dialogRef: MatDialogRef<CommunityMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommunityDialogData, private store: Store,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.communityFormGroup = this.fb.group({
      termsAndConditions: [
        '',
        Validators.requiredTrue
      ]
    });

    this.store.select(selectApplicationConfiguration).subscribe(result => {
      if (result.tenantFeatureConfiguration != null) {
        var feature = result?.tenantFeatureConfiguration?.find(s => s.keyName == "TERMSANDCONDITIONS");
        //if (feature.enable == true ) {
        // this.isTermsandConditionsEnabled = true;
        if (this.data.termsAndConditions != null && this.data.termsAndConditions.length > 0 && feature.enable) {

          this.data.termsAndConditions = this.data.termsAndConditions;
        } else {
          this.data.termsAndConditions = null
        }
        //  }
      }
      //console.log(data,"data");
    })
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onYesClickWithTermsAndConditons(isValid: boolean): void {
    if (isValid) {
      this.dialogRef.close(true);
    }
  }

}

export interface CommunityDialogData {
  title: string;
  messege: string;
  action?: string;
  termsAndConditions?: string;
}
