import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ClientConfiguration, CommunityFeatureConfiguration, HeaderFooterConfig, Manifest, MenuItems, TenantFeatureConfiguration, TenantInfoDetails, TenantLogo } from 'src/app/models/configuration/configuration';
import { UserMembershipPlansStatus } from 'src/app/module/my-profile/store/state/profile';
import { CommunityWidgetsDto } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  getTenantId() {
    return this.http.get<TenantInfoDetails>('/api/common/getTenantId');
  }
  getTenantAllFeatures() {
    return this.http.get<Array<TenantFeatureConfiguration>>('/api/common/gettenantfeatures');
  }
  getClientConfiguration() {
    return this.http.get<ClientConfiguration>('/api/common/gettenantconfiguration');
  }
  getHeaderFooterConfig() {
    return this.http.get<HeaderFooterConfig>('/api/common/getheaderfooterconfig');
  }
  getTenantLogo() {
    return this.http.get<TenantLogo>('/api/common/gettenantlogo');
  }
  getWidgetsInfo(communityId: number) {
    return this.http.get<CommunityWidgetsDto[]>('/api/community/GetCommunityWidgets', { params: { communityId } });
  }
  getMenuList() {
    return this.http.get<MenuItems[]>('/api/common/GetMenuList').pipe(
      map(resp => {
        return resp.map(item => {
          // if(item.parentId == 0){
          //   item.parentId = null;
          // }
          let currentHost = `${window.location.protocol}//${window.location.host}`;
          if (item.path.startsWith(currentHost)) {
            item.path = item.path.replace(currentHost, '');
          }

          if (item.path.startsWith("http") || item.path.startsWith("https") || item.path.startsWith("www")) {
            item.isLink = true;
           // item.path = '/'+item.path;
          }
          else {
            item.isLink = false;
          }
          if (item.path == "#") {
            item.path = "";

          }
          if (item.parentId > 0) {
            //item.groupTitle = true;
            item.class = 'ml-menu';
          }
          if (item.icon == "") {
            item.icon = 'monitor';
          }
          // item.icon='monitor';
          item.moduleName = item.title;
          return item;
        })
      })
    );
  }

  getAvailableLanguage() {
    return this.http.get<string[]>('/api/common/getavailablelangueage');
  }
  getGoogleAnalyticsTraackingId() {
    return this.http.get('/api/common/GetGoogleAnalyticsTraackingId', { responseType: 'text' });
  }
  getMenifest() {
    return this.http.get<Manifest>('/api/common/getmenifest/manifest.json');
  }
  getCommunityAllFeatures() {
    return this.http.get<Array<CommunityFeatureConfiguration>>('/api/common/GetAllCommunityFeatures');
  }


  getUserMembershipStatuses(): Observable<UserMembershipPlansStatus[]> {
    return this.http.get<UserMembershipPlansStatus[]>("/api/user/GetUserMembershipStatuses");
  }
}
