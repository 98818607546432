<footer class="footerDownside" *ngIf="displayFooter">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-sm-auto col-12">
        <ul class="list-inline footer-privacyUL">
          <li class="list-inline-item">
            <a href="#" [routerLink]="['/pages/terms-and-conditions/11']">Terms &amp; Conditions {{ tawkToUrl }}</a>
          </li>
          <li class="list-inline-item">|</li>
          <li class="list-inline-item"><a href="#" [routerLink]="['/pages/privacy-policy/12']">Privacy Policy</a></li>
          <li class="list-inline-item">|</li>
          <li class="list-inline-item" *ngAuthorize="{keyName:'HELPLINK'}"><a href="#" [routerLink]="['/help']">Help</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-auto col-12" *ngIf="headerFooterConfig | async as config">
        <ul class="list-inline footer-socialUL">
          <li class="list-inline-item" *ngIf="config.facebookURL">
            <a href="{{config.facebookURL}}" target="_blank" class="footer-facebook"><i
                class="fab fa-facebook-f"></i></a>
          </li>
          <li class="list-inline-item" *ngIf="config.twitterURL">
            <a href="{{config.twitterURL}}" target="_blank" class="footer-twitter"><i class="fab fa-x-twitter"></i></a>
          </li>
          <li class="list-inline-item" *ngIf="config.linkedInURL">
            <a href="{{config.linkedInURL}}" target="_blank" class="footer-linkedin"><i
                class="fab fa-linkedin-in"></i></a>
          </li>
          <li class="list-inline-item" *ngIf="config.instagramURL">
            <a href="{{config.instagramURL}}" target="_blank" class="footer-instagram"><i
                class="fab fa-instagram"></i></a>
          </li>
        </ul>
      </div>
      <div class="col-sm-auto col-12">
        <p class="footer-copyright">
          &copy; Copyright {{year}} Bizligo.
          <span *ngIf="showEnv" class="badge text-muted" (click)="copy()">Version {{ currentApplicationVersion }}</span>
        </p>
      </div>
      <!-- <div class="col-sm-auto col-12" *ngIf="langs | async as lang">
        <select (change)="langChange($event)" name="language" id="language" class="form-select form-select-sm">
          <option *ngFor="let item of lang" [value]="item">{{item}}</option>
        </select>
      </div> -->
      <div class="col-sm-auto col-12">
        <p class="footer-poweredBy">
          Powered by
          <a target="_blank" href="https://bizligo.com">
            <img src="assets/images/logo/bizligo-logo-icon.png" class="img-fluid" />
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>