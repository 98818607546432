import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { filter, map, switchMap, take, tap, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { settings } from 'src/app/settings/settings';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  errorMessage: boolean;
  constructor(
    public authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private store: Store
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("http://") > -1 || request.url.indexOf("https://") > -1) {
      return next.handle(request);
    } else {
      // var request = request.clone({
      //   url: `${environment.apiUrl}${request.url}`,
      //   setHeaders: {
      //     //Authorization: `Bearer ${this.authService.getToken().access_token}`,
      //     tenant: this.getCurrentTenant(),
      //     //UserToken: this.authService.getToken() ? this.authService.getToken() : "",
      //     //culture: this.translateService.getBrowserCultureLang()
      //   }
      // });

      return this.store.select(selectApplicationConfiguration).pipe(
        take(1),
        map(s => s.tenant?.tenantId),
        switchMap(tenantId => {
          //console.log(request.url)
          if (request.url == `/api/common/getTenantId`) {
            //console.log("request with out tenantId header", request.url)
            return this.handleRequest(request, next);
          } else {
            //console.log("waiting for tenantId");
            return this.waitForTenantId(request, next);
          }
        })
      );

    }
  }

  waitForTenantId(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectApplicationConfiguration).pipe(
      map(config => config.tenant),
      filter(tenantId => tenantId != null),
      //tap(s => console.log("before filter tenantId update in ngrx", s)),
      //tap(s => console.log("after filter tenantId update in ngrx", s)),
      take(1),
      //tap(s => console.log("after filter complete tenantId update in ngrx", s)),
      switchMap(tenant => {
        let tenantId = tenant.tenantId;
        if (!environment.production) {
          tenantId = this.getCurrentTenant();
        }
        //console.log("headers before", request.url, request.headers);
        var requestClond = request.clone({
          headers: request.headers.append('tenant', tenantId)
        });
        //console.log("headers after",request.url, requestClond.headers);
        return this.addToken(requestClond, next);
      })
    )
  }

  addToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getAccessToken2().pipe(
      switchMap(token => {
        if (token) {
          var requestClond = request.clone({
            headers: request.headers.append('authorization', `Bearer ${token}`)
          });
          return this.handleRequest(requestClond, next);
        }
        return this.handleRequest(request, next);
      })
    );
  }

  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var requestClond = request.clone({
      url: `${environment.apiUrl}${request.url}`,
    });
    return next.handle(requestClond).pipe(
      tap({
        next: (evt: HttpEvent<any>) => {
          if (this.errorMessage) {
            this._snackBar.dismiss();
            this.errorMessage = false;
          }
        },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            this.errorMessage = true;
            switch (err.status) {
              case 0:
                //this.router.navigate(['/something-went-wrong']);
                this._snackBar.open(settings.httpErrorMessage.httpFailure, 'Close', {
                  duration: 10000,
                });
                break;
              case 500:
                this._snackBar.open(settings.httpErrorMessage.internalServerError, 'Close', {
                  duration: 10000,
                });
                //this.router.navigate(['/internal-server-error'])
                break;
              default:
            }

          }
        }
      })
    );
  }
  // private addToken(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     url: `${request.url.replace(/{tokenkey}/g, token)}`,
  //     setHeaders: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   });
  // }

  private ensureCorporateIdAndToken(url: string) {
    //return url.replace(/{tokenkey}/g, this.authService.getToken().access_token)
    //.replace(/{corpId}/g, this.authService.getCorporateId());
  }
  private getCurrentTenant(): string {
    return this.authService.getCurrentTenant();
  }
}
