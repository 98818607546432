<section class="content" id="advertisement-member-admin-purchase-receipt-section">
    <div class="container-fluid ">
        <!--************************************************
            communityDonateReceipt #Start#
            ************************************************-->
        <section class="communityDonateReceipt" id="">
            <div *ngIf="loading" class="container-fluid pt-5">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="card p-5">
                            <div class="card-body">
                                <h4 class="text-center"> <i class="fa fa-spinner fa-spin"></i>
                                    {{'advertisement.payment-receipt' | translate}}.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loading && !inSuffecientData" class="container-fluid communityDonateReceipt__headerbg">
                <h3>
                    {{'advertisement.payment-receipt' | translate}}
                </h3>
            </div>
            <div *ngIf="!loading && !inSuffecientData" class="container ">
                <div class="card communityDonateReceiptCard ">
                    <div class="card-body p-xxl-5 px-md-4">
                        <div class="text-end mb-4" *ngIf="data?.communityID != 0">
                            <button class="btn btn-outline-theme"
                            [routerLink]="['/community',data?.communityName | slug,data?.communityID]">{{'advertisement.view-community' | translate}}</button>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <img src="assets/images/my-ads/tick.png" class="img-fluid">
                                <h4 *ngIf="!isTenantAdminPage && data?.communityID == 0 && data?.requiresApproval" class="font-18 fw-500 mt-3">
                                {{'advertisement.your-advertisementTA' | translate}}
                                </h4>
                                <h4 *ngIf="!isCommunityAdminPage && !isTenantAdminPage && data?.communityID >= 0 && !data?.requiresApproval" class="font-18 fw-500 mt-3">
                                    {{'advertisement.your-advertisement' | translate}}
                                    </h4>
                                    <h4 *ngIf="!isCommunityAdminPage && data?.communityID > 0 && data?.requiresApproval" class="font-18 fw-500 mt-3">
                                        {{'advertisement.your-advertisementCA' | translate}}
                                        </h4>
                                        <h4 *ngIf="isTenantAdminPage || isCommunityAdminPage" class="font-18 fw-500 mt-3">
                                            {{'advertisement.your-advertisement' | translate}}
                                            </h4>
                                <p class="mb-2">{{'advertisement.hi' | translate}}<span class="text-theme-secondary fw-500">{{data?.firstName}}</span>,</p>
                                <p>{{'advertisement.payment-confirmation' | translate}} <span class="fw-500">{{data?.totalAmount | currency:currency}}</span> {{'advertisement.for' | translate}} <span class="fw-500">{{data?.planName}}</span></p>
                                <div class="row row-cols-sm-2 row-cols-1">
                                    <div class="col">
                                        <div class="card card-body">
                                            <p class="communityDonateReceiptCard--label">{{'advertisement.payment-id' | translate}}</p>
                                            <h5 class="communityDonateReceiptCard--value">{{data?.transactionID}}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card card-body">
                                            <p class="communityDonateReceiptCard--label">{{'advertisement.payment-date' | translate}}</p>
                                            <h5 class="communityDonateReceiptCard--value">{{data?.transactionDate | date:'MMM dd, yyyy hh:mm a'}}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card card-body">
                                            <p class="communityDonateReceiptCard--label">{{'advertisement.advertisement-location' | translate}}</p>
                                            <h5 class="communityDonateReceiptCard--value">{{data?.adLocation}}</h5>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card card-body">
                                            <p class="communityDonateReceiptCard--label">{{'advertisement.plan-start-date-end-date' | translate}}</p>
                                            <h5 class="communityDonateReceiptCard--value">{{data?.planStartDate | clientConfigDate}} To {{data?.planEndDate | clientConfigDate}}</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card card-body  communityDonateBrokenReceiptCard ">
                                    <h4 class="fw-500 font-16 text-theme-secondary mb-4">{{'advertisement.payment-summary' | translate}}</h4>
                                    <div class="communityDonateBrokenReceiptCard--values ">
                                        <div class="row g-1 justify-content-between">
                                            <div class="col">
                                                <p class="communityDonateBrokenReceiptCard--label">
                                                    {{'advertisement.plan-name' | translate}}
                                                </p>
                                                <h5 class="communityDonateBrokenReceiptCard--value">{{data?.planName}}
                                                </h5>
                                            </div>
                                            <div class="col-auto">
                                                <p class="communityDonateBrokenReceiptCard--label">{{'advertisement.price' | translate}}</p>
                                                <h5 class="communityDonateBrokenReceiptCard--value">{{data?.actualAmount | currency:currency}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="communityDonateBrokenReceiptCard--values text-end border-top-0 ">
                                        <div class="row g-1 ">
                                            <div class="col">
                                                <span class="text-end">{{'advertisement.total-amount' | translate}}:</span>

                                            </div>
                                            <div class="col-auto">
                                                <span class="fw-500">{{data?.actualAmount | currency:currency}}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="communityDonateBrokenReceiptCard--values text-end border-top-0 " *ngIf="data?.discountAmount > 0">
                                        <div class="row g-1 ">
                                            <div class="col">
                                                <span class="text-end">{{'advertisement.promo-code' | translate}} ({{data?.discountCoupon}}):</span>

                                            </div>
                                            <div class="col-auto">
                                                <span class="fw-500"> - {{data?.discountAmount | currency:currency}}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="text-end font-16 mt-4">
                                        <small class="text-dark fw-500">{{'advertisement.amount-paid' | translate}}:</small>
                                        <span class="fw-600 text-theme-secondary">{{data?.totalAmount | currency:currency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" *ngIf="!isTenantAdminPage && !isCommunityAdminPage" [routerLink]="['/advertisement/my-advertisements']" class="btn btn-outline-theme">{{'advertisement.my-advertisement' | translate}}</button>
                            <button type="button" *ngIf="isTenantAdminPage || isCommunityAdminPage" [routerLink]="[isTenantAdminPage ? '/admin/advertisement' : isCommunityAdminPage ? '/admin/community/' + communityId + '/advertisement' : '']" class="btn btn-outline-theme">{{'Manage Member Advertisements' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="inSuffecientData" class="container-fluid pt-5">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="card p-5">
                            <div class="card-body">
                                <p class="fs-5">
                                    {{'advertisement.the-payment-receipt-page-cannot-be-accessed-through-the-url' | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--************************************************
                  communityDonateReceipt #End#
         ************************************************-->
    </div>
</section>