import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class CommunityDetailsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translate.set('community-details.about-community', 'About Community', 'en');
    this.translate.set('community-details.membership-plan-details', 'Membership Plan Details', 'en');
    this.translate.set('community-details.champions', 'Champions', 'en');
    this.translate.set('community-details.community-updates', 'Community Updates', 'en');
    this.translate.set('community-details.spotlight-events', 'Spotlight Events', 'en');
    this.translate.set('community-details.donate', 'Donate', 'en');
    this.translate.set('community-details.join', 'Join', 'en');
    this.translate.set('community-details.leave', 'Leave', 'en');
    this.translate.set('community-details.groups-cancelling-loading', 'Cancelling...', 'en');
    this.translate.set('community-details.the-community-or', 'the community or', 'en');
    this.translate.set('community-details.login', 'Login', 'en');
    this.translate.set('community-details.member', 'if you are already a member', 'en');
    this.translate.set('community-details.careers', 'Careers', 'en');
    this.translate.set('community-details.this-job-is-no-longer-active', 'this job is no longer active', 'en');
    this.translate.set('community-details.resources', 'Resources', 'en');
    this.translate.set('community-details.events', 'Events', 'en');
    this.translate.set('community-details.groups', 'Groups', 'en');
    this.translate.set('community-details.members', 'Members', 'en');
    this.translate.set('community-details.discussions', 'Discussions', 'en');
    this.translate.set('community-details.home', 'Home', 'en');
    this.translate.set('community-details.loading', 'Loading...', 'en');
    this.translate.set('community-details.search-by-events.placeholder', 'Search By Event Name', 'en');
    this.translate.set('community-details.search-by-event-date.placeholder', 'Search By Event Date', 'en');
    this.translate.set('community-details.search', 'SEARCH', 'en');
    this.translate.set('community-details.reset', 'RESET', 'en');
    this.translate.set('community-details.most-popular-events', 'Most Popular Events', 'en');
    this.translate.set('community-details.no-events-found', 'No Events Found.', 'en');
    this.translate.set('community-details.suggested-events', 'Suggested Events', 'en');
    this.translate.set('community-details.to', 'JOIN', 'en');
    this.translate.set('community-details.plane-name', 'Plane Name', 'en');
    this.translate.set('community-details.expiry-date', 'Expiry Date', 'en');
    this.translate.set('community-details.upgrade', 'Upgrade', 'en');
    this.translate.set('community-details.sending-request', 'Sending connection request...', 'en');
    this.translate.set('community-details.canceling-request', 'Canceling connection request...', 'en');
    this.translate.set('community-details.processing-request', 'Processing your request...', 'en');
    this.translate.set('community-details.searching', 'Searching...', 'en');
    this.translate.set('community-details.end-result', ' End of results', 'en');
    this.translate.set('community-details.not-find', ' Oops...we did not find anything that matches this search:(', 'en');
    this.translate.set('community-details.try-search', 'Try Search for something more general, change the filters.', 'en');
    this.translate.set('community-details.advanced-search', 'Advanced Search', 'en');
    this.translate.set('community-details.search-by-name.placeholder', 'Search by member name', 'en');
    this.translate.set('community-details.top', 'Top', 'en');
    this.translate.set('community-details.people-with-similar-interests', 'People with Similar Interests', 'en');
    this.translate.set('community-details.skills', 'Skills', 'en');
    this.translate.set('community-details.no-matching-found', 'No Similar interests/skills matches with your profile', 'en');
    this.translate.set('community-details.all', 'All', 'en');
    this.translate.set('community-details.videos', 'Videos', 'en');
    this.translate.set('community-details.image', 'Images', 'en');
    this.translate.set('community-details.documents', 'Documents', 'en');
    this.translate.set('community-details.audios', 'Audios', 'en');
    this.translate.set('community-details.no-video-found', 'There is no video resources for you as of now', 'en');
    this.translate.set('community-details.no-audio-found', 'There is no audio resources for you as of now', 'en');
    this.translate.set('community-details.no-documents-found', 'There is no document resources for you as of now', 'en');
    this.translate.set('community-details.no-image-found', 'There is no image resources for you as of now', 'en');
    this.translate.set('community-details.login-to-view', 'Please login to view community resources', 'en');
    this.translate.set('community-details.advertisements', 'Advertisements', 'en');
    this.translate.set('community-details.search-by-keyword.placeholder', 'Search By Keyword/Title/Description', 'en');
    this.translate.set('community-details.groups-loading', 'Loading...', 'en');
    this.translate.set('community-details.groups-are-not-created-yet', 'There is no Groups for you as of now!', 'en');
    this.translate.set('community-details.groups-search-btn', 'SEARCH', 'en');
    this.translate.set('community-details.groups-reset-btn', 'RESET', 'en');
    this.translate.set('community-details.groups-trending-groups', 'Trending Groups', 'en');
    this.translate.set('community-details.groups-unable-to-load-trending-groups', 'Unable to load trending groups', 'en');
    this.translate.set('community-details.groups-there-are-no-trending-groups', 'There are no trending groups', 'en');
    this.translate.set('community-details.groups-joining-loading', 'Joining group...', 'en');
    this.translate.set('community-details.groups-leaving-loading', 'Leaving group...', 'en');
    this.translate.set('community-details.membership-plan-details', 'Membership Plan Details', 'en');
    this.translate.set('community-details.plane-name', 'Plan Name', 'en');
    this.translate.set('community-details.professional-membership', 'Professional Membership', 'en');
    this.translate.set('community-details.expiry-date', 'Expiry Date', 'en');
    this.translate.set('community-details.upgrade', 'Upgrade', 'en');
    this.translate.set('community-details.create-new-post', 'CREATE NEW POST', 'en');
    this.translate.set('community-details-no-discussions-found', 'There is no discussions for you as of now!', 'en');
    this.translate.set('community-details.current-job-openings', 'Current Job Openings', 'en');
    this.translate.set('community-details.search-by-job-title.placeholder', 'Search By Job Title', 'en');
    this.translate.set('community-details.search-by-job-type', 'Search By Job Type', 'en');
    this.translate.set('community-details.full-time', 'Full Time', 'en');
    this.translate.set('community-details.part-time', 'Part Time', 'en');
    this.translate.set('community-details.contract', 'Contract', 'en');
    this.translate.set('community-details.contract-to-hire', 'Contract to Hire', 'en');
    this.translate.set('community-details.search-by-job-location.placeholder', 'Search By Job Location', 'en');
    this.translate.set('community-details.search-by-company-name.placeholder', 'Search By  Company Name', 'en');
    this.translate.set('community-details.company', 'Company', 'en');
    this.translate.set('community-details.location', 'Location', 'en');
    this.translate.set('community-details.last-updated-date', 'Last Updated Date', 'en');
    this.translate.set('community-details.user-applied-date', 'Applied Date', 'en');
    this.translate.set('community-details.view-apply', 'View & Apply', 'en');
    this.translate.set('community-details.already-applied', 'Already Applied', 'en');
    this.translate.set('community-details.no-result-found', 'There is no Jobs for you as of now!', 'en');
    this.translate.set('community-details.loading-more', 'Loading more...', 'en');
    this.translate.set('community-details.most-applied-jobs', 'Most Applied Jobs', 'en');
    this.translate.set('community-details.current-job-requirements', 'Below are the current job requirements available. Click on', 'en');
    this.translate.set('community-details.view-and-apply', '"View and Apply"', 'en');
    this.translate.set('community-details.apply-for-that-position', 'to see complete job description and apply for that position.', 'en');
    this.translate.set('community-details.applying-job', 'Applying job...', 'en');
    this.translate.set('community-details.job-requirement-details', 'Job Requirement Details', 'en');
    this.translate.set('community-details.list-of-jobs', 'List Of Jobs', 'en');
    this.translate.set('community-details.unable-to-load-job-details', 'Unable to load job details, please try after some time', 'en');
    this.translate.set('community-details.the-complete-details-of-the-job-posting.', 'Here are the complete details of the job posting you selected.Read all details about it and click on the button to apply for this job.', 'en');
    this.translate.set('community-details.apply', 'Apply', 'en');
    this.translate.set('community-details.job-description', 'Job Description', 'en');
    this.translate.set('community-details.additional-details', 'Additional Details', 'en');
    this.translate.set('community-details.name.placeholder', 'Name', 'en');
    this.translate.set('community-details.email-id.placeholder', 'Email ID', 'en');
    this.translate.set('community-details.message-to-employer.placeholder', 'Message To Employer', 'en');
    this.translate.set('community-details.submit', 'Submit', 'en');
    this.translate.set('community-details.you-have-already-applied-to-this-job', 'You have already applied to this job!', 'en');
    this.translate.set('community-details.ok', 'OK', 'en');
    this.translate.set('community-details.you-have-applied-successfully', 'You have applied successfully', 'en');
    this.translate.set('community-details.most-discussion', 'Most Popular Discussions', 'en');
    this.translate.set('community-details.loading', 'Loading...', 'en');
    this.translate.set('community-details.unable-to-load-popular-discussions', 'Unable To Load Popular Discussions', 'en');
    this.translate.set('community-details.no-popular-discussions', 'No discussions happened so far. Start a discussion by clicking on "Create New Post"', 'en');
    this.translate.set('communities.waiting-for-approval', 'Waiting for approval', 'en');
    this.translate.set('communities.payment-pending', 'Payment Pending', 'en');
    this.translate.set('communities.cancel', 'Cancel', 'en');
    this.translate.set('community-details.subscribed-discussions', 'Subscribed Discussions', 'en');
    this.translate.set('community-details.unable-to-load-subscribed-discussions', 'Unable To Load Subscribed Discussions', 'en');
    this.translate.set('community-details.no-subscribed-discussions', 'You haven’t subscribed to any discussion yet', 'en');
    this.translate.set('community-details.most-subscribed-discussions', 'Most Subscribed Discussions', 'en');
    this.translate.set('community-details.unable-to-load-most-subscribed-discussions', 'Unable To Load Most Subscribed Discussions', 'en');
    this.translate.set('community-details.no-most-subscribed-discussions', 'There are no subscribed discussions.', 'en');
    this.translate.set('community-details.please', 'Please', 'en');
    this.translate.set('community-details.to-view-subscribed-discussions', 'to view subscribed discussions', 'en');
    this.translate.set('community-details.saving-discussion', 'Saving Discussion...', 'en');
    this.translate.set('community-details.updating-discussion', 'Updating Discussion...', 'en');
    this.translate.set('community-details.unable-to-load-community-users', 'Unable to load community users', 'en');
    this.translate.set('community-details.saved-disscussion-successfully', 'Saved disscussion successfully', 'en');
    this.translate.set('community-details.unable-to-save-discussion', 'Unable to save discussion', 'en');
    this.translate.set('community-details.too', 'To', 'en');
    this.translate.set('community-details.registered', 'REGISTERED', 'en');
    this.translate.set('community-details.your-applied-jobs', 'Your Applied Jobs', 'en');
    this.translate.set('community-details.you-dont-apply-any-job-yet', 'You have not applied to any job yet', 'en');
    this.translate.set('community-details.remarks', 'Remarks', 'en');
    this.translate.set('community-details.no-most-applied-job-found', 'No jobs found', 'en');
    this.translate.set('community-details.no-your-applied-job-found', 'You haven\'t applied any jobs yet', 'en');
    this.translate.set('community-details.there-is-no-events-for-you-as-of-now', 'There are no Events for you as of now!', 'en');
    this.translate.set('community-details.there-is-no-resources-for-you-as-of-now', 'There is no resources for you as of now!', 'en');
    this.translate.set('community-details.related-skills', 'RELATED SKILLS', 'en');
    this.translate.set('community-details.interests', 'INTERESTS', 'en');
    this.translate.set('community-details.people-with', 'People with', 'en');
    // latest changes
    this.translate.set('community-details.the-community-to-view-the-community-information', 'the community to view the community information.', 'en');
    this.translate.set('community-details.your-request-is-sent-and-waiting-for-approval', 'Your request is sent and waiting for approval', 'en');
    this.translate.set('community-details.your-request-to-join-the-community-status-is-payment-pending', 'Your payment is pending, please purchase the subscription plan to view community details.', 'en');
    this.translate.set('community-details.message', 'Message', 'en');
    this.translate.set('community-details.upload-resume-doc', '(Upload your resume only in doc, docx, pdf format)', 'en');
    this.translate.set('community-details.upload-resume', 'Upload Resume', 'en');
    this.translate.set('community-details.email', 'Email', 'en');
    this.translate.set('community-details.name', 'Name', 'en');
    this.translate.set('community-details.please-login-to-apply-this-job', 'Please login to apply this job', 'en');
    this.translate.set('community-details.this-community-is-no-longer-available', 'This community is no longer available', 'en');
    this.translate.set('community-details.view-communities', 'View Communities', 'en');
    this.translate.set('community-details.sorry-no-results-found-matching-your-search-criteria', 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('community-details.sorry-this-content-is-not-available-right-now.', 'Sorry,this content is not available right now.', 'en');
    this.translate.set('community-details.the-link-that-you-have-followed-may-have-changed', 'The link that you have followed may have changed or the post might have been removed.', 'en');
    this.translate.set('community-details.click-on-continue-below-to-go-to-the-main-discussion-page', 'Click on continue below to go to the main discussion page.', 'en');
    this.translate.set('community-details.processing-your-request', 'Processing your request', 'en');
    this.translate.set('community-details.no-events-image', '/assets/images/community-details/no_events.png', 'en');
    this.translate.set('community-details.to-view-suggested-events', 'to view suggested events', 'en');
    this.translate.set('community-details.please-provide-valid-info', 'Please provide valid info', 'en');
    this.translate.set('community-details.search-by-groups.placeholder', 'Search by Groups', 'en');
    this.translate.set('community-details.no-groups-image', '/assets/images/community-details/no_groups.png', 'en');
    this.translate.set('community-details.top-5-people-with-similar-interests-skills', 'Top 5 People with Similar Interests & Skills', 'en');
    this.translate.set('community-details.vedio-image', '/assets/images/community-details/videos.png', 'en');
    this.translate.set('community-details.audio-image', '/assets/images/community-details/audios.png', 'en');
    this.translate.set('community-details.document-image', '/assets/images/community-details/documents.png', 'en');
    this.translate.set('community-details.no-resources-image', '/assets/images/community-details/no_resources.png', 'en');
    this.translate.set('community-details.you-will-be-redirect-to-purchase-memberhsip-plans', 'You will be redirect to purchase memberhsip plans', 'en');
    this.translate.set('community-details.attachment', 'Attachment', 'en');
    this.translate.set('community-details.type-a-message', 'Type a Message', 'en');
    this.translate.set('community-details.your-name', 'Your Name', 'en');
    this.translate.set('community-details.your-email', 'Your Email', 'en');
    this.translate.set('community-details.upload-your-resume', 'Upload Your Resume', 'en');
    this.translate.set('community-details.optional-message-to-employer', 'Optional Message To Employer', 'en');
    this.translate.set('community-details.to-view-matched-users', 'to view the people who matches to your skills and interests', 'en');
    this.translate.set('community-details.to-view', 'to view', 'en');
    this.translate.set('community-details.please', 'Please', 'en');
    this.translate.set('community-details.my-applications', 'My Applications', 'en');
    this.translate.set('community-details.this-job-is-no-longer-active', 'this job is no longer active', 'en');

    // Latest Changes
    this.translate.set('community-details.continue', 'Continue', 'en');
    this.translate.set('community-details.donation-payment-completed-successfully', 'Donation payment completed successfully', 'en');
    this.translate.set('community-details.purpose-of-donation', 'Purpose Of Donation', 'en');
    this.translate.set('community-details.select-payment-method', 'Select Payment Method', 'en');
    this.translate.set('community-details.other-amount.placeholder', 'Other Amount', 'en');
    this.translate.set('community-details.total-amount', 'Total Amount', 'en');
    this.translate.set('community-details.donation-receipt', 'Donation Receipt', 'en');
    this.translate.set('community-details.thanks-for-donating', 'Your payment processed successfully and Thanks for Donating.', 'en');
    this.translate.set('community-details.hi', 'Hi', 'en');
    this.translate.set('community-details.donation-confirmation', 'This is the Donation confirmation of', 'en');
    this.translate.set('community-details.payment-id', 'Payment ID', 'en');
    this.translate.set('community-details.payment-date', 'Payment Date', 'en');
    this.translate.set('community-details.payment-summary', 'Payment Summary', 'en');
    this.translate.set('community-details.amount', 'Amount', 'en');
    this.translate.set('community-details.amount-paid', 'Amount Paid', 'en');
    this.translate.set('community-details.unable-to-fetch-user-data', 'Unable to fetch user data', 'en');
    this.translate.set('community-details.print-receipt', 'PRINT RECEIPT', 'en');
    this.translate.set('community-details.payment-completed', 'Payment completed successfully', 'en');
    this.translate.set('community-details.unable-to-update-payment', 'Unable to update payment', 'en');
    this.translate.set('community-details.donation-amount', 'Donation Amount', 'en');
    this.translate.set('community-details.booking-id', 'Booking ID', 'en');
    this.translate.set('community-details.booking-date', 'Booking Date', 'en');
    this.translate.set('community-details.donar-details', 'Donar Details', 'en');
    this.translate.set('community-details.first-name.placeholder', 'First Name', 'en');
    this.translate.set('community-details.first-name', 'First Name', 'en');
    this.translate.set('community-details.event-calender','Event Calendar','en');
    this.translate.set('community-details.last-name.placeholder', 'Last Name', 'en');
    this.translate.set('community-details.event-calender', 'Event Calendar', 'en');
    this.translate.set('community-details.last-name', 'Last Name', 'en');
    this.translate.set('community-details.email.placeholder', 'Email', 'en');
    this.translate.set('community-details.email', 'Email', 'en');
    this.translate.set('community-details.phone.placeholder', 'Phone', 'en');
    this.translate.set('community-details.phone', 'Phone', 'en');
    this.translate.set('community-details.valid-phone-number', 'Please provide valid phone number', 'en');
    this.translate.set('community-details.please-enter-valid-first-name', 'Please enter valid first name', 'en');
    this.translate.set('community-details.please-enter-valid-last-name', 'Please enter valid last name', 'en');

    return this.localResourceService.loadLocalResource("community-details").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
