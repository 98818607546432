import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, switchMap, take } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { selectApplicationConfiguration, selectUserActiveStatus } from './store/selectors/app.selector';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActiveFeatureGuard implements CanActivate {
  isloggedIn: boolean;
  urlRoutes: string[] = [
    '/blogs',
    '/blogs/{0}/{0}',
    '/community/{0}/{0}',
    '/community/{0}/{0}/discussions',
    '/community/{0}/{0}/discussions/create',
    '/community/{0}/{0}/discussions/edit/{0}',
    '/community/{0}/{0}/discussions/{0}',
    '/community/{0}/{0}/members',
    '/community/{0}/{0}/groups',
    '/community/{0}/{0}/events',
    '/community/{0}/{0}/resources',
    '/community/{0}/{0}/careers',
    '/member/directory',
    '/events',
    '/event/details/{0}/{0}',
    '/group/{0}/{0}/discussions',
    '/group/{0}/{0}/discussions/create',
    '/group/{0}/{0}/discussions/edit/{0}',
    '/group/{0}/{0}/discussions/{0}',
    '/group/{0}/{0}/members',
    '/mygroups',
    '/profile/endorsements',
    '/my-events',
    '/member/endorsements',
    '/member/connections',
    '/my-matches',
    '/my-reminders',
    '/reminders-history',
    '/messages',
    '/testimonials',
    '/my-testimonials',
    '/loyalty/loyalty-details',
    '/contact-us',
    '/advertisement/my-advertisements',
    '/advertisement/create-ad',

  ];

  constructor(
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.store.select(selectApplicationConfiguration).pipe(
      filter(s => s.tenantFeatureConfiguration != null),
      map(st => st.tenantFeatureConfiguration),
      take(1),
      map(featureConfiguration => {
        let currentUrl = "";
        if (state.url.includes("#")) {
          currentUrl = state.url.split("#")[0];
        } else {
          currentUrl = state.url;
        }
        if (currentUrl.includes("?")) {
          currentUrl = currentUrl.split("?")[0];
        }
        this.authService.getCurrentUser2().pipe(
          take(1)
        ).subscribe(user => {
          this.isloggedIn = user != null;
        })

        let currentRoute = this.urlRoutes.filter(s => {
          return new RegExp(this.createRegex(s)).test(currentUrl.toLowerCase());
        });

        if (currentRoute.length > 0) {
          let currentRouteUrl = currentRoute[0].split('/').filter(s => s != '' && s != '{0}').join("/");
          let hasFeatureAccess = false;
          switch (currentRouteUrl) {
            case 'blogs':
              var feature = featureConfiguration?.find(s => s.keyName == "BLOG");
              if (this.isloggedIn) {
                if (feature.enable == true) {
                  hasFeatureAccess = true;
                }
              }
              else if (feature.nonMember == true && feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'business-directory':
              var feature = featureConfiguration?.find(s => s.keyName == "ORGDIRECTORY");
              if (this.isloggedIn) {
                if (feature.enable == true) {
                  hasFeatureAccess = true;
                }
              }
              else if (feature.nonMember == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community':
              var feature = featureConfiguration?.find(s => s.keyName == "COMMUNITIES");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/events':
            case 'my-events':
              var feature = featureConfiguration?.find(s => s.keyName == "EVENTS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/discussions':
            case 'community/discussions/create':
            case 'community/discussions/edit':
              var feature = featureConfiguration?.find(s => s.keyName == "COMMUNITYDISCUSSIONS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/members':
              var feature = featureConfiguration?.find(s => s.keyName == "COMMUNITYMEMBERDIRECTORY");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/groups':
            case 'mygroups':
            case 'group/discussions':
            case 'group/discussions/create':
            case 'group/discussions/edit':
            case 'group/discussions/members':
              var feature = featureConfiguration?.find(s => s.keyName == "GROUPS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/resources':
              var feature = featureConfiguration?.find(s => s.keyName == "RESOURCES");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'community/careers':
            case 'job/details':
            case 'my-jobs':
              var feature = featureConfiguration?.find(s => s.keyName == "JOBSBOARD");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'events':
            case '/event/details':
              var feature = featureConfiguration?.find(s => s.keyName == "EVENTS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case '/member/directory':
              var feature = featureConfiguration?.find(s => s.keyName == "BUSINESSDIRECTORY");
              if (this.isloggedIn) {
                if (feature.enable == true) {
                  hasFeatureAccess = true;
                }
              }
              else if (feature.nonMember == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'member/endorsements':
            case 'profile/endorsements':
              var feature = featureConfiguration?.find(s => s.keyName == "ENDORSEMENTS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'member/connections':
              var feature = featureConfiguration?.find(s => s.keyName == "CONNECTIONS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'messages':
              var feature = featureConfiguration?.find(s => s.keyName == "PRIVATEMESSAGES");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'my-matches':
              var feature = featureConfiguration?.find(s => s.keyName == "MYMATCHES");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'my-reminders':
              var feature = featureConfiguration?.find(s => s.keyName == "MYREMINDERS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'testimonials':
              var feature = featureConfiguration?.find(s => s.keyName == "TESTMONIALS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'my-testimonials':
              var feature = featureConfiguration?.find(s => s.keyName == "TESTMONIALS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'loyalty/loyalty-details':
              var feature = featureConfiguration?.find(s => s.keyName == "LOYALITY");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'contact-us':
              var feature = featureConfiguration?.find(s => s.keyName == "CONTACTUS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            case 'advertisement/my-advertisements':
            case 'advertisement/create-ad':
              var feature = featureConfiguration?.find(s => s.keyName == "MEMBERADS");
              if (feature.enable == true) {
                hasFeatureAccess = true;
              }
              break;
            default:
              hasFeatureAccess = true;
              break;
          }

          if (hasFeatureAccess) {
            if (environment.ENV == 'DEV') {
              console.log("has feature access to ", currentRouteUrl);
            }
            return true;
          } else {
            if (this.isloggedIn) {
              console.log("don't have feature access to ", currentRouteUrl);
              this.router.navigate(['/common/featurenotfound']);
              return false;
            } else {
              this.authService.loginRedirect();
              return false;
            }

          }
        } else {
          return true;
        }

      }),
    )
  }

  createRegex(urlPattern: string) {
    return new RegExp("^" + urlPattern.replace(/\{0\}/g, "([\\w-]+)") + "$");
  }


}
