<div class="tempararyCodeFormenu d-none">
  <button type="button" (click)="ltlMenu()" class="btn btn-light"><i class="fas fa-long-arrow-alt-left"></i>
    LTR</button>
  <button (click)="ttbMenu()" class="btn btn-basic"><i class="fas fa-long-arrow-alt-up"></i> TTB</button>
  <button type="button" (click)="rtlMenu()" class="btn btn-light">RTL <i class="fas fa-long-arrow-alt-right"></i>
  </button>
</div>

<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid navbar__Container">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars responsive-menu-bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open') ; !isNavbarCollapsed ? (isNavbarCollapsed = true) : ''"></a>
      <!-- <a href="#" onClick="return false;" class="bars" (click)="!isNavbarCollapsed ? (isNavbarCollapsed = true)"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"></a> -->
      <a href="#" class="fake-cross-bars"><i class="material-icons">close</i></a>
      <ng-container *ngIf="tenantLogo | async as logo">
        <a *ngIf="communityLogoUrl ==null" class="navbar-brand community-logo" routerLink="/">
          <!-- <img [src]="logo.icon" class="navbar-brand--icon" alt="" /> -->
          <!-- <span class="logo-name"> -->
          <img [src]="logo.brand" class="" alt="" />
          <!-- </span> -->
        </a>
      </ng-container>
      <!-- <ng-container *ngIf="tenantLogo | async as logo">
        <a *ngIf="communityLogoUrl ==null" class="navbar-brand" routerLink="/">
          <img [src]="logo.icon" class="navbar-brand--icon" alt="" />
          <span class="logo-name">
            <img [src]="logo.brand" class="navbar-brand--text" alt="" />
          </span>
        </a>
      </ng-container> -->
      <a *ngIf="communityLogoUrl !=null" class="navbar-brand community-logo" [routerLink]="link ? [link] : ['/']">
        <img [src]="communityLogoUrl" alt="community logo">
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon navbarBtnMenuUl">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon header-icon-menu'"></app-feather-icons>
            <app-feather-icons [icon]="'x'" [class]="'header-icon header-icon-x'"></app-feather-icons>
          </button>
        </li>
        <li *ngIf="currentPageName | async as pageName" class="navbarBtnMenuUl--currentpage">
          {{ pageName | sentenceCase }}
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="isPlansExpiredOrAboutToExpire" class="navRenew-item">
          <button (click)="renewMembershipNow()" class="btn btn-outline-danger" [matTooltip]="renewMessage">RENEW YOUR
            MEMBERSHIP NOW</button>
        </li>
        <!-- <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class="nav-notification-icons headerRightmenuIcon">
            <app-feather-icons [icon]="'maximize'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li> -->
        <li class="fullscreen" *ngAuthorize="{keyName:'HELPLINK'}">
          <button mat-icon-button routerLink="/help" class="nav-notification-icons headerRightmenuIcon">
            <app-feather-icons [icon]="'help-circle'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>

        <!-- #START# profile Setting-->
        <ng-container *ngIf="showProfileSettings">
          <li *ngIf="loggedIn  && data$ | async as data" class="nav-item headerRightmenuOnlyProfileLi" ngbDropdown>
            <button ngbTooltip="Profile Settings" mat-icon-button ngbDropdownToggle
              class="nav-notification-icons headerRightmenuIcon">
              <app-feather-icons [icon]="'hexagon'" [class]="'header-icon'"></app-feather-icons>
            </button>
            <!-- <button matTooltip="Profile Settings " mat-icon-button ngbDropdownToggle
              class="nav-notification-icons headerRightmenuIcon">
              <app-feather-icons [icon]="'hexagon'" [class]="'header-icon'"></app-feather-icons>
            </button> -->
            <div ngbDropdownMenu class="notification-dropdown">
              <bzg-loading [show]="profileUpdating" [message]="'Updating...'"></bzg-loading>
              <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px" [perfectScrollbar]>
                <div class="list-group list-group-flush">
                  <div class="list-group-item">
                    <div class="list-group-title">
                      Profile Landing
                      <button matTooltip=" Select the landing page which you want to see post your login"
                        class="btn btn-basic tooltipHeader">
                        <i class="fas fa-info-circle"></i>
                      </button>
                    </div>
                    <ul class="nav nav-pills">
                      <ng-container *ngFor="let landingpage of data.profileSettings?.landingPagedata">
                        <ng-container *ngIf="landingpage.landingPageId == 5 || landingpage.landingPageId == 4">
                          <ng-container *ngAuthorize="{keyName:landingpage.landingPageId ==5 ?'GROUPS':'COMMUNITIES'}">
                            <li class="nav-item" *ngIf="landingpage.pageName != 'My Ecosystem'">
                              <a class="nav-link" [ngClass]="landingpage.isSelected ? ' active' : ''"
                                (click)="updateLandingPage(landingpage.landingPageId)">{{landingpage.pageName}}</a>
                            </li>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="landingpage.landingPageId != 5 && landingpage.landingPageId != 4">
                          <li class="nav-item" *ngIf="landingpage.pageName != 'My Ecosystem'">
                            <a class="nav-link" [ngClass]="landingpage.isSelected ? ' active' : ''"
                              (click)="updateLandingPage(landingpage.landingPageId)">{{landingpage.pageName}}</a>
                          </li>
                        </ng-container>
                      </ng-container>
                      <!-- <li class="nav-item">
                      <a class="nav-link active" href="#">Ecosystem</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Communities</a>
                    </li> -->
                    </ul>
                  </div>
                  <ng-container *ngAuthorize="{keyName:'DIGEST'}">
                    <div class="list-group-item">
                      <div class="list-group-title">
                        Communication Digest
                        <button
                          matTooltip="Select the option to receive unread messages from members and discussion posts, jobs, and events activities from communities daily or weekly in an email.  Change your preferences to  'None' if you don't want to get updates."
                          class="btn btn-basic tooltipHeader">
                          <i class="fas fa-info-circle"></i>
                        </button>
                      </div>
                      <ul class="nav nav-pills">
                        <li class="nav-item">
                          <a class="nav-link"
                            [ngClass]="data.profileSettings?.userSubscriptionVM?.recurrenceTypeID == 1? 'active' : ''"
                            (click)="changeSubscription(1 , data.profileSettings?.userSubscriptionVM?.id)">Daily </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link"
                            [ngClass]="data.profileSettings?.userSubscriptionVM?.recurrenceTypeID == 2? 'active' : ''"
                            (click)="changeSubscription(2 , data.profileSettings?.userSubscriptionVM?.id)">Weekly</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link"
                            [ngClass]="data.profileSettings?.userSubscriptionVM?.recurrenceTypeID == 0? 'active' : ''"
                            (click)="changeSubscription(0 , data.profileSettings?.userSubscriptionVM?.id)">None</a>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  <div class="list-group-item">
                    <div class="list-group-title">Profile Visibility
                      <button matTooltip="Manage your profile visibility" class="btn btn-basic tooltipHeader">
                        <i class="fas fa-info-circle"></i>
                      </button>
                    </div>
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a class="nav-link" [ngClass]="!data.profileSettings?.isPrivate ? ' active' : ''"
                          (click)="profilestatus(0)">Public
                        </a>
                      </li>
                      <li class="nav-item" *ngIf="!data.profileSettings?.isPrivate">
                        <a class="nav-link" [ngClass]="data.profileSettings?.isEmailPrivate ? ' active' : ''"
                          (click)="emailStatusUpdate(data.profileSettings?.isEmailPrivate)"><i
                            class="fas fa-envelope"></i>
                        </a>
                      </li>
                      <li class="nav-item" *ngIf="!data.profileSettings?.isPrivate">
                        <a class="nav-link" [ngClass]="data.profileSettings?.isPhonePrivate ? ' active' : ''"
                          (click)="phoneStatusUpdate(data.profileSettings?.isPhonePrivate)"><i
                            class="fas fa-phone-alt"></i>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" [ngClass]="data.profileSettings?.isPrivate ? ' active' : ''"
                          (click)="profilestatus(1)">Private</a>
                      </li>
                    </ul>
                  </div>
                  <ng-container *ngAuthorize="{keyName:'LOYALITY'}">
                    <div class="list-group-item loyality-points" [routerLink]="['/loyalty/loyalty-details']"
                      *ngIf="isLoyaltyEnabled | async">
                      <div class="d-flex align-items-center">
                        <div class="me-1">
                          <div class="list-group-title">Loyality Points</div>
                          <h4>{{data.profileSettings?.profileLoyaltyPoints?.name}}</h4>
                          <h5>
                            {{data.profileSettings?.profileLoyaltyPoints?.claimPoints}}/{{data.profileSettings?.profileLoyaltyPoints?.maxPoints}}
                          </h5>
                        </div>
                        <div class="ms-auto">
                          <img src="assets/images/header-golden-coin.png" class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
        <!-- #END# profile Setting-->
        <!-- #START# Notifications-->
        <li *ngIf="loggedIn" class="nav-item" ngbDropdown #myDrop="ngbDropdown">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons headerRightmenuIcon">
            <app-feather-icons [icon]="'bell'" [class]="'header-icon'"></app-feather-icons>
            <span class="label-count">{{notificationCount}}</span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px" [perfectScrollbar]>
              <ul class="menu">
                <li class="nfc-header">
                  <h5 class="mb-0">Notifications</h5>
                </li>
                <li *ngFor="let notification of notificationData let i=index; trackBy: discussionsTrackByFn">
                  <a (click)="isNavbarCollapsed = !isNavbarCollapsed" [ngClass]="notification.flagId == 1?'unread':''"
                    (click)="readNotification(notification)">
                    <span class="table-img msg-user" *ngIf="notification.taggedUserProfilePicturePath">
                      <img [src]="notification.taggedUserProfilePicturePath" alt="" />

                    </span>
                    <span class="table-img msg-user" *ngIf="!notification.taggedUserProfilePicturePath">

                      <ngx-letters-avatar [avatarName]="notification?.taggedUserFullName" [width]="32" [circular]="true"
                        fontFamily="Maven Pro">
                      </ngx-letters-avatar>
                    </span>
                    <span class="menu-info">
                      <!-- <span class="menu-title">{{
                        notification.userName
                        }}</span> -->
                      <span *ngIf="notification.notificationFor == 'Post'" class="menu-desc">{{
                        notification.taggedUserFullName }}<span> Mentioned you in a Post</span></span>
                      <span *ngIf="notification.notificationFor == 'Comments'" class="menu-desc">{{
                        notification.taggedUserFullName }}<span> Mentioned you in a comment</span></span>
                      <span *ngIf="notification.notificationFor == 'Commented'" class="menu-desc">{{
                        notification.taggedUserFullName }}<span> Commented on your subscribed Post</span></span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i>
                        {{ notification.tagedDateUTC | clientConfigDate: true}}
                      </span>
                      <!-- <span class="menu-desc">{{ notification.message }}</span> -->
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <a class="text-center d-block border-top py-1" href="#" onClick="return false;">Read All Notifications</a> -->
          </div>
        </li>
        <!-- #END# Notifications-->
        <li *ngIf="loggedIn" class="nav-item user_profile" ngbDropdown>
          <a href="javascript:void(0)" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <ng-container *ngIf="userData?.userName?.length>2 &&userData?.profilePicture == null">
              <ngx-letters-avatar [avatarName]="userData?.userName" [width]="32" [circular]="true"
                fontFamily="Maven Pro">
              </ngx-letters-avatar>
            </ng-container>
            <img *ngIf="currentUser && userData?.profilePicture !== null" [src]="userData?.profilePicture"
              class="rounded-circle" width="32" height="32" alt="User" />
            <div class="user_profileBody">
              <h4 [matTooltipDisabled]="
                  userData?.userName <= maxLengthForName
                " [matTooltip]="userData?.userName" class="user_profileName">
                {{ userData?.userName | stringLimitTo: maxLengthForName }}
              </h4>
              <!-- <p class="user_profileLevel">{{ currentUser.extension_Role }}</p> -->
            </div>
          </a>
          <div ngbDropdownMenu auto class="notification-dropdown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="isTA && !tAScreen">
                      <a ngbDropdownItem routerLink="/admin/dashboard" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <!-- <app-feather-icons [icon]="'dashboard'" [class]="'user-menu-icons'"></app-feather-icons> -->
                        <i class="material-icons user-menu-icons"> admin_panel_settings
                        </i>
                        {{'profile-menu.tenant-admin-module' | translate}}
                      </a>
                    </li>
                    <li *ngIf="isTA && tAScreen">
                      <a ngbDropdownItem href="#" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <!-- <app-feather-icons [icon]="'dashboard'" [class]="'user-menu-icons'"></app-feather-icons> -->
                        <i class="material-icons user-menu-icons"> admin_panel_settings
                        </i>
                        {{'profile-menu.user-module' | translate}}
                      </a>
                    </li>
                    <li>
                      <a ngbDropdownItem routerLink="/dashboard" (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <!-- <app-feather-icons [icon]="'dashboard'" [class]="'user-menu-icons'"></app-feather-icons> -->
                        <i class="material-icons user-menu-icons">dashboard</i>
                        {{'profile-menu.dashboard' | translate}}
                      </a>
                    </li>
                    <li *ngAuthorize="{keyName:'LMS'}">
                      <a ngbDropdownItem [href]="collerUrl | async" target="_blank"
                        (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <i class="material-icons user-menu-icons">school</i>
                        {{'profile-menu.collear' | translate}}
                      </a>
                    </li>
                    <li>
                      <a ngbDropdownItem routerLink="/profile/myprofile"
                        (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>
                        {{'profile-menu.my-profile' | translate}}
                      </a>
                    </li>
                    <li>
                      <a ngbDropdownItem routerLink="/profile/organization"
                        (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <!-- <app-feather-icons [icon]="'domain'" [class]="'material-icons'"></app-feather-icons> -->
                        <i class="material-icons user-menu-icons">domain</i>
                        {{'profile-menu.organization' | translate}}
                      </a>
                    </li>
                    <!-- <li>
                      <a ngbDropdownItem href="#" onClick="return false;">
                        <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                      </a>
                    </li> -->
                    <!-- <li>
                      <a ngbDropdownItem href="javascript:void(0);" (click)="changePassword()">
                        <app-feather-icons [icon]="'key'" [class]="'user-menu-icons'"></app-feather-icons>
                        Change Password 2
                      </a>
                    </li> -->
                    <li>
                      <a ngbDropdownItem routerLink="/profile/change-password"
                        (click)="isNavbarCollapsed = !isNavbarCollapsed">
                        <app-feather-icons [icon]="'key'" [class]="'user-menu-icons'"></app-feather-icons>
                        {{'profile-menu.change-password' | translate}}
                      </a>
                    </li>
                    <li>
                      <a ngbDropdownItem href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>
                        {{'profile-menu.logout' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li *ngIf="!loggedIn" class="nav-item navSingnIn-item">
          <button (click)="login()" mat-flat-button color="primary">Sign In</button>
          <!-- <button (click)="login()" mat-button>Sign In</button> -->
        </li>
        <ng-container *ngIf="isSignUpEnabled | async">
          <li *ngIf="!loggedIn" class="nav-item navSingnIn-item">
            <button routerLink="/auth/signup" mat-flat-button color="warn">Sign Up</button>
            <!-- <button routerLink="/auth/signup" mat-button>Sign Up</button> -->
          </li>
        </ng-container>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>