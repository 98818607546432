import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UserInCommunityStatus } from 'src/app/services/community/community.service';
import { CommunityInfoDetails, CommunityWidgetsDto, CommuntyUserStatusDto, MemberShipPlanDetails } from '../state/community-details';

export const LoadCommunityInfo = createAction('[Community Details] Load CommunityInfo', props<{ communityId: number }>());
export const LoadCommunityInfoSuccess = createAction('[Community Details] Load CommunityInfo Success', props<{ communityinfo: CommunityInfoDetails }>());
export const LoadCommunityInfoError = createAction('[Community Details] Load CommunityInfo Error', props<{ error: HttpErrorResponse }>());

export const LoadCommunityUserInfo = createAction('[Community User Details] Load CommunityUser Info', props<{ communityId: number }>());
export const LoadCommunityUserInfoSuccess = createAction('[Community User Details] Load Community User Info Success', props<{ communityUserStatusinfo: CommuntyUserStatusDto }>());
export const LoadCommunityUserInfoError = createAction('[Community User Details] Load Community User Info Error', props<{ error: HttpErrorResponse }>());

export const LoadMemberShipPlanDetails = createAction('[Community Details] Load MemberShipPlanDetails', props<{ communityId: number }>());
export const LoadMemberShipPlanDetailsSuccess = createAction('[Community Details] Load MemberShipPlanDetails Success', props<{ membershipplandetails: MemberShipPlanDetails }>());
export const LoadMemberShipPlanDetailsError = createAction('[Community Details] Load MemberShipPlanDetails Error', props<{ error: HttpErrorResponse }>());

export const JoinOrLeaveCommunity = createAction('[Community Details] Join or Leave Community', props<{ communityId: number, status: UserInCommunityStatus, userId?:number }>());
export const JoinOrLeaveCommunitySuccess = createAction('[Community Details] Join or Leave Community success', props<{ status: UserInCommunityStatus }>());
export const JoinOrLeaveCommunityError = createAction('[Community Details] Join or Leave Community error', props<{ error: HttpErrorResponse }>());

export const LoadWidgetsInfo = createAction('[Load Widgets Info] Load Widgets Info', (communityId:number)=>({communityId}) );
export const LoadWidgetsInfoSuccess = createAction('[Load Widgets Info] Load Widgets success', props<{ widgetsInfo: CommunityWidgetsDto[] }>());
export const LoadWidgetsInfoError = createAction('[Load Widgets Info] Load Widgets error', props<{ error: HttpErrorResponse }>());