import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';

@Pipe({
    name: 'currencyformatter'
})
export class CurrencyFormatterPipe implements PipeTransform {
    public _local: string;
    constructor(@Inject(LOCALE_ID) private locale: string) {
        this._local = 'en-GB'//locale;
    }
    transform(value: string): string {

        //console.log(this._local);
        // var thousandSeparator = Intl.NumberFormat(this._local).format(11111).replace(/\p{Number}/gu, '');
        // var decimalSeparator = Intl.NumberFormat(this._local).format(1.1).replace(/\p{Number}/gu, '');

        // return value
        //   .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        //   .replace(new RegExp('\\' + decimalSeparator), '.')

       let v=  new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'INR',
          }).format(Number(value)); // '€ 10,000.00'

        let replaceCurrencyValue = "";
        switch (this._local) {
            case 'en-GB':
                replaceCurrencyValue = value.replace(/\./g, '').replace(',', '.');
                break;

            default:
                break;
        }
        
        return replaceCurrencyValue;

    }

}
