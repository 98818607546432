import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class ContactUsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('contact-us.send-us-your-questions-comments', 'Send us your questions or comments using the form below. We will respond promptly to your inquiry.', 'en');
    this.translateService.set('contact-us.name', 'Name', 'en');
    this.translateService.set('contact-us.organization', 'Organization', 'en');
    this.translateService.set('contact-us.mailing-address', 'Mailing Address', 'en');
    this.translateService.set('contact-us.contact-preference', 'Contact Preference', 'en');
    this.translateService.set('contact-us.email', 'Email', 'en');
    this.translateService.set('contact-us.phone', 'Phone', 'en');
    this.translateService.set('contact-us.phone-no', 'Phone No', 'en');
    this.translateService.set('contact-us.email-id', 'Email ID', 'en');
    this.translateService.set('contact-us.comments-questions', 'Comments / Questions', 'en');
    this.translateService.set('contact-us.yes-you-may-release-this-information', 'Yes, you may release this information.', 'en');
    this.translateService.set('contact-us.no-please-do-not-release-this-information', 'No, please do not release this information.', 'en');
    this.translateService.set('contact-us.reset', 'Reset', 'en');
    this.translateService.set('contact-us.send', 'Send', 'en');
    this.translateService.set('contact-us.please-enter-valid-name', 'Please Enter Valid Name', 'en');
    this.translateService.set('contact-us.please-enter-valid-organization-name', 'Please Enter Valid Organization Name', 'en');
    this.translateService.set('contact-us.bizligo-demo', 'The Bizligo Demo will not sell your contact or email. In order to serve your request,our office shares information with chamber member business who can provide you with additional information.Please indicate below if we may share your request for information.', 'en');
    this.translateService.set('contact-us.contact-us', 'CONTACT US', 'en');
    this.translateService.set('contact-us.your-enquiry-recieved-we-will-respond-to-you-shortly','Your Enquiry Recieved. We will respond to you shortly.','en');
    this.translateService.set('contact-us.saving','Saving...','en');
    this.translateService.set('contact-us.comments','comments','en');

    return this.localResourceService.loadLocalResource("contact-us").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
