import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SimilarBusiness } from 'src/app/module/dashboard/store/state/member-dashboard';
import { TinySliderInstance, TinySliderSettings, tns } from 'tiny-slider/src/tiny-slider';

@Component({
  selector: 'bzg-corwa-home',
  templateUrl: './corwa-home.component.html',
  styleUrls: ['./corwa-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CorwaHomeComponent implements OnInit, OnChanges, OnDestroy {

  isSliderInitialized: boolean = false;
  slider: TinySliderInstance;
  settings: TinySliderSettings = {
    container: '.sponsers-corwa-slider',
    items: 3,
    slideBy: 3,
    mouseDrag: true,
    autoplay: true,
    nav: false,
    gutter: 15,
    loop: true,
    edgePadding: 10,
    autoplayButtonOutput: false,
    controlsText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        slideBy: 1,
        items: 1,
      },
      768: {
        slideBy: 4,
        items: 4,
      },
      1200: {
        slideBy: 5,
        items: 5,
        gutter: 30,
      },
      1600: {
        slideBy: 5,
        items: 5,
        gutter: 30,
      }
    }
  }
  sponsors: any[] = []
  constructor() { }

  ngOnInit(): void {
    this.sponsors = [{
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor1.png",
      link: "https://www.rwjbh.org/rwj-university-hospital-new-brunswick/"
    },
    {
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor2.png",
      link: "https://bizligo.com/"
    },
    {
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor3.png",
      link: "https://www.enorthfield.com/"
    },
    {
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor4.png",
      link: "https://www.fiserv.com/en.html"
    },
    {
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor5.png",
      link: "https://www.primepoint.com/"
    },
    {
      image: "https://corwaadmin.bizligo.com/content/images/totalentertainment/sponsor6.png",
      link: "https://www.primepoint.com/"
    },
    ]
  }
  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }
  similarBusinessTrackByFn(index: number, item: SimilarBusiness) {
    return item.memberID;
  }

  initSlider() {

    if (this.isSliderInitialized) return;
    this.isSliderInitialized = true;
    if (this.slider) {
      this.slider.destroy();
    }
    setTimeout(() => {
      this.slider = tns(this.settings)
    }, 10);
    //console.log("init slider completed")
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isSliderInitialized = false;
  }
}
