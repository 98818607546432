import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class JobDetailsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('job-details.my-applications', 'My Applications', 'en');
    this.translateService.set('job-details.list-of-jobs', 'List of Jobs', 'en');
    this.translateService.set('job-details.loading', 'Loading...', 'en');
    this.translateService.set('job-details.job-title', 'Job Title', 'en');
    this.translateService.set('job-details.location', 'Location', 'en');
    this.translateService.set('job-details.last-updated-date', 'last updated date', 'en');
    this.translateService.set('job-details.applied-date', 'applied date', 'en');
    this.translateService.set('job-details.job-type', 'Job Type', 'en');
    this.translateService.set('job-details.full-time', 'Full Time', 'en');
    this.translateService.set('job-details.part-time', 'Part Time', 'en');
    this.translateService.set('job-details.contract', 'Contract', 'en');
    this.translateService.set('job-details.contract-to-hire', 'Contract to Hire', 'en');
    this.translateService.set('job-details.already-applied', 'Already Applied', 'en');
    this.translateService.set('job-details.job-description', 'Job Description', 'en');
    this.translateService.set('job-details.additional-details', 'Additional Details', 'en');
    this.translateService.set('job-details.remarks', 'Remarks', 'en');
    // latest changes
    this.translateService.set('job-details.this-job-is-no-longer-active', 'This job is no longer active', 'en');
    this.translateService.set('job-details.company', 'Company', 'en');
    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("job-details").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }  
}
