<!-- Begin Page Content -->
<!-- <div class="container-fluid">

  <div class="text-center">
    <div class="error mx-auto" data-text="404">404</div>
    <p class="lead text-gray-800 mb-5">Page Not Found</p>
    <p class="text-gray-500 mb-0">
      It looks like you found a glitch in the matrix...
    </p>
    <a routerLink="/">&larr; Back to Home</a>
  </div>
</div> -->
<!-- /.container-fluid -->
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form">
        <span class="error-header p-b-45"> 404 </span>
        <span class="error-subheader p-b-5"> Looks Like You're Lost </span>
        <span class="error-subheader2 p-b-5" *ngIf="!isFromProfile">
          The Page You Are Looking For Not Available!
        </span>
        <span class="error-subheader2 p-b-5" *ngIf="isFromProfile">
         Sorry, the profile details not found.
        </span>
        <div class="container-login100-form-btn p-t-30">
          <a class="btn py-3 w-100 btn-theme" routerLink="/">
            Go To Home Page
          </a>
        </div>
        <div class="w-full p-t-15 p-b-15 text-center">
          <div>
            <a href="#" class="txt1" routerLink="/help"> Need Help? </a>
          </div>
        </div>
      </form>
      <div
        class="login100-more"
        style="background-image: url('assets/images/error/404.png')"
      ></div>
    </div>
  </div>
</div>
