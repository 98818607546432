import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Actions, createEffect , ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, catchError, of } from 'rxjs';
import { AdvertisementService } from 'src/app/services/advertisement/advertisement.service';
import * as fromActions from './../action/my-advertisements.actions';


@Injectable()
export class MyAdvertisementsEffects {


  constructor(private actions$: Actions,
    private service : AdvertisementService) {}
  LoadMyAdvertisementInfo$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadMyAdvertisementInfo),
    mergeMap(param => this.service.getMyAdvertisements(param.query).pipe(
      map(data => {
        return fromActions.LoadMyAdvertisementInfoSuccess({ Advertisementsinfo: data })
      }),
      catchError(err => of(fromActions.LoadMyAdvertisementInfoError({ error: err })))
    )))
  );
  LoadMoreMyAdvertisementInfo$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.LoadMoreMyAdvertisementInfo),
    mergeMap(param => this.service.getMyAdvertisements(param.query).pipe(
      map(data => {
        return fromActions.LoadMoreMyAdvertisementInfoSuccess({ Advertisementsinfo: data })
      }),
      catchError(err => of(fromActions.LoadMoreMyAdvertisementInfoError({ error: err })))
    )))
  );

  //promotion plans //
  // LoadPromotionPlansInfo$ = createEffect(() => this.actions$.pipe(
  //   ofType(fromActions.LoadPromotionPlansInfo),
  //   mergeMap(param => this.service.getPromotionPlans(param.query).pipe(
  //     map(data => {
  //       return fromActions.LoadPromotionPlansInfoSuccess({ Promotionsinfo: data })
  //     }),
  //     catchError(err => of(fromActions.LoadPromotionPlansInfoError({ error: err })))
  //   )))
  // );
}
