import { Action, createReducer, on } from '@ngrx/store';
import { ProfileState, SkillsOrInterestsState } from '../state/profile';
import * as fromActions from '../action/profile.actions';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactsState, OrganizationState } from '../state/organization';
import { EndorsementsandNotesState } from '../state/endorsements';

export const profileFeatureKey = 'profile';

export interface State {

}

export const initialState: ProfileState = {
  skills: null,
  interests: null,
  additionalInfo: null,
  userInfo: null,
  profileCompletenessInfo: null,
  similarSandIuserInfo: null
};

export const orginitialState: OrganizationState = {

  LocationInfo: null,
  ProfessionalMatchesInfo: null,
  OrganizationInfo: null
};

export const contactsinitialState: ContactsState = {
  contactsList: null,
  error: null,
  loading: false,
  loaded: false
};
export const skillsinitialState: SkillsOrInterestsState = {
  skillsOrInterestsData: null,
  error: null,
  loading: false,
  loaded: false
};
export const endorsementsinitialState: EndorsementsandNotesState = {

  endorsements: null,
  notes: null,
};
export const reducer = createReducer<ProfileState>(
  initialState,
  on(fromActions.LoadProfileData, (state) => ({
    ...state,
    skills: {
      error: null,
      skillsList: state.skills?.skillsList,
      loaded: state.skills?.loaded,
      loading: true
    },
    interests: {
      error: null,
      interestList: state.interests?.interstsList,
      loaded: state.interests?.loaded,
      loading: true
    },
    additionalInfo: {
      error: null,
      loaded: state.additionalInfo?.loaded,
      loading: true,
      additionalInfoList: state.additionalInfo?.additionalInfoList
    },
    userInfo: {
      error: null,
      loaded: state.userInfo?.loaded,
      loading: true,
      userInfoList: state.userInfo?.userInfoList
    },
    profileCompletenessInfo: {
      error: null,
      loaded: state.profileCompletenessInfo?.loaded,
      loading: true,
      UserProfileCompletenessInfo: state.profileCompletenessInfo?.UserProfileCompletenessInfo
    },
    similarSandIuserInfo: {
      error: null,
      loaded: state.profileCompletenessInfo?.loaded,
      loading: true,
      similarInterestUsers: state.similarSandIuserInfo?.similarInterestUsers,
    }
  })),
  on(fromActions.LoadSkillsDataSuccess, (state, { skills }) => ({
    ...state,
    skills: {
      error: null,
      skillsList: skills,
      loaded: true,
      loading: false
    }
  })),
  on(fromActions.LoadSkillsDataError, (state, { error }) => ({
    ...state,
    skills: {
      error: error,
      skillsList: null,
      loaded: true,
      loading: false
    }
  })),
  on(fromActions.LoadInterestsDataSuccess, (state, { interests }) => ({
    ...state,
    interests: {
      error: null,
      loaded: true,
      loading: false,
      interstsList: interests
    }

  })),
  on(fromActions.LoadInterestsDataError, (state, { error }) => ({
    ...state,
    interests: {
      error: error,
      loaded: true,
      loading: false,
      interstsList: null
    }

  })),
  on(fromActions.RemoveProfileCategory, (state, { name }) => ({
    ...state,
    additionalInfo: {
      error: null,
      loaded: true,
      loading: false,
      additionalInfoList: {
        portfolio: state.additionalInfo.additionalInfoList.portfolio,
        whoAmI: state.additionalInfo.additionalInfoList.whoAmI.filter(s => s.name != name)
      }
    }
  })),
  on(fromActions.LoadAdditionalInfoSuccess, (state, { additionalInfo }) => ({
    ...state,
    additionalInfo: {
      error: null,
      loaded: true,
      loading: false,
      additionalInfoList: additionalInfo
    }
  })),
  on(fromActions.LoadAdditionalInfoError, (state, { error }) => ({
    ...state,
    additionalInfo: {
      error: error,
      loaded: true,
      loading: false,
      additionalInfoList: null
    }

  })),
  on(fromActions.LoadUserInfoSuccess, (state, { userInfo }) => ({
    ...state,
    userInfo: {
      error: null,
      loaded: true,
      loading: false,
      userInfoList: userInfo
    }

  })),
  on(fromActions.LoadUserInfoError, (state, { error }) => ({
    ...state,
    userInfo: {
      error: error,
      loaded: true,
      loading: false,
      userInfoList: null
    }
  })),
  
  on(fromActions.LoadProfileCompletenessSuccess, (state, { profileCompletenessInfo }) => ({
    ...state,
    profileCompletenessInfo: {
      error: null,
      loaded: true,
      loading: false,
      UserProfileCompletenessInfo: profileCompletenessInfo
    }

  })),
  on(fromActions.LoadProfileCompletenessError, (state, { error }) => ({
    ...state,
    profileCompletenessInfo: {
      error: error,
      loaded: true,
      loading: false,
      UserProfileCompletenessInfo: null
    }
  })),
  on(fromActions.LoadSimillarUsersSuccess, (state, { simillarSandIUsersInfo }) => ({
    ...state,
    similarSandIuserInfo: {
      error: null,
      loaded: true,
      loading: false,
      similarInterestUsers: simillarSandIUsersInfo
    }

  })),
  on(fromActions.LoadSimillarUsersError, (state, { error }) => ({
    ...state,
    similarSandIuserInfo: {
      error: error,
      loaded: true,
      loading: false,
      similarSandIuserInfo: null
    }
  })),
  on(fromActions.LoadMoreSimillarUsers, (state, { query }) => ({
    ...state,
    similarSandIuserInfo: {

      error: null,
      loaded: false,
      loading: true,
      similarInterestUsers: state.similarSandIuserInfo.similarInterestUsers
    }
  })),
  on(fromActions.LoadMoreSimillarUsersSuccess, (state, { simillarSandIUsersInfo }) => ({
    ...state,
    similarSandIuserInfo: {
     
      error: null,
      loaded: true,
      loading: false,
      similarInterestUsers: {
        pageNumber: simillarSandIUsersInfo.pageNumber,
        pageSize: simillarSandIUsersInfo.pageSize,
        myMatchesList: [...state.similarSandIuserInfo.similarInterestUsers.myMatchesList, ...simillarSandIUsersInfo.myMatchesList],
        totalRecords: simillarSandIUsersInfo.totalRecords
      }
    }

  })),
  on(fromActions.LoadMoreSimillarUsersError, (state, { error }) => ({
    ...state,
    similarSandIuserInfo: {
     
      error: error,
      loaded: false,
      loading: false,
      similarInterestUsers: state.similarSandIuserInfo.similarInterestUsers
    }
  })),
);


export const orgreducer = createReducer<OrganizationState>(
  orginitialState,
  on(fromActions.LoadOraganizationData, (state) => ({
    ...state,
    ContactsInfo: {
      error: null,
      contactsList: null,
      loaded: false,
      loading: true
    },
    LocationInfo: {
      error: null,
      loaded: false,
      loading: true,
      locationList: null
    },
    ProfessionalMatchesInfo: {
      error: null,
      loaded: false,
      loading: true,
      ProfessionalMatchesList: null
    },
    OrganizationInfo: {
      error: null,
      loaded: false,
      loading: true,
      OrganizationList: null
    }

  })),
  on(fromActions.LoadContactsDataSuccess, (state, { contacts }) => ({
    ...state,
    ContactsInfo: {
      error: null,
      loaded: true,
      loading: false,
      contactsList: contacts
    }
  })),
  on(fromActions.LoadContactsDataError, (state, { error }) => ({
    ...state,
    ContactsInfo: {
      error: error,
      loaded: true,
      loading: false,
      contactsList: null
    }
  })),
  on(fromActions.LoadLocationsDataSuccess, (state, { locations }) => ({
    ...state,
    LocationInfo: {
      error: null,
      loaded: true,
      loading: false,
      locationList: locations
    }
  })),
  on(fromActions.LoadLocationsDataError, (state, { error }) => ({
    ...state,
    LocationInfo: {
      error: error,
      loaded: true,
      loading: false,
      locationList: null
    }
  })),
  on(fromActions.LoadProfessionalMatchesSuccess, (state, { professionamatches }) => ({
    ...state,
    ProfessionalMatchesInfo: {
      error: null,
      loaded: true,
      loading: false,
      ProfessionalMatchesList: professionamatches
    }
  })),
  on(fromActions.LoadLocationsDataError, (state, { error }) => ({
    ...state,
    ProfessionalMatchesInfo: {
      error: error,
      loaded: true,
      loading: false,
      ProfessionalMatchesList: null
    }
  })),
  on(fromActions.RemoveBusinessCategories, (state, { businessCategoryId }) => ({
    ...state,
    OrganizationInfo: {
      error: null,
      loaded: true,
      loading: false,
      OrganizationList: {
        ...state.OrganizationInfo.OrganizationList,
        businessCategories: state.OrganizationInfo.OrganizationList.businessCategories.filter(s => s.id != businessCategoryId)
      }
    }
  })),
  on(fromActions.AddBusinessCategories, (state, { businessCategory }) => ({
    ...state,
    OrganizationInfo: {
      error: null,
      loaded: true,
      loading: false,
      OrganizationList: {
        ...state.OrganizationInfo.OrganizationList,
        businessCategories: [...state.OrganizationInfo.OrganizationList.businessCategories.filter(s => s.id != businessCategory.id), businessCategory]
      }
    }
  })),
  on(fromActions.RemoveBusinessType, (state, { businessTypeId }) => ({
    ...state,
    OrganizationInfo: {
      ...state.OrganizationInfo,
      OrganizationList: {
        ...state.OrganizationInfo.OrganizationList,
        businessTypes: state.OrganizationInfo.OrganizationList.businessTypes.filter(s => s.id != businessTypeId)
      }
    }
  })),
  on(fromActions.AddBusinessType, (state, { businessType }) => ({
    ...state,
    OrganizationInfo: {
      ...state.OrganizationInfo,
      OrganizationList: {
        ...state.OrganizationInfo.OrganizationList,
        businessTypes: [...state.OrganizationInfo.OrganizationList.businessTypes.filter(s => s.id != businessType.id), businessType]
      }
    }
  })),
  on(fromActions.LoadOraganizationDataSuccess, (state, { organization }) => ({
    ...state,
    OrganizationInfo: {
      error: null,
      loaded: true,
      loading: false,
      OrganizationList: organization
    }
  })),
  on(fromActions.LoadOraganizationDataError, (state, { error }) => ({
    ...state,
    OrganizationInfo: {
      error: error,
      loaded: true,
      loading: false,
      OrganizationList: null
    }
  })),
);

export const contactsreducer = createReducer<ContactsState>(
  contactsinitialState,
  on(fromActions.LoadContactsData, (state) => ({
    ...state,

    error: null,
    contactsList: null,
    loaded: false,
    loading: true
  })),
  on(fromActions.LoadContactsDataSuccess, (state, { contacts }) => ({
    ...state,
    error: null,
    loaded: true,
    loading: false,
    contactsList: contacts
  })),
  on(fromActions.LoadContactsDataError, (state, { error }) => ({
    ...state,
    error: error,
    loaded: true,
    loading: false,
    contactsList: null
  })),

);

export const skillsreducer = createReducer<SkillsOrInterestsState>(
  skillsinitialState,
  on(fromActions.LoadSkillsOrInterests, (state) => ({
    ...state,

    error: null,
    skillsOrInterestsData: null,
    loaded: false,
    loading: true
  })),
  on(fromActions.LoadSkillsOrInterestsSuccess, (state, { skillsorinterestsinfo }) => ({
    ...state,
    error: null,
    loaded: true,
    loading: false,
    skillsOrInterestsData: skillsorinterestsinfo
  })),
  on(fromActions.LoadSkillsOrInterestsError, (state, { error }) => ({
    ...state,
    error: error,
    loaded: true,
    loading: false,
    skillsOrInterestsData: null
  })),

);

export const endorsementsreducer = createReducer<EndorsementsandNotesState>(
  endorsementsinitialState,
  on(fromActions.LoadEndorsementsandNotesData, (state) => ({
    ...state,
    endorsements: {
      error: null,
      endorsementsList: null,
      loaded: false,
      loading: true
    },
    notes: {
      error: null,
      loaded: false,
      loading: true,
      notesList: null
    },
    
  })),
  on(fromActions.LoadEndorsementsSuccess, (state, { endorsements }) => ({
    ...state,
    endorsements: {
      error: null,
      loaded: true,
      loading: false,
      endorsementsList: endorsements
    }
  })),
  on(fromActions.LoadEndorsementsError, (state, { error }) => ({
    ...state,
    endorsements: {
      error: error,
      loaded: true,
      loading: false,
      endorsementsList: null
    }
  })),
  on(fromActions.LoadNotesSuccess, (state, { notes }) => ({
    ...state,
    notes: {
      error: null,
      loaded: true,
      loading: false,
      notesList: notes
    }
  })),
  on(fromActions.LoadNotesError, (state, { error }) => ({
    ...state,
    notes: {
      error: error,
      loaded: true,
      loading: false,
      notesList: null
    }
  })),
  // on(fromActions.LoadLocationsDataSuccess, (state, { locations }) => ({
  //   ...state,
  //   LocationInfo: {
  //     error: null,
  //     loaded: true,
  //     loading: false,
  //     locationList: locations
  //   }
  // })),
  // on(fromActions.LoadLocationsDataError, (state, { error }) => ({
  //   ...state,
  //   LocationInfo: {
  //     error: error,
  //     loaded: true,
  //     loading: false,
  //     locationList: null
  //   }
  // })),
  
);