import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { MostSubscribedDiscussions } from 'src/app/module/community-details/store/state/community-discussions';
import { like, discussionData } from 'src/app/module/shared/discussions/discussions-list/discussions-list.component';

import { GroupDiscussionSearch, GroupDiscussionDetails, GroupDiscussionCommentsSearch, GroupDiscussionCommentsResult, GroupDiscussionLikesSearch, GroupDiscussionsLikes, recentDiscussions, recentdiscussionSearch, trendingDiscussions, GroupDiscussionDetailsInfo, GroupDiscussionComments, GroupAdditionalInfoDto, GroupAdminDto, GroupWidgetsDto, GroupJoinRequests } from '../state/group-discussion';

export const LoadGroupDiscussionsData = createAction('[Group Discussions Data] Load Group Discussions Data', (query: GroupDiscussionSearch) => ({ query }));
export const LoadGroupDiscussionsSuccess = createAction('[Group Discussions Data] Load Group Discussions Data success', props<{ discussions: GroupDiscussionDetailsInfo }>());
export const LoadGroupDiscussionsError = createAction('[Group Discussions Data] Load Group Discussions Data error', props<{ error: HttpErrorResponse }>());

export const LoadMoreGroupDiscussionsData = createAction('[Group Discussions] Load More results Group Discussions ', (query: GroupDiscussionSearch) => ({ query }));
export const LoadMoreGroupDiscussionsSuccess = createAction('[Group Discussions] Load More results Group Discussions  success', props<{ discussions: GroupDiscussionDetailsInfo }>());
export const LoadMoreGroupDiscussionsError = createAction('[Group Discussions] Load More results Search Group Discussions  error', props<{ error: HttpErrorResponse }>());

export const deleteGroupDiscusion = createAction('[Delete Community Discussion] delete community Discussion', props<{data:GroupDiscussionDetails}>())
export const deleteGroupDiscusionComment = createAction('[Delete Community Discussion Comment] delete community Discussion Comment', props<{data:GroupDiscussionComments}>())

export const LikeOrUnlikeDiscussion = createAction('[Group Discussions] Like or unlike Group discussion', props<{ data: like }>());

export const LikeOrUnlikeDiscussionComment = createAction('[Group Discussions Comment] Like or unlike Group discussion comment', props<{ data: like }>());

export const SubscribeorUnsubscribeGroupDiscussion =  createAction('[Group Discussions Subscription] subscribe or unsubscribe Group discussion comment', props<{ data: discussionData }>());

export const LoadGroupDiscussionsCommentsData = createAction('[Group Discussions Comments Data] Load Group Discussions Comments Data', (query: GroupDiscussionCommentsSearch) => ({ query }));
export const LoadGroupDiscussionsCommentsSuccess = createAction('[Group Discussions Comments Data] Load Group Discussions Data Comments success', props<{ discussionComments: GroupDiscussionCommentsResult, query: GroupDiscussionCommentsSearch }>());
export const LoadGroupDiscussionsCommentsError = createAction('[Group Discussions Comments Data] Load Group Discussions Data Comments error', props<{ error: HttpErrorResponse, query: GroupDiscussionCommentsSearch }>());

export const LoadMoreGroupDiscussionsCommentsData = createAction('[More Group Discussions Comments] Load More results Group Discussions Comments', (query: GroupDiscussionCommentsSearch) => ({ query }));
export const LoadMoreGroupDiscussionsCommentsSuccess = createAction('[More Group Discussions Comments] Load More results Group Discussions Comments  success', props<{ discussionComments: GroupDiscussionCommentsResult, query: GroupDiscussionCommentsSearch }>());
export const LoadMoreGroupDiscussionsCommentsError = createAction('[More Group Discussions Comments] Load More results Search Group Discussions Comments  error', props<{ error: HttpErrorResponse, query: GroupDiscussionCommentsSearch }>());

export const updateGroupCommentsCount = createAction('[update Group Comment Count] Update Group comment count',(query:number)=>({query}));

export const LoadGroupDiscussionsLikesData = createAction('[Group Discussions Likes Data] Load Group Discussions Likes Data', (query: GroupDiscussionLikesSearch) => ({ query }));
export const LoadGroupDiscussionsLikesSuccess = createAction('[Group Discussions Likes Data] Load Group Discussions Data Likes success', props<{ discussionLikes: GroupDiscussionsLikes[], query: GroupDiscussionLikesSearch }>());
export const LoadGroupDiscussionsLikesError = createAction('[Group Discussions Likes Data] Load Group Discussions Data Likes error', props<{ error: HttpErrorResponse, query: GroupDiscussionLikesSearch }>());


export const LoadGroupDiscussionsCommentLikesData = createAction('[Group Discussions Comment Likes Data] Load Group Discussions Comment Likes Data', (query: GroupDiscussionLikesSearch) => ({ query }));
export const LoadGroupDiscussionsCommentLikesSuccess = createAction('[Group Discussions Comment Likes Data] Load Group Discussions Comment Likes success', props<{ discussionLikes: GroupDiscussionsLikes[], query: GroupDiscussionLikesSearch }>());
export const LoadGroupDiscussionsCommentLikesError = createAction('[Group Discussions Comment Likes Data] Load Group Discussions Comment Likes error', props<{ error: HttpErrorResponse, query: GroupDiscussionLikesSearch }>());


export const updateGroupDeletedCommentsCount = createAction('[update Group deleted Comment Count] Update Group deleted comment count',(query:number)=>({query}));

// export const LoadGroupDiscussionsCommentsData = createAction('[Group Discussions Comments Data] Load Group Discussions Comments Data', (query: GroupDiscussionCommentsSearch) => ({ query }));
// export const LoadGroupDiscussionsCommentsSuccess = createAction('[Group Discussions Comments Data] Load Group Discussions Data Comments success', props<{ discussionComments: GroupDiscussionComments[] }>());
// export const LoadGroupDiscussionsCommentsError = createAction('[Group Discussions Comments Data] Load Group Discussions Data Comments error', props<{ error: HttpErrorResponse }>());

export const LoadRecentGroupDiscussionsData = createAction('[Recent Group Discussions Data] Load Recent Group Discussions  Data', (query: recentdiscussionSearch, ) => ({ query }));
export const LoadRecentGroupDiscussionsSuccess = createAction('[Recent Group Discussions  Data] Load Recent Group Discussions Data success', props<{ RecentDiscussion: recentDiscussions[] }>());
export const LoadRecentGroupDiscussionsError = createAction('[Recent Group Discussions Data] Load Recent Group Discussions Data error', props<{ error: HttpErrorResponse }>());

export const LoadTrendingGroupDiscussionsData = createAction('[Trending Group Discussions Data] Trending Group Discussions  Data', (query: recentdiscussionSearch) => ({ query }));
export const LoadTrendingGroupDiscussionsSuccess = createAction('[Trending Group Discussions  Data] Trending Group Discussions Data success', props<{ trendingDiscussions: trendingDiscussions[] }>());
export const LoadTrendingGroupDiscussionsError = createAction('[Trending Group Discussions Data] Trending Group Discussions Data error', props<{ error: HttpErrorResponse }>());

export const MostSubscribedGroupDiscussionsData = createAction('[Most Subscribed Group Discussions Data] Load Most Subscribed Group Discussions  Data', (query: number) => ({ query }));
export const MostSubscribedGroupDiscussionsSuccess = createAction('[Most Subscribed Group Discussions  Data] Load Most Subscribed Group Discussions Data success', props<{ MostSubscribedDiscussion: MostSubscribedDiscussions[] }>());
export const MostSubscribedGroupDiscussionsError = createAction('[Most Subscribed Group Discussions Data] Load Most Subscribed Group Discussions Data error', props<{ error: HttpErrorResponse }>());

export const LoadAdditionalData = createAction('[Load Additional Data] Load Additional Data', (query: number) => ({ query }));
export const LoadAdditionalDataSuccess = createAction('[Load Additional Data] Load Additional Data success', props<{ additionalInfo: GroupAdditionalInfoDto }>());
export const LoadAdditionalDataError = createAction('[Load Additional Data] Load Additional Data error', props<{ error: HttpErrorResponse }>());

export const LoadChampionsData = createAction('[Load Champions Data] Load Champions Data', (query: number) => ({ query }));
export const LoadChampionsDataSuccess = createAction('[Load Champions Data] Load Champions Data success', props<{ championsInfo: GroupAdminDto[] }>());
export const LoadChampionsDataError = createAction('[Load Champions Data] Load Champions Data error', props<{ error: HttpErrorResponse }>());

export const LoadJoinRequestsData = createAction('[Load Join Requests Data] Load Join Requests Data', (query: number) => ({ query }));
export const LoadJoinRequestsDataSuccess = createAction('[Load Join Requests Data] Load Join Requests Data success', props<{ requestsInfo: GroupJoinRequests[] }>());
export const LoadJoinRequestsDataError = createAction('[Load Join Requests Data] Load Join Requests Data error', props<{ error: HttpErrorResponse }>());

export const LoadGroupWidgetsInfo = createAction('[Load Group Widgets Info] Load Group Widgets Info', (communityId:number)=>({communityId}) );
export const LoadGroupWidgetsInfoSuccess = createAction('[Load Group Widgets Info] Load Group Widgets success', props<{ widgetsInfo: GroupWidgetsDto[] }>());
export const LoadGroupWidgetsInfoError = createAction('[Load Group Widgets Info] Load Group Widgets error', props<{ error: HttpErrorResponse }>());


export function LoadGroupInfo(LoadGroupInfo: any): import("rxjs").OperatorFunction<import("@ngrx/store").Action, any> {
  throw new Error('Function not implemented.');
}

export function LoadGroupInfoSuccess(arg0: { discussions: import("../../../my-groups/store/state/my-groups").GroupInfoDto; }): any {
  throw new Error('Function not implemented.');
}

export function LoadGroupInfoError(arg0: { error: any; }): any {
  throw new Error('Function not implemented.');
}
// export function LoadRecentCommunityDiscussionsError(LoadRecentCommunityDiscussionsError: any, arg1: (state: import("../state/group-discussion").GroupDiscussionState, { error }: any) => { recentGroupDiscussions: { data: any; error: any; loaded: boolean; loading: false; }; GroupDiscussionDetails: import("../state/group-discussion").GroupDiscussionDetailsState; userSubscribedDiscussions: import("../state/group-discussion").userSubscribedDiscussionState; mostSubscribedDiscussions: import("../state/group-discussion").mostSubscribedDiscussionState; }): import("@ngrx/store").ReducerTypes<import("../state/group-discussion").GroupDiscussionState, readonly import("@ngrx/store").ActionCreator<string, import("@ngrx/store").Creator<any[], object>>[]> {
//   throw new Error('Function not implemented.');
// }

