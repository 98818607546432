import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAdvertisementModule } from '.';
import { MyAdvertisementModule } from '../state';
import { CreateAdStatePageState } from '../state/create-ads';

export const selectPromotionPlanDetail = createSelector(
    selectAdvertisementModule,
    (state: MyAdvertisementModule) => state.createAds
);


export const selectPromotionPlansDetails = createSelector(
    selectPromotionPlanDetail,
    (state: CreateAdStatePageState) => state.promotionPlansDetails
);