import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class BusinessDirectoryModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('business-directory.search-by-business-category.placeholder', 'Search By Business Category (Upto 5)', 'en');
    this.translateService.set('business-directory.search-organization.placeholder', 'Search Organization', 'en');
    this.translateService.set('business-directory.search-location.placeholder', 'Search Location', 'en');
    this.translateService.set('business-directory.search', 'SEARCH', 'en');
    this.translateService.set('business-directory.reset', 'RESET', 'en');
    this.translateService.set('business-directory.searching', 'Searching', 'en');
    this.translateService.set('business-directory.Oops-we-did-not-find-anything-that-matches-your-search', ' Oops...we did not find anything that matches your search:(', 'en');
    this.translateService.set('business-directory.loading', 'Loading...', 'en');
    this.translateService.set('business-directory.loading-more', 'Loading more...', 'en');
    this.translateService.set('business-directory.end-of-result', 'End of Result', 'en');
    this.translateService.set('business-directory.advertisements', 'Advertisements', 'en');
    this.translateService.set('business-directory.business-directory-page-name', 'Business directory', 'en');
    this.translateService.set('business-directory.please-provide-valid-info', 'Please provide valid info', 'en');


    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("business-directory").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
