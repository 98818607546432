import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Advertisement, AdvertisementList, AdvertisementService } from 'src/app/services/advertisement/advertisement.service';
declare var bootstrap: any;
@Component({
  selector: 'bzg-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit, OnChanges {
  static nextId = 0;
  id = `advartisement-${AdvertisementComponent.nextId++}`;
  @Input('pageName') pageName: string;
  @Input('communityId') communityId?: number;
  @Input('hideHeader') hideHeader?: boolean = false;
  isLoading: boolean = false;
  adList: AdvertisementList[] = [];

  constructor(
    private advertisementService: AdvertisementService
  ) { }

  ngOnInit(): void {

    // if (this.pageName == 'COMMUNITY DETAILS') {
    //   if (this.communityId > 0) {
    //     this.loadAds();
    //   }
    // } else {
    //   this.loadAds();
    // }

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.pageName == 'COMMUNITY DETAILS') {
      if (this.communityId > 0) {
        this.loadAds();
      }
    } else {
      this.loadAds();
    }

  }

  loadAds() {

    this.advertisementService.getAdvertisements(this.pageName, this.communityId).subscribe(
      {
        next: (data) => {

          this.adList = this.group(data);
          setTimeout(() => {
            let button = document.querySelectorAll("button.carousel-control-next");
            if (button.length > 0) {


              button.forEach((item) => {
                let btn = item as HTMLButtonElement;
                btn.click();
              })
            }
          }, 3000);

        },
        error: (err) => {

          console.log("Ads err", err);
        }
      }
    )
  }

  group(list: Advertisement[]) {
    const groups: { [key: string]: Advertisement[] } = {};
    const l = list.sort();
    for (const item of l) {
      const key = item['section'] || '';
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    }
    return Object.entries(groups).map<AdvertisementList>(([key, value]) => ({ key, value }));
  }

  ngAfterViewInit(): void {

  }
}
