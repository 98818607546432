import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props } from '@ngrx/store';
import { IProfileSettings, IProfileSettingsSearch, UserTaggedNotificationDto } from '../state/layout';

// export enum LayoutActionTypes {
//   LoadLayouts = '[Layout] Load Layouts',
  
  
// }

// export class LoadLayouts implements Action {
//   readonly type = LayoutActionTypes.LoadLayouts;
// }


// export type LayoutActions = LoadLayouts;

export const LoadProfileSettings = createAction('[ProfileSettings Data] Load Profile Settings Data', (query: IProfileSettingsSearch) => ({ query }));
export const LoadProfileSettingsDataSuccess = createAction('[ProfileSettings Data] Load Profile Settings data success', props<{ profilesettings: IProfileSettings}>());
export const LoadProfileSettingsDataError = createAction('[ProfileSettings Data] Load Profile Settings data error', props<{ error: HttpErrorResponse }>());

export const LoadNotifications = createAction('[Notification Data] Load Notification Data');
export const LoadNotificationsDataSuccess = createAction('[Notification Data] Load Notification data success', props<{ notifications: UserTaggedNotificationDto[]}>());
export const LoadNotificationsDataError = createAction('[Notification Data] Load Notification data error', props<{ error: HttpErrorResponse }>());