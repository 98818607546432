import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvertisementRoutingModule } from './advertisement-routing.module';
import { MyAdvertisementsComponent } from './pages/my-advertisements/my-advertisements.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EffectsModule } from '@ngrx/effects';
import { advertisementModuleFeatureKey } from './store/selector';
import { MyAdvertisementreducer } from './store/reducer/my-advertisements.reducer';
import { StoreModule } from '@ngrx/store';
import { MyAdvertisementsEffects } from './store/effect/my-advertisements.effects';
import { SharedModule } from 'src/app/common/shared/shared.module';
import { CreateAdsComponent } from './pages/create-ads/create-ads.component';
import { ChoosePlanComponent } from './pages/choose-plan/choose-plan.component';
import { TranslateModule } from '@ngx-translate/core';
import { advertisementsReducers } from './store/reducer';
import { CreateAdsEffects } from './store/effect/create-ads.effects';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PaymentGatewayModule } from '../shared/paymentgateway/paymentgateway.module';
import { PromotionpurchaseReceiptComponent } from './pages/promotionpurchase-receipt/promotionpurchase-receipt.component';
import { MomentModule } from 'ngx-moment';
import { PaymentConfirmationComponent } from './pages/payment-confirmation/payment-confirmation.component';
import { Html2Module } from 'src/app/common/shared/components/html2/html2.module';
import { PaymentFailedComponent } from './pages/payment-failed/payment-failed.component';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingModule } from 'src/app/common/shared/components/loading/loading.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter, CustomDateFormatsProvider } from 'src/app/services/common/mat-dateformat.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    MyAdvertisementsComponent,
    CreateAdsComponent,
    ChoosePlanComponent,
    PromotionpurchaseReceiptComponent,
    PaymentConfirmationComponent,
    PaymentFailedComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatStepperModule,
    FormsModule,
    DirectiveModule,
    MatInputModule,
    MatTooltipModule,
    AdvertisementRoutingModule,
    InfiniteScrollModule,
    MatSlideToggleModule,
    PipeModule,
    NgxSpinnerModule,
    LoadingModule,
    TranslateModule.forChild(),
    PaymentGatewayModule,
    ImageCropperModule,
    MomentModule,
    NgxSliderModule,
    Html2Module,
    StoreModule.forFeature(advertisementModuleFeatureKey, advertisementsReducers),
    EffectsModule.forFeature([MyAdvertisementsEffects, CreateAdsEffects]),
  ],
  exports:[
    CreateAdsComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }, // Set default locale
    { provide: DateAdapter, useClass: CustomDateAdapter },
    CustomDateFormatsProvider,
  ]
})
export class AdvertisementModule { }
