import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class PaymentgatewaySharedModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('paymentgateway.unable-to-load', 'Unable to Load Authorize.Net SDK', 'en');
    this.translateService.set('paymentgateway.payment-is-not-configured-properly', 'Authorize.Net Payment Gateway is not configured properly', 'en');
    this.translateService.set('paymentgateway.pay-now', 'Pay Now', 'en');
    this.translateService.set('paymentgateway.please-wait-while-processing-your-request', 'Please wait while processing your request...', 'en');
    this.translateService.set('paymentgateway.loadingText', 'Loading...', 'en');
    //--------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("paymentgateway").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
