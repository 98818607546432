import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { TenantInfoDetails } from 'src/app/models/configuration/configuration';
import { ChangePageName, SideNavClose, SideNavOpen } from 'src/app/store/actions/app.action';
import { SelectTenantInfo } from 'src/app/store/selectors/app.selector';
import { LoadHomepageData } from './store/action/home.actions';
import { selectHomePageData } from './store/selector/home.selectors';
import { HomePageDataState } from './store/state/home';
@Component({
  selector: 'bzg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  htmlData: string;
  isCustomHomePage: boolean = true;
  homePage: Observable<HomePageDataState>;
  tenant: Observable<TenantInfoDetails>;
  constructor(
    private renderer: Renderer2,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'body-homepage');
    this.store.dispatch(ChangePageName({ pageName: '' }));
    //this.store.dispatch(SideNavClose());
    this.store.dispatch(SideNavOpen());

    this.tenant = this.store.select(SelectTenantInfo)

    this.homePage = this.store.select(selectHomePageData).pipe(
      tap(s => {
        this.isCustomHomePage = s.data != null;
      })
    );

  }


}
