import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyRemindersModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('my-reminders.dashboard', 'Dashboard', 'en');
    this.translateService.set('my-reminders.my-reminders', 'My Reminders', 'en');
    this.translateService.set('my-reminders.reminders', 'Reminders', 'en');
    this.translateService.set('my-reminders.view-history', 'View History', 'en');
    this.translateService.set('my-reminders.create-task', 'Create Task', 'en');
    this.translateService.set('my-reminders.loading', 'Loading...', 'en');
    this.translateService.set('my-reminders.edit', 'Edit', 'en');
    this.translateService.set('my-reminders.delete', 'Delete', 'en');
    this.translateService.set('my-reminders.task-due-date', 'Task Due Date', 'en');
    this.translateService.set('my-reminder.reminder-date', 'Reminder Date', 'en');
    this.translateService.set('my-reminders.new-task', 'New Task', 'en');
    this.translateService.set('my-reminders.edit-task', 'Edit Task', 'en');
    this.translateService.set('my-reminders.title', 'Title', 'en');
    this.translateService.set('my-reminders.enter-task-title', 'Enter Task Title', 'en');
    this.translateService.set('my-reminders.start-date', 'Start Date', 'en');
    this.translateService.set('my-reminders.due-date', 'due Date', 'en');
    this.translateService.set('my-reminders.reminder-date-and-time.placeholder', 'Reminder Date and Time', 'en');
    this.translateService.set('my-reminders.ok', 'OK', 'en');
    this.translateService.set('my-reminders.cancel', 'Cancel', 'en');
    this.translateService.set('my-reminders.save', 'Save', 'en');
    this.translateService.set('my-reminders.update', 'Update', 'en');
    this.translateService.set('my-reminders.please-wait-while-processing-your-request', 'Please wait while processing your request...', 'en');
    this.translateService.set('my-reminders.are-you-sure-you-want-to-delete-this', 'Are you sure you want to delete this', 'en');
    this.translateService.set('my-reminders.are-you-sure-you-want-to-mark-this', 'Are you sure you want to mark this', 'en');
    this.translateService.set('my-reminders.as-completed', 'as completed?', 'en');
    this.translateService.set('my-reminders.you-have-completed-your', 'You have completed your', 'en');
    this.translateService.set('my-reminders.deleted', 'deleted', 'en');
    this.translateService.set('my-reminders.task-is-updated', 'Task is updated', 'en');
    this.translateService.set('my-reminders.task-is-created', 'Task is created', 'en');
    this.translateService.set('my-reminders.something-went-wrong-please-try-again', 'Something went wrong. Please try again', 'en');
    this.translateService.set('my-reminders.smart-reminders-notify-you-when-it-is-time-for-a-scheduled-close', 'Smart reminders notify you when it is time for a scheduled close.', 'en');
    this.translateService.set('my-reminders.status', 'Status', 'en');
    this.translateService.set('my-reminders.new', 'New', 'en');
    this.translateService.set('my-reminders.in-progress', 'In Progress', 'en');
    this.translateService.set('my-reminders.completed', 'Completed', 'en');
    // latest changes 
    this.translateService.set('my-reminders.my-reminders-page-name', 'My Reminders', 'en');
    this.translateService.set('my-reminders.no-reminders-found', 'No Reminders Found', 'en');
    this.translateService.set('my-reminders.reminder-date-and-time', 'Reminder Date and Time', 'en');
    
    //----------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("my-reminders").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
