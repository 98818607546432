import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyGroupsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translate.set('my-groups.dashboard', 'Dashboard', 'en');
    this.translate.set('my-groups.my-groups', 'My Groups', 'en');
    this.translate.set('my-groups.search-by-group-name.placeholder', 'Search By Name', 'en');
    this.translate.set('my-groups.search', 'Search', 'en');
    this.translate.set('my-groups.reset', 'Reset', 'en');
    this.translate.set('my-groups.inactive', 'Inactive', 'en');
    this.translate.set('my-groups.waiting-for-approval', 'Waiting for approval', 'en');
    this.translate.set('my-groups.cancel', 'Cancel', 'en');
    this.translate.set('my-groups.leave', 'Leave', 'en');
    this.translate.set('my-groups.rejected', 'Rejected', 'en');
    this.translate.set('my-groups.community', 'Community', 'en');
    this.translate.set('my-groups.loading' , 'Loading...', 'en');
    this.translate.set('my-groups.my-groups' , 'My Groups', 'en');
    this.translate.set('my-groups.no-groups-image' , '/assets/images/community-details/no_groups.png', 'en');
    this.translate.set('my-groups.you-have-not-joined-any-group' , 'You have not joined any groups yet', 'en');
    this.translate.set('my-groups.sorry-no-result-found' , 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('my-groups.we-did-not-find-anything', 'Oops...we did not find anything that matches your search:(', 'en');
    this.translate.set('my-groups.please-wait-while-processing-your-request', 'Please wait while processing your request...', 'en');
    return this.localResourceService.loadLocalResource("my-groups").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
