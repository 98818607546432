<bzg-loading [show]="isLoading" [ngClass]="isLoading?'loadingBackdrop':''" [message]="loadingText"></bzg-loading>
<div class="text-danger" *ngIf="hasError">
    <ul>
        <li *ngFor="let err of error">{{err}}</li>
    </ul>
</div>
<ng-container *ngIf="!hasError && !isLoading && !isTxnIdFetching && paymentData?.successUrl && paymentData?.failureUrl">
    <form [action]="paymentData?.action" method='post' ngNoForm>
        <input type="hidden" name="key" [value]="paymentData?.key" />
        <input type="hidden" name="txnid" [value]="paymentData?.txnid" />
        <input type="hidden" name="productinfo" [value]="paymentData?.productInfo" />
        <input type="hidden" name="amount" [value]="paymentData?.amount" />
        <input type="hidden" name="email" [value]="paymentData?.email" />
        <input type="hidden" name="firstname" [value]="paymentData?.firstName" />
        <input type="hidden" name="lastname" [value]="paymentData?.lastName" />
        <input type="hidden" name="surl" [value]="paymentData?.successUrl" />
        <input type="hidden" name="furl" [value]="paymentData?.failureUrl" />
        <input type="hidden" name="phone" [value]="paymentData?.phone" />
        <input type="hidden" name="hash" [value]="paymentData?.hash" />
        <input type="hidden" name="udf1" [value]="paymentData?.udf1" />
        <input type="hidden" name="udf2" [value]="paymentData?.udf2" />
        <input type="hidden" name="udf3" [value]="paymentData?.udf3" />
        <input type="hidden" name="udf4" [value]="paymentData?.udf4" />
        <input type="hidden" name="udf5" [value]="paymentData?.udf5" />
        <div class="text-center">
            <input type="submit" class="btn btn-theme btn-lg px-5" value="Checkout">
        </div>
    </form>
</ng-container>