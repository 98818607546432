<div class="spotlight-events">
    <div *ngFor="let item of spotLightEvents;let last = last; trackBy: spotLightTrackByFn" class="">
        <div class="card gEPEventCard">
            <a [routerLink]="['/event','details', item.eventName | slug, item.eventId]">
                <img *ngIf="!item.isVideo" alt="Card image" class="card-img-top" [src]="item.eventImage" />
                <video *ngIf="item.isVideo" class="card-img-top">
                    <source [src]="item.eventImage">
                </video>
            </a>
            <div class="card-body">
                <h4 class="card-title">
                    <a matTooltip="{{item.eventName}}"
                        [routerLink]="['/event','details', item.eventName | slug, item.eventId]">
                        {{item.eventName}}
                    </a>
                </h4>
                <ul class="list-inline">
                    <li class="list-inline-item gEPEventCard-date">
                        <ng-container *ngIf="item.isSameDayEvent">
                            {{item.eventStartDate | date:'MMM dd, YYYY'}} |
                            {{item.eventStartDate | date:'hh:mm a'}} -
                            {{item.eventEndDate | date:'hh:mm a'}}
                        </ng-container>
                        <ng-container *ngIf="!item.isSameDayEvent">
                            {{item.eventStartDate | date:'MMM dd, YYYY hh:mm a'}}
                            <b>{{'community-details.too' | translate}}</b>
                            {{item.eventEndDate | date:'MMM dd, YYYY hh:mm a'}}
                        </ng-container>
                        <span class="ps-2">{{item.timeZone | timezoneInitial}}</span>
                    </li>
                </ul>
                <div class="gEPEventCardBtnNCategory">
                    <div class="me-1">
                        <a target="_blank"
                            *ngIf="item.isJoinButtonEnabled && item.registrantInfoData?.join_url;else showPaid"
                            type="button" [href]="item.registrantInfoData?.join_url"
                            class="btn btn-outline-theme-secondary col-auto">
                            {{'community-details.to' | translate}}
                        </a>
                    </div>
                    <ng-template #showPaid>
                        <p class="card-price free ">
                            {{item.isPaid}}
                        </p>
                    </ng-template>
                    <p class="col text-end mb-0">
                        <i class="fas fa-bookmark"></i> {{item.eventCategory}}
                    </p>
                </div>
            </div>
            <div class="card-footer">
                <div class="gEPEventCard__fstatus status-in-person">
                    <i class="fas fa-map-marker-alt"></i> {{eventMode[item.eventMode]}}
                </div>
                <div *ngIf="item.userId>0" class="gEPEventCard__fstatus text-primary">
                    <i class="fas fa-check-circle"></i> {{'community-details.registered' | translate}}
                </div>
            </div>
        </div>
        <span *ngIf="last">{{ initSlider() }}</span>
    </div>
</div>