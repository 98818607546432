import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpotlightEventsComponent } from './spotlight-events/spotlight-events.component';
import { EventInfoSharedModule } from '../event-info/event-info-shared.module';
import { SpotlightEventComponent } from './spotlight-event/spotlight-event.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    SpotlightEventsComponent,
    SpotlightEventComponent
  ],
  imports: [
    CommonModule,
    EventInfoSharedModule,
    TranslateModule.forChild(),
    DirectiveModule,
    MatTooltipModule,
    RouterModule
  ],
  exports:[
    SpotlightEventsComponent,
    SpotlightEventComponent
  ]
})
export class SpotlightEventModule { }
