import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CommunitiesResponseDto, CommunitiesSearchDto } from '../state/communities';

export const resetCommunities = createAction('[Communities] Reset Communities');
export const resetCommunitiesSuccess = createAction('[Communities] Reset Communities success');
export const LoadCommunities = createAction('[Communities] Load Communities', (query: CommunitiesSearchDto) => ({ query }));
export const LoadCommunitiesSuccess = createAction('[Communities] Load Communities Success', props<{ communities: CommunitiesResponseDto }>());
export const LoadCommunitiesError = createAction('[Communities] Load Communities Error', props<{ error: HttpErrorResponse }>());

export const LoadMoreCommunities = createAction('[Communities] Load More Communities', (query: CommunitiesSearchDto) => ({ query }));
export const LoadMoreCommunitiesSuccess = createAction('[Communities] Load More Communities Success', props<{ communities: CommunitiesResponseDto }>());
export const LoadMoreCommunitiesError = createAction('[Communities] Load More Communities Error', props<{ error: HttpErrorResponse }>());

export const SearchCommunities = createAction('[Communities] Search Communities', (query: CommunitiesSearchDto) => ({ query }));
export const SearchCommunitiesSuccess = createAction('[Communities] Search Communities Success', props<{ communities: CommunitiesResponseDto }>());
export const SearchCommunitiesError = createAction('[Communities] Search Communities Error', props<{ error: HttpErrorResponse }>());

export const LoadMoreSearchCommunities = createAction('[Communities] Load More Search Communities', (query: CommunitiesSearchDto) => ({ query }));
export const LoadMoreSearchCommunitiesSuccess = createAction('[Communities] Load More Search Communities Success', props<{ communities: CommunitiesResponseDto }>());
export const LoadMoreSearchCommunitiesError = createAction('[Communities] Load More Search Communities Error', props<{ error: HttpErrorResponse }>());






