import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventInfoComponent } from './event-info.component';
import { MaterialModule } from 'src/app/common/material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { ApplicationCommonModule } from 'src/app/common/application-common/application-common.module';

@NgModule({
  declarations: [
    EventInfoComponent
  ],
  imports: [
    CommonModule,
    // MaterialModule,
    RouterModule,
    TranslateModule.forChild(),
    MatTooltipModule,
    PipeModule,
    ApplicationCommonModule
  ],
  exports: [
    EventInfoComponent
  ]
})
export class EventInfoSharedModule { }