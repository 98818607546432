import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsletterComponent } from './newsletter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { DirectiveModule } from 'src/app/directive/directive.module';



@NgModule({
  declarations: [
    NewsletterComponent,
   
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    DirectiveModule
  ],
  exports:[
    NewsletterComponent
  ]
})
export class NewsletterModule { }
