import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MessagesModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('messages.write-new-message-to', 'write new message to', 'en');
    this.translateService.set('messages.find-person.placeholder', 'find person', 'en');
    this.translateService.set('messages.search-contact', 'Search Contact', 'en');
    this.translateService.set('messages.add-member-from-your-contacts', 'Add member from your contacts', 'en');
    this.translateService.set('messages.search-member', 'Search Member', 'en');
    this.translateService.set('messages.you-do-not-have-any-messages-yet', 'You do not have any messages yet, get connected to members to start the conversation.', 'en');
    this.translateService.set('messages.non-resut-image', 'assets/images/messages/chat-noresult-preview.png', 'en');
    this.translateService.set('messages.type-a-new-message.placeholder', 'Type a new message', 'en');
    this.translateService.set('messages.something-went-wrong-please-try-again', 'Something went wrong. Please try again', 'en');
    // latest changes
    this.translateService.set('messages.dashboard', 'Dashboard', 'en');
    this.translateService.set('messages.messages', 'Messages', 'en');
    this.translateService.set('messages.page-name-messages', 'Messages', 'en');
    this.translateService.set('messages.message', 'Message', 'en');

   return this.localResourceService.loadLocalResource("messages").pipe(
            tap(resp => {
                resp.data.forEach(data => {
                    this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
                });
            })
        );
  }
}
