import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyDetailsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('loyalty-details.back-to-profile', 'Back To Profile', 'en');
    this.translateService.set('loyalty-details.action', 'Action', 'en');
    this.translateService.set('loyalty-details.date-and-time', 'Date & Time', 'en');
    this.translateService.set('loyalty-details.reward-points', 'Reward Points', 'en');

    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("loyalty-details").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
