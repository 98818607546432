import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { ClientConfigDatePipe } from 'src/app/pipe/client-config-date.pipe';
import { SelectClientConfiguration } from 'src/app/store/selectors/app.selector';


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    private dateFormat: string;
    culture: string;

    constructor(private store: Store, private clientConfigDate: ClientConfigDatePipe) {
        super('en'); // Default locale
        this.updateDateFormat();
    }

    updateDateFormat(): void {
        console.log("updateDateFormat gets called");
        this.store.select(SelectClientConfiguration)
            .pipe(filter(s => s != null), take(1))
            .subscribe((config) => {
                this.dateFormat = config.dateFormat.csharp;
                this.culture = config.culture
                this._localeChanges.next();
            });
    }

    override format(date: Date, displayFormat: Object): string {
        if (this.dateFormat) {
            var s = this.clientConfigDate.transform(date);
            console.log("formatted date", s);
            return s;
        }
        console.log("display format default => ", displayFormat);
        return super.format(date, displayFormat);
    }
}

export const MY_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};

@Injectable()
export class CustomDateFormatsProvider {
    constructor(
        private customDateAdapter: CustomDateAdapter,
        private store: Store) {
        this.store.select(SelectClientConfiguration)
            .pipe(filter(s => s != null), take(1))
            .subscribe((config) => {
                this.customDateAdapter.updateDateFormat();
            });


    }

    getDateFormats(): MatDateFormats {
        // You can fetch date formats from the API if needed
        return MY_DATE_FORMATS;
    }
}

export const MY_DATE_FORMATS_PROVIDER = {
    provide: MAT_DATE_FORMATS,
    useValue: MY_DATE_FORMATS,
};