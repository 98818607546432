<div class="testimonial-list">
    <h4 class="text-center" *ngIf="isLoading"> <i class="fa fa-spinner fa-spin"></i> Loading Testimonials...</h4>
    <p class="text-center" *ngIf="hasError">Unable to load testimonials...</p>
    <div class="testimonials">
        <div class="card" *ngFor="let testimonial of testimonials;let last = last; trackBy: testimonialTrackByFn">
            <div class="card-body testimonialCard">
                <div class=" testimonialCard__img">
                    <img *ngIf="testimonial.profilePicturePath" [src]="testimonial.profilePicturePath" alt="avatar"
                        class="img-fluid">
                    <ngx-letters-avatar *ngIf="testimonial.profilePicturePath == ''" [avatarName]="testimonial.fullName"
                        [width]="80" [circular]="true" fontFamily="Maven Pro">
                    </ngx-letters-avatar>
                </div>
                <div class="testimonialCard__body">
                    <div class="testimonialCard__Des">
                        <p>{{testimonial.details}}</p>
                        <h4><i class="fas fa-quote-left"></i> <span>{{testimonial.fullName}}</span></h4>
                    </div>
                </div>
            </div>
            <span *ngIf="last">{{ initSlider() }}</span>
        </div>
    </div>
</div>