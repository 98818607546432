import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { MembershipPaymentSummary, PaymentMethod, QRPaymentDto, SaveMembershipPlanPurchaseInfo, SaveMembershipPlanPurchaseInfoResponse, SignupWithFreePlan, Updatemembershipplanpurchaseinfo } from 'src/app/models/payment/payment-method';
import { PromotionPaymentSummaryDto, PromotionPaymentTransactionsDto } from 'src/app/module/advertisement/store/state/create-ads';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient
  ) { }

  getPaymentMethods() {
    const requestUrl = `/api/payment/getpaymentmethods`;
    return this.http.get<Array<PaymentMethod>>(requestUrl, { params: { communityId: 0 } });
  }

  getPaymentMethodById(paymentMethodId: number) {
    const requestUrl = `/api/payment/getpaymentmethods`;
    return this.http.get<Array<PaymentMethod>>(requestUrl, { params: { communityId: 0, paymentMethodId } }).pipe(take(1), map(data => data[0]));
  }

  getPaymentMethodsByCommunityId(communityId: number) {
    const requestUrl = `/api/payment/getpaymentmethods`;
    return this.http.get<Array<PaymentMethod>>(requestUrl, { params: { communityId } });
  }

  getPaymentMethodByCommunityIdAndPaymentMethodId(communityId: number, paymentMethodId: number) {
    const requestUrl = `/api/payment/getpaymentmethods`;
    return this.http.get<Array<PaymentMethod>>(requestUrl, { params: { communityId, paymentMethodId } }).pipe(take(1), map(data => data[0]));
  }

  saveMemberShipPlan(plan: SaveMembershipPlanPurchaseInfo) {
    const requestUrl = `/api/payment/savemembershipplanpurchaseinfo`;
    return this.http.post<SaveMembershipPlanPurchaseInfoResponse>(requestUrl, plan);
  }
  updateMemberShipPlanPurchaseInfo(info: Updatemembershipplanpurchaseinfo) {
    const requestUrl = `/api/payment/updatemembershipplanpurchaseinfo`;
    return this.http.post<number>(requestUrl, info);
  }
  updatePromotionPlanPurchaseInfo(info: PromotionPaymentTransactionsDto) {
    const requestUrl = `/api/payment/UpdatePromotionPlanPurchaseInfo`;
    return this.http.post<number>(requestUrl, info);
  }
  getMembershipPaymentSummary(paymentId: number, transactionId: string) {
    const requestUrl = `/api/payment/getmembershippaymentsummary`;
    if (transactionId == null || transactionId == "0") {
      return this.http.get<MembershipPaymentSummary>(requestUrl, { params: { paymentId } });
    }
    return this.http.get<MembershipPaymentSummary>(requestUrl, { params: { paymentId, transactionId } });
  }

  getPromotionPaymentSummary(paymentId: number) {
    const requestUrl = `/api/payment/GetPromotionPaymentSummary`;

    return this.http.get<PromotionPaymentSummaryDto>(requestUrl, { params: { paymentId } });

  }



  signUpWithFreePlan(param: SignupWithFreePlan) {
    const requestUrl = `/api/payment/signupwithfreeplan`;
    return this.http.post<number>(requestUrl, param);
  }
  
  signUpWithQRPaymentForCorwa(param: QRPaymentDto) {
    const requestUrl = `/api/payment/MembershipPaymentWithQRForCorwa`;
    return this.http.post<number>(requestUrl, param);
  }


}
