import { Component, Input, OnInit } from '@angular/core';
import { CommunitySpotLightEvents } from 'src/app/module/community-details/store/state/community-home';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'bzg-spotlight-events',
  templateUrl: './spotlight-events.component.html',
  styleUrls: ['./spotlight-events.component.scss']
})
export class SpotlightEventsComponent implements OnInit {

  events: CommunitySpotLightEvents[];
  @Input('displayCount') displayCount?: number;
  isLoading: boolean = false;
  hasError: boolean = false;
  constructor(
    private commonService: CommonService,
  ) {

  }

  ngOnInit(): void {

    if (this.displayCount == null) {
      this.displayCount = 3;
    }

    this.isLoading = true;
    this.commonService.getAllSpotlightEvents().subscribe({
      next: (data) => {
        this.events = data;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.hasError = true;
      }
    })
  }


}
