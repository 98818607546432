import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneInitial'
})
export class TimezoneInitialPipe implements PipeTransform {

  transform(value: string): string {
    if (value && value.length) {
      let initial = "";
      let words = value.split(" ");
      words.forEach(item => {
        let word = item.trim();
        if (word.length > 0) {
          initial += word.charAt(0).toUpperCase();
        }
      });
      return initial;
    }
    return value;
  }

}
