import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyJobsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('my-jobs.search-by-job-title.placeholder', 'Search by Job Title', 'en');
    this.translateService.set('my-jobs.search-by-job-type', 'Search by Job Type', 'en');
    this.translateService.set('my-jobs.full-time', 'Full Time', 'en');
    this.translateService.set('my-jobs.part-time', 'Part Time', 'en');
    this.translateService.set('my-jobs.contract', 'Contract', 'en');
    this.translateService.set('my-jobs.contract-to-hire', 'Contract to Hire', 'en');
    this.translateService.set('my-jobs.search-by-location.placeholder', 'Search  by Location', 'en');
    this.translateService.set('my-jobs.search-by-community.placeholder', 'Search  by Community', 'en');
    this.translateService.set('my-jobs.search', 'SEARCH', 'en');
    this.translateService.set('my-jobs.reset', 'RESET', 'en');
    this.translateService.set('my-jobs.loading', 'Loading...', 'en');
    this.translateService.set('my-jobs.location', 'Location', 'en');
    this.translateService.set('my-jobs.company', 'Company', 'en');
    this.translateService.set('my-jobs.applied-date', 'Applied Date', 'en');
    this.translateService.set('my-jobs.view', 'View', 'en');
    // Latest changes
    this.translateService.set('my-jobs.dashboard', 'Dashboard', 'en');
    this.translateService.set('my-jobs.my-applications', 'My Applications', 'en');
    this.translateService.set('my-jobs.no-jobs-found', 'No Jobs Found', 'en');
    this.translateService.set('my-jobs.my-applications-page-name', 'My Applications', 'en');
    //------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("my-jobs").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }  
}
