import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class GlobalCareersModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translate: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
   
    this.translate.set('global-careers-page-name', 'Global Careers', 'en');
    this.translate.set('global-careers.search-by-job-title.placeholder', 'Search by Job Title', 'en');
    this.translate.set('global-careers.search-by-job-type', 'Search by Job Type', 'en');
    this.translate.set('global-careers.full-time', 'Full Time', 'en');
    this.translate.set('global-careers.part-time', 'Part Time', 'en');
    this.translate.set('global-careers.jobs-contract', 'Jobs contract', 'en');
    this.translate.set('global-careers.contract-to-hire', 'Contract to hire', 'en');
    this.translate.set('global-careers.search-by-location.placeholder', 'Search by Location', 'en');
    this.translate.set('global-careers.search-by-company-name.placeholder', 'Search by Company Name', 'en');
    this.translate.set('global-careers.search', 'SEARCH', 'en');
    this.translate.set('global-careers.reset', 'RESET', 'en');
    this.translate.set('global-careers.location', 'Location', 'en');
    this.translate.set('global-careers.company', 'Company', 'en');
    this.translate.set('global-careers.last-updated-date', 'Last Updated Date', 'en');
    this.translate.set('global-careers.view-and-apply', 'View and Apply', 'en');
    this.translate.set('global-careers.already-applied', 'Already Applied', 'en');
    this.translate.set('global-careers.no-results-found', 'No Results Found', 'en');
    this.translate.set('global-careers.there-is-no-global-careers-for-you', 'There is no global careers for you as of now! Please Visit Communities', 'en');
    this.translate.set('global-careers.sorry-no-results-found-matching-your-search-criteria', 'Sorry! No results found matching your search criteria', 'en');
    this.translate.set('global-careers.loading', 'Loading...', 'en');
    this.translate.set('global-careers.loading-more', 'Loading More...', 'en');
    this.translate.set('global-careers.job-requirment-details', 'Job Requirement Details', 'en');
    this.translate.set('global-careers.list-of-jobs', 'List of Jobs', 'en');
    this.translate.set('global-careers.the-complete-details-of-the-job-posting.', 'Here are the complete details of the job posting you selected.Read all details about it and click on the button to apply for this job.', 'en');
    this.translate.set('global-careers.user-applied-date', 'User Applied Date', 'en');
    this.translate.set('global-careers.apply', 'Apply', 'en');
    this.translate.set('global-careers.this-job-is-no-longer-active', 'This job is no longer active', 'en');
    this.translate.set('global-careers.job-description', 'Job description', 'en');
    this.translate.set('global-careers.additional-details', 'Additional details', 'en');
    this.translate.set('global-careers.your-name', 'Your name', 'en');
    this.translate.set('global-careers.name.placeholder', 'name', 'en');
    this.translate.set('global-careers.your-email', 'Your e-mail', 'en');
    this.translate.set('global-careers.email-id.placeholder', 'E-mail', 'en');
    this.translate.set('global-careers.upload-your-resume', 'Upload your resume', 'en');
    this.translate.set('global-careers.attachment', 'Attachment', 'en');
    this.translate.set('global-careers.upload-resume-doc', '(Upload your resume only in doc, docx, pdf format)', 'en');
    this.translate.set('global-careers.optional-message-to-employer', 'Optional message to employer', 'en');
    this.translate.set('global-careers.type-a-message.placeholder', 'Type a message', 'en');
    this.translate.set('global-careers.submit', 'Submit', 'en');
    this.translate.set('global-careers.you-have-already-applied-to-this-job', 'You have already applied to this job', 'en');
    this.translate.set('global-careers.please-login-to-apply-this-job', 'Please login to apply for this job', 'en');
    this.translate.set('global-careers.you-have-applied-successfully', 'You have applied Successfully', 'en');
    this.translate.set('global-careers.ok', 'OK', 'en');
    this.translate.set('global-careers.remarks', 'Remarks', 'en');
    this.translate.set('global-careers.please-wait-while-processing-request', 'Please wait while processing your request', 'en');

    return this.localResourceService.loadLocalResource("global-careers").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translate.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
