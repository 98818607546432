import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { ITestimonial } from '../../testimonials/store/state/testimonials';
import { TinySliderInstance, TinySliderSettings, tns } from 'tiny-slider/src/tiny-slider';

@Component({
  selector: 'bzg-testimonials-dynamic',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsDynamicComponent implements OnInit, OnDestroy {
  testimonials: ITestimonial[]
  isLoading: boolean = false;
  hasError: boolean = false;

  isSliderInitialized: boolean = false;
  slider: TinySliderInstance;
  settings: TinySliderSettings = {
    container: '.testimonials',
    items: 1,
    slideBy: 1,
    mouseDrag: true,
    autoplay: false,
    autoplayButtonOutput: false,
    nav: false,
    gutter: 15,
    loop: true,
    edgePadding: 10,
    controlsText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    responsive: {
      640: {
        items: 1,
      },
      1200: {
        items: 1,
      }
    }
  }
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.commonService.getAllTestimonials().subscribe({
      next: (data) => {
        this.isLoading = false;
        this.testimonials = data;
      },
      error: (err) => {
        this.isLoading = false;
        this.hasError = true;
      }
    })
  }



  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  initSlider() {

    if (this.isSliderInitialized) return;
    this.isSliderInitialized = true;
    if (this.slider) {
      this.slider.destroy();
    }
    setTimeout(() => {
      this.slider = tns(this.settings)
    }, 10);

  }

  testimonialTrackByFn(index: number, item: ITestimonial) {
    return item.id;
  }

}
