import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bzg-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit, AfterViewInit {
  transactionId: string;
  paymentId: number;
  status: string;
  paymentStatus: string;
  paymentFrom: string;
  currentURL: URL;
  isTenantAdminPage: boolean = false;
  isCommunityAdminPage: boolean = false;
  isCommunityAdmin: boolean = false;
  communityId: number = 0;
  constructor(private route: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.route.params.subscribe((route: any) => {
      this.transactionId = route.transactionId;
      this.paymentId = route.paymentId;
      this.status = route.status;
      this.paymentFrom = route.paymentFrom;
    });
    var currentURL = new URL(window.location.toString());
    this.currentURL = currentURL;
    if (currentURL.pathname.startsWith("/admin") && this.communityId == 0) {
      this.isTenantAdminPage = true;
    } else if (this.communityId > 0 && currentURL.pathname.startsWith("/admin/community")) {
      this.isCommunityAdminPage = true;
      this.isCommunityAdmin = true;
    }
    if (this.paymentFrom?.toUpperCase() == "MOBILE_APP") {
      window.location.href = `msauth.com.pamten.bizligo://auth?transactionId=${this.transactionId}&paymentId=${this.paymentId}`;
    }

    if (this.status.toLocaleLowerCase() == 'failed') {
      this.paymentStatus = 'failed';
    }
    else {
      this.paymentStatus = 'cancelled'
    }

  }

  ngAfterViewInit(): void {
    
    if (this.isTenantAdminPage || this.isCommunityAdminPage) {
      // Replace 'your-element-id' with the actual ID of the element you want to modify
      const element = this.el.nativeElement.querySelector('#advertisement-member-admin-section');

      // Replace 'your-class' with the class you want to remove
      this.renderer.removeClass(element, 'content');
    }
    
  }

}
