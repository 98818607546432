import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserSubscription } from 'src/app/common/layout/header/header.component';
import { IProfileSettings, IProfileSettingsSearch, UserTaggedNotificationDto } from 'src/app/common/layout/store/state/layout';
import { IEndorsements, INotes, ISearchEndorsements, ISearchNotes } from '../store/state/endorsements';
import { IContactsList, ILocationList, IOrganization, IProfessionalMatches, ISearchContacts, ISearchLocations, ISearchOrganization, ISearchProfessionalMatches, SearchContacts } from '../store/state/organization';
import { AdditionalInfo, additionalInfoData, AddPortfolio, IInterests, interestsData, ISearchAdditionalInfo, ISearchSimilarSandIUsersInfo, ISearchSkills, ISimilarSandIUserInfo, ISkills, IuserInfo, IUserProfileCompletenessInfo, SearchSkillsOrInterests, skillsData, SkillsOrInterestsState, skillsOrInterestsInfo, userInfoData, ISimilarSandIUserList, IChangePasswordDto, UserMembershipPlansStatus } from '../store/state/profile';
import { UserCommunitiesAndGroups } from 'src/app/models/accounts/user';

@Injectable({
  providedIn: 'root'
})
export class MyprofileService {
  redirectUrl: string;
  constructor(
    private http: HttpClient
  ) {

  }

  GetSkills(search: ISearchSkills): Observable<ISkills[]> {
    let params: any = { 'userId': search.userId, 'categoryType': search.categoryType }
    return this.http.get<ISkills[]>("/api/user/GetUserSkillsOrInterests", { params: params });
  }
  GetInterests(search: ISearchSkills): Observable<IInterests[]> {
    let params: any = { 'userId': search.userId, 'categoryType': search.categoryType }
    return this.http.get<IInterests[]>("/api/user/GetUserSkillsOrInterests", { params: params });
  }

  GetSkillsorInterests(search: SearchSkillsOrInterests): Observable<skillsOrInterestsInfo[]> {
    let params: any = { 'categoryType': search.categoryType }
    return this.http.get<skillsOrInterestsInfo[]>("/api/user/getskillsorinterests", { params: params });
  }

  GetAdditionalInfo(search: ISearchAdditionalInfo): Observable<AdditionalInfo> {
    let params: any = { 'userId': search.userId }
    return this.http.get<AdditionalInfo>("/api/user/GetUserAdditionalInfo", { params: params }).pipe(
      map(data => {
        if (data == null) {
          let noData: AdditionalInfo = {
            portfolio: [],
            whoAmI: []
          };
          return noData;
        }
        return data;
      })
    );
  }

  GetUserInfo(search: ISearchAdditionalInfo): Observable<IuserInfo> {
    let params: any = { 'userId': search.userId }
    return this.http.get<IuserInfo>("/api/user/GetUserProfileInfo", { params: params });
  }
  getUserInfoByUserId(userId: number): Observable<IuserInfo> {
    let params: any = { 'userId': userId }
    return this.http.get<IuserInfo>("/api/user/GetUserProfileInfo", { params: params });
  }
  getUserCommunitiesAndGroupsByUserId(userId: number): Observable<UserCommunitiesAndGroups> {
    return this.http.get<UserCommunitiesAndGroups>(`/api/admin/user/GetMemberCommunitiesAndGroupsByUserId/${userId}`);
  }
  GetConnectionStatus(curUserId: number, userId: number) {
    let params: any = { 'CurUserId': curUserId, 'userId': userId }
    return this.http.get<number>("/api/user/GetConnectionStatus", { params: params });
  }
  GetProfileCompleteness(search: ISearchAdditionalInfo): Observable<IUserProfileCompletenessInfo> {
    let params: any = { 'userId': search.userId }
    return this.http.get<IUserProfileCompletenessInfo>("/api/user/GetProfileCompleteness", { params: params });
  }

  // GetSimillarUsers(search: ISearchSimilarSandIUsersInfo): Observable<ISimilarSandIUserInfo[]> {
  //   let params: any = { 'userId': search.userId}
  //   return this.http.get<ISimilarSandIUserInfo[]>("/api/user/GetSimilarInterestUsers", { params: params });
  // }
  GetSimillarUsers(search: ISearchSimilarSandIUsersInfo): Observable<ISimilarSandIUserList> {
    let params: any = { 'pageNumber': search.pageNumber, 'pageSize': search.pageSize }
    return this.http.get<ISimilarSandIUserList>("/api/user/GetMyMatches", { params: params });
  }

  ApproveOrRejectAsSecondaryContact(userId: number, isAccept: boolean) {
    let params: any = { 'userId': userId, 'value': isAccept }
    return this.http.get<boolean>("/api/user/ApproveOrRejectAsSecondaryContact", { params: params });
  }

  GetContacts(search: SearchContacts): Observable<IContactsList[]> {
    let params: any = { 'id': search.id, 'memberName': search.memberName, 'orgId': search.orgId }
    return this.http.get<IContactsList[]>("/api/user/GetContacts", { params: params });
  }
  GetLocations(search: ISearchLocations): Observable<ILocationList[]> {
    let params: any = { 'id': search.id, 'orgId': search.orgId }
    return this.http.get<ILocationList[]>("/api/user/GetLocations", { params: params });
  }
  GetProfessionalMatches(search: ISearchProfessionalMatches): Observable<IProfessionalMatches> {
    let params: any = { 'userId': search.userId, 'orgId': search.orgId }
    return this.http.get<IProfessionalMatches>("/api/user/GetProfessionalMatches", { params: params });
  }
  GetOrganizationData(search: ISearchOrganization): Observable<IOrganization> {
    let params: any = { 'userId': search.userId, 'orgId': search.orgId }
    return this.http.get<IOrganization>("/api/user/GetUserOrganizationInfo", { params: params });
  }
  GetProfileSettingsData(search: IProfileSettingsSearch): Observable<IProfileSettings> {
    let params: any = { 'userId': search.userId }
    return this.http.get<IProfileSettings>("/api/user/GetProfileSettings", { params: params });
  }
  GetNotificationsData(): Observable<UserTaggedNotificationDto[]> {
    return this.http.get<UserTaggedNotificationDto[]>("/api/community/GetAllTagNotifications", {});
  }

  AddPortfolio(data: AddPortfolio) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SavePortfolioInfo", data);
  }
  updateProfile(data: IuserInfo) {
    console.log(data);
    return this.http.post<boolean>("/api/user/UpdateUserProfile", data);
  }
  AddWhoAmI(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveWhoAmI", data);
  }
  updateSkills(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveUserSkills", data);
  }
  updateInterests(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveUserInterests", data);
  }

  addUser(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/AddUserToOrganization", data);
  }
  updateUser(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/UpdateUserFormProfile", data);
  }
  addLocation(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveUpdateLocation", data);
  }
  addBusinessCategories(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveBusinessCategories", data);
  }
  addBusinessTypes(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveOrgBusinessTypes", data);
  }
  updateProfessionalServices(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveOrganizationServices", data);
  }
  updateOrganizationInfo(data: any) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveORUpdateOrgInfo", data);
  }
  updateSubscription(data: UserSubscription) {
    console.log(data);
    return this.http.post<boolean>("/api/user/SaveUserEmailSubscriptions", data);
  }
  updateLandingPage(UserId: number, selectedLandingId: number) {
    return this.http.post<boolean>("/api/user/SaveProfileLanding", {
      "UserId": UserId,
      "selectedLandingId": selectedLandingId
    });
  }
  updateProfileVisibleStatus(UserId: number, status: number, emailStatus: number, phoneStatus: number) {
    return this.http.post<boolean>("/api/user/saveProfileVisibilityStatus", {
      "AppUserId": UserId,
      "status": status,
      "emailStatus": emailStatus, "phoneStatus": phoneStatus

    });
  }
  GetEndorsements(search: ISearchEndorsements): Observable<IEndorsements[]> {
    let params: any = { 'endorsementType': search.endorseType, 'userId': search.userId }
    return this.http.get<IEndorsements[]>("/api/user/GetUserEndorsements", { params: params });
  }
  GetNotes(search: ISearchNotes): Observable<INotes[]> {
    let params: any = { 'userId': search.userId, 'forUserId': search.forUserId }
    return this.http.get<INotes[]>("/api/user/GetUserNotes", { params: params });
  }
  sendEndorsement(data: any) {
    return this.http.post<boolean>("/api/user/SubmitUserEndorsement", data);
  }
  saveNotes(data: any) {
    return this.http.post<boolean>("/api/user/SaveUserNotes", data);
  }
  changePassword(data: IChangePasswordDto) {
    return this.http.post("/api/user/ChangePassword", data, { observe: 'body', responseType: 'text' });
  }

  getUserMembershipStatuses(): Observable<UserMembershipPlansStatus[]> {
    return this.http.get<UserMembershipPlansStatus[]>("/api/user/GetUserMembershipStatuses");
  }
}
