import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { takeUntil, filter, map, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { ChangePageName } from 'src/app/store/actions/app.action';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';
import { PromotionPaymentSummaryDto } from '../../store/state/create-ads';

@Component({
  selector: 'bzg-promotionpurchase-receipt',
  templateUrl: './promotionpurchase-receipt.component.html',
  styleUrls: ['./promotionpurchase-receipt.component.scss'],
  providers: [
    TranslatePipe
  ]
})
export class PromotionpurchaseReceiptComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  inSuffecientData: boolean = false;
  data: PromotionPaymentSummaryDto;
  hasError: boolean = false;
  timeZone: string;
  currency: string;
  pageClosed = new Subject();
  transactionNo: string;
  paymentId: number;
  currentURL: string;
  communityId: number = 0;
  isTenantAdminPage: boolean = false;
  isCommunityAdminPage: boolean = false;
  
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private translate: TranslatePipe,
    private authService: AuthService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private el: ElementRef,  
    private store: Store,
    private paymentService: PaymentService) {
    this.renderer.addClass(document.body, 'side-closed');
    this.renderer.addClass(document.body, 'submenu-closed');
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { transactionNo: string, paymentId: number };
    if (state) {
      this.transactionNo = state.transactionNo;
      this.paymentId = state.paymentId;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(ChangePageName({ pageName: this.translate.transform('advertisement.payment-receipt-page-name') }));
    this.store.select(selectApplicationConfiguration).pipe(
      takeUntil(this.pageClosed),
      filter(s => s.clientConfiguration != null),
      map(s => s.clientConfiguration),
    ).subscribe(s => {
      this.timeZone = s.timeZone;
      this.currency = s.currencyCode
    })

    this.currentURL = this.router.url;
    this.activatedRoute.params.subscribe(async (route: any) => {
       this.communityId = route.communityId > 0 ? route.communityId : 0;
    });
    if (this.currentURL.startsWith("/admin") && this.communityId == 0) {
      this.isTenantAdminPage = true;
    } else if (this.communityId > 0 && this.currentURL.startsWith("/admin/community")) {
      this.isCommunityAdminPage = true;
    }
   // this.paymentId = 4750;
    if (this.paymentId) {
      this.loading = true;
      this.paymentService.getPromotionPaymentSummary(this.paymentId).subscribe({
        next: (response) => {
          this.loading = false;
          this.data = response;
          console.log(this.data,"data");
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.hasError = true;
          console.log(error);
        }
      })
    } else {
      this.loading = false;
      this.inSuffecientData = true;
    }
  }

  ngAfterViewInit(): void {
   
    if (this.isTenantAdminPage || this.isCommunityAdminPage) {
      // Replace 'your-element-id' with the actual ID of the element you want to modify
      const element = this.el.nativeElement.querySelector('#advertisement-member-admin-purchase-receipt-section');

      // Replace 'your-class' with the class you want to remove
      this.renderer.removeClass(element, 'content');
    }
    
  }

  ngOnDestroy(): void {
    this.pageClosed.next(null);
    this.pageClosed.complete();
  }

}
