<ng-container *ngIf="tenant | async as tenant">
  <div [ngSwitch]="tenant.tenantId">
    <!-- <ng-container *ngSwitchCase="'gatewaychamber'">
      <bzg-gwc-template></bzg-gwc-template>
    </ng-container>
    <ng-container *ngSwitchCase="'gwcuat'">
      <bzg-gwc-template></bzg-gwc-template>
    </ng-container> -->
    <ng-container *ngSwitchDefault>
      <section class="content homePage fullWidthMainContent">
        <ng-container *ngIf="homePage | async as home">
          <ng-container *ngIf="!isCustomHomePage && home.loaded">
            <section class="homeBannerSection commonHomeLayoutContent"
              style="background-image: url('assets/images/company/company-meeting.jpg')">
              <div class="container">
                <div class="row">
                  <div class="col-xl-7 col-lg-8 col-xxl-8 col-md-8 mx-auto">
                    <div class="homeBannerSectionText">
                      <h1> We combine smart design with rich technology to craft innovative
                        brand
                      </h1> <a hrf="#" class="btn btn-basic"><i class="fas fa-arrow-down"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="homeAboutSection">
              <div class="container custom-container">
                <div class="row align-items-center">
                  <div class="col-lg-6 homeAboutSectionLeftPart">
                    <a [routerLink]="['/advertisement/create-ad']" *ngAuthorize="{keyName:'MEMBERADS'}">
                      <h3 class="homeAboutSection--Title"><b>Advertise</b> Your Business</h3>
                    </a>
                    <!-- <h3 class="homeAboutSection--Title">Advertisements</h3> -->
                    <bzg-advertisement [hideHeader]="true" [pageName]="'Home'"
                      *ngAuthorize="{keyName:'MEMBERADS'}"></bzg-advertisement>
                  </div>
                  <div class="col-lg-6 mt-5 mt-lg-0 homeAboutSectionRightPart">
                    <h3 class="homeAboutSection--Title">About <b>Creative</b></h3>
                    <p> Creative thinking is the ability to consider something in a new
                      way.Creative thinking includes analysis, open-mindedness,
                      problem-solving, organization and communication.
                    </p>
                    <p> Many employers value creative thinkers, so consider highlighting your
                      creative thinking skills on your resume and in interviews.
                    </p> <button type="button" class="btn btn-theme-secondary btn-lg"> Reach Us
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <section class="homeKeyPointsSection" style="background-image: url('assets/images/home/keypoints.jpg')">
              <div class="container custom-container">
                <h3 class="homeKeyPointsSection--Title"> Take your <b>business</b> to the next level
                </h3>
                <ul class="list-unstyled homeKeyPointsSectionUL">
                  <li> <img src="assets/images/home/business.png" class="img-fluid" />
                    <h4>Put your business front and center</h4>
                  </li>
                  <li> <img src="assets/images/home/cost.png" class="img-fluid" />
                    <h4>Cost effective</h4>
                  </li>
                  <li> <img src="assets/images/home/audience.png" class="img-fluid" />
                    <h4>Reach specific audiences</h4>
                  </li>
                  <li> <img src="assets/images/home/connections.png" class="img-fluid" />
                    <h4>New Connections</h4>
                  </li>
                </ul>
              </div>
            </section>
            <section class="homeTestimonialSection">
              <div class="container custom-container">
                <h3 class="homeTestimonialSection--Title">What <b>People</b> Say</h3>
                <div class="row">
                  <div class="col-md-4">
                    <div class="homeTestimonialCard"> <img src="https://i.pravatar.cc/150?img=1" class="img-fluid" />
                      <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
                        itaque quidem vitae nostrum aspernatur facere culpa dolorum
                        consequatur non, commodi molestias quam harum, nesciunt, tempore
                        aliquid esse corrupti soluta exercitationem.
                      </p>
                      <div class="homeTestimonialCard__Quote">
                        <h5><i class="fas fa-quote-left"></i></h5>
                        <h4>SHOKO MUGIKURA</h4>
                        <h6>DESIGN MANAGER</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="homeTestimonialCard"> <img src="https://i.pravatar.cc/150?img=2" class="img-fluid" />
                      <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
                        itaque quidem vitae nostrum aspernatur facere culpa dolorum
                        consequatur non, commodi molestias quam harum, nesciunt, tempore
                        aliquid esse corrupti soluta exercitationem.
                      </p>
                      <div class="homeTestimonialCard__Quote">
                        <h5><i class="fas fa-quote-left"></i></h5>
                        <h4>Alexander smith</h4>
                        <h6>CO founder/ CEO</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="homeTestimonialCard"> <img src="https://i.pravatar.cc/150?img=3" class="img-fluid" />
                      <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
                        itaque quidem vitae nostrum aspernatur facere culpa dolorum
                        consequatur non, commodi molestias quam harum, nesciunt, tempore
                        aliquid esse corrupti soluta exercitationem.
                      </p>
                      <div class="homeTestimonialCard__Quote">
                        <h5><i class="fas fa-quote-left"></i></h5>
                        <h4>LINDSAY SWANSON</h4>
                        <h6>Creative Director</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="homeDigitalProductsSection">
              <div class="container custom-container">
                <div class="row">
                  <div class="col-md-6">
                    <h3 class="homeDigitalProductsSection--Title"> We are delivering beautiful <b>digital products</b>
                      for
                      you.
                    </h3>
                    <div class="homeProductMedia">
                      <div class="homeProductMedia--Icon"> <i class="fas fa-window-restore"></i> </div>
                      <div class="homeProductMedia--Text">Powerful website Builder</div>
                    </div>
                    <div class="homeProductMedia">
                      <div class="homeProductMedia--Icon"> <i class="fas fa-outdent"></i> </div>
                      <div class="homeProductMedia--Text">Different Layout Type</div>
                    </div>
                    <div class="homeProductMedia">
                      <div class="homeProductMedia--Icon"> <i class="fas fa-laptop"></i> </div>
                      <div class="homeProductMedia--Text">True Responsiveness</div>
                    </div>
                    <div class="homeProductMedia">
                      <div class="homeProductMedia--Icon"> <i class="fas fa-lightbulb"></i> </div>
                      <div class="homeProductMedia--Text">Elegant & Unique design</div>
                    </div> <button type="button" class="mt-4 btn btn-theme-secondary btn-lg"> Reach Us
                    </button>
                  </div>
                  <div class="col-md-6 d-md-none mt-5 mt-md-0"> <img src="assets/images/home/digital-products.jpg"
                      class="img-fluid" /> </div>
                </div>
              </div>
            </section>
          </ng-container>
          <ng-container *ngIf="isCustomHomePage && home.loaded">
            <!-- <bzg-html2 [data]="home.data"></bzg-html2> -->
            <bzg-html3 [data]="home.data"></bzg-html3>
          </ng-container>
        </ng-container>
      </section>
    </ng-container>
  </div>
</ng-container>