import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class EventsModuleLanguageResolver implements Resolve<LocaleResourceSet> {

  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    //Events Page
    this.translateService.set('events.event-page-name', 'Events', 'en');
    this.translateService.set('events.event-name.placeholder', 'Event Name', 'en');
    this.translateService.set('events.select-category', 'Select Category', 'en');
    this.translateService.set('events.event-location.placeholder', 'Event Location', 'en');
    this.translateService.set('events.event-date.placeholder', 'Event Date', 'en');
    this.translateService.set('events.loading', 'Loading...', 'en');
    this.translateService.set('events.search', 'Search', 'en');
    this.translateService.set('events.reset', 'Reset', 'en');
    this.translateService.set('events.upcoming-events', 'Upcoming Events', 'en');
    this.translateService.set('events.no-more-events-found', 'No More Events Found', 'en');
    this.translateService.set('events.past-events', 'Past Events', 'en');
    this.translateService.set('events.search-results', 'Search Results', 'en');
    this.translateService.set('events.no-result', 'Oops...we did not find anything that matches this search:(', 'en');
    this.translateService.set('events.search-again', 'Try Search for something more general, change the filters.', 'en');
    this.translateService.set('events.no-more-upcoming-events-found', 'No upcoming Events as of now', 'en');
    this.translateService.set('events.no-more-past-events-found', 'No more Past Events ', 'en');
    // latest changes
    this.translateService.set('events.no-upcoming-events-image', '/assets/images/community-details/no_groups.png', 'en');
    this.translateService.set('events.no-result-image', 'assets/images/member-directory/md-noresult.png', 'en');
    this.translateService.set('events.valid-info', 'Please provide valid info', 'en');

    //-----------------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("events").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
