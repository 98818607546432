import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class EventInfoSharedModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
    this.translateService.set('event-info.registered', 'Registered', 'en');
    this.translateService.set('event-info.join' , 'Join', 'en');
    // latest changes
    this.translateService.set('event-info.hybrid' , 'Hybrid', 'en');
    this.translateService.set('event-info.online' , 'Online', 'en');
    this.translateService.set('event-info.in-person' , 'In person', 'en');
    
    //--------------------------------------------------------------------------
    return this.localResourceService.loadLocalResource("event-info").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
