import { Component, Inject, Input, OnInit } from '@angular/core';
import { PaymentMethod, payUPaymentRequestData } from 'src/app/models/payment/payment-method';
import { ConfigItem } from '../paypal/paypal.component';
import { HttpClient } from '@angular/common/http';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogData, MessageDailogComponent } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'bzg-payu',
  templateUrl: './payu.component.html',
  styleUrls: ['./payu.component.scss']
})
export class PayuComponent implements OnInit {

  @Input("paymentData") paymentData: payUPaymentRequestData;
  @Input("config") config: PaymentMethod;
  @Input("prePaymentUrl") prePaymentUrl?: string;
  @Input("beforeValidation") beforeValidation?: boolean = false;
  @Input("beforeValidationData") beforeValidationData?: any;
  @Input("prePaymentData") prePaymentData?: any;
  @Input("currency") currency?: any;

  isLoading: boolean = true;
  loadingText: string = "Loading...";
  error: string[] = [];
  hasError: boolean = false;
  merchantKey: string;
  tenantId: string;
  isTxnIdFetching: boolean = true;

  constructor(
    private http: HttpClient,
    private store: Store,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  async ngOnInit() {

    if (this.config && this.config.configure) {
      let config = JSON.parse(this.config.configure) as ConfigItem[];
      let info = config.find(x => x.ControlID?.toLowerCase() == "merchantkey");
      if (info == undefined || info.DefaultValue == "") {
        this.hasError = true;
        this.error.push("Merchant Key is not configured");
      } else {
        this.merchantKey = info.DefaultValue;
        this.paymentData.key = this.merchantKey;
      }

      this.store.select(selectApplicationConfiguration).pipe(
        map(config => config.tenant),
        filter(tenantId => tenantId != null),
        take(1)
      ).subscribe(tenant => {
        this.tenantId = tenant.tenantId;
        if (!environment.production) {
          this.tenantId = this.authService.getCurrentTenant();
        }

        const successUrlSearchParams = new URLSearchParams(this.paymentData.successUrl.split("?")[1]);
        const failureUrlSearchParams = new URLSearchParams(this.paymentData.failureUrl.split("?")[1]);

        if (!successUrlSearchParams.has("tenant")) {
          this.paymentData.successUrl = this.paymentData.successUrl + '?tenant=' + this.tenantId;
        }

        if (!failureUrlSearchParams.has("tenant")) {
          this.paymentData.failureUrl = this.paymentData.failureUrl + '?tenant=' + this.tenantId;
        }
      })

    } else {
      this.hasError = true;
      this.error.push("PayU is not configured");
    }

    if (this.currency.toUpperCase() != "INR") {
      this.hasError = true;
      this.error.push("PayU doesn't support this currency at the moment.");
    }

    try {
      let txnId = await this.getRefId();
      if (txnId && parseInt(this.paymentData.txnid) > 0) {
        await this.getHashValue();
      }
    } catch {
      this.isLoading = false;
      this.isTxnIdFetching = true;
    }
  }

  getHashValue(): Promise<object> {
    return new Promise((resolve, reject) => {
      this.http.post('/api/common/generate-hash', this.paymentData, { responseType: 'text' }).subscribe({
        next: (data: any) => {
          this.paymentData.hash = data;
          this.isLoading = false;
          resolve(data);
        },
        error: (err) => {
          this.paymentData.hash = "";
          reject(err);
          console.log(err);
        }
      })
    });
  }

  getRefId(): Promise<object> {
    return new Promise((resolve, reject) => {
      
      if (this.prePaymentUrl && !this.beforeValidation) {
        this.http.post(this.prePaymentUrl, this.prePaymentData).subscribe({
          next: (data: any) => {
            if(data.value){
              this.paymentData.txnid = data.value.toString();
            }else{
              this.paymentData.txnid = data.toString();
            }
            
            resolve(data);
            this.isTxnIdFetching = false;
          },
          error: (err) => {
            this.paymentData.txnid = "";
            reject(err);
            console.log(err);
          }
        })
      }
      else if (this.prePaymentUrl && this.beforeValidation) {
        this.http.post(this.prePaymentUrl, this.beforeValidationData).subscribe({
          next: (data: any) => {
            this.isLoading = false;
            this.paymentData.txnid = this.prePaymentData.paymentId.toString();
            if (data == true) {
              this.paymentData.txnid = this.paymentData.txnid;
            }
            else {
              let dailogData: DialogData = {
                title: 'Error',
                messege:
                  'It seems ticket(s) or applied promocode is not available now, please try again after sometime.',
              };
              let dailogRef = this.dialog.open(MessageDailogComponent, {
                width: '400px',
                maxWidth: '100%',
                maxHeight: '100%',
                role: 'alertdialog',
                disableClose: true,
                data: dailogData,
              });

              dailogRef.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            }
            this.isTxnIdFetching = false;
            resolve(this.prePaymentData.paymentId);
          },
          error: (err) => {
            this.isLoading = false;
            this.paymentData.txnid = "";
            reject(err);
            if (err.status == 500 && this.prePaymentData.paymentPurpose == 'PROMOTION_PLAN_PURCHASE') {
              Swal.fire({
                title: '',
                text: err.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/advertisement/my-advertisements']);
                } else {

                }
              });
            }
            console.log(err);
          }
        })
      }
      else {
        resolve({});
      }
    });
  }

}

