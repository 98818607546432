import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'bzg-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  //encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('data') html: string;
  data: SafeHtml;
  @ViewChild('html', { static: false }) htmlDiv: ElementRef;
  //script: SafeScript;
  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router : Router
  ) { }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    //this.paintHtml();
  }
  onClick(event: Event) {
    let element = event.target as HTMLElement;
    if (element.tagName == "A") {
       let link = element.getAttribute("href");
    //   element.setAttribute('target', '_blank');
    //   if (!link.includes("#")) {
    //     event.preventDefault();
    //     console.log("Navigating : ", link);
    //     this.router.navigateByUrl(link);
    //   } else {
    //     this.router.navigateByUrl(link);
    //   }
    // }
    if (link && link.includes("#")) {
      // Extract the anchor part of the link
      let anchor = link.substring(link.indexOf("#") + 1);
      if (anchor) {
        event.preventDefault();
        const shadowRoot = this.elementRef.nativeElement.shadowRoot;
        if (shadowRoot) {
          const dynamicIdElement = shadowRoot.getElementById(anchor);
          if (dynamicIdElement) {
            dynamicIdElement.scrollIntoView({ behavior: "smooth" });
            }
        }       
      }
    } else {
      element.setAttribute('target', '_blank');
      console.log("Navigating : ", link);
      //this.router.navigateByUrl(link);
    }
  }
  
  }
  // onClick(event: Event) {
  //   let element = event.target as HTMLElement;
  //   if (element.tagName == "A") {
  //     let link = element.getAttribute("href");
  //     element.setAttribute('target', '_blank');
  //     // if (!link.includes("#")) {
  //     //   event.preventDefault();
  //     //   console.log("Navigating : ", link);
  //     //   this.router.navigateByUrl(link);
  //     // } else {
  //     //   console.log(link);
  //     // }
  //   }
  // }
  ngOnChanges(changes: SimpleChanges): void {
    this.paintHtml();
  }

  paintHtml() {
    let d = document.createElement("body");

    //console.log(this.html);

    // let cssLink = `
    // <link href="https://tenant1.bizligotest.com/bundles/layout/themes/Default?v=jadNOZO0JCkQnrc__EEKVI-rxzSO11sXxW8X6yh4Vuc1" rel="stylesheet" />
    // <link href="https://tenant1.bizligotest.com/bundles/layout/css/member?v=7xrXFGWy-X2EJmJVjKofQJ9rmgfKw9bO4H3yphbR3LM1" rel="stylesheet" />
    // <link href="https://localhost:44391/Content/Css/Events/multi1.css" rel="stylesheet" />

    // `;
    d.innerHTML = this.html;// + cssLink;
    let customScripts = d.querySelectorAll("script");
    //let customStyles = d.querySelectorAll("style");
    //let customLinks = d.querySelectorAll("link");

    customScripts.forEach(script => {

      var scriptToInject = document.createElement("script")
      scriptToInject.type = "text/javascript";
      if (script.src.length > 0) {
        scriptToInject.src = script.src;
      }

      if (script.innerText.length > 0) {
        scriptToInject.innerText = script.innerText;
      }
      this.renderer.appendChild(this.elementRef.nativeElement, scriptToInject);
      script.remove();
    });

    //console.log("element ", this.elementRef.nativeElement);
    this.data = this.sanitizer.bypassSecurityTrustHtml(d.innerHTML);
    //console.log(this.htmlDiv.nativeElement);
    //this.renderer.setProperty(this.htmlDiv.nativeElement, "innerHTML", d.innerHTML);
    // var iframe =this.elementRef.nativeElement.querySelectorAll("iframe")[0] as HTMLIFrameElement;
    // iframe.contentDocument.body.innerHTML = d.innerHTML;
    // iframe.contentDocument.head.appendChild($(cssLink)[0]);
  }
}
