import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ActiveFeatureGuard } from './active-feature.guard';
import { ActiveUserGuard } from './active-user.guard';
import { AppLanguageResolver } from './app-language.resolver';
import { AppResolver } from './app.resolver';
import { BaseComponent } from './common/base/base.component';
//import { CommunityLayoutComponent } from './common/community-layout/community-layout.component';
import { LayoutComponent } from './common/layout/layout.component';
import { ImageCroppieComponent } from './common/shared/components/image-croppie/image-croppie.component';
import { MultiSelectComponent } from './common/shared/components/multi-select/multi-select.component';
import { PageNotFoundComponent } from './common/shared/components/page-not-found/page-not-found.component';
import { PermissionDeniedComponent } from './common/shared/components/permission-denied/permission-denied.component';
import { SelectComponent } from './common/shared/components/select/select.component';
import { AuthModuleLanguageResolver } from './module/auth/auth-module-language.resolver';
import { BlogsModuleLanguageResolver } from './module/blogs/blogs-module-language.resolver';
import { BusinessDirectoryModuleLanguageResolver } from './module/business-directory/business-directory-module-language.resolver';
import { CommonPagesModuleLanguageResolver } from './module/common-pages/pages/feature-not-found/common-pages-module-language';
import { CommunitiesModuleLanguageResolver } from './module/communities/communities-module-language.resolver';
import { CommunityDetailsModuleLanguageResolver } from './module/community-details/community-details-module-language.resolver';
import { ConnectionsModuleLanguageResolver } from './module/connections/connections-module-language.resolver';
import { ContactUsModuleLanguageResolver } from './module/contact-us/pages/contact-us/contact-us-module-language.resolver';
import { DashboardModuleLanguageResolver } from './module/dashboard/dashboard-module-language.resolver';
import { EndorsementsModuleLanguageResolver } from './module/endorsements/endorsements-module-language.resolver';
import { EventDetailsModuleLanguageResolver } from './module/event-details/event-details-module-language.resolver';
import { EventsModuleLanguageResolver } from './module/events/events-module-language.resolver';
import { GroupDetailsModuleLanguageResolver } from './module/group-details/group-details-module-language.resolver';
import { HelpModuleLanguageResolver } from './module/help/help-module-language.resolver';
import { HomeModuleLanguageResolver } from './module/home/home-module-language.resolver';
import { HomeComponent } from './module/home/home.component';
import { CorwaHomeComponent } from './module/home/pages/corwa-home/corwa-home.component';
import { GwcTemplateComponent } from './module/home/pages/gwc-template/gwc-template.component';
import { JobDetailsModuleLanguageResolver } from './module/job-details/job-details-module-language.resolver';
import { MemberModuleLanguageResolver } from './module/member/member-module-language.resolver';
import { MessagesModuleLanguageResolver } from './module/messages/messages-module-language.resolver';
import { MyEventsModuleLanguageResolver } from './module/my-events/my-events-module-language.resolver';
import { MyGroupsModuleLanguageResolver } from './module/my-groups/my-groups-module-language.resolver';
import { MyJobsModuleLanguageResolver } from './module/my-jobs/my-jobs-module-language.resolver';
import { MyMatchesModuleLanguageResolver } from './module/my-matches/my-matches-module-language.resolver';
import { ProfileModuleLanguageResolver } from './module/my-profile/profile-module-language.resolver';
import { MyRemindersModuleLanguageResolver } from './module/my-reminders/my-reminders-module-language.resolver';
import { MyTestimonialsModuleLanguageResolver } from './module/my-testimonials/my-testimonials-module-language.resolver';
import { PagesModuleLanguageResolver } from './module/pages/pages/page/page-module-language.resolver';
import { RemindersHistoryModuleLanguageResolver } from './module/reminders-history/reminders-history-module-language.resolver';
import { AdvertisementSharedModuleLanguageResolver } from './module/shared/advertisement/advertisement-module-language.resolver';
import { DiscussionSharedModuleLanguageResolver } from './module/shared/discussions/discussion-module-language.resolver';
import { EventInfoSharedModuleLanguageResolver } from './module/shared/event-info/event-info-module-language.resolver';
import { GroupsResolver } from './module/shared/group/groups.resolver';
import { MemberSharedModuleLanguageResolver } from './module/shared/member/member-module-language.resolver';
import { PaymentgatewaySharedModuleLanguageResolver } from './module/shared/paymentgateway/paymentgateway-module-language.resolver';
import { TestimonialsModuleLanguageResolver } from './module/testimonials/testimoinials-module-language.resolver';
import { environment } from 'src/environments/environment';
import { GlobalCareersModuleLanguageResolver } from './module/global-careers/global-careers-module-language.resolver';
import { AdvertisementModuleLanguageResolver } from './module/advertisement/advertisement-module-language.resolver';
import { SurveyModuleLanguageResolver } from './module/survey/survey-module-language.resolver';
import { LoyaltyDetailsModuleLanguageResolver } from './module/loyalty-details/loyalty-details-module-language.resolver';
import { AdminLayoutComponent } from './admin/layout/admin-layout/admin-layout.component';
import { AccountSuspendedComponent } from './module/home/pages/account-suspended/account-suspended.component';
import { GroupsManageMembersComponent } from './admin/module/admin-manage-groups/pages/groups-manage-members/groups-manage-members.component';


const routes: Routes = [
  {
    path: '', component: BaseComponent, resolve: { app: AppResolver, lang: AppLanguageResolver }, children: [
      {
        path: '', component: LayoutComponent, children: [
          {
            path: '',
            pathMatch: 'full',
            component: HomeComponent,
            resolve: { lang1: AdvertisementSharedModuleLanguageResolver }
          },
          environment.production == false ? {
            path: 'corwa-home',
            component: CorwaHomeComponent,
            resolve: { lang1: AdvertisementSharedModuleLanguageResolver },
          } : { path: 'corwa-home', redirectTo: '/404-not-found' },
          environment.production == false ? {
            path: 'gwc-home',
            component: GwcTemplateComponent,
            resolve: { lang1: AdvertisementSharedModuleLanguageResolver },
          } : { path: 'gwc-home', redirectTo: '/404-not-found' },
          { path: 'account-status', component: AccountSuspendedComponent },
          {
            path: '',
            loadChildren: () => import('./module/dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: DashboardModuleLanguageResolver, lang1: AdvertisementSharedModuleLanguageResolver }
          },
          {
            path: 'profile',
            loadChildren: () => import('./module/my-profile/my-profile.module').then(m => m.MyProfileModule),
            // canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: ProfileModuleLanguageResolver, lang1: MemberModuleLanguageResolver }
          },
          {
            path: 'groupadmin',
            loadChildren: () =>
              import('./module/member-group-admin/member-group-admin.module').then((m) => m.MemberGroupAdminModule),

          },
          {
            path: 'member',
            loadChildren: () =>
              import('./module/member/member.module').then((m) => m.MemberModule),
            resolve: { lang: MemberModuleLanguageResolver, lang1: AdvertisementSharedModuleLanguageResolver, lang2: MemberSharedModuleLanguageResolver },
            canActivate: [ActiveFeatureGuard],
          },
          {
            path: 'community',
            loadChildren: () =>
              import('./module/community-details/community-details.module').then((m) => m.CommunityDetailsModule),

            resolve: {
              lang: CommunityDetailsModuleLanguageResolver,
              lang1: PaymentgatewaySharedModuleLanguageResolver,
              lang2: MemberSharedModuleLanguageResolver,
              lang3: AdvertisementSharedModuleLanguageResolver,
              lang4: DiscussionSharedModuleLanguageResolver,
              lang5: GroupDetailsModuleLanguageResolver,
              lang6: EventInfoSharedModuleLanguageResolver,
              lang7: CommunitiesModuleLanguageResolver,
              lang8: PaymentgatewaySharedModuleLanguageResolver
            }

          },
          {
            path: 'events',
            loadChildren: () =>
              import('./module/events/events.module').then((m) => m.EventsModule),
            resolve: { lang: EventsModuleLanguageResolver, lang1: EventInfoSharedModuleLanguageResolver }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'event',
            loadChildren: () =>
              import('./module/event-details/event-details.module').then((m) => m.EventDetailsModule),
            resolve: { lang: EventDetailsModuleLanguageResolver, lang1: PaymentgatewaySharedModuleLanguageResolver, lang2: EventInfoSharedModuleLanguageResolver, lang3: CommunityDetailsModuleLanguageResolver }
          },
          {
            path: 'blogs',
            loadChildren: () =>
              import('./module/blogs/blogs.module').then((m) => m.BlogsModule),
            resolve: { lang: BlogsModuleLanguageResolver }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'survey',
            loadChildren: () =>
              import('./module/survey/survey.module').then((m) => m.SurveyModule),
            resolve: { lang: SurveyModuleLanguageResolver }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'communities',
            loadChildren: () =>
              import('./module/communities/communities.module').then((m) => m.CommunitiesModule),
            resolve: {
              lang: CommunitiesModuleLanguageResolver,
              lang1: PaymentgatewaySharedModuleLanguageResolver,
              lang2: AdvertisementSharedModuleLanguageResolver
            }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'mygroups',
            loadChildren: () =>
              import('./module/my-groups/my-groups.module').then((m) => m.MyGroupsModule),
            resolve: { lang: MyGroupsModuleLanguageResolver, lang1: GroupsResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'my-events',
            loadChildren: () =>
              import('./module/my-events/my-events.module').then((m) => m.MyEventsModule),
            resolve: { lang: MyEventsModuleLanguageResolver, lang2: EventInfoSharedModuleLanguageResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'global-careers',
            loadChildren: () =>
              import('./module/global-careers/global-careers.module').then((m) => m.GlobalCareersModule),
            resolve: { lang: GlobalCareersModuleLanguageResolver },
            canActivate: [ActiveUserGuard],
          },
          {
            path: 'group',
            loadChildren: () =>
              import('./module/group-details/group-details.module').then((m) => m.GroupDetailsModule),
            resolve: {
              lang: GroupDetailsModuleLanguageResolver,
              lang1: DiscussionSharedModuleLanguageResolver,
              lang2: MemberSharedModuleLanguageResolver,
              lang3: CommunitiesModuleLanguageResolver,
              lang4: AdvertisementSharedModuleLanguageResolver,
              lang5: EventInfoSharedModuleLanguageResolver,
            }
          },
          {
            path: 'member/endorsements',
            loadChildren: () =>
              import('./module/endorsements/endorsements.module').then((m) => m.EndorsementsModule),
            canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: EndorsementsModuleLanguageResolver }
          },
          {
            path: 'member/connections',
            loadChildren: () =>
              import('./module/connections/connections.module').then((m) => m.ConnectionsModule),
            canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: ConnectionsModuleLanguageResolver }
          },
          {
            path: 'my-matches',
            loadChildren: () =>
              import('./module/my-matches/my-matches.module').then((m) => m.MyMatchesModule),
            canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: MyMatchesModuleLanguageResolver, lang1: ConnectionsModuleLanguageResolver, lang2: MemberModuleLanguageResolver, lang3: MemberSharedModuleLanguageResolver }
          },
          {
            path: 'my-reminders',
            loadChildren: () =>
              import('./module/my-reminders/my-reminders.module').then((m) => m.MyRemindersModule),
            resolve: { lang: MyRemindersModuleLanguageResolver, lang1: CommunitiesModuleLanguageResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'reminders-history',
            loadChildren: () =>
              import('./module/reminders-history/reminders-history.module').then((m) => m.RemindersHistoryModule),
            resolve: { lang: RemindersHistoryModuleLanguageResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'pages',
            loadChildren: () =>
              import('./module/pages/pages.module').then((m) => m.PagesModule),
            resolve: { lang: PagesModuleLanguageResolver }
          },
          {
            path: 'contact-us',
            loadChildren: () =>
              import('./module/contact-us/contact-us.module').then((m) => m.ContactUsModule),
            resolve: { lang: ContactUsModuleLanguageResolver }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'business-directory',
            loadChildren: () =>
              import('./module/business-directory/business-directory.module').then((m) => m.BusinessDirectoryModule),
            resolve: { lang: BusinessDirectoryModuleLanguageResolver, lang1: AdvertisementSharedModuleLanguageResolver }
            //canActivate: [ActiveUserGuard],
          },
          {
            path: 'messages',
            loadChildren: () =>
              import('./module/messages/messages.module').then((m) => m.MessagesModule),
            resolve: { lang: BlogsModuleLanguageResolver, lang1: MessagesModuleLanguageResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'help',
            loadChildren: () =>
              import('./module/help/help.module').then((m) => m.HelpModule),
            resolve: { lang: ContactUsModuleLanguageResolver, lang1: HelpModuleLanguageResolver }
          },
          {
            path: 'testimonials',
            loadChildren: () =>
              import('./module/testimonials/testimonials.module').then((m) => m.TestimonialsModule),
            resolve: { lang: TestimonialsModuleLanguageResolver }
          },
          environment.production == false ?
            {
              path: 'templates',
              loadChildren: () =>
                import('./module/templates/templates.module').then((m) => m.TemplatesModule),
            } : { path: 'templates', redirectTo: '/404-not-found' },
          {
            path: 'loyalty',
            loadChildren: () =>
              import('./module/loyalty-details/loyalty-details.module').then((m) => m.LoyaltyDetailsModule),
            canActivate: [MsalGuard, ActiveUserGuard],
            resolve: { lang: LoyaltyDetailsModuleLanguageResolver }
          },
          {
            path: 'my-jobs',
            loadChildren: () =>
              import('./module/my-jobs/my-jobs.module').then((m) => m.MyJobsModule),
            resolve: { lang: MyJobsModuleLanguageResolver },
            canActivate: [ActiveUserGuard],
          },
          {
            path: 'my-testimonials',
            loadChildren: () =>
              import('./module/my-testimonials/my-testimonials.module').then((m) => m.MyTestimonialsModule),
            resolve: { lang: MyTestimonialsModuleLanguageResolver }
          },
          {
            path: 'job',
            loadChildren: () =>
              import('./module/job-details/job-details.module').then((m) => m.JobDetailsModule),
            resolve: { lang: JobDetailsModuleLanguageResolver },
            canActivate: [ActiveUserGuard],
          },
          {
            path: 'advertisement',
            loadChildren: () =>
              import('./module/advertisement/advertisement.module').then((m) => m.AdvertisementModule),
            resolve: { lang: AdvertisementModuleLanguageResolver, lang1: PaymentgatewaySharedModuleLanguageResolver, lang2: CommunitiesModuleLanguageResolver },
            canActivate: [MsalGuard, ActiveUserGuard],
          },
          {
            path: 'unsubscribedigestemails',
            loadChildren: () =>
              import('./module/unsubscribe-email/unsubscribe-email.module').then((m) => m.UnsubscribeEmailModule)
          }
        ],
      },
      {
        path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [MsalGuard, ActiveUserGuard],
      },
      {
        path: 'super-admin', loadChildren: () => import('./superAdmin/super-admin.module').then(m => m.SuperAdminModule),
        canActivate: [MsalGuard, ActiveUserGuard]
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./module/auth/auth.module').then((m) => m.AuthModule),
        resolve: { lang: AuthModuleLanguageResolver, lang1: PaymentgatewaySharedModuleLanguageResolver },
      },
      environment.production == false ? {
        path: 'example',
        loadChildren: () =>
          import('./example/example.module').then((m) => m.ExampleModule)
      } : { path: 'example', redirectTo: '/404-not-found' },
      {
        path: 'common',
        loadChildren: () =>
          import('./module/common-pages/common-pages.module').then((m) => m.CommonPagesModule),
        resolve: { lang: CommonPagesModuleLanguageResolver }
      },
      {
        path: 'business-directory-mobile',
        loadChildren: () =>
          import('./module/business-directory/business-directory.module').then((m) => m.BusinessDirectoryModule),
        resolve: { lang: BusinessDirectoryModuleLanguageResolver, lang1: AdvertisementSharedModuleLanguageResolver }
        //canActivate: [ActiveUserGuard],
      },
      {
        path: 'my-reminders-mobile',
        loadChildren: () =>
          import('./module/my-reminders/my-reminders.module').then((m) => m.MyRemindersModule),
        resolve: { lang: MyRemindersModuleLanguageResolver, lang1: CommunitiesModuleLanguageResolver },
        canActivate: [MsalGuard, ActiveUserGuard],
      },
      {
        path: 'contact-us-mobile',
        loadChildren: () =>
          import('./module/contact-us/contact-us.module').then((m) => m.ContactUsModule),
        resolve: { lang: ContactUsModuleLanguageResolver }
        //canActivate: [ActiveUserGuard],
      },
      {
        path: 'paymentgateway-webview',
        loadChildren: () =>
          import('./module/payment-gateway-webview/payment-gateway-webview.module').then((m) => m.PaymentGatewayWebviewModule),
        resolve: { lang: PaymentgatewaySharedModuleLanguageResolver, }
      }
    ],

  },
  environment.production == false ? { path: 'select', component: SelectComponent } : { path: 'select', redirectTo: '/404-not-found' },
  environment.production == false ? { path: 'multi-select', component: MultiSelectComponent } : { path: 'multi-select', redirectTo: '/404-not-found' },
  environment.production == false ? { path: 'croppie', component: ImageCroppieComponent } : { path: 'croppie', redirectTo: '/404-not-found' },
  { path: 'privateGroup', component: PermissionDeniedComponent },
  { path: '404-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AppResolver,
    AppLanguageResolver,
    AuthModuleLanguageResolver,
    CommunityDetailsModuleLanguageResolver,
    ProfileModuleLanguageResolver,
    EventsModuleLanguageResolver,
    EventDetailsModuleLanguageResolver,
    DashboardModuleLanguageResolver,
    CommunitiesModuleLanguageResolver,
    BlogsModuleLanguageResolver,
    MyGroupsModuleLanguageResolver,
    GroupDetailsModuleLanguageResolver,
    PaymentgatewaySharedModuleLanguageResolver,
    MemberSharedModuleLanguageResolver,
    AdvertisementSharedModuleLanguageResolver,
    EventInfoSharedModuleLanguageResolver,
    HomeModuleLanguageResolver,
    DiscussionSharedModuleLanguageResolver,
    EndorsementsModuleLanguageResolver,
    MyRemindersModuleLanguageResolver,
    ConnectionsModuleLanguageResolver,
    RemindersHistoryModuleLanguageResolver,
    ContactUsModuleLanguageResolver,
    MyMatchesModuleLanguageResolver,
    MyEventsModuleLanguageResolver,
    BusinessDirectoryModuleLanguageResolver,
    MessagesModuleLanguageResolver,
    HelpModuleLanguageResolver,
    CommonPagesModuleLanguageResolver,
    PagesModuleLanguageResolver,
    GroupsResolver,
    GlobalCareersModuleLanguageResolver,
    MyTestimonialsModuleLanguageResolver,
    LoyaltyDetailsModuleLanguageResolver
  ],
})
export class AppRoutingModule { }
