import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAdsComponent } from './pages/create-ads/create-ads.component';
import { MyAdvertisementsComponent } from './pages/my-advertisements/my-advertisements.component';
import { PromotionpurchaseReceiptComponent } from './pages/promotionpurchase-receipt/promotionpurchase-receipt.component';
import { PaymentConfirmationComponent } from './pages/payment-confirmation/payment-confirmation.component';
import { ActiveFeatureGuard } from 'src/app/active-feature.guard';
import { PaymentFailedComponent } from './pages/payment-failed/payment-failed.component';

const routes: Routes = [
  {path:'my-advertisements', component:MyAdvertisementsComponent, canActivate: [ActiveFeatureGuard]},
  {path:'create-ad', component:CreateAdsComponent, canActivate: [ActiveFeatureGuard]},
  {path:'edit-ad/:planId', component:CreateAdsComponent, canActivate: [ActiveFeatureGuard]},
  {path:'create-ad/:communityName/:CommunityId', component:CreateAdsComponent, canActivate: [ActiveFeatureGuard]},
  {path: 'promotion-payment-receipt', component: PromotionpurchaseReceiptComponent },
  {path: 'payment-confirmation/:paymentId/:transactionId', component: PaymentConfirmationComponent },
  { path: 'payment-status/:status/:transactionId/:paymentId/:paymentFrom', component: PaymentFailedComponent },
  { path: 'payment-status/:status/:transactionId/:paymentId', component: PaymentFailedComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvertisementRoutingModule { }
