import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { TinySliderInstance, TinySliderSettings, tns } from 'tiny-slider/src/tiny-slider';
import { Sponsor } from '../../home/store/state/home';

@Component({
  selector: 'bzg-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SponsorComponent implements OnInit, OnDestroy {
  isSliderInitialized: boolean = false;
  slider: TinySliderInstance;
  settings: TinySliderSettings = {
    container: '.sponsers-slider',
    items: 3,
    slideBy: 3,
    mouseDrag: true,
    autoplay: true,
    autoplayButtonOutput: false,
    nav: false,
    gutter: 15,
    loop: true,
    edgePadding: 10,
    controlsText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        slideBy: 1,
        items: 1,
      },
      768: {
        slideBy: 4,
        items: 4,
      },
      1200: {
        slideBy: 4,
        items: 4,
        gutter: 30,
      },
      1600: {
        slideBy: 5,
        items: 5,
        gutter: 30,
      }
    }
  }

  sponsors: Sponsor[];
  sponsorError = false;
  sponsorLoading = false;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.sponsorLoading = true;
    this.commonService.getAllSponsors().subscribe({
      next: (data) => {
        this.sponsorLoading = false;
        this.sponsors = data;
      },
      error: (err) => {
        this.sponsorError = true;
        this.sponsorLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  similarBusinessTrackByFn(index: number, item: Sponsor) {
    return item.id;
  }

  initSlider() {

    if (this.isSliderInitialized) return;
    this.isSliderInitialized = true;
    if (this.slider) {
      this.slider.destroy();
    }
    setTimeout(() => {
      this.slider = tns(this.settings)
    }, 10);
    //console.log("init slider completed")
  }
}
