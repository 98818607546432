import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, ElementRef, inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdvertisementComponent } from 'src/app/module/shared/advertisement/advertisement.component';
import { BannerImagesComponent } from 'src/app/module/shared/banner-images/banner-images.component';
import { SponsorComponent } from 'src/app/module/shared/sponsor/sponsor.component';
import { SpotlightEventsComponent } from 'src/app/module/shared/spotlight-event/spotlight-events/spotlight-events.component';
import { TestimonialsDynamicComponent } from 'src/app/module/shared/testimonials/testimonials.component';

@Component({
  selector: 'bzg-html2',
  templateUrl: './html2.component.html',
  styleUrls: ['./html2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Html2Component implements OnInit, AfterViewInit, OnChanges {
  @Input('data') html: string;
  data: SafeHtml;
  @ViewChild('html', { static: false }) htmlDiv: ElementRef;
  //script: SafeScript;
  vcr = inject(ViewContainerRef);

  customComponents: string[] = [
    'bzg-banner-images',
    'bzg-sponsor',
    'bzg-spotlight-events',
    'bzg-testimonials-dynamic',
    'bzg-advertisement'
  ]

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    //this.paintHtml();
  }

  onClick(event: Event) {
    let element = event.target as HTMLElement;
    if (element.tagName == "A") {
      let link = element.getAttribute("href");
      element.setAttribute('target', '_blank');
      // if (!link.includes("#")) {
      //   event.preventDefault();
      //   console.log("Navigating : ", link);
      //   this.router.navigateByUrl(link);
      // } else {
      //   console.log(link);
      // }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.paintHtml();
  }

  paintHtml() {
    let d = document.createElement("body");

    //console.log(this.html);

    // let cssLink = `
    // <link href="https://tenant1.bizligotest.com/bundles/layout/themes/Default?v=jadNOZO0JCkQnrc__EEKVI-rxzSO11sXxW8X6yh4Vuc1" rel="stylesheet" />
    // <link href="https://tenant1.bizligotest.com/bundles/layout/css/member?v=7xrXFGWy-X2EJmJVjKofQJ9rmgfKw9bO4H3yphbR3LM1" rel="stylesheet" />
    // <link href="https://localhost:44391/Content/Css/Events/multi1.css" rel="stylesheet" />

    // `;
    d.innerHTML = this.html;// + cssLink;
    let customScripts = d.querySelectorAll("script");
    //let customStyles = d.querySelectorAll("style");
    //let customLinks = d.querySelectorAll("link");

    customScripts.forEach(script => {

      var scriptToInject = document.createElement("script")
      scriptToInject.type = "text/javascript";
      if (script.src.length > 0) {
        scriptToInject.src = script.src;
      }

      if (script.innerText.length > 0) {
        scriptToInject.innerText = script.innerText;
      }
      this.renderer.appendChild(this.elementRef.nativeElement, scriptToInject);
      script.remove();
    });

    //console.log("element ", this.elementRef.nativeElement);
    this.data = this.sanitizer.bypassSecurityTrustHtml(d.innerHTML);
    //console.log("Html rendered", new Date());
    let el = this.elementRef.nativeElement as HTMLDivElement;
    setTimeout(() => {
      if (el) {

        this.customComponents.forEach(component => {
          let compRef: ComponentRef<BannerImagesComponent | SponsorComponent | SpotlightEventsComponent | TestimonialsDynamicComponent>;

          let elements = el.querySelectorAll(component.toLowerCase());
          //console.log(elements);
          if (elements.length) {


            elements.forEach(el => {
              el.innerHTML = null;
              switch (component.toLowerCase()) {

                case "bzg-spotlight-events":
                  let displayCount = el.getAttribute("displayCount");

                  if (displayCount && displayCount.length && parseInt(displayCount) > 0) {
                    compRef = this.getSpotlightEventComponents(parseInt(displayCount));
                  } else {
                    compRef = this.getSpotlightEventComponents(3);
                  }

                  break;
                case "bzg-banner-images":
                  compRef = this.getBannerImagesComponents();
                  break;
                case "bzg-sponsor":
                  compRef = this.getSponsorComponents();
                  break;
                case "bzg-testimonials-dynamic":
                  compRef = this.getTestimonialsComponents();
                  break;
                case "bzg-advertisement":

                  let pagename = el.getAttribute("pagename");
                  let hideHeader = el.getAttribute("hideHeader");
                  let communityId;
                  if (el.getAttribute("communityId") && parseInt(el.getAttribute("communityId")) != NaN) {
                    communityId = parseInt(el.getAttribute("communityId"));
                  }

                  compRef = this.getAdvertisementsComponents(pagename, hideHeader?.toLowerCase() == "true", communityId);
                  break;
              }

              if (compRef) {
                this.renderer.appendChild(el, compRef);
              }

            })


          }
        })
      }
    }, 500)
    //console.log(this.htmlDiv.nativeElement);
    //this.renderer.setProperty(this.htmlDiv.nativeElement, "innerHTML", d.innerHTML);
    // var iframe =this.elementRef.nativeElement.querySelectorAll("iframe")[0] as HTMLIFrameElement;
    // iframe.contentDocument.body.innerHTML = d.innerHTML;
    // iframe.contentDocument.head.appendChild($(cssLink)[0]);
  }

  getBannerImagesComponents() {
    let compRef = this.vcr.createComponent(BannerImagesComponent);
    compRef.changeDetectorRef.detectChanges();
    return compRef.location.nativeElement;
  }

  getSponsorComponents() {
    let compRef = this.vcr.createComponent(SponsorComponent);
    compRef.changeDetectorRef.detectChanges();
    return compRef.location.nativeElement;
  }

  getSpotlightEventComponents(displayCount: number) {
    let compRef = this.vcr.createComponent(SpotlightEventsComponent);
    if (displayCount > 0) {
      compRef.setInput('displayCount', displayCount);
    }
    compRef.changeDetectorRef.detectChanges();
    return compRef.location.nativeElement;
  }

  getTestimonialsComponents() {
    let compRef = this.vcr.createComponent(TestimonialsDynamicComponent);
    compRef.changeDetectorRef.detectChanges();
    return compRef.location.nativeElement;
  }
  getAdvertisementsComponents(pageName: string, hideHeader: boolean, communityId?: number) {
    let compRef = this.vcr.createComponent(AdvertisementComponent);
    compRef.setInput('pageName', pageName);
    compRef.setInput('hideHeader', hideHeader);
    if (communityId > 0) {
      compRef.setInput('communityId', communityId);
    }
    compRef.changeDetectorRef.detectChanges();
    return compRef.location.nativeElement;
  }
}
