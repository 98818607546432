import { Injectable } from '@angular/core';

import { Resolve, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocaleResourceSet } from './store/state/app.state';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';





@Injectable()
export class AppLanguageResolver implements Resolve<LocaleResourceSet> {
   

    constructor(
        private translateService: TranslateService,
        private localResourceService: LocalResourceService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {
        //this.dataService.loadLocalResource().subscribe(s => console.log(s,"completed"));
        // this.translateService.set('login', 'Login', 'en');
        // this.translateService.set('register', 'Sign Up', 'en');
        // this.translateService.set('home', 'Home', 'en');
        //--------------------------------------------------------------------------
        // shared module for payment gateway
        this.translateService.set('validation.required-validation-error-message', '{0} is required', 'en');
        this.translateService.set('validation.is-not-valid-error-message', '{0} is not valid', 'en');
        this.translateService.set('validation.phone', '{0} is not valid', 'en');
        this.translateService.set('validation.must-be-long', '{0} must be atleast {1} characters long', 'en');
        this.translateService.set('validation.max-upto-long', '{0} max upto {1} characters long', 'en');
        // Latest changes
        this.translateService.set('validation.positive-number-allowed', '{0} should be positive', 'en');
        this.translateService.set('validation.minimum-datepicker', '{0} display minimum date', 'en');
        this.translateService.set('validation.should-be-integer', '{0} must be integer', 'en');
        this.translateService.set('validation.maximum-number', '{0} should be maximum number upto {1}', 'en');
        this.translateService.set('validation.minimum-number', '{0} should be minimum number upto {1}', 'en');
        // Latest changes
        this.translateService.set('profile-menu.dashboard', 'Dashboard', 'en');
        this.translateService.set('profile-menu.collear', 'Collear', 'en');
        this.translateService.set('profile-menu.my-profile', 'My Profile', 'en');
        this.translateService.set('profile-menu.organization', 'Organization', 'en');
        this.translateService.set('profile-menu.change-password', 'Change Password', 'en');
        this.translateService.set('profile-menu.logout', 'Logout', 'en');
        this.translateService.set('profile-menu.tenant-admin-module', 'Tenant Admin Module', 'en');
        this.translateService.set('profile-menu.user-module', 'User Module', 'en');
        this.translateService.set('communities-service.are-you-sure-want-to-leave-community', 'Are you sure want to leave {0} community?', 'en');
        this.translateService.set('communities-service.you-will-be-removed-from-all-the-groups-of-this-community-vineeth', 'You will be removed from all the groups of this community and also you will lose all membership benefits. Do you want to proceed?', 'en');
        this.translateService.set('communities-service.do-you-want-to-join', 'Do you want to join {0} community?', 'en');
        this.translateService.set('communities-service.if-the-community-is-private', 'Note: If the community is private, then the request needs to be approved by community admin.', 'en');
        this.translateService.set('communities-service.your-request-to-join-this-community-is-waiting', 'Your request to join this community is waiting for approval', 'en');
        this.translateService.set('communities-service.you-have-been-removed-from-the-community', 'You have been removed from the community', 'en');
        this.translateService.set('communities-service.are-you-sure-want-to-cancel', 'Are you sure want to cancel ?', 'en');
        this.translateService.set('communities-service.your-join-request-to-community-canceled', 'Your join request to {0} community will be canceled!', 'en');
        this.translateService.set('communities-service.your-request-is-canceled', 'Your request is canceled', 'en');
        this.translateService.set('communities-service.you-have-added-to-the-community', 'You have added to the community', 'en');
        this.translateService.set('communities-service.your-request-has-been-processed', 'Your request has been processed', 'en');
        this.translateService.set('communities-service.something-went-wrong', 'Something went wrong. Please try again', 'en');
        return this.localResourceService.loadLocalResource("common").pipe(
            tap(resp => {
                resp.data.forEach(data => {
                    this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
                });
            })
        );
    }
}
