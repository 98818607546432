import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take, takeUntil } from 'rxjs';
import { SimilarBusiness } from 'src/app/module/dashboard/store/state/member-dashboard';
import { TinySliderInstance, TinySliderSettings, tns } from 'tiny-slider/src/tiny-slider';
import { LoadGWCHomepageData } from '../../store/action/home.actions';
import { selectGWCHomePageData } from '../../store/selector/home.selectors';
import { GWCHomePageDataState, Sponsor } from '../../store/state/home';
import { Carousel } from 'bootstrap';
@Component({
  selector: 'bzg-gwc-template',
  templateUrl: './gwc-template.component.html',
  styleUrls: ['./gwc-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GwcTemplateComponent implements OnInit, OnChanges, OnDestroy {
  carousel: Carousel;
  isSliderInitialized: boolean = false;
  slider: TinySliderInstance;

  settings: TinySliderSettings = {
    container: '.sponsers-slider',
    items: 3,
    slideBy: 3,
    mouseDrag: true,
    autoplay: false,
    nav: false,
    gutter: 15,
    loop: true,
    edgePadding: 10,
    controlsText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
    responsive: {
      0: {
        slideBy: 1,
        items: 1,
      },
      768: {
        slideBy: 4,
        items: 4,
      },
      1200: {
        slideBy: 4,
        items: 4,
        gutter: 30,
      },
      1600: {
        slideBy: 5,
        items: 5,
        gutter: 30,
      }
    }
  }

  data: Observable<GWCHomePageDataState>
  constructor(
    private elementRef: ElementRef,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(LoadGWCHomepageData());
    this.data = this.store.select(selectGWCHomePageData);
    this.data.pipe(
      filter(s => s.loaded == true && s.data != null),
      map((x) => x.data.bannerSlides),
      take(1)
    ).subscribe(s => {
      this.init();
    })
  }
  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }
  similarBusinessTrackByFn(index: number, item: Sponsor) {
    return item.id;
  }

  initSlider() {

    if (this.isSliderInitialized) return;
    this.isSliderInitialized = true;
    if (this.slider) {
      this.slider.destroy();
    }
    setTimeout(() => {
      this.slider = tns(this.settings)
    }, 10);
    //console.log("init slider completed")
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isSliderInitialized = false;
  }
  init() {

    setTimeout(() => {

      const myCarouselElement = this.elementRef.nativeElement.querySelector('#gwc-footer-bannerID') as HTMLElement;

      if (myCarouselElement) {
        this.carousel = new Carousel(myCarouselElement, {
          wrap: true,
          interval: 3000
        })
        this.carousel.cycle();

      }
    }, 2000);
  }

}
