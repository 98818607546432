import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, map, switchMap, take } from 'rxjs';
import { AppResolverData } from 'src/app/app.resolver';
import { License } from 'src/app/models/accounts/user';
import { SlugPipe } from 'src/app/pipe/slug.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'bzg-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
  status: License;
  showPage: boolean = false;
  isLoading = true;
  role: string;
  destroy = new Subject<void>();
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private slugPipe: SlugPipe
  ) { }

  ngOnInit() {

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => this.activatedRoute.data.pipe(map(s => s['app'] as AppResolverData))),
        switchMap(data => this.authService.getCurrentUser2().pipe(
          map(claims => {
            return { claims, data }
          })
        )),
        take(1)
      ).subscribe({
        next: async (s) => {
          let data = s.data;
          let status = data?.userStatus?.license;
          let community = data?.userStatus?.communityConfig;
          this.isLoading = false;
          if (status) {
            this.status = status;
            if (!status.issuccess) {
              if (status.reDirectTo > 0) {
                this.showPage = true;
                switch (status.reDirectTo) {
                  case 1:
                    
                    if (this.router.routerState.snapshot.url.toLowerCase() != '/communities') {

                      console.log("redirecting to membership plan", this.router.routerState.snapshot.url);

                      //if (this.router.routerState.snapshot.url == '/' && data.loggedInRedirect == true) {
                      if (data.loggedInRedirect == true) {
                        this.showPage = true;
                        setTimeout(() => {
                          if (community && community.hasMultipleCommunity) {
                            this.router.navigate(['/communities']);
                          }
                          if (community && community.defaultCommunityId > 0) {
                            let url = `/communities/${this.slugPipe.transform(community.communityName)}/membership-plan/${community.defaultCommunityId}`;
                            this.router.navigate([url]);
                          }
                        }, 100);
                        this.matSnackBar.open("Please purchase a membership plan to continue", '', { duration: 5000 });
                      }
                    }
                    break;
                  case 2:
                    if (this.router.routerState.snapshot.url.toLowerCase() != '/auth/signup') {
                      console.log("redirecting to signup for email verification");
                      setTimeout(() => {
                        this.router.navigate(['/auth/signup'], { state: status });
                      }, 100);
                    }
                    break;
                }
              } else {
                localStorage.setItem('account-status', JSON.stringify(this.status));
                this.router.navigate(['/account-status'], { state: { isAllowed: true } });
                this.showPage = true;
              }
            } else {
              this.showPage = true;
              if (this.authService.shouldReturnToMobileApp() && data?.loggedInRedirect == true) {
                this.authService.navigateToMobileApp();
              }

              if (this.router.routerState.snapshot.url.toLowerCase() == '/') {

                if (data?.userStatus?.profileLandingPageId > 0 && data?.loggedInRedirect == true) {

                  setTimeout(() => {
                    switch (data?.userStatus?.profileLandingPageId) {
                      case 1:
                        if (s.claims.extension_Role == "EVENTMANAGER") {
                          this.router.navigate(['/admin/dashboard']);
                        } else {
                          this.router.navigate(['/']);
                        }
                        break;
                      case 2:
                        if (s.claims.extension_Role == "EVENTMANAGER") {
                          this.router.navigate(['/admin/dashboard']);
                        } else {
                          this.router.navigate(['/dashboard']);
                        }
                        break;
                      case 3:
                        if (s.claims.extension_Role == "EVENTMANAGER") {
                          this.router.navigate(['/admin/dashboard']);
                        } else {
                          this.router.navigate(['/dashboard']);
                        }
                        break;
                      case 4:
                        //this.router.navigate(['/communities']);
                        //debugger
                        var url = data?.userStatus?.communityLandingUrl.replace(/ /g, '');

                        this.router.navigate([url]);
                        break;
                      case 5:
                        //this.router.navigate(['/mygroups']);
                        //debugger;
                        var url = data?.userStatus?.groupLandingUrl.replace(/ /g, '');

                        this.router.navigate([url]);
                        break;
                    }
                  }, 200)

                }
              }

            }
          } else {
            this.showPage = true;
          }
        }, error: err => {
          this.isLoading = false;
          this.showPage = true;
          console.log("Error => ", err);
        }
      });

  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  logout() {
    this.msalService.logout();
  }

}
