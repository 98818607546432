import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';
import { ConfigService } from 'src/app/config/config.service';
import { InConfiguration } from 'src/app/core/models/config.interface';
import { ClientConfiguration } from 'src/app/models/configuration/configuration';
import { SelectClientConfiguration } from 'src/app/store/selectors/app.selector';

@Component({
  selector: 'bzg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  config: InConfiguration;
  ClientConfig$: ClientConfiguration;
  destroy$ = new Subject<void>();
  menuDisplay: string;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private store: Store,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.config = this.configService.configData;
    this.store.select(SelectClientConfiguration).pipe(
      takeUntil(this.destroy$),
      filter(s => s?.menuDisplay != null),
    ).subscribe(state => {

      this.menuDisplay = state.menuDisplay;
      //console.log(this.menuDisplay,'display')
      if (this.menuDisplay?.toLowerCase() == 'right') {
        const body = document.querySelector('body');
        this.renderer.addClass(body, 'rtl-body-menu');
        this.renderer.removeClass(body, 'top-body-menu');
      } else if (this.menuDisplay?.toLowerCase() == 'left') {
        const body = document.querySelector('body');
        this.renderer.removeClass(body, 'rtl-body-menu');
        this.renderer.removeClass(body, 'top-body-menu');
      } else if (this.menuDisplay == null) {
        const body = document.querySelector('body');
        this.renderer.removeClass(body, 'rtl-body-menu');
        this.renderer.removeClass(body, 'top-body-menu');
      } else if (this.menuDisplay?.toLowerCase() == 'top') {
        const body = document.querySelector('body');
        this.renderer.addClass(body, 'top-body-menu');
        this.renderer.removeClass(body, 'rtl-body-menu');
        if (this.router.url.startsWith('/admin')) {
          this.renderer.removeClass(body, 'top-body-menu');
        }
      }

    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, this.config.layout.variant);
    this.renderer.removeClass(this.document.body, 'menu_' + this.config.layout.sidebar.backgroundColor);
    this.renderer.removeClass(this.document.body, 'logo-' + this.config.layout.logo_bg_color);
    this.destroy$.next();
    this.destroy$.complete();
  }
  /*--------#Start#--pageScrolled------------*/
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const body = document.querySelector('body');
    if (window.scrollY > 65) {
      this.renderer.addClass(body, 'pageScrolled');
    } else {
      this.renderer.removeClass(body, 'pageScrolled');
    }
  }
  /*--------#End#--pageScrolled------------*/
}
