import { ErrorHandler, Injectable } from "@angular/core";
import { LoggerService, LogLevel } from "./logger.service";
import { Router } from "@angular/router";
//ref https://angular.io/api/core/ErrorHandler 
declare const gtag: Function;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private logger: LoggerService,
        private router: Router
    ) {
    }

    handleError(error: Error) {
        console.log("global error", error)
        if (error.message.includes("NG04002")) {
            this.router.navigate(["/404-error"]);
        }
        this.logger.logException(error, LogLevel.Error, { "stack": error.stack, "name": error.name, "message": error.message });
        this.logger.LogError(error.message, { "stack": error.stack, "name": error.name, "message": error.message });
        // do something with the exception
        // gtag('event', 'exception', {
        //     'description': error,
        //     'fatal': false
        //   });
    }
}