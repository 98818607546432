import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAdvertisementModule } from '.';
import { MyAdvertisementModule } from '../state';
import { MyAdvertisementPageState, MyAdvertisementState } from '../state/my-advertisements';


export const selectMyAdvertisementDetails = createSelector(
    selectAdvertisementModule,
    (state: MyAdvertisementModule) => state.myAdvertisement);

export const selectAdvertisementsDetails = createSelector(
    selectMyAdvertisementDetails,
    (state:  MyAdvertisementPageState) => state.myAdvertisement
);