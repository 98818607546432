import { Action, createReducer, on } from '@ngrx/store';
import { Notificationsstate, ProfileSettingsstate } from '../state/layout';
import * as fromActions from '../action/layout.actions';

export const layoutFeatureKey = 'layout';

export interface State {

}

export const profileinitialState: ProfileSettingsstate = {
  profileSettings: null,
  error: null,
  loading: false,
  loaded: false
};

export const notificationInitialState: Notificationsstate={
  notifications: null,
  error: null,
  loading: false,
  loaded: false
}

export const profileSettingsreducer = createReducer<ProfileSettingsstate>(
  profileinitialState,
  on(fromActions.LoadProfileSettings, (state) => ({
    ...state,
   
      error: null,
      profileSettings: null,
      loaded: false,
      loading: true   
  })),
  on(fromActions.LoadProfileSettingsDataSuccess, (state, { profilesettings }) => ({
    ...state,
      error: null,
      loaded: true,
      loading: false,
      profileSettings: profilesettings
  })),
  on(fromActions.LoadProfileSettingsDataError, (state, { error }) => ({
    ...state,
      error: error,
      loaded: true,
      loading: false,
      profileSettings: null
  })),
  
);

export const notificationsreducer = createReducer<Notificationsstate>(
  notificationInitialState,
  on(fromActions.LoadNotifications, (state) => ({
    ...state,
   
      error: null,
      notifications: null,
      loaded: false,
      loading: true   
  })),
  on(fromActions.LoadNotificationsDataSuccess, (state, { notifications }) => ({
    ...state,
      error: null,
      loaded: true,
      loading: false,
      notifications: notifications
  })),
  on(fromActions.LoadNotificationsDataError, (state, { error }) => ({
    ...state,
      error: error,
      loaded: true,
      loading: false,
      notifications: null
  })),
  
);