import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslatePipe } from '@ngx-translate/core';
import { DialogData, MessageDailogComponent } from 'src/app/common/shared/components/message-dailog/message-dailog.component';
import { DynamicScriptLoaderService } from 'src/app/core/service/dynamic-script-loader.service';
import { AuthorizeNetPaymentTransactionRequest, AuthorizeNetUpdatemembershipplanpurchaseinfo, IPaymentInfo, PaymentMethod, SaveMembershipPlanPurchaseInfo } from 'src/app/models/payment/payment-method';
import { environment } from 'src/environments/environment';
import { ConfigItem } from '../paypal/paypal.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
declare var window: any;
@Component({
  selector: 'bzg-authorizenet',
  templateUrl: './authorizenet.component.html',
  styleUrls: ['./authorizenet.component.scss'],
  providers: [
    TranslatePipe
  ],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizenetComponent implements OnInit {

  @Input("amount") amount: number;
  @Input("refId") refId?: string;
  @Input("prePaymentUrl") prePaymentUrl?: string;
  @Input("transactionApiUrl") transactionApiUrl: string;
  @Input("prePaymentData") prePaymentData?: any;
  @Input("beforeValidationData") beforeValidationData?: any;
  @Output("paymentSuccess") paymentSuccess = new EventEmitter<any>();
  @Input("config") config: PaymentMethod;
  @Input("currency") currency: string;
  @Input("authorizeHeaderTxt") authorizeHeaderTxt?: string;
  @Input("beforeValidation") beforeValidation?: boolean = false;

  apiLoginID: string;
  clientKey: string;
  isTA: boolean;
  communityId: number;
  isLoading: boolean = false;
  loadingText: string = "Loading...";
  scriptNotLoaded: boolean = false;
  error: string[] = [];
  hasError: boolean = false;
  handlePaymentCallBack: boolean = false;
  modalRef: NgbModalRef;
  constructor(
    private http: HttpClient,
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslatePipe,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit() {
    const self = this;


    console.log(this.prePaymentData, 'ppd');
    this.isTA = this.prePaymentData.isTA;
    this.communityId = this.prePaymentData.communityId;
    console.log(this.config);
    var container = document.querySelector('#AcceptUIContainer');
    if (container) {
      container.remove();
    }
    var containerBackground = document.querySelector('#AcceptUIBackground');
    if (containerBackground) {
      containerBackground.remove();
    }

    if (this.config && this.config.configure) {
      let config = JSON.parse(this.config.configure) as ConfigItem[];
      let info = config.find(x => x.ControlID?.toLowerCase() == "txtapiloginid");
      if (info == undefined || info.DefaultValue == "") {
        this.hasError = true;
        this.error.push("Login Id is not configured");
      } else {
        this.apiLoginID = info.DefaultValue;
      }

      let clientKey = config.find(x => x.ControlID.toLowerCase() == "txtclientkey");
      if (clientKey == undefined || clientKey.DefaultValue == "") {
        this.hasError = true;
        this.error.push("Client Key is not configured");
      } else {
        this.clientKey = clientKey.DefaultValue;
      }

    } else {
      this.hasError = true;
      this.error.push("Authorize.Net is not configured");
    }


    if (this.currency == undefined || this.currency == "") {
      this.hasError = true;
      this.error.push("Currency is not configured");
    }

    try {
      await this.loadAuthorizeNetScript();
      $("html, body").animate({ scrollTop: $(document).height() }, 1000);
      let self = this;
      window.responseHandler = function (response: any) {
        if (response.messages.resultCode === "Error") {
          var i = 0;
          while (i < response.messages.message.length) {
            self.matSnackBar.open(response.messages.message[i].code + ": " +
              response.messages.message[i].text, "", { duration: 5000 });
            console.log(
              response.messages.message[i].code + ": " +
              response.messages.message[i].text
            );
            i = i + 1;
          }
        }
        if (!self.handlePaymentCallBack) {
          self.handlePaymentCallBack = true;
          console.log("Response from authorize.net", response);
          self.handlePayment(response);
        }

      };
    } catch {
      this.scriptNotLoaded = true;
      this.matSnackBar.open("Error loading payment gateway SDK", "", { duration: 5000 });
    }

  }

  loadAuthorizeNetScript() {
    return new Promise((resolve, reject) => {
      let existingScript = document.getElementById("authorizenetScript");
      if (existingScript) {
        existingScript.remove();
      }
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'authorizenetScript';
      script.src = environment.acceptJsUrl;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = (error: any) => {

        reject(false);
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  async handlePayment(response: any) {
    console.log("Handle respose from angular ", response);
    if (response.messages.resultCode === "Error") {
      var i = 0;
      while (i < response.messages.message.length) {
        this.matSnackBar.open(response.messages.message[i].code + ": " +
          response.messages.message[i].text, "", { duration: 5000 });
        console.log(
          response.messages.message[i].code + ": " +
          response.messages.message[i].text
        );
        i = i + 1;
      }
      if (this.prePaymentData.paymentPurpose == 'Test_Payment_purchase' && this.prePaymentData.isTA) {
        $('.modal-backdrop').remove();
        this.router.navigate(['admin/manage-payment-gateways/transactionFailed/' + "paymentgatewayconfigurationfailed"]);
      } else if (this.prePaymentData.paymentPurpose == 'Test_Payment_purchase' && !this.prePaymentData.isTA) {
        $('.modal-backdrop').remove();
        this.router.navigate(['admin/community/'+ this.prePaymentData.communityId + '/manage-payment-gateways/transactionFailed/' + "paymentgatewayconfigurationfailed"]);
      }
    } else {
      // $("html, body").animate({ scrollTop: $(document).height() }, 1000);
      this.isLoading = true;
      this.loadingText = this.translate.transform('paymentgateway.please-wait-while-processing-your-request');
      console.log("trans data", this.prePaymentData);
      let data = await this.getRefId() as any;
      let payiD: any;
      if (data.value) {
        payiD = data.value
      } else {
        payiD = data;
      }
      let param: AuthorizeNetPaymentTransactionRequest = {
        paymentId: this.prePaymentData.paymentId ? this.prePaymentData.paymentId.toString() : payiD.toString(),
        userId: this.prePaymentData.userId,
        dataDescriptor: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
        firstName: response.customerInformation.firstName,
        lastName: response.customerInformation.lastName,
        currencyCode: this.currency,
        membershipPlanId: this.prePaymentData.membershipPlanId,
        orderDescription: this.prePaymentData.paymentPurpose,
        eventId: this.prePaymentData.eventId,
        communityId: this.prePaymentData.communityId,
        ticketsSummary: this.prePaymentData?.ticketSummary,
        uniqueSessionID: this.prePaymentData?.uniqueSessionID,
        planPurchaseId: this.prePaymentData?.planPurchaseID,
        promotionPlanId: this.prePaymentData?.PromotionPlanId,
        donationInfo: this.prePaymentData?.donationInfo,
        discountCouponID: this.prePaymentData?.discountCouponID,
        discountAmount: this.prePaymentData?.discountAmount,
        discountCoupon: this.prePaymentData?.discountCoupon,
        paymentConfigurationId: this.prePaymentData?.PaymentConfiguredId
      };

      //let transactionData = { ...param, ...this.prePaymentData }
      this.http.post(this.transactionApiUrl, param).subscribe({
        next: (data: any) => {
          this.isLoading = false;
          this.handlePaymentCallBack = false;
          if (data.isSuccess && data.transactionId > 0) {
            this.paymentSuccess.emit({ paymentId: param.paymentId, transactionId: data.transactionId });
          }
          else {
            Swal.fire({
              title: 'Transaction Failed',
              text: data.errorMessage,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }
        },
        error: (err) => {
          if (err.status == 403) {
            this.matSnackBar.open("Payment failed", "", { duration: 5000 });
          }
          this.handlePaymentCallBack = false;
          this.isLoading = false;
          console.log(err);
        }
      })
    }
  }


  getRefId(): Promise<object> {
    return new Promise((resolve, reject) => {
      if (this.prePaymentUrl && !this.beforeValidation) {
        this.http.post(this.prePaymentUrl, this.prePaymentData).subscribe({
          next: (data: any) => {
            if (data.statusCode != 500) {
              if (data.value) {
                this.refId = data.value
              } else {
                this.refId = data;
              }

              resolve(data);
            } else {
              this.isLoading = false;
              Swal.fire({
                title: 'Transaction Failed',
                text: data.value,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            }

          },
          error: (err) => {
            this.refId = "";
            reject(err);
            if (err.status == 500 && this.prePaymentData.paymentPurpose == 'PROMOTION_PLAN_PURCHASE') {
              Swal.fire({
                title: '',
                text: err.error,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/advertisement/my-advertisements']);
                } else {

                }
              });
            }
            console.log(err);
          }
        })
      }
      else if (this.prePaymentUrl && this.beforeValidation) {
        this.http.post(this.prePaymentUrl, this.beforeValidationData).subscribe({
          next: (data: any) => {
            this.refId = this.prePaymentData.paymentId;
            if (data == true) {
              this.refId = this.refId;
            }
            else {
              let dailogData: DialogData = {
                title: 'Error',
                messege:
                  'It seems ticket(s) or applied promocode is not available now, please try again after sometime.',
              };
              let dailogRef = this.dialog.open(MessageDailogComponent, {
                width: '400px',
                maxWidth: '100%',
                maxHeight: '100%',
                role: 'alertdialog',
                disableClose: true,
                data: dailogData,
              });

              dailogRef.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            }
            resolve(this.prePaymentData.paymentId);
          },
          error: (err) => {
            this.refId = "";
            this.isLoading = false;
            reject(err);
            console.log(err);
          }
        })
      }
      else {
        resolve({});
      }
    });

  }
}
