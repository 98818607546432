import { Directive, ElementRef, Output, EventEmitter, ChangeDetectorRef, OnInit, Input, OnDestroy, ViewChild, Renderer2, QueryList, ViewChildren } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormArray } from '@angular/forms';

@Directive({
  selector: 'form:not([ngNoForm])',
})
export class FormSubmitDirective implements OnInit, OnDestroy {
  private stopValidating: boolean;
  private destroy = new Subject<void>();
  @Input('formGroup') formGroup: UntypedFormGroup;
  //@Input('readonly') readonly?: EventEmitter<boolean>;
  @Output('isValid') isFormValid: EventEmitter<boolean>;
  submit$ = fromEvent(this.elementRef.nativeElement, 'submit');

  constructor(
    private elementRef: ElementRef<HTMLFormElement>,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.isFormValid = new EventEmitter<boolean>();
  }


  ngOnInit() {
    this.submit$.pipe(takeUntil(this.destroy))
      .subscribe(async (s) => {
        s.preventDefault();
        if (this.formGroup) {
          try {
            const isValid = await this.isValid(this.formGroup, this.elementRef.nativeElement);
            this.isFormValid.emit(isValid);
          } catch (e) {
            console.log(e)
            this.isFormValid.emit(this.formGroup.valid);
          }
        }
      });

    // if (this.readonly) {
    //   this.readonly.pipe(takeUntil(this.destroy)).subscribe(v => {
    //     this.markAsReadOnlyControls(v, this.formGroup, this.elementRef.nativeElement, []);
    //   })
    // }

  }

  async isValid(form: UntypedFormGroup, element: HTMLElement): Promise<boolean> {
    if (form.pending) {
      if (form.valid) {
        return this.showError(form, element);
      }
      return false;
    }
    return this.showError(form, element);
  }

  private async showError(form: UntypedFormGroup, element: HTMLElement): Promise<boolean> {
    if (form.invalid) {

      this.markDirtyAndTouchAllControl(form);

      let fields: Array<string> = [];
      this.stopValidating = false;

      try {
        await this.focusOnInvalidControls(form, element, fields);
      } catch (e) {
        console.warn("unable to focus on invalid control");
      }

      return form.valid;
    }

    return form.valid;
  }

  private markDirtyAndTouchAllControl(controls: UntypedFormGroup | UntypedFormControl) {

    if (controls instanceof UntypedFormArray) {

      const controlList = controls as UntypedFormArray;
      controlList.controls
      controlList.markAsDirty();
      controlList.markAsTouched();
      controlList.updateValueAndValidity();
      for (let i = 0; i < controlList.length; i++) {
        console.log(controlList);
        // const abstractControl = controlList[i];
        const abstractControl = controlList.at(i) as UntypedFormControl;
        if (abstractControl) {
          this.markDirtyAndTouchAllControl(abstractControl);
        }
      }
    } else if (controls instanceof UntypedFormGroup) {

      Object.keys(controls.controls).forEach((key: string) => {

        const abstractControl = controls.controls[key];

        if (abstractControl instanceof UntypedFormGroup) {

          this.markDirtyAndTouchAllControl(abstractControl);

        } else if (abstractControl instanceof UntypedFormControl) {

          abstractControl.markAsDirty();
          abstractControl.markAsTouched();
          abstractControl.updateValueAndValidity();

        } else if (abstractControl instanceof UntypedFormArray) {
          const abstractControlList = abstractControl as UntypedFormArray;
          abstractControlList.markAsDirty();
          abstractControlList.markAsTouched();
          abstractControlList.updateValueAndValidity();
          for (let j = 0; j < abstractControlList.controls.length; j++) {
            // const abstractControl = abstractControlList[j];
            const abstractControl = abstractControlList.at(j) as UntypedFormControl;
            if (abstractControl) {
              this.markDirtyAndTouchAllControl(abstractControl);
            }
          }
        } else {
          console.warn("conditions not matched");
        }

      });

    } else if (controls instanceof UntypedFormControl) {
      controls.markAsDirty();
      controls.markAsTouched();
      controls.updateValueAndValidity();
    }
  }


  private focusOnInvalidControls(controls: UntypedFormGroup | UntypedFormControl, element: HTMLElement, selectorList: Array<string>, parentSelector: string = null): Promise<void> {
    return new Promise(async (resolve, rejects) => {

      if (controls instanceof UntypedFormGroup) {

        Object.keys(controls.controls).every(async (key: string) => {

          const abstractControl = controls.controls[key];

          if (abstractControl instanceof UntypedFormGroup) {

            if (parentSelector === null) {

              parentSelector = `${key}.`;

            } else {
              parentSelector += `${key}.`;
            }
            this.focusOnInvalidControls(abstractControl, element, selectorList, parentSelector);
            parentSelector = null;
          } else if (abstractControl instanceof UntypedFormControl) {

            if (this.stopValidating) {
              resolve();
              return true;
            }

            if (parentSelector === null) {
              selectorList.push(`[formcontrolname='${key}']`);
              const isValid = await this.validateControls(abstractControl, element, `[formcontrolname='${key}']`);

              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            } else {
              let selector = "";
              const depth = parentSelector.split(".");
              for (let i = 0; i < depth.length - 1; i++) {
                selector += ` [formgroupname='${depth[i]}']`;
              }
              selector += ` [formcontrolname='${key}']`;
              selectorList.push(selector);
              const isValid = await this.validateControls(abstractControl, element, selector);

              if (!isValid) {
                this.stopValidating = true;
                resolve();
                return true;
              }
            }
            resolve()
          }
          return null;
        });

      } else if (controls instanceof UntypedFormControl) {

        Object.keys(controls).every(async (key: string) => {

          const abstractControl = controls.get(key);
          if (parentSelector == null) {
            selectorList.push(`[formcontrolname='${key}']`);
          } else {
            let selector = "";
            const depth = parentSelector.split(".");

            for (let i = 0; i < depth.length - 1; i++) {
              selector += ` [formgroupname='${depth[i]}']`;
            }

            selector += ` [formcontrolname='${key}']`;
            selectorList.push(selector);
            const isValid = await this.validateControls(abstractControl, element, selector);

            if (!isValid) {
              this.stopValidating = true;
              resolve();
              return true;
            }
          }
          return null;
        })

      }

    });

  }

  // private markAsReadOnlyControls(readonly: boolean, controls: UntypedFormGroup | UntypedFormControl, element: HTMLElement, selectorList: Array<string>, parentSelector: string = null) {
  //   if (controls instanceof UntypedFormGroup) {

  //     Object.keys(controls.controls).every(async (key: string) => {

  //       const abstractControl = controls.controls[key];

  //       if (abstractControl instanceof UntypedFormGroup) {

  //         if (parentSelector === null) {

  //           parentSelector = `${key}.`;

  //         } else {
  //           parentSelector += `${key}.`;
  //         }
  //         this.markAsReadOnlyControls(readonly, abstractControl, element, selectorList, parentSelector);
  //         parentSelector = null;
  //       } else if (abstractControl instanceof UntypedFormControl) {

  //         if (parentSelector === null) {
  //           selectorList.push(`[formcontrolname='${key}']`);
  //           const isValid = this.readOnlyControls(element, `[formcontrolname='${key}']`, readonly);

  //         } else {
  //           let selector = "";
  //           const depth = parentSelector.split(".");
  //           for (let i = 0; i < depth.length - 1; i++) {
  //             selector += ` [formgroupname='${depth[i]}']`;
  //           }
  //           selector += ` [formcontrolname='${key}']`;
  //           selectorList.push(selector);
  //           const isValid = this.readOnlyControls(element, selector, readonly);
  //         }
  //       }
  //     });

  //   } else if (controls instanceof UntypedFormControl) {

  //     Object.keys(controls).every(async (key: string) => {

  //       const abstractControl = controls.get(key);
  //       if (parentSelector == null) {
  //         selectorList.push(`[formcontrolname='${key}']`);
  //       } else {
  //         let selector = "";
  //         const depth = parentSelector.split(".");

  //         for (let i = 0; i < depth.length - 1; i++) {
  //           selector += ` [formgroupname='${depth[i]}']`;
  //         }

  //         selector += ` [formcontrolname='${key}']`;
  //         selectorList.push(selector);
  //         const isValid = this.readOnlyControls(element, selector, readonly);

  //       }
  //     })

  //   }

  // }

  private async isAsyncValid(controls: AbstractControl): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let wait = setInterval(() => {
        if (controls.pending == false) {
          clearInterval(wait);
          resolve(true)
        }
      }, 100)
    })
  }

  private validateControls(property: AbstractControl, element: HTMLElement, selector: string): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      if ((property.invalid || !property.valid) && !property.pending) {
        const input = element.querySelector(selector) as HTMLInputElement;
        if (input) {

        }
        if (input && input.focus instanceof Function) {
          if (this.stopValidating) {
            resolve(false);
          } else {
            this.stopValidating = true;
            input.focus();
            this.cd.detectChanges();
            resolve(false);
          }
        }
      } else {
        if (property.pending) {
          await this.isAsyncValid(property);
          if ((property.invalid || !property.valid) && !property.pending) {
            const input = element.querySelector(selector) as HTMLInputElement;
            if (input && input.focus instanceof Function) {
              if (this.stopValidating) {
                resolve(false);
              } else {
                this.stopValidating = true;
                input.focus();
                this.cd.detectChanges();
                resolve(false);
              }
            }
          }
        }
      }
      resolve(true);
    });

  }

  // private readOnlyControls(element: HTMLElement, selector: string, readonly: boolean) {
  //   const input = element.querySelector(selector) as HTMLInputElement;
  //   if (readonly) {
  //     this.renderer.setAttribute(input, "readonly", 'true');
  //   } else {
  //     this.renderer.removeAttribute(input, "readonly");

  //   }


  // }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
