import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, tap } from 'rxjs';
import { LocalResourceService } from 'src/app/services/local-resource/local-resource.service';
import { LocaleResourceSet } from 'src/app/store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class MyEventsModuleLanguageResolver implements Resolve<LocaleResourceSet> {
  constructor(
    private translateService: TranslateService,
    private localResourceService: LocalResourceService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LocaleResourceSet> {

    this.translateService.set('my-events.enter-a-date-range', 'Search By Event Date', 'en');
    this.translateService.set('my-events.start-date.placeholder', 'Start Date', 'en');
    this.translateService.set('my-events.end-date.placeholder', 'End Date', 'en');
    this.translateService.set('my-events.search', 'Search', 'en');
    this.translateService.set('my-events.reset', 'Reset', 'en');
    this.translateService.set('my-events.loading', 'Loading...', 'en');
    this.translateService.set('my-events.there-is-no-events-for-you-as-of-now', 'There is no Events for you as of now!', 'en');
    this.translateService.set('my-events.you-have-not-registered-any-event', 'You have not registered for any event yet.', 'en');
    // Latest changes
    this.translateService.set('my-events.dashboard', 'Dashboard', 'en');
    this.translateService.set('my-events.my-events', 'My Events', 'en');
    this.translateService.set('my-events.no-events-image', '/assets/images/community-details/no_events.png', 'en');
    this.translateService.set('my-events.my-events-page-name', 'My Events', 'en');
    this.translateService.set('my-events.no-events-found', 'No Events Found', 'en');
    this.translateService.set('my-events.we-did-not-find-anything', 'Oops...we did not find anything that matches your search:(', 'en');

    return this.localResourceService.loadLocalResource("my-events").pipe(
      tap(resp => {
        resp.data.forEach(data => {
          this.translateService.set(data.resourceKey, data.resourceValue, data.localeId);
        });
      })
    );
  }
}
