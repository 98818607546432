<div [ngClass]="hideHeader ?'':'card'">
  <div class="card-header" *ngIf="!hideHeader">
    <span class=""><i class="fas fa-ad"></i> {{'display-advertisement.advertisement' | translate}}</span>
  </div>
  <div class="" [ngClass]="hideHeader ? '':'card-body'">
    <p *ngIf="adList.length ==0" class="text-center adv-single-image">
      <img [src]="'display-advertisement.default-advertisement-image' | translate" alt="no ads available"
        class="img-fluid rounded-3 mx-auto" />
    </p>
    <ng-container *ngFor="let section of adList">

      <div [id]="id+'-'+section.key" class="carousel slide mb-3" data-bs-ride="carousel" data-interval="3000"
        data-pause="false">
        <div class="carousel-inner" #cc>
          <div *ngFor="let ad of section.value;let i=index" class="carousel-item" [ngClass]="i==0?'active':''">
            <ng-container *ngIf="ad.adType=='image'">
              <img *ngIf="ad.linkUrl==null || ad.linkUrl=='' || ad.linkUrl?.length<10" [src]="ad.image" [alt]="ad.name"
                [matTooltip]="ad.name" class="d-block rounded-3" style="width: 100%" />
              <a *ngIf="ad.linkUrl?.length>12" [href]="ad.linkUrl" target="_blank" [matTooltip]="ad.name">
                <img [src]="ad.image" [alt]="ad.name" class="d-block rounded-3" style="width: 100%" />
              </a>
            </ng-container>
            <ng-container *ngIf="ad.adType=='video'">
              <div class="video-container">
                <iframe width="100%" [src]="ad.videoUrl | safe: 'resourceUrl'" title="YouTube video player"
                  frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="section.value.length >1">
          <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#'+ id +'-'+section.key"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{'display-advertisement.previous' | translate}}</span>
          </button>
          <button class="carousel-control-next" type="button" [attr.data-bs-target]="'#'+ id +'-'+section.key"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{'display-advertisement.next' | translate}}</span>
          </button>
        </ng-container>
      </div>

    </ng-container>

  </div>
</div>