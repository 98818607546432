<!-- {{form.errors | json}}

{{form.value | json}} -->
<div role="group" [formGroup]="form" class="component-container" (click)="onContainerClick($event)">
    <mat-form-field appearance="outline">
        <mat-label>{{label}}</mat-label>
        <mat-select [disableOptionCentering]="true" [error]="error" [attr.name]="elementName" matInput #multiSelect
            (selectionChange)="selected($event)" formControlName="select" [multiple]="multiple">
            <mat-option>
                <ngx-mat-select-search name="search" [formControl]="selectFilterCtrl" placeholderLabel="Search..."
                    noEntriesFoundLabel="no matches found">
                </ngx-mat-select-search>
            </mat-option>
            <ng-container *ngIf="filteredSelect | async as data">
                <mat-option [disabled]="maxSelection && value?.length == maxSelection && !value?.includes(dd.id)"
                    *ngFor="let dd of data" [value]="dd.id">{{ dd.text }}</mat-option>
                <mat-option [disabled]="true" *ngIf="data.length == 0">
                    No Results Found
                </mat-option>
            </ng-container>

        </mat-select>
        <button *ngIf="value && value.length" matTooltip="clear selection" color="primary" (click)="clearValue($event)"
            type="button" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error #error></mat-error>
    </mat-form-field>
</div>