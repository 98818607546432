import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IDirectoryCard, IMembersList, ISearchMemberDirectory } from 'src/app/module/member/store/state/member-directory';
import { SimilarPeople } from '../state/community-member';


export const SearchCommunityMember = createAction('[CommunityMember] Search community member ', (query: ISearchMemberDirectory) => ({ query }));
export const SearchCommunityMemberSuccess = createAction('[CommunityMember] Search community member success', props<{ members: IMembersList }>());
export const SearchCommunityMemberError = createAction('[CommunityMember] Search community member error', props<{ error: HttpErrorResponse }>());

export const LoadMoreCommunityMemberResult = createAction('[CommunityMember] Load More results community member ', (query: ISearchMemberDirectory) => ({ query }));
export const LoadMoreCommunityMemberSuccess = createAction('[CommunityMember] Load More results community member  success', props<{ members: IMembersList }>());
export const LoadMoreCommunityMemberError = createAction('[CommunityMember] Load More results Search community member  error', props<{ error: HttpErrorResponse }>());

export const UpdateConnectionStatus = createAction('[CommunityMember] Update Connection Status', props<{ member: IDirectoryCard }>());

export const LoadSimilarPeople = createAction('[Community Member] Load SimilarPeople');
export const LoadSimilarPeopleSuccess = createAction('[Community Member] Load SimilarPeople Success', props<{ similarpeople: SimilarPeople[] }>());
export const LoadSimilarPeopleError = createAction('[Community Member] Load SimilarPeople Error', props<{ error: HttpErrorResponse }>());
