<div *ngIf="isLoading" class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            <h5> <i class="fa fa-spinner fa-spin"></i> Loading ...</h5>
        </div>
    </div>
</div>
<router-outlet *ngIf="showPage"></router-outlet>
<div *ngIf="!showPage && status && !status.issuccess" class="container-fluid">
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <div class="card pt-5">
                <div class="card-body pt-5">
                    <div class="text-center">
                        <h5 class="mb-5">{{status.message}}</h5>
                        <button (click)="logout()" mat-raised-button color="primary">Logout</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>