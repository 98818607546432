import { Injectable } from '@angular/core';

import { ActivatedRoute, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, forkJoin, map, Observable, take, tap } from 'rxjs';
import { UserInitialData } from './models/accounts/user';
import { TenantFeatureConfiguration } from './models/configuration/configuration';
import { TenantBusinessType } from './models/organization/organization';
import { AuthService } from './services/auth/auth.service';
import { ConfigurationService } from './services/config/configuration.service';
import { OrganizationService } from './services/organization/organization.service';

import * as fromAction from './store/actions/app.action';
import { selectApplicationConfiguration, selectAvailableLanguage, selectUserActiveStatus } from './store/selectors/app.selector';

@Injectable()
export class AppResolver implements Resolve<AppResolverData> {

    constructor(
        private translateService: TranslateService,
        private store: Store
    ) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppResolverData> {

        //console.log("App resolver", new Date().toDateString());

        return forkJoin({
            loggedInRedirect: this.store.select(selectUserActiveStatus).pipe(
                filter(s => s.loaded == true),
                map(s => s.loggedInRedirect),
                take(1)
            ),
            userStatus: this.store.select(selectUserActiveStatus).pipe(
                filter(s => s.loaded == true),
                map(s => s.userStatus),
                take(1)
            ),
            businessType: this.store.select(selectApplicationConfiguration).pipe(
                filter(s => s.tenantBusinessType != null),
                map(s => s.tenantBusinessType),
                take(1)
            ),
            tenantId: this.store.select(selectApplicationConfiguration).pipe(
                filter(s => s.tenant != null),
                map(s => s.tenant.tenantId),
                take(1)
            ),
            tenantFeatureConfiguration: this.store.select(selectApplicationConfiguration).pipe(
                filter(s => s.tenantFeatureConfiguration != null),
                map(s => s.tenantFeatureConfiguration),
                take(1)
            ),
            languages: this.store.select(selectAvailableLanguage).pipe(
                filter(s => s && s.length > 0),
                tap(lang => {
                    lang.forEach(item => {
                        this.translateService.use(item)
                    })
                    this.translateService.use('en');
                }),
                take(1)
            )
        });
    }
}

export interface AppResolverData {
    userStatus: UserInitialData,
    loggedInRedirect: boolean,
    businessType: TenantBusinessType,
    tenantId: string,
    tenantFeatureConfiguration: Array<TenantFeatureConfiguration>,
    languages: string[]
}