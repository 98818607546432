<section class="content" id="advertisement-member-admin-section" [ngClass]="edit?'editAdMainPage':'createAdMainPage'">
    <div class="container-fluid pt-2">
        <div class="card">
            <div class="card-body custom-pad-card-body" [ngClass]="(isCommunityAdminPage || isTenantAdminPage) && edit?'tenantAdminCanEdit-adCardBody':''">
                
                <bzg-loading [show]="isPymentProcessing" [ngClass]="isPymentProcessing?'loadingBackdrop':''"
                                                            [message]="'advertisement.payment-processing' | translate"></bzg-loading>
                <mat-horizontal-stepper [ngClass]="proceedToNextStepCreateAd?'createAdEdited':''"
                    [ngClass]="selected?'selectPlanEdited':''" class="create-ads-stepper"
                    (selectionChange)="stepChanged($event, stepper)" [selectedIndex]="currentStepIndex"
                    [linear]="isLinear" #stepper>
                    <mat-step [stepControl]="HFormGroup1" *ngIf="!edit">
                        <form [formGroup]="HFormGroup1">
                            <ng-template matStepLabel>{{'advertisement.select-plan' | translate}}</ng-template>

                            <div class="row mt-3" *ngIf="PromotionPlans$ | async as plans">

                                <p *ngIf="plans?.promotionPlansDetails?.loading" class="text-center pt-5">
                                    <i class="fa fa-spinner fa-spin"></i> {{'advertisement.loading' | translate}}
                                </p>

                                <div class="row" *ngIf="plans?.promotionPlansDetails?.data?.length == 0">
                                    <div class="noDataAds col-12">

                                        <div class="text-center">
                                            <h5>{{'advertisement.sorry' | translate}}</h5>
                                            <p>{{'advertisement.no-plans-available' | translate}}</p>
                                        </div>

                                    </div>
                                </div>

                                <!-- <div class="col-md-4 col-xl-3">
                                    <div class="card selectPlanCard"
                                        [ngClass]="selectPlanCardStaus ? 'active' : 'inActive'">
                                        <div class="selectPlanCard__Title">Gold Plan</div>
                                        <div class="selectPlanCard__Price">$29 <span>/1 Month</span></div>
                                        <div class="selectPlanCard__Content">
                                            <p class="selectPlanCard--label">Location</p>
                                            <h5 class="selectPlanCard--value">Global Members</h5>
                                            <p class="selectPlanCard--label">Advertisement Type</p>
                                            <h5 class="selectPlanCard--value">Video</h5>
                                            <button type="button" (click)="selectPlanCardClickEvent()"
                                                class="btn btn-outline-theme">
                                                <span>CHOOSE PLAN</span> <i class="fas fa-check-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-4 col-xl-3 selectPlanCardCol"
                                    *ngFor="let plan of plans?.promotionPlansDetails?.data">
                                    <div class="card selectPlanCard" [class.active]="plan.active">
                                        <div class="selectPlanCard__Title">{{plan.promotionPlanName}}</div>
                                        <div class="selectPlanCard__Price">{{plan.price | currency:
                                            tenantConfig?.currencyCode}} <span>/
                                                {{plan.duration}}</span>
                                        </div>
                                        <div class="selectPlanCard__Content">
                                            <p class="selectPlanCard--label">{{'advertisement.location' | translate}}
                                            </p>
                                            <h5 class="selectPlanCard--value">{{plan.adLocation}}</h5>
                                            <p class="selectPlanCard--label">{{'advertisement.advertisement-type' |
                                                translate}}</p>
                                            <h5 class="selectPlanCard--value" *ngIf="plan.viewType == 1">
                                                {{'advertisement.image' | translate}}</h5>
                                            <h5 class="selectPlanCard--value" *ngIf="plan.viewType == 2">
                                                {{'advertisement.video' | translate}}</h5>
                                            <button type="button" (click)="toggleActive(plan)"
                                                class="btn btn-outline-theme">
                                                <span>{{'advertisement.choose-plan' | translate}}</span> <i
                                                    class="fas fa-check-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card selectPlanCard" *ngIf="selected">
                                <div class="selectPlanCard__Content text-start">
                                    <div class="row">
                                        <div class="col-12 text-theme-secondary mb-2 font-16 fw-600">
                                            {{selectedData.promotionPlanName}}
                                            {{'advertisement.details' | translate}}
                                        </div>
                                        <div class="col-6 col-sm-auto">
                                            <p class="selectPlanCard--label">{{'advertisement.duration' | translate}}
                                            </p>
                                            <h5 class="selectPlanCard--value">{{selectedData.duration}}</h5>
                                        </div>
                                        <div class="col-6 col-sm">
                                            <p class="selectPlanCard--label">{{'advertisement.location' | translate}}
                                            </p>
                                            <h5 class="selectPlanCard--value">{{selectedData.adLocation}}</h5>
                                        </div>
                                        <div class="col-12">
                                            <p class="selectPlanCard--label">{{'advertisement.details' | translate}}</p>
                                            
                                            <!-- <h5 class="selectPlanCard--value" [innerHtml]="selectedData.details"></h5> -->
                                            <bzg-html2 [data]="selectedData.details"></bzg-html2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-end">
                                <button *ngIf="communityUrl == ''" class="btn btn-outline-danger btn-lg px-sm-3 me-2"
                                    [routerLink]="isTenantAdminPage ? '/admin/advertisement' : isCommunityAdminPage? '/admin/community/'+ communityId + '/advertisement'  : '/advertisement/my-advertisements'">{{'advertisement.cancel' |
                                    translate}}</button>
                                <button *ngIf="communityUrl != ''" class="btn btn-outline-danger btn-lg px-sm-3 me-2"
                                    [routerLink]="communityUrl">{{'advertisement.cancel' | translate}}</button>
                                <button matStepperNext class="btn btn-theme btn-lg px-sm-4"
                                    [disabled]="!HFormGroup1.valid">{{'advertisement.next' | translate}}</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="HFormGroup2" >
                        <form [formGroup]="HFormGroup2" (isValid)="submit($event)" #HFormGroup2E1="ngForm">
                            <ng-template matStepLabel>{{editLable}}</ng-template>

                            <bzg-loading [show]="isFormSaving"
                                [message]="'advertisement.saving' | translate"></bzg-loading>
                                <bzg-loading [show]="isLoading"
                                [message]="'advertisement.loading' | translate"></bzg-loading>
                            <div class="row mt-2">
                                
                                <div class="row align-items-sm-center" *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage)" >
                                    <div class="col">
                                        <h4 class="AMC-title">Update Member Advertisement</h4>
                                    </div>
                                    <div class="col-auto">
                                        <button [routerLink]="isTenantAdminPage ? '/admin/advertisement' : '/admin/community/'+ communityId + '/advertisement'"
                                            class="btn btn-theme text-uppercase" type="button">
                                            <i class="fas fa-chevron-circle-left"></i>
                                            Back To List</button>
                                    </div>
                                </div>

                                <div class="col-md-6" >
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.advertisement-location' | translate}}</mat-label>
                                        <input matInput formControlName="AdvertisementLocation"
                                            [name]="'advertisement.advertisement-location' | translate"
                                            [error]="AdvertisementLocation" required>
                                        <!-- <mat-icon matSuffix>face</mat-icon> -->
                                        <mat-error #AdvertisementLocation></mat-error>  
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.advertisement-type' | translate}}</mat-label>
                                        <mat-select formControlName="viewType" [error]="viewType">
                                            <mat-option value="1">{{'advertisement.image' | translate}}</mat-option>
                                            <mat-option value="2">{{'advertisement.video' | translate}}</mat-option>
                                        </mat-select>
                                        <mat-error #viewType></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12"  [ngClass]="edit && (isTenantAdminPage || isCommunityAdminPage) ? 'col-md-12':'col-md-6'">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.plan-name' | translate}}</mat-label>
                                        <input matInput formControlName="PlanName" [error]="PlanName"
                                            [name]="'advertisement.plan-name' | translate" required>
                                        <!-- <mat-icon matSuffix>face</mat-icon> -->
                                        <mat-error #PlanName></mat-error>
                                    </mat-form-field>
                                </div>
                                <ng-container *ngIf="(edit && (isTenantAdminPage || isCommunityAdminPage))">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label> Duration</mat-label>
                                            <input matInput placeholder=" Plan  Name" formControlName="duration" required value="7 Day(s)" />
                                           
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.advertisement-title' | translate}}</mat-label>
                                        <input matInput formControlName="AdvertisementTitle"
                                            [name]="'advertisement.advertisement-title' | translate" [error]="AdvertisementTitle" required>
                                        <!-- <mat-icon matSuffix>face</mat-icon> -->
                                        <mat-error #AdvertisementTitle></mat-error>
                                    </mat-form-field>
                                </div>
                                <ng-container *ngIf="(!edit && (isTenantAdminPage || isCommunityAdminPage)) || (!isTenantAdminPage && !isCommunityAdminPage)">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label> {{'advertisement.preferred-duration-start-date' |
                                                translate}}</mat-label>
                                            <input matInput [matDatepicker]="picker"   [readonly]="true" (dateInput)="addEvent( $event)" [min]="minDate"
                                                formControlName="startDate" [name]="'advertisement.start-date' | translate" [error]="startDate" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-error #startDate></mat-error>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width " appearance="outline">
                                            <mat-label> {{'advertisement.preferred-duration-end-date' |
                                                translate}}</mat-label>
                                            <input matInput [matDatepicker]="pickerEndDate" [readonly]="true"
                                                formControlName="endDate" [name]="'advertisement.end-date' | translate" [error]="endDate" [disabled]="true">
                                            <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                                            <mat-error #endDate></mat-error>
                                            <mat-datepicker #pickerEndDate></mat-datepicker>
                                        </mat-form-field>

                                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>{{'advertisement.preferred-duration-end-date' |
                                                translate}}</mat-label>
                                            <input matInput formControlName="endDate"
                                                [name]="'advertisement.end-date' | translate" [error]="endDate" required>
                                                                                    
                                            <mat-hint>URL to where member will be redirected when clicking on
                                                advertisement</mat-hint>
                                            <mat-error #endDate></mat-error>
                                        </mat-form-field> -->
                                    </div>
                                </ng-container>
                                        
                                        <ng-container *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage)">
                                            <div class="col-md-12 ">
                                        
                                                <div class="card card-body rounded-2 border">
                                                    <h5 class="fw-500 font-14 mb-2">Validity *:</h5>
                                                    <div class="row">
                                                        <div class="col-lg-4 col-sm-6">
                                                            <mat-form-field class="example-full-width mat-form-control-sm mat-form-control-sm-mb"
                                                                appearance="outline">
                                                                <mat-label>From</mat-label>
                                                                <input matInput [matDatepicker]="startDatePicker" [readonly]="true" formControlName="updateStartDate" [min]="minUpdateStartDate">
                                                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #startDatePicker></mat-datepicker>
                                                            </mat-form-field>
                                        
                                                        </div>
                                                        <div class="col-lg-4 col-sm-6">
                                                            <mat-form-field class="example-full-width mat-form-control-sm mat-form-control-sm-mb"
                                                                appearance="outline">
                                                                <mat-label>To</mat-label>
                                                                <input matInput [matDatepicker]="endDatePicker" [readonly]="true" formControlName="updateEndDate" [min]="minUpdateEndDate" >
                                                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #endDatePicker></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </ng-container>
                                
                                <!-- <div class="col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.preferred-duration-end-date' |
                                            translate}}</mat-label>
                                        <input matInput [matDatepicker]="picker2" formControlName="endDate"
                                            [name]="'advertisement.end-date' | translate" [error]="endDate" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                        <mat-error #endDate></mat-error>
                                    </mat-form-field>

                                </div> -->
                                <!-- <div class="col-md-6" *ngIf="isImage">
                                    <div class="mb-3">
                                        <label for="attachment" class="required">
                                           {{'advertisement.advertisement-image' | translate}} *
                                        </label>
                                        <input id="attachment" [error]="AdvertisementImage" (change)="fileChangeEvent($event)"
                                                            formControlName="AdvertisementImage" [name]="'advertisement.advertisement-image' | translate" type="file"
                                                            class="form-control form-control-lg rounded-3" placeholder="\" />
                                        
                                            <p class="text-danger" #AdvertisementImage></p>
                                    </div>
                                </div> -->
                                <!-- <div *ngIf="isImage">
                                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="3 / 3"
                                    format="png" [roundCropper]="false" [transform]="transform" [allowMoveImage]="true" [cropperMinWidth]="508.115"
                                    [cropperMinHeight]="253.693" [aspectRatio]="4/3"
                                    [cropperMaxWidth]="508.115"
                                    [cropperMaxHeight]="253.693"
                                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                                    (loadImageFailed)="loadImageFailed()"></image-cropper>
                              
                                  <ngx-slider *ngIf="showCropper" [(value)]="value" [options]="options"
                                    (valueChange)="updateSlider($event)"></ngx-slider>
                                </div> -->
                                <div class="col-md-6" *ngIf="isImage">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.advertisement-redirection-url' |
                                            translate}}</mat-label>
                                        <input matInput formControlName="AdvertisementRedirectionURL"
                                            [name]="'advertisement.advertisement-redirection-url' | translate"
                                            [error]="AdvertisementRedirectionURL">

                                        <mat-hint>{{'advertisement.url-to-where-member-will-be-redirected-when-clicking-on-advertisement'
                                            | translate}}</mat-hint>
                                        <mat-error #AdvertisementRedirectionURL></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12" *ngIf="isVideo">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.youtube-video-url' | translate}}</mat-label>
                                        <!-- <textarea matInput formControlName="videoUrl" ></textarea> -->
                                        <input matInput formControlName="videoUrl" (change)="showVideoUrlPreview()"
                                            [name]="'advertisement.video-url' | translate" [error]="videoUrl" required>
                                        <mat-error #videoUrl></mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.short-description' | translate}}</mat-label>
                                        <textarea matInput formControlName="description" rows="1"></textarea>

                                    </mat-form-field>
                                </div>

                                <ng-container *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage) && adLocation == 'GLOBAL MEMBERS'">
                                    <div class="col-6 ">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label> Section</mat-label>
                                            <input matInput  formControlName="section" placeholder="section"
                                                required />
                                        </mat-form-field>
                                    </div>
                                    
                                </ng-container>
                                
                                <div class="col-md-6 mt-2">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>{{'advertisement.additional-instructions' | translate}}</mat-label>
                                        <textarea matInput formControlName="additionalInstructions"></textarea>

                                    </mat-form-field>
                                </div>
                                <div class="col-md-6" *ngIf="isImage">
                                    <div class="mb-3">
                                        <label for="attachment" class="required">
                                            {{'advertisement.advertisement-image' | translate}} *
                                        </label>
                                        <input id="attachment" (change)="fileChangeEvent($event)"
                                            formControlName="AdvertisementImage" type="file"
                                            class="form-control form-control-lg rounded-3"
                                            [placeholder]="'advertisement.placeholder' | translate" />
                                        <!-- <input matInput formControlName="AdvertisementImage" [error]="AdvertisementImage" required> -->
                                        <!-- <mat-icon matSuffix>face</mat-icon> -->
                                        <mat-hint>{{'advertisement.for-optimal-display' | translate}}</mat-hint>
                                        <!--  <mat-error #AdvertisementImage></mat-error> -->
                                        <p class="text-danger">{{errormessage}}</p>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="isImage">
                                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                        [containWithinAspectRatio]="true" [aspectRatio]="16/9" format="png"
                                        [roundCropper]="false" [transform]="transform" [allowMoveImage]="false"
                                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()" [cropperStaticHeight]="250"
                                        [cropperStaticWidth]="500" (loadImageFailed)="loadImageFailed()">
                                    </image-cropper>
                                    <!-- [cropperMinHeight]="250" 
                                        [cropperMinWidth]="500" [cropperMaxWidth]="500" [cropperMaxHeight]="250"<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                        [aspectRatio]="3 / 3" format="png" [roundCropper]="false"
                                        [transform]="transform" [allowMoveImage]="false"
                                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()"></image-cropper> -->
                                    <!-- [cropperMinWidth]="128"
                                  [cropperMinHeight]="128"
                                  [cropperMaxWidth]="128"
                                  [cropperMaxHeight]="128" -->
                                    <ngx-slider *ngIf="showCropper" [(value)]="value" [options]="options"
                                        (valueChange)="updateSlider($event)"></ngx-slider>
                                </div>
                                <div class="col-md-12 text-center" *ngIf="isImage">
                                    <img [src]="img" class="img-fluid" />
                                </div>
                                <div class="col-md-6" *ngIf="isVideo && showVideoPreview && editedVideo!= ''">
                                    <ng-container >
                                        <div class="video-container">
                                          <iframe width="100%" [src]="editedVideo | safe: 'resourceUrl'" title="YouTube video player"
                                            frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen>
                                          </iframe>
                                        </div>
                                      </ng-container>
                                </div>
                                <div class="col-md-12  mb-3" *ngIf="(isCommunityAdminPage || isTenantAdminPage) && edit">
                                    <div class="d-flex align-items-center">
                                        <label class="example-margin fw-500 me-2">Status:</label>
                                        <div class="badge bg-warning text-capitalize" *ngIf="entityStatusId == 2">WAITING FOR APPORVAL</div>
                                        <div class="badge bg-success text-capitalize" *ngIf="entityStatusId == 3">APPROVED</div>
                                        <div class="badge bg-warning text-capitalize" *ngIf="entityStatusId == 4">REJECTED</div>
                                        <div class="badge bg-danger text-capitalize" *ngIf="entityStatusId == 5">EXPIRED</div>
                                        <div class="badge bg-danger text-capitalize" *ngIf="entityStatusId == 7">INACTIVE</div>
                                    </div>
                                </div>
                                <div class="col-md-12  mb-3" *ngIf="(isCommunityAdminPage || isTenantAdminPage) && entityStatusId != 2 && entityStatusId != 4 && entityStatusId != 5 && edit">
                                    <div class="d-flex align-items-center">
                                        <label class="example-margin fw-500"> Current Status:</label>
                                        <!-- <mat-slide-toggle formControlName="currentStatus">Active/Inactive </mat-slide-toggle> -->
                                        <mat-slide-toggle formControlName="currentStatus">{{isActive ? 'Active' :
                                            'InActive'}}</mat-slide-toggle>
                                    </div>
                                </div>
                            </div>

                            <div class="text-start mt-3">
                                <button type="button" *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage)"
                                    class="btn btn-outline-dark btn-lg px-sm-3 me-2" (click)="back()">{{'advertisement.cancel' | translate}}</button>
                                <button type="button" *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage) && entityStatusId == 2"
                                    (click)="rejectAdvertisement()" class="btn btn-outline-danger btn-lg px-sm-3 me-2" [disabled]="!HFormGroup2.get('AdvertisementTitle').value ||isVideo && !HFormGroup2.get('videoUrl').value">Reject</button>
                                <button type="button"
                                    *ngIf="edit && (isTenantAdminPage || isCommunityAdminPage) && (entityStatusId == 2 || entityStatusId == 4)"
                                    (click)="approveAdvertisement()" class="btn btn-theme-secondary btn-lg px-sm-3"  [disabled]="!HFormGroup2.get('AdvertisementTitle').value ||isVideo && !HFormGroup2.get('videoUrl').value">Approve</button>
                                <button type="submit" *ngIf="(isTenantAdminPage || isCommunityAdminPage) && edit && (entityStatusId == 3 || entityStatusId == 7)"
                                    class="btn btn-outline-theme btn-lg px-sm-3">{{'advertisement.save' |
                                    translate}}</button>
                                <!-- [disabled]="!HFormGroup2.valid" -->
                            </div>
                            <div class="text-end mt-3">
                                <button *ngIf="!edit" matStepperPrevious type="button"
                                    class="btn btn-outline-danger btn-lg  me-2 px-sm-3">{{'advertisement.back' |
                                    translate}}</button>
                                <button type="button" *ngIf="edit && !isTenantAdminPage && !isCommunityAdminPage" class="btn btn-outline-danger btn-lg px-sm-3 me-2"
                                    (click)="back()">{{'advertisement.back' | translate}}</button>
                                <button type="submit" *ngIf="(edit && !isTenantAdminPage && !isCommunityAdminPage) || (!edit && (isTenantAdminPage || isCommunityAdminPage)) || (!edit && !isTenantAdminPage && !isCommunityAdminPage)"
                                    class="btn btn-outline-theme btn-lg px-sm-3 me-2">{{'advertisement.save' |
                                    translate}}</button>
                                <button type="button" *ngIf="(edit && !isTenantAdminPage && !isCommunityAdminPage) || (!edit && (isTenantAdminPage || isCommunityAdminPage)) || (!edit && !isTenantAdminPage && !isCommunityAdminPage)" [disabled]="!HFormGroup2.valid" (click)="proceedToPayment()"
                                    class="btn btn-theme btn-lg px-sm-3">{{'advertisement.procees-to-payment' |
                                    translate}}</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{'advertisement.make-payment' | translate}}</ng-template>
                        <!-- <bzg-loading [show]="paymentProcessing" [message]="'communities.processing' | translate"></bzg-loading> -->
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="card makePaymentCard">
                                    <div class="card-body">
                                        <h4 class="fw-500 font-16 mb-3 text-theme-secondary">
                                            {{'advertisement.order-summary' | translate}}</h4>
                                        <div class="row">
                                            <div class="col-md">
                                                <div class="h-100 d-flex flex-column">
                                                    <div class="d-flex border-bottom justify-content-between mb-3">
                                                        <div>
                                                            <p class="makePaymentCard--label">{{'advertisement.plan' |
                                                                translate}}</p>
                                                            <h5 class="makePaymentCard--value">
                                                                {{selectedData?.promotionPlanName}}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <p class="makePaymentCard--label">{{'advertisement.price' |
                                                                translate}}</p>
                                                            <h5 class="makePaymentCard--value text-theme-secondary">
                                                                {{selectedData?.price | currency:
                                                                tenantConfig?.currencyCode}}</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-auto">
                                                        <div class="col-md ">
                                                            <bzg-loading [show]="showPromoCodeLoader"
                                                                [message]="'advertisement.validating' | translate"></bzg-loading>
                                                            <form [formGroup]="discountForm"
                                                                (isValid)="validateDiscount($event)">
                                                                <div class="input-group ">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="discountCoupon"   (input)="keyPress($event)"
                                                                        [placeholder]="'advertisement.enter-promo-code.placeholder' | translate">
                                                                    <button [disabled]="disablePromoApply" *ngIf="!hideDiscountBtn"
                                                                        class="btn btn-outline-secondary"
                                                                        type="submit">{{'advertisement.apply' |
                                                                        translate}}</button>
                                                                </div>
                                                                <p class="alert alert-warning" role="alert"
                                                                    *ngIf="!discountCouponResult.isValid && showPromoCodeError">
                                                                    {{ promoCodeErrMsg }}
                                                                </p>
                                                                <p class="alert alert-success" role="alert"
                                                                    *ngIf="discountCouponResult.isValid">
                                                                    {{'advertisement.promo-code-applied' | translate}}
                                                                </p>
                                                            </form>
                                                        </div>
                                                        <div class="col-md-auto text-end">
                                                            <div>
                                                                <p class="makePaymentCard--label d-inline-block">
                                                                    {{'advertisement.total-amount' | translate}}:
                                                                </p>
                                                                <h5 class="makePaymentCard--value d-inline-block">
                                                                    {{ selectedData?.price | currency:
                                                                    tenantConfig?.currencyCode}}
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <p *ngIf="discountCouponResult.isValid">
                                                                    {{'advertisement.promo-code' | translate}}:
                                                                    <span class="text-uppercase">({{
                                                                        discountCouponResult.discountCoupon }})
                                                                        <button type="button"
                                                                            (click)="removePromoCode()"
                                                                            class="btn btn-danger">
                                                                            X
                                                                        </button></span>:
                                                                    <span>{{ discountPrice | currency:
                                                                        tenantConfig?.currencyCode }}</span>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p class="makePaymentCard--label d-inline-block">
                                                                    {{'advertisement.amount-to-be-paid' | translate}}:
                                                                </p>
                                                                <h5 class="makePaymentCard--value d-inline-block">
                                                                    {{totalAmountToBePaid | currency:
                                                                    tenantConfig?.currencyCode}}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-auto">
                                                <div class="paycard card shadow border"  [ngClass]="freePlanEnable ?'disabledPayCard':''">
                                                    <div class="card-body">
                                                       
                                                        <h4 class="text-theme font-15 fw-500 mb-3">
                                                            {{'advertisement.pay-using' | translate}}</h4>
                                                        <ul class="list-inline makePaymentCard__PaymentMedium">
                                                            <ng-container 
                                                                *ngFor="let payment of paymentMethodList">
                                                                <li *ngIf="payment.paymentMethodID === 1 || payment.paymentMethodID === 2 || payment.paymentMethodID === 8"
                                                                    class="list-inline-item"
                                                                    (click)="selectPaymentGateway(payment.paymentMethodID)">
                                                                    <a href="javascript:void(0)"
                                                                        [ngClass]="selectedPaymentGatewayId == payment.paymentMethodID ?'active':''"><img
                                                                            [src]="payment.imageUrl"
                                                                            class="img-fluid"></a>
                                                                </li>
                                                            </ng-container>
                                                            <!-- <li class="list-inline-item"><a
                                                                    href="javascript:void(0)"><img
                                                                        src="assets/images/choose-plan/anet.png"
                                                                        class="img-fluid"></a></li> -->

                                                        </ul>
                                                    </div>
                                                    <div class="card-footer ">
                                                        <ng-container *ngFor="let gateway of paymentMethodList">
                                                            <div [ngSwitch]="gateway.paymentMethodID">
                                                                <ng-container *ngSwitchCase="1">
                                                                    <ng-container *ngIf="tenantConfig?.currencyCode">
                                                                        <bzg-paypal [config]="gateway"
                                                                            [currency]="tenantConfig?.currencyCode"
                                                                            [hidden]="!enablePaypalPaymentOption"
                                                                            (paymentSuccess)="paymentSuccess($event)"
                                                                            [prePaymentUrl]="'/api/payment/SavePromotionPlanPurchaseInfo'"
                                                                            [prePaymentData]='savePaymentInfoData'
                                                                            [amount]="totalAmountToBePaid">
                                                                        </bzg-paypal>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngSwitchCase="2">
                                                                    <ng-container *ngIf="tenantConfig?.currencyCode && authorizeNetInitialized">
                                                                        <bzg-authorizenet [config]="gateway"
                                                                            [currency]="tenantConfig?.currencyCode"
                                                                            (paymentSuccess)="paymentSuccessAuthorizeNet($event)"
                                                                            [hidden]="!enableAuthorizedNetPaymentOption"
                                                                            [transactionApiUrl]="'/api/payment/AuthorizeNetAcceptPaymentTransactionRequestForPromotionPlan'"
                                                                            [prePaymentUrl]="'/api/payment/SavePromotionPlanPurchaseInfo'"
                                                                            [prePaymentData]='savePaymentInfoData'
                                                                            [amount]="totalAmountToBePaid"
                                                                            [authorizeHeaderTxt]="authorizeHeaderTxt">
                                                                        </bzg-authorizenet>
                                                                    </ng-container>
                                                                </ng-container>

                                                                <ng-container *ngSwitchCase="8">
                                                                    <ng-container
                                                                        *ngIf="tenantConfig?.currencyCode && enablePayUPaymentOption">
                                                                        <bzg-payu [config]="gateway"
                                                                            [currency]="tenantConfig?.currencyCode"
                                                                            [paymentData]="payuPaymentInfo"
                                                                            [prePaymentUrl]="'/api/payment/SavePromotionPlanPurchaseInfo'"
                                                                            [prePaymentData]='savePaymentInfoData'></bzg-payu>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>

                                                    </div>

                                                </div>
                                                <div *ngIf="freePlanEnable">
                                                    <button class=" btn btn-theme "
                                                        (click)="purchaseForFree()">{{'advertisement.submit' |
                                                        translate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-start">
                            <button matStepperPrevious type="button" (click)="paymentSecreenback()"
                                class="btn btn-outline-danger btn-lg  me-2 px-sm-3">{{'advertisement.back' |
                                translate}}</button>

                        </div>
                        <!-- <div>
                            <button mat-raised-button matStepperPrevious color="warn" class="msr-2">Back</button>
                            <button mat-raised-button color="primary" (click)="stepper.reset()">Reset</button>
                        </div> -->
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
</section>

