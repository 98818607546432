<!-- <img *ngFor="let image of bannerImages" [src]="image.logoFileName" [alt]="image.name" [title]="image.name"> -->
<h4 class="text-center" *ngIf="isLoading"> <i class="fa fa-spinner fa-spin"></i> Loading Banner Images...</h4>
<p class="text-center" *ngIf="hasError">Unable to load banner images...</p>
<div [id]="id" class="carousel slide mb-6" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button *ngFor="let image of bannerImages;let i=index" type="button" [attr.data-bs-target]="'#'+id"
            [attr.data-bs-slide-to]="i" [ngClass]="i==0?'active':''" [attr.aria-current]="i==0?'true':''"
            [attr.aria-label]="'Slide ' + i">
        </button>
    </div>
    <div class="carousel-inner bg-theme">
        <div class="carousel-item" *ngFor="let image of bannerImages;let i=index;let last = last;"
            [ngClass]="i==0?'active':''">
            <img class="img-fluid" [src]="image.logoFileName" [alt]="image.name" [title]="image.name">

            <!-- <div class="container">
                <div class="carousel-caption">
                    <h1>{{image.name}}</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p>
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>
                </div>
            </div> -->
            <span *ngIf="last">{{init()}}</span>
        </div>
    </div>
    <button class="carousel-control-prev" type="button" [attr.data-bs-target]="'#'+id" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button #nextButton class="carousel-control-next" type="button" [attr.data-bs-target]="'#'+id" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>