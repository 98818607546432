import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/services/common/common.service';
import { CustomValidatorService } from 'src/app/services/validation/custom-validator.service';

@Component({
  selector: 'bzg-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  
  addNewsLetterForm: FormGroup;
  emailId: string;
 // @ViewChild("addNewsLetterFormE1") addNewsLetterFormE1: NgForm;


  constructor(
    private fb: FormBuilder,
    private customValidator: CustomValidatorService,
    private service: CommonService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    const remoteEmailValidationUrl = `/api/common/CheckforDuplications?keyName=subscribe&Value={0}`;
    

    this.addNewsLetterForm = this.fb.group({
      email:['',
      [Validators.required, this.customValidator.email],
      [this.customValidator.isDuplicate(remoteEmailValidationUrl, 'Address already exists')]
    ]
    })
  }

  onSubmitEmail() {

    if(this.addNewsLetterForm.valid){
      this.emailId = this.addNewsLetterForm.get('email').value;
      //console.log('show', this.emailId);
      this.service.saveNewsLetter(this.emailId).subscribe({
        next: (resp: any) => {
          if (resp) {

          this.matSnackBar.open('Email subscribed Successfully', '', { duration: 5000, panelClass: 'bg-success' });
          //this.addNewsLetterForm.get('email').setValue('');
          //this.addNewsLetterForm.markAsPristine();
          //this.addNewsLetterForm.markAsUntouched();
          this.addNewsLetterForm.reset({}, { emitEvent:false});
         } },
        error: (err) => {
          this.matSnackBar.open('something went wrong', '', { duration: 5000, panelClass: 'bg-danger' });
        }
      })
    }
    
  }
 

}
