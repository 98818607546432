import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { GroupDiscussionDetails } from 'src/app/module/group-details/store/state/group-discussion';
import { discussionData, like } from 'src/app/module/shared/discussions/discussions-list/discussions-list.component';
import { CommunityDiscussionComments, CommunityDiscussionCommentsResult, CommunityDiscussionCommentsSearch, CommunityDiscussionDetails, CommunityDiscussionDetailsInfo, CommunityDiscussionLikesSearch, CommunityDiscussionSearch, CommunityDiscussionsLikes, MostSubscribedDiscussions } from '../state/community-discussions';

export const LoadCommunityDiscussionsData = createAction('[Community Discussions Data] Load Community Discussions Data', (query: CommunityDiscussionSearch) => ({ query }));
export const LoadCommunityDiscussionsSuccess = createAction('[Community Discussions Data] Load Community Discussions Data success', props<{ discussions: CommunityDiscussionDetailsInfo }>());
export const LoadCommunityDiscussionsError = createAction('[Community Discussions Data] Load Community Discussions Data error', props<{ error: HttpErrorResponse }>());


export const LoadMoreCommunityDiscussionsData = createAction('[Community Discussions Data] Load more Community Discussions Data', (query: CommunityDiscussionSearch) => ({ query }));
export const LoadMoreCommunityDiscussionsSuccess = createAction('[Community Discussions Data] Load more  Community Discussions Data success', props<{ discussions: CommunityDiscussionDetailsInfo }>());
export const LoadMoreCommunityDiscussionsError = createAction('[Community Discussions Data] Load more Community Discussions Data error', props<{ error: HttpErrorResponse }>());

export const deleteCommunityDiscusion = createAction('[Delete Community Discussion] delete community Discussion', props<{data:CommunityDiscussionDetails}>())
export const deleteCommunityDiscusionComment = createAction('[Delete Community Discussion Comment] delete community Discussion Comment', props<{data:CommunityDiscussionComments}>())

export const LikeOrUnlikeDiscussion = createAction('[Community Discussions] Like or unlike community discussion', props<{ data: like }>());

export const LikeOrUnlikeDiscussionComment = createAction('[Community Discussions Comment] Like or unlike community discussion comment', props<{ data: like }>());

export const SubscribeorUnsubscribeDiscussion =  createAction('[Community Discussions Subscription] subscribe or unsubscribe community discussion comment', props<{ data: discussionData }>());

export const LoadCommunityDiscussionsCommentsData = createAction('[Community Discussions Comments Data] Load Community Discussions Comments Data', (query: CommunityDiscussionCommentsSearch) => ({ query }));
export const LoadCommunityDiscussionsCommentsSuccess = createAction('[Community Discussions Comments Data] Load Community Discussions Data Comments success', props<{ discussionComments: CommunityDiscussionCommentsResult, query: CommunityDiscussionCommentsSearch }>());
export const LoadCommunityDiscussionsCommentsError = createAction('[Community Discussions Comments Data] Load Community Discussions Data Comments error', props<{ error: HttpErrorResponse, query: CommunityDiscussionCommentsSearch }>());

export const LoadMoreCommunityDiscussionsCommentsData = createAction('[More Community Discussions Comments] Load More results Community Discussions Comments', (query: CommunityDiscussionCommentsSearch) => ({ query }));
export const LoadMoreCommunityDiscussionsCommentsSuccess = createAction('[More Community Discussions Comments] Load More results Community Discussions Comments  success', props<{ discussionComments: CommunityDiscussionCommentsResult, query: CommunityDiscussionCommentsSearch }>());
export const LoadMoreCommunityDiscussionsCommentsError = createAction('[More Community Discussions Comments] Load More results Search Community Discussions Comments  error', props<{ error: HttpErrorResponse, query: CommunityDiscussionCommentsSearch }>());

export const updateCommentsCount = createAction('[update Comment Count] Update comment count',(query:number)=>({query}));

export const updateDeletedCommentsCount = createAction('[update deleted Comment Count] Update deleted comment count',(query:number)=>({query}));
export const LoadCommunityDiscussionsLikesData = createAction('[Community Discussions Likes Data] Load Community Discussions Likes Data', (query: CommunityDiscussionLikesSearch) => ({ query }));
export const LoadCommunityDiscussionsLikesSuccess = createAction('[Community Discussions Likes Data] Load Community Discussions Data Likes success', props<{ discussionLikes: CommunityDiscussionsLikes[], query: CommunityDiscussionLikesSearch }>());
export const LoadCommunityDiscussionsLikesError = createAction('[Community Discussions Likes Data] Load Community Discussions Data Likes error', props<{ error: HttpErrorResponse, query: CommunityDiscussionLikesSearch }>());

export const LoadCommunityDiscussionsCommentLikesData = createAction('[Community Discussions Comment Likes Data] Load Community Discussions Comment Likes Data', (query: CommunityDiscussionLikesSearch) => ({ query }));
export const LoadCommunityDiscussionsCommentLikesSuccess = createAction('[Community Discussions Comment Likes Data] Load Community Discussions Comment Likes success', props<{ discussionLikes: CommunityDiscussionsLikes[], query: CommunityDiscussionLikesSearch }>());
export const LoadCommunityDiscussionsCommentLikesError = createAction('[Community Discussions Comment Likes Data] Load Community Discussions Comment Likes error', props<{ error: HttpErrorResponse, query: CommunityDiscussionLikesSearch }>());


// export const LoadCommunityDiscussionsCommentsData = createAction('[Community Discussions Comments Data] Load Community Discussions Comments Data', (query: CommunityDiscussionCommentsSearch) => ({ query }));
// export const LoadCommunityDiscussionsCommentsSuccess = createAction('[Community Discussions Comments Data] Load Community Discussions Data Comments success', props<{ discussionComments: CommunityDiscussionComments[] }>());
// export const LoadCommunityDiscussionsCommentsError = createAction('[Community Discussions Comments Data] Load Community Discussions Data Comments error', props<{ error: HttpErrorResponse }>());

export const LoadPopularCommunityDiscussionsData = createAction('[Popular Community Discussions Data] Load Popular Community Discussions  Data', (query: number) => ({ query }));
export const LoadPopularCommunityDiscussionsSuccess = createAction('[Popular Community Discussions  Data] Load Popular Community Discussions Data success', props<{ PopularDiscussion: MostSubscribedDiscussions[] }>());
export const LoadPopularCommunityDiscussionsError = createAction('[Popular Community Discussions Data] Load Popular Community Discussions Data error', props<{ error: HttpErrorResponse }>());

export const LoadUserSubscribedCommunityDiscussionsData = createAction('[User Subscribed Community Discussions Data] Load User Subscribed Community Discussions  Data', (query: number) => ({ query }));
export const LoadUserSubscribedCommunityDiscussionsSuccess = createAction('[User Subscribed Community Discussions  Data] Load User Subscribed Community Discussions Data success', props<{ userSubscribedDiscussion: MostSubscribedDiscussions[] }>());
export const LoadUserSubscribedCommunityDiscussionsError = createAction('[User Subscribed Community Discussions Data] Load User Subscribed Community Discussions Data error', props<{ error: HttpErrorResponse }>());

export const MostSubscribedCommunityDiscussionsData = createAction('[Most Subscribed Community Discussions Data] Load Most Subscribed Community Discussions  Data', (query: number) => ({ query }));
export const MostSubscribedCommunityDiscussionsSuccess = createAction('[Most Subscribed Community Discussions  Data] Load Most Subscribed Community Discussions Data success', props<{ MostSubscribedDiscussion: MostSubscribedDiscussions[] }>());
export const MostSubscribedCommunityDiscussionsError = createAction('[Most Subscribed Community Discussions Data] Load Most Subscribed Community Discussions Data error', props<{ error: HttpErrorResponse }>());
