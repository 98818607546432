<div class="row">

  <div class="col-xl-8 col-md-10 mx-auto">
    {{title}}
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
      format="png" [roundCropper]="roundCropper" [transform]="transform" [allowMoveImage]="true"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()" [cropperMinWidth]="minWidth" [cropperMinHeight]="minHeight"
      [cropperMaxWidth]="maxWidth" [cropperMaxHeight]="maxLength"></image-cropper>
    <!-- [cropperMinWidth]="128"
    [cropperMinHeight]="128"
    [cropperMaxWidth]="128"
    [cropperMaxHeight]="128" -->
    <ngx-slider *ngIf="showCropper" [(value)]="value" [options]="options"
      (valueChange)="updateSlider($event)"></ngx-slider>
    <!-- <button *ngIf = "showCropper" (click)="zoomOut()">Zoom -</button>
 <button *ngIf = "showCropper" (click)="zoomIn()">Zoom +</button> -->
    <br>

    <form [formGroup]="imageform">
      <br>

      <input class="file-input form-control" autocomplete="off" formControlName="uploadfile" type="file"
        (change)="fileChangeEvent($event)" />
      <br />
      <span class="emsg">{{errormessage}}</span>

      <div class="body mt-3" style="text-align: center;">
        <div class="example-button-row">

          <button mat-raised-button type="button" [disabled]="croppedImage == ''?true:false"
            [mat-dialog-close]="croppedImage" color="primary">Submit</button>
          <button mat-raised-button type="button" (click)="onNoClick()">Cancel</button>

        </div>
      </div>
    </form>
    <!-- <button  [mat-dialog-close]="croppedImage">Submit</button>
 <button (click)="onNoClick()">Cancel</button> -->


    <!-- <img [src]="croppedImage" /> -->
  </div>
</div>